const createOption = (id, value, dis) => {
    return { "id": id, "value": value, "dis": dis };
}

export const GenderList = [
    createOption(1, "MALE", "Male"),
    createOption(2, "FEMALE", "Female")];

export const MaritalList = [
    createOption(1, "SINGLE", "Single"),
    createOption(2, "MARRIED", "Married")];

export const EmployeeRoles = [
    createOption(1, "ADMIN", "Admin"),
    createOption(3, "MANAGER", "Manager"),
    createOption(4, "STAFF", "Staff")];

export const headCounts = [
    createOption(1, "1-10"),
    createOption(2, "11-50"),
    createOption(3, "51-200"),
    createOption(4, "201-500"),
    createOption(5, "501-1000"),
    createOption(6, "1001-2000")];


export const GoalLevels = [
    createOption(1, "INDIVIDUAL", "Personal"),
    createOption(2, "ORG", "Organization"),
    createOption(3, "TEAM", "Team")];

export const GoalProgressUpdateType = [
    createOption(1, "AUTOMATIC", "Automatic"),
    createOption(2, "MANUAL", "Manual"),];

export const ProgressType = [
    createOption(1, "PERCENTAGE", "percent"),
    createOption(2, "NUMBER", "number"),
    createOption(3, "DOLLAR", "dollar"),
    createOption(4, "BOOLEAN", "boolean"),
    createOption(5, "INCREASE_KPI", "increase_kpi"),
    createOption(5, "DECREASE_KPI", "decrease_kpi")
];

export const ProgressTypeForManual = [
    createOption(1, "PERCENTAGE", "percent"),
    createOption(2, "NUMBER", "number"),
    createOption(3, "DOLLAR", "dollar"),
    createOption(4, "BOOLEAN", "boolean")
];

export const GoalCycles = [
    createOption(1, "QUARTERLY", "Quarterly"),
    createOption(2, "MONTHLY", "Monthly"),
    createOption(3, "YEARLY", "Yearly")
];

export const GoalCycle = [
    createOption(1, "MONTHLY", "Monthly"),
    createOption(2, "QUARTERLY", "Quarterly"),
    createOption(3, "HALF_YEARLY", "HalfYearly"),
    createOption(4, "ANNUALLY", "Annually"),
    createOption(5, "CUSTOM", "Custom")
]

export const PrivacyType = [
    createOption(1, "PUBLIC", "Public"),
    createOption(2, "PRIVATE", "Private")
];


export const GoalLevelList = ["INDIVIDUAL", "ORG", "TEAM"];
export const VISIBILITY = ["Public", "Private"];