import React, { useState, useEffect } from 'react'
import { Button } from "@mui/material";
import SyncLockIcon from '@mui/icons-material/SyncLock';
import './UserDetails.css'
import { useNavigate } from 'react-router-dom';
import AvatarBuilder  from 'utils/AvatarBuilder';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { showDemoData } from 'Services/OrgService';
import { ToastError, ToastSuccess } from "utils/Toast";
import { styled } from '@mui/material/styles';
import myAcountIcon from "../assets/images/myAccount.svg"
import Integration from "../assets/images/NotStartedGoals.svg"
import LogoutIcon from "../assets/images/Logout.svg"

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#EF6D00',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 150,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));


const UserDetails = ({ ...others }) => {

  const navigate = useNavigate();
  
    const handleLogout = async () => {
      localStorage.removeItem('token');
      localStorage.removeItem('userRole', 'ADMIN');
      localStorage.removeItem('orgId');
      localStorage.removeItem('employeeId');
      localStorage.removeItem('showDemo');
      localStorage.removeItem('allowedFeatures');
      redirectToLogin();
  };
  
  const redirectToLogin = () => {
      navigate("/login");
     }

  const redirectToMyAccount = () => {
    navigate("/setting/yourAccount");
  }

  const redirectToIntegration = () => {
    navigate("/setting/integration");
  }

     const [showDemo, setShowDemo] = useState(false);
     const [checked, setChecked] = useState(true);
   
     const handleChange = (event) => {
       const newChecked = event.target.checked;
       showDemoData(newChecked).then(() => {
        newChecked ? ToastSuccess({text:"Succesfully Enabled Demo Data"}) : ToastSuccess({text:"Succesfully Disabled Demo Data"})
        setChecked(newChecked);
        localStorage.setItem('showDemo', newChecked);
       }).catch(() =>{
        ToastError({text:"Error while setting demo data"});
       })
     }
   
     useEffect(() => {
       const storedValue = localStorage.getItem('showDemo');
       if (storedValue) {
         setChecked(storedValue === 'true'); 
         setShowDemo(storedValue === 'true');
       }
     }, []);
    
     return (
      <>
      <div className='userdetails'>
          <div className='avtarcontainer'>
          <AvatarBuilder employee={ {'name' : others.userDetails.name } } variant="circular" size="small" />
          <div className='avtarcontent'>
          <h3>{others.userDetails.name}</h3>
          <h5>{others.userDetails.email}</h5>
          </div>
          </div>
          {localStorage.getItem("userRole") === "OWNER" || localStorage.getItem("userRole") === "ADMIN" ? (
            <>
          <FormControlLabel
          className='showDemoSwitch'
              control={<AntSwitch
                checked={checked}
                onChange={handleChange}
                color="primary" />}
              label="Show Demo Data"
              labelPlacement="end"
            />
          </>
          ) : null}
          <div className='dashedLine'></div>
          <div className='myAccount' onClick={redirectToMyAccount}>
            <img src={myAcountIcon} />
            <h1>My Account</h1>
          </div>
          <div className='integration' onClick={redirectToIntegration}>
            <img src={Integration} />
            <h1>Integration</h1>
          </div>
          <div className='dashedLine'></div>
          <div onClick={handleLogout} className='logout'>
          <img src={LogoutIcon} />
            <h1>Logout</h1>
          </div>
        </div>
       </>
      )

}


export default UserDetails;
