import React, { useEffect, useState } from 'react';
import "./Billing.css";
import { Button, Grid } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import Modal from '@mui/material/Modal';
import BillingDetails from './BillingDetails';
import { getSubscriptionDetails } from 'Services/PaymentService';

export default function Billing() {
  const plans = [
    {
      name: "Free",
      features: [
        { question: "OKR Management", answer: "Streamline your organizational goal-setting with data-driven insights and streamlined management." },
        { question: "Employee & Team Management", answer: "Easily manage teams, assign objectives, and ensure transparency and accountability." },
        { question: "KeyResult Type", answer: "Customise your key results with a range of types, from numeric to binary." },
        { question: "OKR Cloning & Carry Forward", answer: " Save time and ensure consistency by cloning and carrying forward objectives and key results." },
        { question: "Shared Owners & Collaborators", answer: "Foster collaboration and teamwork by assigning shared owners and collaborators to objectives and key results." },
        { question: "Transparency & Accountability", answer: "Encourage transparency and accountability with visibility into objectives, key results, and progress." },
        { question: "Team Progress Dashboard", answer: "Monitor progress and track metrics with a customisable team progress dashboard" },
      ]
    },
    {
      name: "Basic",
      features: [
        { question: "All Free Plan Feature", answer: "Upgrade to our Basic plan while still enjoying all the essential features included in our Free plan" },
        { question: "Activity History", answer: "Effortlessly stay informed and up-to-date on changes made to your objectives and key results with our Activity History feature." },
        { question: "and Many More Features", answer: "Checkout our roadmap for Future updates." }
      ]
    },
    {
      name: "Advance",
      features: [
        { question: "All Basic Plan Feature", answer: "Upgrade to our Advanced plan and unlock additional premium tools and benefits while still enjoying all the essential features included in our basic plan" },
        { question: "Activity Insights", answer: "Gain greater visibility and insights into the progress of your Objectives and Key Results" },
        { question: "and Many More Features", answer: "Checkout our roadmap for Future updates." }
      ]
    }
  ];

  const [expanded, setExpanded] = useState([]);
  const [loaded, setLoaded] = useState([]);

  const handleTreeItemToggle = (event, nodes) => {
    setExpanded(nodes);
  };

  const handleClickSwitch = (event, nodes) => {

  }
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentPlanDetails, setCurrentPlanDetails] = useState([]);

  const getCurrentPlan = () =>{
    getSubscriptionDetails().then((res) => {
      let results = res.data;
      if(results) {
        console.log(results);
        setOpen(false);
        setCurrentPlanDetails(results);
        return;
      }
    })
  }

  useEffect(()=>{
    getCurrentPlan();
  }, [loaded])

  return (
    <div className='billingContainer'>
      <div className='billingHeadings'>
        <h1> Choose The Right <br/> Plan For Your Company </h1>
      </div>
      <div className='billingContent'>
        {plans.map((plan, index) => (
          <div className='billingPlans' key={index}>
            <h1>{plan.name}</h1>
            <TreeView
              aria-label={`goals-${index}`}
              defaultCollapseIcon={<KeyboardArrowUpIcon fontSize="large"/>}
              defaultExpandIcon={<KeyboardArrowDownIcon fontSize="large"/>}
              expanded={expanded}
              onNodeToggle={handleTreeItemToggle}
            >
                {plan.features.map((feature, featureIndex) => (
                  <TreeItem
                    key={featureIndex}
                    nodeId={`featureToken-${index}-${featureIndex}`}
                    label={
                      <div className="featureDropdown">
                        <h2>{feature.question}</h2>
                      </div>
                    }
                    className="planTreeView"
                  >
                    <Grid container className="billingPlanItem">
                      <h3>{feature.answer}</h3>
                    </Grid>
                  </TreeItem>
                ))}
            </TreeView>
            <Button 
              id={plan.name == "Basic" ? "basic-plan-monthly-USD-Yearly":"advance-plan-USD-Yearly"} 
              className={plan.name == "Free" ? "PrimaryButton" : "SecondaryButton"}
              onClick={function(event){ handleOpen()}}>
                {plan.name == "Free" ? <p>Click to Start</p> : <p>Comming Soon</p>}
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
            >
              <BillingDetails billingDetailsClose={setLoaded} currentPlanDetails={currentPlanDetails}/>
            </Modal>
          </div>
        ))}
      </div>
    </div>
  )
}
