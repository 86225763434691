import React, { useEffect, useState } from "react";
import "./Home.css";
import 'react-dropdown/style.css';
import Personal from './Personal';
import DateNavigator from "utils/DateNavigator";
import { getCurrentQuarterRange } from "Constants/DateConstants";
import UpCommingFeatures from "Components/UpCommingFeatures";
import { useNavigate } from "react-router-dom";
import { checkLogin } from 'Services/AuthService';
import personalSVG from "../../assets/images/user.svg"
import teamSVG from "../../assets/images/users.svg"
import companySVG from "../../assets/images/fi-rr-briefcase.svg"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getEmployee, getTeamsByRole } from "Services/OrgService";
import Team from "./Team";
import Organisation from "./Organisation";
import refreshFeatures from 'utils/PaymentUtils';
import { Popover } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const Home = () => {

  const navigate = useNavigate();

  const [state, setState]= useState('personal');
  const rangeStartDate = getCurrentQuarterRange()[0]
  const rangeEndDate = getCurrentQuarterRange()[1]
  const [fromDate, setFromDate] = useState(rangeStartDate);
  const [toDate, setToDate] = useState(rangeEndDate);
  const [showHome, setShowHome] = useState(false);
  const [isUserAvailable , setIsUserAvailable] = useState(false);

  const [teamList, setTeamList] = React.useState([]);

  const role = localStorage.getItem('userRole')
  const [selectedTeamId, setSelectedTeamId] = useState("");

  const getFromDate = (date) =>{
    setFromDate(date);
  }
  const getToDate = (date) =>{
    setToDate(date);
  }

  const redirectToSignUpDetails = () => {
    navigate("/signup-details");
  }

  const redirectToLogin = () => {
    navigate("/login");
  }


  const checkLoginFn = () => {
    checkLogin().then((res) => {
      setIsUserAvailable(true);
      let results = res.data;
      if (results != undefined && results.status == "SUCCESS") {
        if (results.empId == 0 || results.orgId == 0) {
          redirectToSignUpDetails();
          setShowHome(true);
        } else {
          localStorage.setItem('showDemo', results.isDemoEnabled);
          setShowHome(true);

        }
        return;
      }
    }).catch(() => {
      redirectToLogin();
    });
  }

    useEffect(() => {
      checkLoginFn();
      if(isUserAvailable){
        refreshFeatures();
      }
  }, []);



  const loadTeam = () => {
    getTeamsByRole().then((res) => {
        let allTeams = res.data;
        if (allTeams) {
            setTeamList(allTeams);
            loadEmp();
        }
    })
}



const loadEmp = () =>{
  getEmployee().then((res) =>{
    let employeeDetails = res.data;
    setSelectedTeamId(employeeDetails.teamId);
    setState("team");
  })
}

  const onChangeTeam = (value) => {
    setSelectedTeamId(value);
}

const changeState = (value) => {

  if(value=="team"){
    // setting team state after fetching team
      loadTeam();
  }else{
    setState(value);
  }
  
}


useEffect(() => {

}, [(state =="team")]);

const loginAgain = localStorage.getItem("loginAgain");

const [travelState , setTravelState] = useState(loginAgain);

const handleClose = () => {
  setTravelState(false);
  localStorage.removeItem("loginAgain");
};

  return (
    <div className="Home">
      <div>
        { showHome ? 
          <>
            <div className="homeTabs">
                <div className="tabButtons">
                <button onClick={()=>changeState('personal')} className='btn-personal' style={{backgroundColor:state==="personal" ? 'var(--primary-color)':'var(--white-color)' , color :state==="personal" ? 'var(--white-color)':'var(--text-color)' }}>
                  <img src={personalSVG} 
                  alt="Personal Icon" 
                  style={{
                    filter: state === "personal" ? "invert(0)" : "invert(1)",
                  }}
                  />
                  Personal
                  </button>
                <button onClick={()=>changeState('team')} className='btn-personal' style={{backgroundColor:state==="team" ? 'var(--primary-color)':'var(--white-color)' , color :state==="team" ? 'var(--white-color)':'var(--text-color)' }}>
                <img src={teamSVG} 
                  alt="Team Icon" 
                  style={{
                    filter: state === "team" ? "invert(0)" : "invert(1)",
                  }}
                  />
                  Team</button>
                <button onClick={()=>changeState('company')} className='btn-personal' style={{backgroundColor:state==="company" ? 'var(--primary-color)':'var(--white-color)' , color :state==="company" ? 'var(--white-color)':'var(--text-color)' }}>
                <img src={companySVG} 
                  alt="Company Icon" 
                  style={{
                    filter: state === "company" ? "invert(0)" : "invert(1)",
                  }}
                  />
                  Company
                </button>
                </div>
                {(state==="team" && (role=="MANAGER" || role == "OWNER")) ? (
                    <div className="autocompleteContainer">
                    <Autocomplete
                      options={teamList}
                      name="teamId"
                      size="small"
                      className="teamSelected"
                      value={
                        teamList.find((team) => team.id === selectedTeamId) || null
                      }
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        if (value != null) {
                          onChangeTeam(value.id);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Team"
                          className="autocompleteInput"
                        />
                      )}
                    />
                  </div>
                ) : null}
                <div className="dateNavigatorContainer">
                <DateNavigator fromDate={getFromDate} toDate={getToDate} />
                </div>
            </div>
            {state==="personal"?(<Personal params={"INDIVIDUAL"} fromDate={fromDate} toDate={toDate}/>):null}
            {state==="team"?(<Team params={"TEAM"} fromDate={fromDate} toDate={toDate} selectedTeamId={selectedTeamId}/>):null}
            {state==="company"?(<Organisation params={"ORG"} fromDate={fromDate} toDate={toDate} selectedTeamId={selectedTeamId}/>):null}
            </> : null }
      </div>

      <Popover
          open={travelState}
          onClose={handleClose}
          className="travelPopup"
        >
          <CloseIcon onClick={handleClose} className="crossIcon"/>
          <h2 className="travelContent"> We have added sample data for you, So that you can experience the product well . You can disable/Enable Sample data from profile section</h2>
        </Popover>

    </div>
  );
};

export default Home;