import React,{useState} from 'react';
import Button from '@mui/material/Button';
import Forms from './Forms'
import './Form.css';
import UpCommingFeatures from "Components/UpCommingFeatures";
import ManageEmployee from './ManageEmployee';
import GeneralSetting from './GeneralSetting';
import ManageTeam from './ManageTeam';
import Integration from './Integration';
import Billiy from './Billing';
import Identity from './Identity';
import Billing from './Billing';
import { useEffect } from 'react';
import RoleBasedAccess from 'utils/RoleBasedAccess';

export default function Grid(props) {
  const [state, setState]= useState(props.tabName);

  useEffect(() => {
    setState(props.tabName);
  }, [props.tabName]);

  const changeTabs = (tabs) =>{
    setState(tabs)
  }

  return (
    <div className='setting'>
      <div className='buttons'>
      <Button onClick={()=>setState('yourAccount')} className='Multigrid-buttons' style={{backgroundColor:state==="yourAccount" ? 'var(--deep-grey-color)':'var(--light-grey-color)' }}>My Account</Button><hr/>
      {
        RoleBasedAccess() ?
        <>
        <Button onClick={()=>setState('generalSetting')} className='Multigrid-buttons' style={{backgroundColor:state==="generalSetting" ? 'var(--deep-grey-color)':'var(--light-grey-color)' }}>Company Settings</Button><hr/>
        <Button onClick={()=>setState('manageEmployee')} className='Multigrid-buttons' style={{backgroundColor:state==="manageEmployee" ? 'var(--deep-grey-color)':'var(--light-grey-color)' }}>Manage Employee</Button><hr/>
        <Button onClick={()=>setState('manageTeam')} className='Multigrid-buttons' style={{backgroundColor:state==="manageTeam" ? 'var(--deep-grey-color)':'var(--light-grey-color)' }}>Manage Team</Button><hr/>
        <Button onClick={()=>setState('integration')} className='Multigrid-buttons' style={{backgroundColor:state==="integration" ? 'var(--deep-grey-color)':'var(--light-grey-color)' }}>Integrations</Button><hr/>
        <Button onClick={()=>setState('billing')} className='Multigrid-buttons' style={{backgroundColor:state==="billing" ? 'var(--deep-grey-color)':'var(--light-grey-color)' }}>Billing</Button><hr/>
        <Button onClick={()=>setState('identity')} className='Multigrid-buttons' style={{backgroundColor:state==="identity" ? 'var(--deep-grey-color)':'var(--light-grey-color)' }}>Identity</Button><hr/>
        </> : null
      }
      </div>
      
      {state==="yourAccount"?(<GeneralSetting/>):null}
      {
        RoleBasedAccess() ?
        <>
        {state==="generalSetting"?(<Forms/>):null}
        {state==="manageEmployee"?(<ManageEmployee changeTabs ={changeTabs}/>):null}
        {state==="manageTeam"?(<ManageTeam/>):null}
        {state==="integration"?(<Integration pageName="Integration..."/>):null}
        {state==="billing"?(<Billing />):null}
        {state==="identity"?(<UpCommingFeatures pageName="Identity..."/>):null}
        </> : null
      }
    </div>
  )
}
