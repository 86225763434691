import { googleSheetIntegration } from 'Services/AuthService';
import React, {useEffect} from 'react'
import { useLocation } from "react-router-dom";
import { ToastError, ToastSuccess } from './Toast';
import { useNavigate } from "react-router-dom";


function GoogleSheetsIntegration() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    if(queryParams.has("error")){
      navigate('/setting/integration');
    }
  
    useEffect(() => {
        const handleIntegration = async () => {
          try {
            await googleSheetIntegration({
              code: code,
              state: state,
            }).then((res)=>{
              if(res.data == "SUCCESS"){
                ToastSuccess({ text: 'Google Sheets Integration Done Successfully..' });
              }else{
                ToastSuccess({ text: "Please try again after some time" });
              }
            })
    
          } catch (error) {
            ToastError({ text: 'Failed to integrate google sheet.. Please try again later' });
          } finally {
            navigate('/setting/integration');
          }
        };
    
        if (code && state) {
          handleIntegration();
        }
      }, [code, state]);
    


  return (
    <>
    </>
  )
}

export default GoogleSheetsIntegration