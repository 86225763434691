import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { getCurrentQuarterRange } from 'Constants/DateConstants';
import { DateRangePicker } from 'Date-Range-Picker/DateRangePicker';
import { SingleInputDateRangeField } from 'Date-Range-Picker/SingleInputDateRangeField';
import "./DateNavigator.css";

export default function DateNavigator({ fromDate, toDate, goalCycle, disableButton }) {
  const [startDate, setStartDate] = React.useState(getCurrentQuarterRange()[0]);
  const [endDate, setEndDate] = React.useState(getCurrentQuarterRange()[1]);

  React.useEffect(() => {
    updateDateRange();
  }, [goalCycle]);

  const updateDateRange = () => {
    if(goalCycle === 'MONTHLY'){
      const today = dayjs();
      const startDate = today.startOf('month');
      const endDate = today.endOf('month');
      setStartDate(startDate);
      setEndDate(endDate);
      fromDate(startDate);
      toDate(endDate);
    }
    else if (goalCycle === 'QUARTERLY') {
      const quarterRange = getCurrentQuarterRange();
      setStartDate(quarterRange[0]);
      setEndDate(quarterRange[1]);
      fromDate(quarterRange[0]);
      toDate(quarterRange[1]);
    } 
    else if (goalCycle === 'HALF_YEARLY') {
      const today = dayjs();
      let startDate;
      let endDate;
      if (today.month() < 6) {
        startDate = dayjs().startOf('year').month(0);
        endDate = dayjs().startOf('year').month(5).endOf('month');
      } else {
        startDate = dayjs().startOf('year').month(6);
        endDate = dayjs().startOf('year').month(11).endOf('month');
      }
      setStartDate(startDate);
      setEndDate(endDate);
      fromDate(startDate);
      toDate(endDate);
    } 
    else if (goalCycle === 'ANNUALLY') {
      const today = dayjs();
      let startDate = today.startOf('year');
      let endDate = today.endOf('year');
      setStartDate(startDate);
      setEndDate(endDate);
      fromDate(startDate);
      toDate(endDate);
    }
  };

  const handleLeftClick = () => {
    let diff = 3;
    if(goalCycle === 'MONTHLY'){
      diff=1;
    } else if (goalCycle === 'QUARTERLY') {
      diff = 3;
    } else if (goalCycle === 'HALF_YEARLY') {
      diff = 6;
    } else if(goalCycle == 'ANNUALLY'){
      diff = 12;
    }
    const previousStartDate = startDate.subtract(diff, 'month').startOf('month');
    const previousEndDate = endDate.subtract(diff, 'month').endOf('month');
  
    const currentDate = dayjs();
    const isPreviousYearsReached = disableButton ? previousStartDate.isBefore(currentDate, 'month') : previousStartDate.year() < dayjs().subtract(5, 'year').year() ;
  
    if (!isPreviousYearsReached) {
      setStartDate(previousStartDate);
      setEndDate(previousEndDate);
      fromDate(previousStartDate);
      toDate(previousEndDate);
    }
  };
  
  const handleRightClick = () => {
    let diff = 3;
    if(goalCycle === 'MONTHLY'){
      diff=1;
    } else if (goalCycle === 'QUARTERLY') {
      diff = 3;
    } else if (goalCycle === 'HALF_YEARLY') {
      diff = 6;
    } else if(goalCycle == 'ANNUALLY'){
      diff = 12;
    }
    const nextStartDate = startDate.add(diff, 'month').startOf('month');
    const nextEndDate = endDate.add(diff, 'month').endOf('month');

    const isNextYearReached = nextEndDate.year() > dayjs().add(1, 'year').year();
  
    if (!isNextYearReached) {
      setStartDate(nextStartDate);
      setEndDate(nextEndDate);
      fromDate(nextStartDate);
      toDate(nextEndDate);
    }
  };

  

  return (
    <div className="dateNavigator">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['SingleInputDateRangeField']}>
          <div className='dateRangePicker'>
            <KeyboardArrowLeftIcon onClick={handleLeftClick} className="arrowButton rightArrowButton" />
            <DateRangePicker
              slots={{ field: SingleInputDateRangeField }}
              value={[dayjs(startDate), dayjs(endDate)]}
              open={false}
              disabled
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  height: "4px !important",
                  WebkitTextFillColor : "rgb(0, 0, 0)"
              },
            }}
              className="dateRange"
              format="D MMM YYYY"
            />
            <KeyboardArrowRightIcon onClick={handleRightClick} className="arrowButton leftArrowButton" />
          </div>
        </DemoContainer>
      </LocalizationProvider>
    </div>
  )
}
