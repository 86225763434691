import React from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import layersIcons from '../../assets/images/fi-rr-layers.svg';
import totalGoal from '../../assets/images/totalGoal.svg'
import notUpdate from '../../assets/images/notUpdated.svg'
import alignOrg from '../../assets/images/clipboard-svgrepo-com 1.svg'
import upArrow from '../../assets/images/upArrow.svg'
import { getColorByStatus } from 'Constants/Styles';
import { capitalizeFirstLetter, getFormatedDate } from 'Constants/DateConstants';
import { getGoalsSummary } from 'Services/Goalservice';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJs, Title, Tooltip, ArcElement, Legend } from 'chart.js';
import './Charts.css'


ChartJs.register(
  Title, Tooltip, ArcElement, Legend
)

export default function PieChart({ params, fromDate, toDate , selectedTeamId}) {

    const chartData = {
        datasets: [{
            label: '',
            data: [],
        }]
    }

    const [countData, setCountData] = useState("");
    const [data, setData] = useState(chartData);
    const [goalSummaryCountData, setGoalSummaryCountData] = useState("");

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItemLabel, setSelectedItemLabel] = useState('ALL');
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (label) => () => {
        setSelectedItemLabel(label);
        handleClose();
    };

    const loadSummaryData = async () => {
        try {
            const res = await getGoalsSummary({
                'goalLevel': params,
                'fromDate': getFormatedDate(fromDate),
                'toDate': getFormatedDate(toDate),
                'filterType': selectedItemLabel,
                'teamId': selectedTeamId
            });

            const results = res.data;
            let isPieChartDataAvailable = 0;
            if (results && results != null) {
                let input = [];
                let color = [];
                let statusCount = results.statusCount;
                for (let key in statusCount) {
                    input.push({ 'label': key, value: statusCount[key] });
                    isPieChartDataAvailable += statusCount[key];
                    color.push(getColorByStatus(key));
                }
                const obj = {
                    datasets: [{
                        datasetIdKey: "value",
                        label: 'Goal Summary',
                        data: isPieChartDataAvailable > 0 ? input : ['No Data' , '1'],
                        backgroundColor: isPieChartDataAvailable > 0 ? color : ["#E7EDFD"],
                        hoverOffset: 10
                    }]
                }
                setData(obj);
                setCountData(results.statusCount);
                setGoalSummaryCountData(results.goalSummaryStatusCount);
            }
        } catch (error) {
            console.error("Error loading summary data:", error);
        }
    };
    let showDemoData = localStorage.getItem("showDemo");

    React.useEffect(() => {
        loadSummaryData();
    }, [toDate, selectedItemLabel, showDemoData, selectedTeamId]);

    function calculatePercentage(value, total) {
        if (typeof value == 'number' && typeof total == 'number' && total != 0 && value != undefined && total != undefined && value != null && total != null) {
            return Math.round((value / total) * 100);
        }
        return 0;
    }
    let notUpdatedInOneWeekPercentage = calculatePercentage(goalSummaryCountData.UPDATED_AT_BEFORE, goalSummaryCountData.NUMBER_OF_GOAL);
    let alignOrgPercentage = calculatePercentage(goalSummaryCountData.ALIGNED_WITH_ORG, goalSummaryCountData.NUMBER_OF_GOAL);
      
    return (
        <div className='piChartData'>
            <div className='piChartContainer'>
            <Box className='pieChartContainer'>
                <Pie data={data} className='pieChart'/>
            </Box>
                <div className='piChartContent'>
                    <div className='topHeadingChart'>
                        <h3>Count by Status</h3>
                        <div className='pieChartDropDown'>
                            <Button
                                aria-controls="dropdown-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                startIcon={<img src={layersIcons} alt='Layer Icons' />}
                                endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            >
                                <p>{ capitalizeFirstLetter(selectedItemLabel) }</p>
                            </Button>
                            <Menu
                                id="dropdown-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleMenuItemClick('ALL')} className='pieChartMenuItem'>All</MenuItem>
                                <MenuItem onClick={handleMenuItemClick('GOAL')} className='pieChartMenuItem'>Goals</MenuItem>
                                <MenuItem onClick={handleMenuItemClick('KEYRESULT')} className='pieChartMenuItem'>Key Results</MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <div className='statusItems'>
                        {Object.entries(countData)?.map(([label, value], index) => (
                            <div className='statusItem' key={index}>
                                <div>
                                    <div className='statusSquare' style={{ backgroundColor: getColorByStatus(label) }}></div>
                                    <h3>{capitalizeFirstLetter(label)}</h3>
                                </div>
                                <h3>{value < 10 ? `0${value}` : value}</h3>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='statusCount'>
                <div className='totalGoalCount summaryGoalCount'>
                    <img src={totalGoal} />
                    <h3>{goalSummaryCountData.NUMBER_OF_GOAL}</h3>
                    <div className='statusCountContent'>
                        <p>Total Goals</p>
                    </div>
                </div>
                <div className='notUpdate summaryGoalCount'>
                    <img src={notUpdate} />
                    <h3>{goalSummaryCountData.UPDATED_AT_BEFORE}</h3>
                    <div className='statusCountContent'>
                        <p>Not update in 1 week</p>
                        <div className='statusPercentage'>
                            <h5>{notUpdatedInOneWeekPercentage}%</h5>
                            {notUpdatedInOneWeekPercentage > 0 ? <img src={upArrow} /> : ""}
                        </div>
                    </div>
                </div>
                <div className='alignOrg summaryGoalCount'>
                    <img src={alignOrg} />
                    <h3>{goalSummaryCountData.ALIGNED_WITH_ORG}</h3>
                    <div className='statusCountContent'>
                        <p>Aligned with Org</p>
                        <div className='statusPercentage'>
                            <h5>{alignOrgPercentage}%</h5>
                            {notUpdatedInOneWeekPercentage > 0 ? <img src={upArrow} /> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
