import React, { useState } from "react";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Autocomplete from '@mui/material/Autocomplete';
import { UpdateEmployeeDetail, getAllEmployee } from 'Services/OrgService';
import { updateKeyResult } from 'Services/Goalservice';
import InputLabel from '@mui/material/InputLabel';
import { ProgressType } from 'Constants/EmpConstants';
import "./Summary.css"
import { ToastError, ToastSuccess } from "utils/Toast";
import { PROGRESSTYPE } from "Constants/Styles";


export default function UpdateKeyResult({ krId, close, krDetails, loadKRS }) {

    const [updateValues, setupdateValues] = useState({});
    const [data, setData] = useState(krDetails);

    const [employeeList, setEmployeeList] = React.useState([]);

    const [managerName, setManagerName] = useState('')

    const validateFields = () => {
        let valid = true;
        let newErrors = {};
        if (data.title && data.title.trim() === "") {
            newErrors.title = true;
            valid = false;
        } else {
            newErrors.title = false;
        }

        if (data.progressType === "" || data.progressType === null) {
            newErrors.progressType = true;
            valid = false;
        } else {
            newErrors.progressType = false;
        }

        if (data.progressEndValue === "" || data.progressEndValue === null) {
            newErrors.progressEndValue = true;
            valid = false;
        } else {
            newErrors.progressEndValue = false;
        }


        if (data.progressStartValue === "" || data.progressStartValue === null) {
            newErrors.progressStartValue = true;
            valid = false;
        } else {
            newErrors.progressStartValue = false;
        }
        return valid;
    }


    const handleSubmit = () => {
        if (validateFields()) {

            let summaryUpdateReq = {
                goalId: data.goalId,
                title: data.title,
                assignee: data.assignee,
                progressType: data.progressType,
                progressStartValue: data.progressStartValue,
                progressEndValue: data.progressEndValue,
                krId: data.id
            }

            updateKeyResult(summaryUpdateReq).then((res) => {
                let results = res.data;
                if (results) {
                    ToastSuccess({ text: "Key result updated successfully" });
                    loadKRS();
                    close(true);
                }
            })


        } else {
            ToastError({ text: "invalid data" });
        }
    }

    const loadEmpl = () => {
        getAllEmployee().then((res) => {
            let results = res.data;
            if (results) {
                setEmployeeList(results);
                results.forEach(element => {
                    if (data.assigneeDetails && element.id == data.assigneeDetails.empId) {
                        setManagerName(element);
                        data.assignee = element.id;
                    }

                });
                return;
            }
        })
    }

    React.useEffect(() => {
        loadEmpl();
    }, []);


    const handleChangeProgress =(e, val) => {
        if (val === null) {
            setData({ ...data, progressType: null });
          } else {
            if (val.value === PROGRESSTYPE.BOOLEAN) {
                setData({
                ...data,
                progressType: val.value,
                progressStartValue: "0",
                progressEndValue: "1",
              });
            } else {
                setData({
                ...data,
                progressType: val.value,
                progressStartValue: "",
                progressEndValue: "",
              });
            }
          }
    }
    
    return (
        <div>
            <Box className="update-popup">
                <Typography className="addEmployee-heading">
                    <h2>Update KeyResult</h2>
                </Typography>
                <hr />
                <form className="updateemployee">

                    <Grid item xs={8} className="updatefeilds">
                        <Grid xs={12} sm={3}>
                            <InputLabel>Key Result</InputLabel>
                        </Grid>
                        <Grid xs={12} sm={9} >
                            <TextField
                                name='name'
                                required
                                fullWidth
                                value={data.title}
                                onChange={(e) => {
                                    setData({ ...data, title: e.target.value })
                                }}
                                className="updateinput"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={8} className="updatefeilds">
                        <Grid xs={12} sm={3}>
                            <InputLabel>Assignee</InputLabel>
                        </Grid>
                        <Grid xs={12} sm={9}>
                            <Autocomplete className="updateinput"
                                disablePortal
                                options={employeeList}
                                getOptionLabel={(option) => (!!option && !!option.name) ? option.name : option}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => <TextField {...params} />}
                                name="assignee"
                                value={data.assigneeDetails.empName}
                                onChange={(e, val) => {
                                    if (val) {
                                        setData({ ...data, assignee: val.id, assigneeDetails:{...data.assigneeDetails, empName:val.name} });
                                    } else {
                                        setData({ ...data, assignee: null, assigneeDetails:{...data.assigneeDetails, empName:""} });
                                    }
                                }}
                            />


                        </Grid>

                    </Grid>
                    <Grid item xs={8} className="updatefeilds">
                        <Grid xs={12} sm={3}>
                            <InputLabel>Progress Type</InputLabel>
                        </Grid>

                        <Grid xs={12} sm={9}>
                            <Autocomplete
                                className="updateinput"
                                disablePortal
                                options={ProgressType}
                                value={data.progressType}
                                getOptionLabel={(option) => (!!option && !!option.dis) ? option.value : option}
                                isOptionEqualToValue={(options, value) => options === value}
                                renderInput={(params) => <TextField {...params} />}
                                name="goalLevel"
                                onChange={handleChangeProgress}
                            />
                        </Grid>
                    </Grid>
                    {data.progressType == PROGRESSTYPE.BOOLEAN ? 
                      null 
                    :
                    <>
                    <Grid item xs={8} className="updatefeilds">
                        <Grid xs={12} sm={3}>
                            <InputLabel>Start</InputLabel>
                        </Grid>
                        <Grid xs={12} sm={9}>
                            <TextField
                                fullWidth
                                className="updateinput"
                                name='startValue'
                                type="number"
                                value={data.progressStartValue}
                                required
                                onChange={(e) => {
                                    setData({ ...data, progressStartValue: e.target.value })

                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={8} className="updatefeilds">
                        <Grid xs={12} sm={3}>
                            <InputLabel>End</InputLabel>
                        </Grid>
                        <Grid xs={12} sm={9}>
                            <TextField
                                className="updateinput"
                                name='targetValue'
                                type="number"
                                value={data.progressEndValue}
                                required
                                onChange={(e) => {
                                    setData({ ...data, progressEndValue: e.target.value })
                                }}
                            />
                        </Grid>
                    </Grid>
                    </>
                    }
                    <Grid item className="goalData goalSummarySubmit" sm={"12"}>
                        <Button variant="contained" onClick={handleSubmit} >Update</Button>
                    </Grid>
                </form>
            </Box>
        </div>
    )
}
