import React from "react";
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const heading = "h1";

function LoadedSkeleton() {
    return (
        <div>
            <Typography component="div" key={heading} variant={heading}>
                <Skeleton />
            </Typography>
        </div>
    );
}

export default function NoRecord() {

  return (
    <Card className='blankGoal'>
    <div>
      <div className='blankGoalHeading'>
        <h2>No Record</h2>
        <Grid container spacing={8}>
          <Grid item xs>
            <LoadedSkeleton />
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className='blankGoalContent'>
        <p>You don't have any Records</p>
      </div>
    </div>
  </Card>
  )
}
