import React from 'react'
import "./AuthLogin.css"
import GoogleIcon from "../../assets/images/GoogleIcon.svg"
import MicroSoftIcon from "../../assets/images/microsoft.svg"
import AuthLeftContent from './AuthLeftContent'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography, useMediaQuery } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error'
import useScriptRef from 'hooks/useScriptRef';
import { strengthColorFunc, strengthIndicatorFunc } from 'utils/password-strength';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FRONT_END_BASE_URL, OAUTH_BASE_URL } from 'Constants/ApiContants';
import errorAuthIcon from "../../assets/images/ErrorAuth.svg"
import { signUp } from 'Services/AuthService';

export default function AuthSignUp({ ...others }) {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    let utm_source = null;
    let utm_campaign = null;

    if (queryParams.has("utm_source") && queryParams.has("utm_campaign")) {
        utm_source = queryParams.get('utm_source');
        utm_campaign = queryParams.get('utm_campaign');
        console.log(utm_source);
    }
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(true);

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();
    const googleAuthRedirectUrl = "" + OAUTH_BASE_URL + "/oauth2/authorize/google?redirect_uri=" + FRONT_END_BASE_URL + "/oauth2/redirect";
    const azureAuthRedirectUrl = "" + OAUTH_BASE_URL + "/oauth2/authorize/azure?redirect_uri=" + FRONT_END_BASE_URL + "/oauth2/redirect";
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicatorFunc(value);
        setStrength(temp);
        setLevel(strengthColorFunc(temp));
    };

    const redirectToHome = () => {
        localStorage.setItem('loginAgain', true);
        navigate("/signup-details");
    }

    useEffect(() => {
        changePassword('123456');
    }, []);

    return (
        <div className='authentication'>
            <AuthLeftContent />
            <div className='authLoginRightContainer'>
                <h1>👋 Welcome to 10XOKR</h1>
                <h2>Your OKR Journey Starts Here</h2>
                <a href={googleAuthRedirectUrl}>
                    <img src={GoogleIcon} />
                    <h3>Sign up with Google</h3>
                </a>
                <a href={azureAuthRedirectUrl}>
                    <img src={MicroSoftIcon} />
                    <h3>Sign up with Microsoft</h3>
                </a>
                <div className='lineBreak'>
                    <div></div>
                    <p>Or</p>
                    <div></div>
                </div>
                <h4>Sign up with Email address</h4>
                <Formik
                    initialValues={{
                        emailId: '',
                        password: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        emailId: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        password: Yup.string().max(255).required('Password is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        if (utm_source != null && utm_campaign != null) {
                            values.utm_source = utm_source
                            values.utm_campaign = utm_campaign
                        }
                        signUp(values).then((res) => {
                            let results = res.data;
                            if (results != null && results.status == "SUCCESS") {
                                setStatus({ success: true });
                                setSubmitting(false);
                                localStorage.setItem('token', results.token);
                                localStorage.setItem('userRole', results.role);
                                localStorage.setItem('orgId', results.orgId);
                                localStorage.setItem('employeeId', results.empId);
                                localStorage.setItem('showDemo', results.isDemoEnabled);
                                redirectToHome();
                            } else {
                                setStatus({ success: false });
                                setErrors({ submit: !!results.detail ? results.detail : "Something went wrong. Please try again." });
                                setSubmitting(false);
                            }
                        });
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} {...others} className='authForm'>
                            <ConnectedFocusError />
                            <FormControl fullWidth error={Boolean(touched.emailId && errors.emailId)}>
                                <OutlinedInput
                                    type="emailId"
                                    value={values.emailId}
                                    name="emailId"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    className={`authInput ${touched.emailId ? 'errorAuthInput' : ''}`}
                                    placeholder='Email'
                                />
                                {touched.emailId && errors.emailId && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon} /> {errors.emailId}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                fullWidth
                                error={Boolean(touched.password && errors.password)}
                            >
                                <OutlinedInput
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    name="password"
                                    label="Password"
                                    onBlur={handleBlur}
                                    placeholder='Password'
                                    className={`authInput ${touched.password ? 'errorAuthInput' : ''}`}
                                    onChange={(e) => {
                                        handleChange(e);
                                        changePassword(e.target.value);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                size="large"
                                                className='authPasswordEye'
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputProps={{}}
                                />
                                {touched.password && errors.password && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon} /> {errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {strength !== 0 && (
                                <FormControl fullWidth className='strengthAuth'>
                                    <Box
                                        style={{ backgroundColor: level?.color }}
                                        sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                    />
                                    <Typography variant="subtitle1" fontSize="0.75rem">
                                        {level?.label}
                                    </Typography>
                                </FormControl>
                            )}
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} className='actionContent'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        name="checked"
                                        color="primary"
                                    />
                                }
                                label="Agree with Terms & Condition."
                            />
                            </Stack>
                            {errors.submit && (
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            )}
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    className='authButton'
                                >
                                    Sign up
                                </Button>
                        </form>
                    )}
                </Formik>
                <div className='newUserLogin'>
                    <h2>Don&apos;t have an account?</h2>
                    <Typography
                        component={Link}
                        to="/login"
                        variant="subtitle1"
                        sx={{ textDecoration: 'none' }}
                    >
                        Login
                    </Typography>
                </div>
            </div>
        </div>
    )
}
