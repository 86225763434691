// material-ui
import { Typography } from '@mui/material';

// project imports
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation
} from 'react-router-dom'
// ==============================|| SAMPLE PAGE ||============================== //
import { useNavigate } from "react-router-dom";
import {APP_BASE_URL} from 'Constants/ApiContants';
import axiosInstance from 'layout/ServerAxios';
import { checkLogin } from 'Services/AuthService';
import callApi from 'utils/ApiUtils';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const headers = {
    Accept : 'application/json',
    'Content-Type': 'application/json',
  }


const OAuthRedirect = () => {
    const navigate = useNavigate();
    const useQuery= () => {
       return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const redirectToDashboard = () => {
        navigate("/home");
    }

    const redirectToSignUpDetails = () => {
        navigate("/signup-details");
    }

    const redirectToLogInPage = () => {
        navigate("/login");
    }
        
    const validateToken = () => {

        let userToken = localStorage.getItem('token');

        if (userToken == null) {
            redirectToLogInPage();
            return;
        }
        headers.Authorization= "Bearer "+userToken;

        checkLogin().then((res) => {
            let results = res.data;
            if(results != undefined && results.status == "SUCCESS"){
                localStorage.setItem('userRole',  results.role);
                localStorage.setItem('orgId', results.orgId);
                localStorage.setItem('employeeId', results.empId);
                localStorage.setItem('showDemo', results.isDemoEnabled);
                if (results.empId == 0 || results.orgId == 0) {
                    redirectToSignUpDetails();
                } else {
                    redirectToDashboard();    
                }
            } else{
                redirectToLogInPage();
            }
        }).catch(() => {
            redirectToLogInPage();
        });
    }

    React.useEffect(() => {
        if( query.get('token') === null) {
            window.close();
            return;
        }
        localStorage.setItem('token', query.get('token'));
        validateToken();
    }, [query.get('token')]);


    return (
        <div></div>
    );
};

export default withParams(OAuthRedirect);
