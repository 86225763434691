import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import "./Summary.css"
import { getCheckinHistory } from 'Services/Goalservice';
export default function GoalAudit(props) {


  const [goalDatas, setGoalDatas] = useState(props.goalDetails);
  const [checkinHis, setCheckinHis] = useState([]);

  React.useEffect(() => {
    getCheckinHistory(goalDatas.goalId).then((res) => {
      let results = res.data;

      if (results !=null ) {
        setCheckinHis(results);
      }

      })

  }, []);

  return (
    <div>
      <Box sx={{ '& > :not(style)': { m: 1, width: '1' } }}>
        <Grid container spacing={1.5} className="summaryContent" >
        
        </Grid>
      </Box>
    </div>
  )
}
