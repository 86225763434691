import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import './ManageEmployee.css';
import { getAllEmployee, getAllTeams, deleteTeam } from 'Services/OrgService';
import UpdateTeam from "./UpdateTeam";
import AddTeam from "./AddTeam";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import AddEmployee from "./AddEmployee";
// import AddSlackChannel from "./AddSlackChannel";


//table styling
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `var(--light-grey-color)`,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function CustomizedTables() {
  const [status, setStatus] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [teamFilter, setTeamFilter] = useState('');
  const [managerFilter, setManagerFilter] = useState('');

  const onChangeTeamComplete = (event) => {
    setTeamFilter((event == null) ? "all" : event.id);
    getAllTeams({ status: status, teamFilter: (event == null) ? "all" : event.id, managerFilter: managerFilter }).then((res) => {
      let results = res.data;
      if (results) {
        setTeamList(results);
        return;
      }
    })
  }

  const onChangeManagerComplete = (event) => {
    setManagerFilter((event == null) ? "all" : event.id);
    getAllTeams({ status: status, teamFilter: teamFilter, managerFilter: (event == null) ? "all" : event.id }).then((res) => {
      let results = res.data;
      if (results) {
        setTeamList(results);
        return;
      }
    })
  }


  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    var flag
    if (event.target.value === "false") {
      flag = false;
    }
    else {
      flag = true;
    }
    getAllTeams({ status: flag, teamFilter: teamFilter, managerFilter: managerFilter }).then((res) => {
      let results = res.data;
      if (results) {
        setTeamList(results);
        return;
      }
    })
  };

  //popup add employee button
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //update and delete menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event, index, row) => {
    setSelectedIndex(index)
    setSelectedRow(row)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //update data from table
  const [updateTeam, setUpdateTeam] = useState(false);
  const handleUpdateOpen = () => setUpdateTeam(true);
  const handleUpdateClose = () => setUpdateTeam(false);
  // const [slackOpen, setSlackOpen] = useState(false);
  // const handleSlackOpen = () => setSlackOpen(true);
  // const handleSlackClose = () => setSlackOpen(false);

  const [teamList, setTeamList] = React.useState([]);

  const loadTeam = () => {
    getAllTeams({ status: status, teamFilter: teamFilter, managerFilter: managerFilter }).then((res) => {
      let results = res.data;
      if (results) {
        setOpen(false);
        setUpdateTeam(false);
        setNewTeam(false);
        setTeamList(results);
        return;
      }
    })
  }
const [newTeam, setNewTeam]= useState(false)
  React.useEffect(() => {
    loadTeam();
  }, [newTeam]);
 

  const [employeeList, setEmployeeList] = React.useState([]);
  const [managerName, setManagerName] = React.useState([]);

  const loadEmpl = () => {
    getAllEmployee().then((res) => {
      let results = res.data;
      if (results) {
        setEmployeeList(results);
        const managerMapping = []
        results.map(item => {
          managerMapping[item.id] = item.name;
        });
        setOpenEmp(false);
        setManagerName(managerMapping)
        return;
      }
    })
  }
  React.useEffect(() => {
    loadEmpl();
  }, [newTeam]);

  const removeemployeeDetails = (index, data) => {
    deleteTeam(data).then(res => {
      console.log(res)
    }).catch(e => {
      console.log(e)
    })
    setTeamList(pre => {
      return [
        ...pre.slice(0, index),
        ...pre.slice(index + 1, pre.length)
      ]
    })
  }

  const [openEmp, setOpenEmp] = useState(false);
  const handleCloseEmp = () => setOpenEmp(false);
  const handleAddEmp = () => setOpenEmp(true);
  const [team, setteam]= useState(null);

  const getTeam = (team)=>{
    setteam(team);
  }

  return (
    <div className="manage-employee">
      <Stack spacing={1} className="allButtons_ME" >
        <div className="manageButtons1" >
          <FormControl sx={{ m: 1, minWidth: 120, minHeight: 40 }} size="small">
            <Autocomplete
              options={teamList}
              name='teamId'
              size="small"
              value={teamList.teamId}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                onChangeTeamComplete(value)
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Team" className="autocompleteInput" />
              )}
            />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120, minHeight: 40 }} size="small">
            <Autocomplete
              options={employeeList}
              name='manager'
              size="small"
              value={employeeList.managerId}
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                onChangeManagerComplete(value)
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Manager" className="autocompleteInput" />
              )}
            />
          </FormControl>
          <FormControl size="small">
            <InputLabel id="demo-select-small">Status</InputLabel>
            <Select
              className="manageteamsbuttons statusfilter"
              value={status}
              onChange={handleChangeStatus}
            >
              <MenuItem value={"true"}>Active</MenuItem>
              <MenuItem value={"false"}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="manageButtons2">
          <Button variant="text" className="import" onClick={function (event) { handleOpen() }}>Add Team</Button>
        </div>
      </Stack>

      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow >
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Manager</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamList.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="center">{managerName[row.managerId]}</StyledTableCell>
                <StyledTableCell align="center">{row.isActive == true ? "Active" : "Inactive"}</StyledTableCell>
                <StyledTableCell align="center">
                <Button onClick={function (event) { handleAddEmp(); getTeam(row) }} variant="contained">Add Employee</Button>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className="actionButtons">
                  
                  <Button
                    id="fade-button"
                    aria-controls={openMenu ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={function (event) { handleClickMenu(event, index, row) }}
                  >
                    <MoreVertIcon />
                  </Button>
                  <Menu
                    MenuListProps={{
                      'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={function (event) { handleCloseMenu(); handleUpdateOpen(index) }}>Update</MenuItem>
                    {/* <MenuItem onClick={function (event) { handleCloseMenu(); handleSlackOpen(index) }}> Add Slack Channel</MenuItem> */}
                    <MenuItem onClick={function (event) {
                      removeemployeeDetails(selectedIndex, row);
                      handleCloseMenu()
                    }}
                    >Delete</MenuItem>
                  </Menu>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* modal for popup add Team */}
      <Modal
        open={open}
        onClose={handleClose}
      >
        <AddTeam closeTeam={loadTeam} setNewTeam={setNewTeam} />
      </Modal>

      <Modal
        open={updateTeam}
        onClose={handleUpdateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpdateTeam closeTeam={loadTeam} data={selectedRow} close={handleUpdateClose} />
      </Modal>

      <Modal
        open={openEmp}
        onClose={handleCloseEmp}
      >
       <AddEmployee addEmployeeClose={loadEmpl} team={team}/>
      </Modal>

      {/* <Modal
        open={slackOpen}
        onClose={handleSlackClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddSlackChannel closeTeam={loadTeam} data={selectedRow} close={handleSlackClose} />
      </Modal> */}
    </div>
  );
}