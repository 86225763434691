import React from 'react'
import { Chip, ClickAwayListener, Popover } from '@mui/material';
import "./GoalDetails.css"
import { useState } from 'react';
import AvatarBuilder from 'utils/AvatarBuilder';
import CloseSvg from "../assets/images/close.svg"
import { capitalizeFirstLetter, getNumericformatedDate, getformatedTime } from 'Constants/DateConstants';
import { getKeyInitiativeDetails, updateKeyInitiatives } from 'Services/Goalservice';
import 'react-quill/dist/quill.snow.css';
import { EditProgress, SessionDropDown } from './GoalDetails';
import EditIcon from "../assets/images/EditIcon.svg"
import BackIcon from "../assets/images/backButton.svg"
import KeyInitiativeMenu from './KeyInitiativeMenu';
import SliderProgressBar from 'Pages/Home/SliderProgressBar';
import { getColorByStatus } from 'Constants/Styles';
import { useEffect } from 'react';

export default function KeyInitiativeDetails({ kiData, goalCycle, updateKRData, handleCloseDrawer, deleteKi }) {
    const [kiDetail, setKiDetail] = useState(kiData);

    const maxVisibleAssignees = 3;

    const visibleAssignees = kiDetail.ownerDetails ? kiDetail.ownerDetails.slice(0, maxVisibleAssignees) : [];
    const hiddenAssignees = kiDetail.ownerDetails ? kiDetail.ownerDetails.slice(maxVisibleAssignees) : [];

    const [showHiddenAssignees, setShowHiddenAssignees] = useState(false);

    const handleToggleHiddenAssignees = () => {
        setShowHiddenAssignees(!showHiddenAssignees);
    };

    const handleDelete = (employeeId) => {

    };

    const [showSessionDropdown, setShowSessionDropdown] = useState(false);

    const handleKiDetailsChange = (updatedGoalDetail) => {
        updateKeyInitiatives(updatedGoalDetail);
        updateKRData(updatedGoalDetail);
    }

    const updateSessionSelected = (sessions) => {
        const kiDetails = {
            ...kiDetail,
            goalCycle: sessions.goalCycle,
            startDate: sessions.startDate,
            dueDate: sessions.dueDate
        }
        setKiDetail(kiDetails);
        handleKiDetailsChange(kiDetails);
    }

    const updateSelectedProgress = (progress) => {
        const updatedKrDetails = {
            ...kiDetail,
            progressType: progress.progressType,
            progressStartValue: progress.progressStartValue,
            progressEndValue: progress.progressEndValue,
        }
        setKiDetail(updatedKrDetails);
        updateKeyInitiatives(updatedKrDetails);
    }

    useEffect(() => {
        loadKIS()
        updateKRData(kiDetail);
      }, [kiDetail.progressType, kiDetail.progressStartValue, kiDetail.progressEndValue]);

    const [originalTitle, setOriginalTitle] = useState(kiDetail.title);

    const [anchorElProgress, setAnchorElProgress] = React.useState(null);
    const handleOpenPopOver = (event) => {
        setAnchorElProgress(event.currentTarget);
    };
    const handleClosePopOver = () => {
        setAnchorElProgress(null);
    };
    const openProgressPopOver = Boolean(anchorElProgress);
    const id = openProgressPopOver ? 'simple-popover' : undefined;

    const loadKIS = () => {
        getKeyInitiativeDetails(kiDetail.id).then((res) => {
            let results = res.data;
            if (results && results !== null) {
                setKiDetail(results);
            }
        });
    }

    return (
        <div className='goalDetails'>
            <div className='goalDetailsHeading'>
                <div className='goalDetailButtons'>
                    <img src={BackIcon} onClick={handleCloseDrawer} />
                    <h2>Key Initiative details</h2>
                </div>
                <div className='goalDetailsButtons'>
                    <KeyInitiativeMenu
                        kiId={kiDetail.id}
                        kiData={kiDetail}
                        krId={kiDetail.keyResultId}
                        deleteKi={deleteKi}
                    />
                </div>
            </div>
            <div>
                <div className="goalNameGD">
                    <h6>KI</h6>
                    <input
                        value={kiDetail.title}
                        onChange={(e) => {
                            setKiDetail({ ...kiDetail, title: e.target.value });
                        }}
                        className='goalNameInput'
                        multiline
                        rows={3}
                        onBlur={() => {
                            if (kiDetail.title !== originalTitle) {
                                handleKiDetailsChange(kiDetail)
                            }
                        }}
                    />
                </div>
                <div className='goalDetailsItems'>
                    <p>Sessions</p>
                    <ClickAwayListener onClickAway={() => setShowSessionDropdown(false)}>
                        <div className='sessionContent'>
                            <h4>{capitalizeFirstLetter(goalCycle)} - {getNumericformatedDate(kiDetail.startDate)} to {getNumericformatedDate(kiDetail.dueDate)}</h4>
                            <p onClick={() => setShowSessionDropdown(true)}><img src={EditIcon} />  Edit</p>
                            {showSessionDropdown && (
                                <SessionDropDown goalTimeline={{
                                    goalCycle: kiDetail.goalCycle,
                                    startDate: kiDetail.startDate,
                                    dueDate: kiDetail.dueDate,
                                }} updateSessionSelected={updateSessionSelected} />
                            )}
                        </div>
                    </ClickAwayListener>
                </div>
                <div className='goalDetailsItems'>
                    <p>Assignee</p>
                    <div className='assigneeGD'>
                        {visibleAssignees.map((assignee) => (
                            <Chip
                                key={assignee.employeeId}
                                label={assignee.name}
                                variant="outlined"
                                avatar={<AvatarBuilder employee={{ name: assignee.name }} variant="circular" size="extra-samll" />}
                                onDelete={() => handleDelete(assignee.employeeId)}
                                className='goalAssignees'
                                deleteIcon={<img src={CloseSvg} alt="Close Icon" />}
                            />
                        ))}

                        {hiddenAssignees.length > 0 && (
                            <div
                                className='hidden-assignees'
                                onMouseEnter={handleToggleHiddenAssignees}
                                onMouseLeave={handleToggleHiddenAssignees}
                            >
                                <div className={`hidden-assignees-toggle ${showHiddenAssignees ? 'active' : ''}`}>
                                    +{hiddenAssignees.length}
                                </div>
                                {showHiddenAssignees && (
                                    <div className='hidden-assignees-list'>
                                        {hiddenAssignees.map((assignee) => (
                                            <Chip
                                                key={assignee.employeeId}
                                                label={assignee.name}
                                                variant="outlined"
                                                avatar={<AvatarBuilder employee={{ name: assignee.name }} variant="circular" size="extra-samll" />}
                                                onDelete={() => handleDelete(assignee.employeeId)}
                                                className='goalAssignees'
                                                deleteIcon={<img src={CloseSvg} alt="Close Icon" />}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                </div>
                <div className='goalDetailsItems'>
                    <p>Progress</p>
                    <div className={`goalDetailProgressContent ${anchorElProgress ? 'showProgressBorder' : ''}`}>
                        <h4>{capitalizeFirstLetter(kiDetail.progressType)} - {Math.round(kiDetail.progressStartValue)} to {kiDetail.progressEndValue}</h4>
                        <p onClick={handleOpenPopOver}><img src={EditIcon} />  Edit</p>
                        <Popover
                            id={id}
                            open={openProgressPopOver}
                            anchorEl={anchorElProgress}
                            onClose={handleClosePopOver}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <EditProgress progressData={kiDetail} handleProgressClose={handleClosePopOver} updateSelectedProgress={updateSelectedProgress} />
                        </Popover>
                    </div>
                </div>
                <div className='goalDetailsItems'>
                    <div className='kiDetailsSlider'>
                    <SliderProgressBar progressEndValue={kiDetail.progressEndValue} progressStartValue={kiDetail.progressStartValue} progressType={kiDetail.progressType} krId={kiDetail.keyResultId} kiId={kiDetail.id} goalId={kiDetail.goalId} progress={kiDetail.progress} progressPercentage={kiDetail.kiDetailiativeProgress} status={kiDetail.keyValueStatus} loadKIS={loadKIS} />
                    <div className='goalSliderDetails'>
                        <h3 style={{ color: getColorByStatus(kiDetail.keyValueStatus) }}>{capitalizeFirstLetter(kiDetail.keyValueStatus)}</h3>
                        <h2>{Math.round(kiDetail.keyInitiativeProgress)}%</h2>
                        <h1>{getformatedTime(kiDetail.updatedAt)}</h1>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}