import React, { useState } from "react";
import "./TopSection.css";
import { useLocation, useNavigate } from "react-router-dom";
import NewAddGoal from "./NewAddGoal";
import Modal from '@mui/material/Modal';
import DoneIcon from "../assets/images/Done.svg"
import { PROGRESSTYPE } from "Constants/Styles";
import AddKeyResults from "./AddKeyResults";


export default function TopSection({page}) {
  const location = useLocation();
  const navigate = useNavigate();

  const getHeaderName = () => {
    const pathSegments = location.pathname.split('/');

    if (pathSegments[1] === 'goals') {
      return `OKRs`;
    }else if (pathSegments[1] === 'setting') {
      return "Setting";
    }else if (pathSegments[1] === 'performance-review') {
      return "Performance Review";
    }else{
      return "OKR Insight"
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openKR, setOpenKR] = useState(false);

  const handleOpenKR = () =>{
    setOpenKR(true);
  }
  const handleCloseKR = () => {
    setOpenKR(false);
    redirectToGoals();
  }

  const [goal, setGoal] = useState();

  const getGoalData = (AddGoalData) =>{
    setGoal(AddGoalData);
  }

  const [openAddKr, setOpenAddKr] = useState(false);
  const handleOpenAddKr = () => setOpenAddKr(true);

  const handleModalCloseAddKr = () => {
      setOpenAddKr(false);
  };

  const temp = {
    title: "",
    assignee: "",
    progressType: PROGRESSTYPE.PERCENTAGE,
    progressStartValue: "0",
    progressEndValue: "100",
    keyValueStatus: "",
    completeddate: "",
    keyValueStatus: "",
    progress: "",
    id: "",
    weightage :"50",
    goalId: -1,
    progressStartDate : "",
    progressDueDate : ""
}

const [newKeyResult, setNewKeyResult] = useState(temp)

const redirectToGoals = () => {
  navigate("/goals/" + Date.now());
}

const handleUpdateCloseKR = () =>{
  redirectToGoals();
}

  return (
    <div className={page ? "centerSection" : "Top-section"}>
      <div>
      <button className="SecondaryButton" onClick={handleOpen}>{page ? "+ Create New Goal" : "+ Add Goals"}</button>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <NewAddGoal handleClose={handleClose} handleOpenKR={handleOpenKR} goalData={getGoalData} />
        </Modal>
        <Modal
          open={openKR}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className='addingKR'>
            <div className="addingKrContent">
              <img src={DoneIcon} className="done-icon" />
              <div className="addingKrItem">
                <h1>Goal added successfully!</h1>
                <h2>Do you want to Add key results?</h2>
              </div>
            </div>
            <div className='newKrHR'></div>
            <div className="newKrButton">
              <button onClick={handleCloseKR} className="newKrCancelButton" variant="contained">Do it later</button>
              <button className="PrimaryButton" variant="contained"
                onClick={() => {
                  handleOpenAddKr();
                  let newKey = temp;
                  newKey.goalId = goal.goalId;
                  newKey.progressStartDate = goal.goalTimeline.startDate;
                  newKey.progressDueDate = goal.goalTimeline.dueDate;
                  setNewKeyResult(newKey)
                  handleCloseKR();
                }}>Add key result</button>
            </div>
          </div>
        </Modal>
        <Modal
          open={openAddKr}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddKeyResults onCancel={handleModalCloseAddKr} goalData={goal} updateGoalProgress={handleUpdateCloseKR} updateKR={handleUpdateCloseKR} newKeyResult={newKeyResult} setNewKeyResult={setNewKeyResult} />
        </Modal>
      </div>
      {
        page ? null :
          <div className="topHeading">
            <h1>{getHeaderName()}</h1>
          </div>
      }
    </div>
  )
}
