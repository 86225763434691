import React, { useEffect, useState } from 'react'
import "./EmployeesComparison.css"
import totalGoal from '../../assets/images/totalGoal.svg'
import notUpdate from '../../assets/images/notUpdated.svg'
import alignOrg from '../../assets/images/clipboard-svgrepo-com 1.svg'
import { Grid } from '@mui/material'
import AvatarBuilder from 'utils/AvatarBuilder'
import { getEmployeeStatus } from 'Services/Goalservice'
import { getFormatedDate } from 'Constants/DateConstants'

export default function EmployeesComparison({ params, fromDate, toDate , selectedTeamId}) {

  const [teamEmpData, setTeamEmpData] = useState([]);
  const [empCom , setEmpCom] = useState("");
  const getEmpCom = () =>{
    getEmployeeStatus({
      'goalLevel': params,
      'fromDate': getFormatedDate(fromDate),
      'toDate': getFormatedDate(toDate),
      'teamId': selectedTeamId
  }).then((res) => {
        let results = res.data;
        if (results) {
          setTeamEmpData(results.employeesStatusResponse)
          setEmpCom(results);
          return;
        }
    })
  }

  let showDemoData = localStorage.getItem("showDemo");

  useEffect(()=>{
    getEmpCom();
  },[showDemoData, selectedTeamId])
  return (
    <div className='EmployeesComparison'>
      <h2>Employees Status</h2>
      <div className='EmpCmpItems'>
        <div className='EmpCmpItem'>
         <img src={totalGoal} />
         <h1>{empCom.notUpdatedGoal}</h1>
         <h3>Not updated goals</h3>
        </div>
        <div className='EmpCmpItem'>
         <img src={notUpdate} />
         <h1>{empCom.recently}</h1>
         <h3>Recently</h3>
        </div>
        <div className='EmpCmpItem'>
         <img src={alignOrg} />
         <h1>{empCom.alignedWithOrg}</h1>
         <h3>Aligned with org</h3>
        </div>
      </div>
      <Grid container spacing={1} className='EmpDetailedItemsHead' justifyContent="space-between">
      <Grid item xs={4} className='EmpDetailedItemHead'>
        Employee's Name
      </Grid>
      <Grid item xs={1} className='EmpDetailedItemHead'>
        Objective
      </Grid>
      <Grid item xs={1} className='EmpDetailedItemHead'>
        KRs
      </Grid>
      <Grid item xs={1} className='EmpDetailedItemHead'>
        Completed
      </Grid>
      <Grid item xs={1} className='EmpDetailedItemHead'>
        Ahead
      </Grid>
      <Grid item xs={1} className='EmpDetailedItemHead'>
        On Track
      </Grid>
      <Grid item xs={1} className='EmpDetailedItemHead'>
        Behind
      </Grid>
      <Grid item xs={1} className='EmpDetailedItemHead'>
        At Risk
      </Grid>
      <Grid item xs={1} className='EmpDetailedItemHead'>
        Not Started
      </Grid>
    </Grid>
    <div className='EmpDetailedContent'>
    {teamEmpData?.map((employee, index) => (
        <Grid container spacing={1} key={index} className='EmpDetailedItems' justifyContent="space-between">
          <Grid item xs={4} className='EmpDetailedItem'>
          <AvatarBuilder employee={ {'name' : employee.employeeName } } variant="circular" size="small" />
            <h1>{employee.employeeName}</h1>
          </Grid>
          <Grid item xs={1} className='EmpDetailedItem'>
            <h1>{employee.objective}</h1>
          </Grid>
          <Grid item xs={1} className='EmpDetailedItem'>
            <h1>{employee.krs}</h1>
          </Grid>
          <Grid item xs={1} className='EmpDetailedItem'>
            <h1>{employee.complete}</h1>
          </Grid>
          <Grid item xs={1} className='EmpDetailedItem'>
            <h1>{employee.ahead}</h1>
          </Grid>
          <Grid item xs={1} className='EmpDetailedItem'>
            <h1>{employee.onTrack}</h1>
          </Grid>
          <Grid item xs={1} className='EmpDetailedItem'>
            <h1>{employee.behind}</h1>
          </Grid>
          <Grid item xs={1} className='EmpDetailedItem'>
            <h1>{employee.atRisk}</h1>
          </Grid>
          <Grid item xs={1} className='EmpDetailedItem'>
            <h1>{employee.notStarted}</h1>
          </Grid>
        </Grid>
      ))}
    </div>
    </div>
  )
}
