import React from 'react'
import Grid from './Grid'
import './Setting.css'
import { useParams } from "react-router-dom";




export default function Setting() {
  const { tabName } = useParams();

  // refreshFeatures();

  return (
    <div className='settingSection'>
    <Grid tabName={tabName}/>
    </div>
  )
}
