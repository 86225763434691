import React, { useState } from "react";
import "./GoalsGrids.css"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { deleteKeyInitiatives, deleteKeyResult } from 'Services/Goalservice';
import { ToastError, ToastSuccess } from "utils/Toast";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Modal } from "@mui/material";
import MenuIcon from "../assets/images/threeDot.svg"
import CheckInHistory from "./CheckInHistory";
import CheckInKIHistory from "./CheckInKIHistory";

export default function KeyInitiativeMenu({kiId, kiData , krId,deleteKi }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
  
    const [openHistory, setOpenHistory] = React.useState(false);
  
    const handleHistoryOpen = () => {
        let allowedFeaturesString = localStorage.getItem("allowedFeatures");
        let allowedFeatures = []
        if(allowedFeaturesString && allowedFeaturesString !=null){
            allowedFeatures =  allowedFeaturesString.split(",");
        }
        if(allowedFeatures.includes("checkInHistory")) {
            setOpenHistory(true);
        } else {
            ToastSuccess({ text: "Kindly upgrade your plan to use this feature" })
        }
      
    };
    const handleHistoryClose = () => {
      setOpenHistory(false);
    };
  
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  
    const handleDeleteOpen = () => {
      setDeleteDialogOpen(true);
    };
  
    const handleDeleteClose = () => {
      setDeleteDialogOpen(false);
    };
  
    const handleDeleteConfirmed = () => {
      deleteKeyInitiatives(krId, kiId)
      .then((res) => {
        ToastSuccess({ text: "Key Initiative Deleted successfully" });
      }).catch((error) => {
        ToastError({ text: "Error while Deleting keyInitiative" });
      });
      handleDeleteClose();
      deleteKi();
    };
  
    return (
      <div className="menuitems">
        <img src={MenuIcon} onClick={handleClickMenu}/>
        <Menu
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={function () {handleHistoryOpen() ;  handleCloseMenu()}}>CheckIn History</MenuItem>
          <MenuItem onClick={function () {handleDeleteOpen() ;  handleCloseMenu()}}>Delete KeyResult</MenuItem>
        </Menu>
        <Modal
          open={openHistory}
          onClose={handleHistoryClose}
        >
            <CheckInKIHistory krId={krId} kiId={kiId} onClose={handleHistoryClose}/>
        </Modal>
  
        <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
        <DialogTitle id="responsive-dialog-title">
            <h2>DELETE KEY RESULT</h2>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this KEY RESULT
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
  
      </div>
    );
  }