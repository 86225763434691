import React, { useEffect, useState } from "react";
import "./GoalsGrids.css"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import {  deleteAlignedGoal, getGoalDetails, updateGoalSummary } from 'Services/Goalservice';
import { ToastSuccess } from "utils/Toast";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Modal } from "@mui/material";
import MenuIcon from "../assets/images/threeDot.svg"
import GoalAlignment from "./GoalAlignment";
import KrWeightage from "./KrWeightage";

export default function GoalMenu({ goalData,parentId, onDelete,isTopGoal, updateGoal , handleGoalDetails , callFrom , deleteAlignment}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
  
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  
    const handleDeleteOpen = () => {
      setDeleteDialogOpen(true);
    };
  
    const handleDeleteClose = () => {
      setDeleteDialogOpen(false);
    };
  
    const handleDeleteConfirmed = () => {
      onDelete(goalData.goalId);
      handleDeleteClose();
    };
  
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
  
    const handleModalClose = () => {
        setOpen(false);
    };
  
    const [goalDetail, setGoalDetail] = useState("");
  
  useEffect(()=>{
    setGoalDetail(goalData);
  },[goalData])
  
  const handleProgressUpdateType =() =>{
    const updatedGoalData = { ...goalData };
    updatedGoalData.goalProgressUpdateType = "MANUAL"
    updateGoalSummary(updatedGoalData)
    updateGoal();
  }
  
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState(null);
  
  const handleDeleteAlignedGoal = (alignmentId, goalId, alignmentType) => {
    setGoalToDelete({ alignmentId , goalId, alignmentType });
    setIsDeleteConfirmationOpen(true);
  };
  
  const handleConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false);
  };
  
  const handleConfirmationDelete = () => {
    setIsDeleteConfirmationOpen(false);
    const { alignmentId , goalId, alignmentType } = goalToDelete;
    deleteAlignedGoal(alignmentId, goalId, alignmentType).then((res) => {
      ToastSuccess({ text: "Goal Alignment removed successfully." });
      deleteAlignment();
      updateGoal();
    });
  };
  
  const [openKRw, setOpenKRw] = useState(false);
  const handleKRwOpen = () => setOpenKRw(true);
  
  const handleKRwModalClose = () => {
      setOpenKRw(false);
  };
    return (
      <div className="menuitems">
        <img src={MenuIcon} onClick={handleClickMenu}/>
        <Menu
          id={goalData.goalId}
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
        >
          {
            callFrom ? 
            (
              [
              <MenuItem onClick={function (event) { handleGoalDetails(goalData.goalId); handleCloseMenu() }}>View Details</MenuItem>,
                (isTopGoal == 0 && (
                  <MenuItem onClick={function (event) { handleDeleteAlignedGoal(parentId, goalData.goalId, goalData.type); handleCloseMenu() }}>Remove Alignment</MenuItem>
                ))
              ]
            )
            :
            (
              [
                <MenuItem key="goalAlignment" onClick={(event) => handleOpen()}>Aligned to Goal</MenuItem>,
                <MenuItem key="convertToManual" onClick={(event) => { handleProgressUpdateType(); handleCloseMenu() }}>
                  Convert to Manual Goal
                </MenuItem> ,
                <MenuItem key="goalAlignment" onClick={(event) => handleKRwOpen()}>Add KRs Weightage</MenuItem>,
              ]
            )
          }
          <MenuItem onClick={function (event) { handleDeleteOpen(); handleCloseMenu() }}>Delete</MenuItem>
        </Menu>
  
        <Modal
          open={open}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <GoalAlignment goalDetail={goalDetail} onCancel={handleModalClose} updateGoal={updateGoal}/>
        </Modal>
  
        <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
        <DialogTitle id="responsive-dialog-title">
            <h2>DELETE GOAL</h2>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this GOAL
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
  
        <Dialog
          open={isDeleteConfirmationOpen}
          onClose={handleConfirmationClose}
        >
          <DialogTitle id="responsive-dialog-title">
            <h2>Remove Alignment</h2>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove this alignment
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmationClose} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleConfirmationDelete} className="warningButton" variant="contained">
              Remove
            </Button>
          </DialogActions>
        </Dialog>
  
        <Modal
          open={openKRw}
          onClose={handleKRwModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <KrWeightage goalDetail={goalDetail} onCancel={handleKRwModalClose} updateGoal={updateGoal}/>
        </Modal>
      </div>
    )
  }  
