export const isValidStr = (value) => {
    if(value === undefined || value === null || value === "" || value.trim() === ""){
        return false;
    }
    return true;
}

export const isValidNumber = (value) => {
    if(value === undefined || value === null || value <= 0){
        return false;
    }
    return true;
}