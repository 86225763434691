import React from 'react';
import "./AuthLogin.css"
import AuthLeftContent from './AuthLeftContent'
import {Button,FormControl,FormHelperText,OutlinedInput,Typography} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link } from "react-router-dom";
import errorAuthIcon from "../../assets/images/ErrorAuth.svg"
import logoIcon from "../../assets/logo.png"

export default function AtlassianLogin({ ...others }) {

    return (
        <div className='authentication'>
            <AuthLeftContent />
            <div className='authLoginRightContainer'>
                <img src={logoIcon} />
                <h1>Jira Credentials</h1>
                <Formik
                    initialValues={{
                        email: '',
                        apiToken: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        apiToken: Yup.string().max(255).required('Api Token is required')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        //onsubmit api call here
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} {...others} className='authForm'>
                            <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                                <OutlinedInput
                                    type="email"
                                    value={values.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder='Email'
                                    inputProps={{}}
                                    className={`authInput ${touched.email ? 'errorAuthInput' : ''}`}
                                />
                                {touched.email && errors.email && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon}/> {errors.email}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                fullWidth
                                error={Boolean(touched.apiToken && errors.apiToken)}
                            >
                                <OutlinedInput
                                    type='text'
                                    value={values.apiToken}
                                    name="apiToken"
                                    className={`authInput ${touched.apiToken ? 'errorAuthInput' : ''}`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder='Jira API Token'
                                    inputProps={{}}
                                />
                                {touched.apiToken && errors.apiToken && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon}/> {errors.apiToken}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {errors.submit && (
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            )}
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                className='authButton jiraButton'
                            >
                                Submit
                            </Button>
                        </form>
                    )}
                </Formik>
                <div className='newUserLogin'>
                    <Typography
                        component="a"
                        href="https://id.atlassian.com/manage/api-tokens"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="subtitle1"
                        sx={{ textDecoration: 'none' }}
                    >
                        Get my api token
                    </Typography>
                </div>
            </div>
        </div>
    )
}