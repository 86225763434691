import React from 'react';
import { Typography, Grid,} from '@mui/material';
import { Chart as ChartJs, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import './Charts.css';
import { getProgressTrend } from 'Services/Goalservice';
import { getFormatedDate } from 'Constants/DateConstants';
import { ChartOptions } from 'Constants/Styles';

ChartJs.register(
    Title, Tooltip, LineElement, Legend,CategoryScale, LinearScale, PointElement, Filler
  )
  

export default function CheckInProgress({params, fromDate, toDate, selectedTeamId}) {
  const chartData = {
    labels: [''],
    datasets: [
      {
        label: 'All CheckIn',
        data: [],
      },
    ],
  };
    const [progressData, setProgressData] = React.useState(chartData);

    const loadProgress = async () => {
      try {
          const res = await getProgressTrend({
              'goalLevel': params,
              'fromDate': getFormatedDate(fromDate),
              'toDate': getFormatedDate(toDate),
              'teamId': selectedTeamId
          });

          const results = res.data;
          if (results && results != null && results.length > 0) {
              let labels = [];
              let input = [];
              results?.map((item, i) => {
                  labels.push(item.label);
                  input.push(item.value);
              });

              const obj = {
                  datasets: [{
                      datasetIdKey: "value",
                      label: "All (Goals + KeyResults)",
                      data: input,
                      hoverOffset: 10,
                      borderColor: '#35BE95',
                      fill: false,
                      tension: 0.4,
                      pointRadius: 5,
                      pointBackgroundColor: 'transparent',
                  }],
                  labels: labels
              };

              setProgressData(obj);
          }
      } catch (error) {
          console.error("Error loading progress data:", error);
      }
  }
  let showDemoData = localStorage.getItem("showDemo");
    
    React.useEffect(() => {
        loadProgress();
    }, [toDate, showDemoData, selectedTeamId]);
    
  return (
    <Grid container spacing={2} className='lineChartContainer'>
        <Grid item xs={9} className='chartHeading'>
          <Typography variant="h3">CheckIn Progress</Typography>
        </Grid>
        <Grid item xs={12}>
          <Line data={progressData} options={ChartOptions} className='lineChartItem'/>
        </Grid>
      </Grid>
  )
}
