import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export default function DateCC({updateTimeline, closeCalender}) {
  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setSelectedDate([ranges.selection]);
  };

  const numDaysSelected = Math.ceil(
    (selectedDate[0].endDate - selectedDate[0].startDate) / (1000 * 60 * 60 * 24)
  );

  const handleOkButtonClick = () => {
    const startDate = selectedDate[0].startDate;
    const dueDate = selectedDate[0].endDate;
    updateTimeline((startDate), (dueDate));
    closeCalender();
  };

  const handleCancelButtonClick = () =>{
    closeCalender();
  }

  return (
    <div>
      <DateRange
        ranges={selectedDate}
        onChange={handleSelect}
        moveRangeOnFirstSelection={false}
      />
      <div className="date-range-info">
        <p>Range <span>{numDaysSelected} Days</span></p>
        <button onClick={handleCancelButtonClick} className="calenderCancelButton">Cancel</button>
        <button onClick={handleOkButtonClick} className="calenderApplyButton">Apply</button>
      </div>
    </div>
  );
}
