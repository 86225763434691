const styles = {
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
    cardcontent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    textOverflow: {
        overflow: "hidden", textOverflow: "ellipsis", width: '5rem'
    }
};

export const getColorByStatus=(value)=>{
    if(value == "NOT_STARTED"){
      return "#B3B3B3"
    }
    else if(value == "AT_RISK")
    {
      return "#FE3333";
    }
    else if(value == "BEHIND"){
      return "#FE8000";
    }
    else if(value == "ON_TRACK"){
      return "#66B975";
    }
    else if(value == "AHEAD"){
      return "#87CEEA"
    }
    else{
      return "#6570B9";
    }
    
  }

  export const PROGRESSTYPE = {
    PERCENTAGE: 'PERCENTAGE',
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER',
    DOLLAR: 'DOLLAR',
    INCREASE_KPI: 'INCREASE_KPI',
    DECREASE_KPI: 'DECREASE_KPI'
  };
  
  export const booleanProgressTypeValue = (value) => {
    if(value == 1){
      return "True"
    }else{
      return "False"
    }
  }

  export const PERFORMANCE_STEPPERS = {
    PR_CYCLE : 0,
    PR_TIMELINE : 1,
    SELF_AND_MANAGER : 2,
    EMPLOYEE_TO_MANAGER : 3,
    PEER : 4
  };

  export const PERF_REVIEW_TYPE ={
    SELF : "SELF",
    EMPLOYEE_TO_MANAGER : "EMPLOYEE_TO_MANAGER",
    PEER : "PEER",
    MANAGER_TO_EMPLOYEE : "MANAGER_TO_EMPLOYEE"
  }

  export const PERF_REVIEW_QUESTION_TYPE = {
    TEXT : "TEXT",
    RATING : "RATING",
    OPTIONS : "OPTION"
  }

  export const CSVData = [
    ['employeeName*', 'email*', 'teamName*', 'managerEmailId*', 'role*'],
    ['Jane Smith',	'jane@example.com',	'Engineering',	'manager@example.com',	'OWNER'],
    ['Chris Evans',	'chris@example.com',	'Finance',	'admin@example.com',	'ADMIN'],
    ['Andrew Clark',	'andrew@example.com',	'Engineering',	'staff@example.com',	'STAFF'],
    ['Oliver King',	'oliver@example.com',	'Sales',	'susan@example.com',	'MANAGER']
  ];

  export const ChartOptions = {
    plugins: {
      legend: {
        display: false,
        position: 'top',
        align:'end',
        labels: {
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            family: 'Inter',
            size: 9,
            color:"#94A3B8",
          },
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          font: {
            family: 'Inter',
            size: 9,
            color: "#94A3B8",
          },
          stepSize: 10,
        },
        grid: {
            lineWidth: 2,
            color: 'rgba(148, 163, 184, 0.1)',
        },
        border: {
            dash: [8,8],
        }, 
      }
    },
  };

  export const truncateText = (text, limit) => {
    if (text.length <= limit) {
        return text;
    }

    let truncatedText = text.substring(0, limit);
    const lastSpaceIndex = truncatedText.lastIndexOf(" ");

    if (lastSpaceIndex !== -1) {
        truncatedText = truncatedText.substring(0, lastSpaceIndex);
    }

    return `${truncatedText}...`;
}