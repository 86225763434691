import React, { useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './ManageEmployee.css';
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { getAllTeams } from 'Services/OrgService';
import { createEmployee } from 'Services/OrgService';
import { toast } from 'react-toastify'


export default function AddEmployee({ addEmployeeClose, team }) {
  const employeeTemplate = { name: "", email: "", teamId: "", role: "" };
  if(team){
    employeeTemplate.teamId = team.id;
  }
  const [employes, setEmployes] = useState([employeeTemplate]);
  const [teamList, setTeamList] = React.useState([]);
  const [errors, setErrors] = useState([]);

  const addemployee = () => {
    setEmployes([...employes, employeeTemplate])
  }
  const onChange = (e, index) => {
    const filledEmployee = employes.map((employee, i) => index === i ?
      Object.assign(employee, { [e.target.name]: e.target.value }) :
      employee
    );
    setEmployes(filledEmployee);
  }
  const onChangeAutocomplete = (value, index) => {
    const filledEmployee = employes.map((employee, i) => index === i ?
      Object.assign(employee, { 'teamId': value.id }) :
      employee
    );
    setEmployes(filledEmployee);
  }

  const removeemployee = (index) => {
    const deletemploye = [...employes];
    deletemploye.splice(index, 1);
    setEmployes(deletemploye);
  }

  const loadTeam = () => {
    getAllTeams().then((res) => {
      let results = res.data;
      if (results) {
        setTeamList(results);
        return;
      }
    })
  }

  React.useEffect(() => {
    loadTeam();
  }, []);

  const validate = () => {
    const errors = [];
    employes.forEach((employee, index) => {
      if (!employee.name) {
        errors[index] = { ...errors[index], name: "Name is required." };
      }
      if (!employee.email) {
        errors[index] = { ...errors[index], email: "Email is required." };
      } else if (!/\S+@\S+\.\S+/.test(employee.email)) {
        errors[index] = { ...errors[index], email: "Invalid email address." };
      }
      if (!employee.teamId) {
        errors[index] = { ...errors[index], teamId: "Team is required." };
      }
      if (!employee.role) {
        errors[index] = { ...errors[index], role: "Role is required." };
      }
    });
    setErrors(errors);
    return errors.every(error => Object.keys(error).length === 0);
  }

  const submit = () => {
    if (validate()) {
      createEmployee(employes).then((res) => {
        let results = res.data;
        if (results !=null && results.length && results.length >0) {
          toast.success("Employee created successfully",{
            position: "bottom-left",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
          addEmployeeClose();
          setEmployes([employeeTemplate])
        } else {
          toast.error("Employee already exist",{
            position: "bottom-left",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
        }
       
      });
    }
  }

  return (
    <div>
      <Box className="addemployee-popup" style={{ width: '900px' }} >
        <Typography className="addEmployee-heading">
          <h2>Add Employee</h2>
        </Typography>
        <hr />
        {
          employes.map((employee, index) => (
            <Grid container key={index} className="addEmployee-content" columnSpacing={2}>
              <Grid item xs={3} >
                <InputLabel>Name</InputLabel>
                <TextField variant="outlined"
                  name="name"
                  className="addEmployee-input"
                  onChange={e => onChange(e, index)}
                  value={employee.name}
                  error={errors[index]?.name ? true : false}
                  helperText={errors[index]?.name}
                />
              </Grid>
              <Grid item xs={3} >
                <InputLabel>Email</InputLabel>
                <TextField variant="outlined"
                  name="email"
                  className="addEmployee-input"
                  onChange={e => onChange(e, index)}
                  value={employee.email}
                  error={errors[index]?.email ? true : false}
                  helperText={errors[index]?.email}
                />
              </Grid>
              <Grid item xs={3}>
                <InputLabel>Team</InputLabel>
                <Autocomplete
                  options={teamList}
                  value={team ? { name: team.name, teamId: team.id } : teamList.teamId}
                  name='teamId'
                  disabled={team}
                  className="addEmployee-input"
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    onChangeAutocomplete(value, index)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Team"
                      error={errors[index]?.teamId ? true : false}
                      helperText={errors[index]?.teamId}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <InputLabel>Role</InputLabel>
                <Select
                  style={{minWidth:"150px"}}
                  className="addEmployee-input"
                  value={employee.role}
                  name="role"
                  onChange={e => onChange(e, index)}
                  sx={{ boxShadow: 'none' }}
                  error={errors[index]?.role ? true : false}
                  helperText={errors[index]?.role}
                >
                  <MenuItem value={"STAFF"}>Staff</MenuItem>
                  <MenuItem value={"VIEWER"}>Viewer</MenuItem>
                  <MenuItem value={"MANAGER"}>Manager</MenuItem>
                  <MenuItem value={"ADMIN"}>Admin</MenuItem>
                </Select>
              </Grid>
              <Grid item className="removeEmployee" >
                <RemoveCircleOutlineIcon onClick={() => removeemployee(index)} />
              </Grid>
            </Grid>
          ))
        }
        <AddCircleOutlineIcon onClick={addemployee} className="addEmployee-button" />
        <Grid item xs={8} className="updatebuttons" justifyContent={'center'}>
          <Grid item>
            <Button type="submit" color="primary" variant="contained" onClick={submit} className="updatesubmitbutton">Submit</Button>
          </Grid>
          <Grid>
            <Button variant="contained" color="secondary" onClick={addEmployeeClose}> Cancel</Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
