import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import UploadEmployee from './UploadEmployee';
import UploadDetails from './UploadDetails';

export default function ImportEmployee({ addEmployeeClose ,setNewEmployee, onClose }) {

    const [activeSteps, setActiveSteps] = useState(1);
    const [empData , setEmpData] = useState("");

    const handleSetActiveStep = (step) => {
        setActiveSteps(step);
      };

    const handleClose = () => {
        onClose();
    };

    const steps = [
        'Upload File',
        'Import Summary',
    ];

    const handleEmployeeDetails = (data) =>{
        setEmpData(data);
    }


    return (
        <div className='importCSV'>
            <div className="yourReviewHeading">
                <CloseIcon onClick={handleClose} />
            </div>

            <Stepper activeStep={activeSteps} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel className='importStepLabel'>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {activeSteps == 1 && (
                <UploadEmployee handleSetActiveStep={handleSetActiveStep} activeStep={activeSteps} handleEmployeeDetails={handleEmployeeDetails}/>
            )}
            {activeSteps == 2 && (
                <UploadDetails handleSetActiveStep={handleSetActiveStep} setNewEmployee={setNewEmployee} addEmployeeClose={addEmployeeClose} activeStep={activeSteps} onClose={onClose} importEmpData={empData}/>
            )}



        </div>
    )
}
