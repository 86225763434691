import callApiV2 from 'utils/ApiUtilsV2';

export async function createGoal(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/goal");
};

export async function searchGoal(data) {
  return callApiV2("/v1/proxy/goal","GET",null,data,false,false,"/v1/search-alignment");
};

export async function getGoals(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/goals");
};

export async function getFilteredGoals(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/filter-goals");
};

export async function getTeamGoals(data,teamId) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/goals/team/"+teamId);
};

export async function getOrgGoals(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/goals/org");
};

export async function getGoalsSummary(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/goal-status-summary");
};

export async function getCheckInTrend(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/get-checkin-trend");
};

export async function getProgressTrend(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/get-progress-trend");
};

export async function getEmployeeStatus(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/employees-status");
};

export async function createCheckIn(data,goalId) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/goal/"+goalId+"/checkin");
};

export async function createCheckInKI(data,kiId) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/key-initiative/"+kiId+"/checkin");
};

export async function getGoalDetails(goalId) {
  return callApiV2("/v1/proxy/goal","GET",null,null,false,false,"v1/goal/"+goalId);
};

export async function updateGoalAlignment(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/goal/align-parent");
}
export async function getCheckinHistory(goalId,krId) {
  return callApiV2("/v1/proxy/goal","GET",null,null,false,false,"/v1/goal/"+goalId+"/"+ krId +"/checkin");
}

export async function updateGoalSummary(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/update-goal-summary");
};

export async function addNewKeyResult(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/add-keyresult-to-goal");
};

export async function addNewKeyInitiatives(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/create-key-initiative");
};

export async function getKeyInitiatives(keyResultId) {
  return callApiV2("/v1/proxy/goal","GET",null,null,false,false,"v1/key-result/"+keyResultId+"/key-initiatives");
};

export async function getKeyInitiativeDetails(keyInitId) {
  return callApiV2("/v1/proxy/goal","GET",null,null,false,false,"v1/key-initiatives/"+keyInitId);
};

export async function updateKeyResult(data) {
  return callApiV2("/v1/proxy/goal","PATCH",data,null,false,false,"/v1/update-kr");
};

export async function updateKeyInitiatives(data) {
  return callApiV2("/v1/proxy/goal","PATCH",data,null,false,false,"/v1/update-key-initiative");
};

export async function deleteKeyInitiatives(krId, kiId) {
  return callApiV2("/v1/proxy/goal","DELETE",null,null,false,false,"/v1/key-result/"+krId+"/key-initiatives/"+kiId);
};

export async function deleteKeyResult(goalId, krId) {
  return callApiV2("/v1/proxy/goal","DELETE",null,null,false,false,"/v1/goal/"+goalId+"/key-result/"+krId);
};

export async function deleteGoal(goalId) {
  return callApiV2("/v1/proxy/goal","DELETE",null,null,false,false,"/v1/delete-goal/"+goalId);
};

export async function deleteAlignedGoal(alignedId , goalId , alignedType) {
  return callApiV2("/v1/proxy/goal","DELETE",null,null,false,false,"/v1/delete-aligned-goal/"+alignedId+"/goal/"+goalId+"/type/"+alignedType);
};

export async function getKeyInitCheckinHistory(krId,kiId) {
  return callApiV2("/v1/proxy/goal","GET",null,null,false,false,"/v1/key-result/"+krId+"/"+kiId+"/checkin");
}

export async function updateGoalProgress(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/update-goal-progress");
};

export async function getTeamGoalsByTeamId(teamId,data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/get-team-goals/"+teamId);
};

export async function createConversation(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/create-conversation");
};

export async function getConversation(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/get-conversation");
}

export async function getKPIs() {
  return callApiV2("/v1/proxy/goal","GET",null,null,false,false,"/v1/getKPIs");
}

export async function addKrWeightage(data) {
  return callApiV2("/v1/proxy/goal","POST",data,null,false,false,"/v1/add-kr-weightage");
};

// export function getProgressData (dashBoardType){
//     const obj = {
//         labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul"],
//         datasets: [{
//           data: LinechartData1,
//           fill: true,
//           borderColor: 'rgb(0, 204, 102)',
//           backgroundColor: 'rgb(204, 255, 229)',
//           tension: 1,
      
//         }]
//       }
//       return obj;
// }

// export function getEventData (dashBoardType){
//     const obj = {
//         labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "Jul"],
//         datasets: [{
//           data: LinechartData2,
//           fill: true,
//           borderColor: 'rgb(0, 204, 102)',
//           backgroundColor: 'rgb(204, 255, 229)',
//           tension: 1,
      
//         }]
//       }
//       return obj;
// }

