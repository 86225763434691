import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { deleteAlignedGoal, searchGoal, updateGoalAlignment } from 'Services/Goalservice';
import "./GoalAlignment.css"
import { ToastSuccess } from 'utils/Toast';
import DeleteIcon from "../assets/images/deleteIcon.svg"
import { capitalizeFirstLetter } from 'Constants/DateConstants';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Button } from '@mui/material';

const GoalAlignment = ({ onCancel, goalDetail, updateGoal }) => {
  const [searchValue, setSearchValue] = useState('');
  const [alignGoalList, setAlignGoalList] = useState([]);
  const [isListVisible, setIsListVisible] = useState(false);

  const handleSearch = () => {
    if (searchValue.length > 0) {
      setIsListVisible(true);
      searchGoal({ search: searchValue }).then((res) => {
        const results = res.data;
        const filteredResults = results.filter((item) => item.id !== goalDetail.goalId);
        setAlignGoalList(filteredResults);
      });
    }
  };

  const handleSearchInputChange = (event) => {
    const newValue = event.target.value;
    setSearchValue(newValue);
    if (newValue.length == 0) {
      setIsListVisible(false);
    } else {
      handleSearch();
    }
  };

  const handleSearchIconClick = () => {
    handleSearch();
    setIsListVisible(true);
  };

  const [alignGoalRequest, setAlignGoalRequest] = useState("")

  const handleItemSelected = (selectedItem) => {
    setSearchValue(selectedItem.title);
    setIsListVisible(false);
    let alignReq = {
      goalId: goalDetail.goalId,
      parentId: selectedItem.id,
      goalAlignmentType: selectedItem.goalAlignmentType
    }
    setAlignGoalRequest(alignReq);
  };

  const handleSubmit = () => {
    updateGoalAlignment(alignGoalRequest).then((res) => {
      let results = res.data;
      if (results) {
        ToastSuccess({ text: "Goal Alignment updated successfully." });
        onCancel();
        updateGoal();
      }
    })
  }

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState(null);

  const handleDeleteAlignedGoal = (alignmentId, goalId, alignmentType) => {
    // Set the goal to delete and open the confirmation dialog
    setGoalToDelete({ alignmentId , goalId, alignmentType });
    setIsDeleteConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleConfirmationDelete = () => {
    setIsDeleteConfirmationOpen(false);
    const { alignmentId , goalId, alignmentType } = goalToDelete;
    deleteAlignedGoal(alignmentId, goalId, alignmentType).then((res) => {
      ToastSuccess({ text: "Goal Alignment deleted successfully." });
      onCancel();
      updateGoal();
    });
  };

  return (
    <div className='goalAlignment'>
      <h1>Goal Alignment</h1>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        className='goalAlignmentPaper'
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search..."
          value={searchValue}
          onChange={(event) => handleSearchInputChange(event)}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleSearchIconClick}>
          <SearchIcon />
        </IconButton>
      </Paper>

      {isListVisible && (
        <ul className='searchList'>
          {alignGoalList.map((item) => (
            <li key={item.id} onClick={() => handleItemSelected(item)} className='searchItems'>
              {item.title}
            </li>
          ))}
        </ul>
      )}
      {goalDetail.parentGoals.length > 0 ? (
        <div className='alignedGoal'>
          <h2>Aligned Goal</h2>
          {goalDetail.parentGoals.map((parentGoal) => (
            <div key={parentGoal.id} className="parentGoalItems">
              <div>
                <p>{capitalizeFirstLetter(parentGoal.type)}</p>
                <h4>{parentGoal.title}</h4>
              </div>
              <img src={DeleteIcon}
                onClick={() => handleDeleteAlignedGoal(parentGoal.id, goalDetail.goalId, parentGoal.type)}
                className="deleteAlignedGoalButton"
              />
            </div>
          ))}
        </div>
      ) : null}
      <div className='horizontalLine'></div>
      <div className="goalAlignmentButtons">
        <button onClick={() => { onCancel(); }} className="newKrCancelButton" variant="contained">Cancel</button>
        <button className="PrimaryButton" variant="contained" onClick={handleSubmit}>Save</button>
      </div>

      <Dialog
        open={isDeleteConfirmationOpen}
        onClose={handleConfirmationClose}
      >
        <DialogTitle id="responsive-dialog-title">
          <h2>DELETE GOAL</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this GOAL
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirmationDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default GoalAlignment;
