import React from "react";
import "./GoalsGrids.css"
import Button from '@mui/material/Button';
import SmsIcon from '@mui/icons-material/Sms';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckInHistory from "./CheckInHistory";
import CheckInKIHistory from "./CheckInKIHistory";
import { ToastError, ToastSuccess } from "utils/Toast";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  function CheckinHistoryDialog(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  CheckinHistoryDialog.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

export default function CheckInMenu({node}) {

    const [openHistory, setOpenHistory] = React.useState(false);

    const handleHistoryOpen = () => {
        let allowedFeaturesString = localStorage.getItem("allowedFeatures");
        let allowedFeatures = []
        if(allowedFeaturesString && allowedFeaturesString !=null){
            allowedFeatures =  allowedFeaturesString.split(",");
        }
        if(allowedFeatures.includes("checkInHistory")) {
            setOpenHistory(true);
        } else {
            ToastSuccess({ text: "Kindly upgrade your plan to use this feature" })
        }
      
    };
    const handleHistoryClose = () => {
      setOpenHistory(false);
    };

    return (
        <div className="menuitems smsIcon">
            <Button
                onClick={handleHistoryOpen}
            >
                <SmsIcon />
            </Button>
            <BootstrapDialog
                onClose={handleHistoryClose}
                aria-labelledby="customized-dialog-title"
                open={openHistory}
            >
                <CheckinHistoryDialog id="customized-dialog-title" onClose={handleHistoryClose}>
                    CheckIn History
                </CheckinHistoryDialog>
                <DialogContent dividers className="checkInMenuContent">
                    {node.keyResultId ? 
                     <CheckInKIHistory krId={node.keyResultId} kiId={node.id} />
                     :<CheckInHistory goalId={node.goalId} krId={node.id} />
                    }
                </DialogContent>
            </BootstrapDialog>
        </div>
    )
}
