import React from 'react';
import "./NewAddGoals.css"
import LevelIcon from "../assets/images/fi-rr-layers.svg"
import User from "../assets/images/UserIcon.svg"
import ProgressIcon from "../assets/images/Progress.svg"
import TimeLineIcon from "../assets/images/LevelIcon.svg"
import VisibilityIcon from "../assets/images/visibility.svg"
import { ClickAwayListener, Popover } from '@mui/material';
import { useState } from 'react';
import { AssigneeDropDown, EditProgress, LevelDropDown, SessionDropDown, VisibilityDropDown } from './GoalDetails';
import { PROGRESSTYPE } from 'Constants/Styles';
import { capitalizeFirstLetter, getCurrentQuarterRange, getNumericformatedDate } from 'Constants/DateConstants';
import { createGoal } from 'Services/Goalservice';
import { ToastSuccess } from 'utils/Toast';
import ErrorIcon from '@mui/icons-material/Error';

export default function NewAddGoal({goals, handleClose, handleOpenKR, goalData }) {
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [showLevelDropdown, setShowLevelDropdown] = useState(false);
  const [showSessionDropdown, setShowSessionDropdown] = useState(false);
  const [showVisibilityDropdown, setShowVisibilityDropdown] = useState(false);
  const rangeStartDate = getCurrentQuarterRange()[0]
  const rangeEndDate = getCurrentQuarterRange()[1]
  const [errorMessage, setErrorMessage] = useState('');
  const [goalDetail, setGoalDetail] = useState({
    goalName: "",
    goalOwners: [],
    goalLevel: "",
    teamId: "",
    visibility: "",
    alignedId: "",
    alignedType: null,
    progress: {
      kpiType:"",
      kpiName: "",
      progressType: PROGRESSTYPE.PERCENTAGE,
      progressStartValue: "0",
      progressEndValue: "100",
    },
    goalTimeline: {
      goalCycle: "",
      dueDate: rangeEndDate,
      startDate: rangeStartDate
    },
    goalProgressUpdateType: "AUTOMATIC",
    krId: ""
  });

  React.useEffect(() => {
    if (goals) {
      setGoalDetail(goals);
    }
  },[goals]);
  
  const updateSelectedEmployees = (selected) => {
    const updatedGoalDetail = { ...goalDetail, goalOwners: selected };
    setGoalDetail(updatedGoalDetail);
  };

  const updateSelectedLevel = (selected) => {
    const updatedGoalDetail = { ...goalDetail, goalLevel: selected };
    setGoalDetail(updatedGoalDetail);
    setShowLevelDropdown(false);
  };

  const updateTeamSelected = (selected) => {
    const updatedGoalDetail = {
      ...goalDetail,
      goalLevel: selected.level,
      teamName: selected.team.name,
      teamId: selected.team.id,
    };
    setGoalDetail(updatedGoalDetail);
    setShowLevelDropdown(false);
  };

  const [anchorElProgress, setAnchorElProgress] = useState(null);

  const handleOpenPopOver = (event) => {
    setAnchorElProgress(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorElProgress(null);
  };

  const openProgressPopOver = Boolean(anchorElProgress);

  const id = openProgressPopOver ? 'simple-popover' : undefined;

  const updateSelectedProgress = (progress) => {
    if(progress.kpiType){
      if(progress.progressStartValue < progress.progressEndValue){
        progress.progressType = "INCREASE_KPI"
      }else{
        progress.progressType = "DECREASE_KPI"
      }
    }
    const updatedGoalDetail = {
      ...goalDetail,
      progress: {
        ...goalDetail.progress,
        kpiName: progress.kpiName,
        kpiType : progress.kpiType,
        progressType: progress.progressType,
        progressStartValue: progress.progressStartValue,
        progressEndValue: progress.progressEndValue,
      },
    };
    setGoalDetail(updatedGoalDetail);
  }

  const closeSessions = () => {
    setShowSessionDropdown(false);
  }

  const updateSessionSelected = (sessions) => {
    const updatedGoalDetail = {
      ...goalDetail,
      goalTimeline: {
        goalCycle: sessions.goalCycle,
        startDate: sessions.startDate,
        dueDate: sessions.dueDate,
      },
    }
    setGoalDetail(updatedGoalDetail);
  }

  const updateSelectedVisibility = (selected) => {
    const updatedGoalDetail = { ...goalDetail, visibility: selected };
    setGoalDetail(updatedGoalDetail);
    setShowVisibilityDropdown(false);
};

  const handleAddGoal = (e) => {
    e.preventDefault();
    const missingFields = [];

    if (!goalDetail.goalName) {
      missingFields.push('Goal Name');
    }

    if (!goalDetail.visibility) {
      missingFields.push('Visibility');
    }

    if (goalDetail.goalOwners.length === 0) {
      missingFields.push('Assignee');
    }

    if (!goalDetail.goalLevel) {
      missingFields.push('Level');
    }

    if (goalDetail.progress.progressType === 'INCREASE_KPI' || goalDetail.progress.progressType === 'DECREASE_KPI') {
      if (!goalDetail.progress.kpiName) {
        missingFields.push('KPI Name');
      }
    }

    if (!goalDetail.goalTimeline.goalCycle) {
      missingFields.push('Timeline');
    }

    if (missingFields.length > 0) {
      setErrorMessage(`Please fill in the following fields: ${missingFields.join(' , ')}.`);
    } else {
      if (goalDetail.progress.kpiType) {
        goalDetail.progress.progressType = goalDetail.progress.kpiType;
      }
      createGoal(goalDetail).then((res) => {
        let results = res.data;
        if (results) {
          ToastSuccess("Goal created successfully", {
            position: "bottom-left",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });
          goalData(results);
          handleClose();
          handleOpenKR();
          setErrorMessage("")
        }
      });
    }
  }

  return (
    <>
      <div className='addGoalModal'>
        <div className='addGoals'>
          <div className='addGoalsHeading'>
          <h1>Add Goals</h1>
          </div>
          <input
            name="title"
            placeholder='Goal name'
            value={goalDetail.goalName}
            fullWidth
            className="addGoalsInputs"
            onChange={(e) => {
              setGoalDetail({ ...goalDetail, goalName: e.target.value })
            }}
          />
          <div className='newAddGoalContent'>
            <ClickAwayListener onClickAway={() => setShowAssigneeDropdown(false)}>
              <div className='newAddGoalItem' onClick={() => setShowAssigneeDropdown(true)}>
                <img src={User} />
                {goalDetail.goalOwners.length > 0 ? `Assigned to ${goalDetail.goalOwners.length} Emp` : 'Assignee'}
                {showAssigneeDropdown && (
                  <AssigneeDropDown selected={goalDetail.goalOwners} updateSelected={updateSelectedEmployees} />
                )}
              </div>
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => setShowLevelDropdown(false)}>
              <div className='newAddGoalItem' onClick={() => setShowLevelDropdown(!showLevelDropdown)}>
                <img src={LevelIcon} />
                {goalDetail.goalLevel ? (
                  <>
                    {capitalizeFirstLetter(goalDetail.goalLevel)}
                    {goalDetail.goalLevel === "TEAM" ? ` - ${goalDetail.teamName}` : null}
                  </>
                ) : 'Level'}
                {showLevelDropdown && (
                  <LevelDropDown selected={goalDetail.goalLevel} updateSelected={updateSelectedLevel} updateTeamSelected={updateTeamSelected} />
                )}
              </div>
            </ClickAwayListener>

            <div className='newAddGoalItem' onClick={handleOpenPopOver}>
              <img src={ProgressIcon} /> Progress
            </div>
            <Popover
              id={id}
              open={openProgressPopOver}
              anchorEl={anchorElProgress}
              onClose={handleClosePopOver}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <EditProgress progressData={goalDetail.progress} handleProgressClose={handleClosePopOver} updateSelectedProgress={updateSelectedProgress} />
            </Popover>
            <ClickAwayListener onClickAway={() => setShowSessionDropdown(false)}>
              <div className='newAddGoalItem'>
              <div className='sessionsItem' onClick={() => setShowSessionDropdown(true)}>
                <img src={TimeLineIcon} />
                {goalDetail.goalTimeline.goalCycle ? (
                  <>
                    {`${getNumericformatedDate(goalDetail.goalTimeline.startDate)} - ${getNumericformatedDate(goalDetail.goalTimeline.dueDate)}`}
                  </>
                ) : 'TimeLine'}
              </div>
              {showSessionDropdown && (
                  <SessionDropDown goalTimeline={goalDetail.goalTimeline} updateSessionSelected={updateSessionSelected} closeSessions={closeSessions} />
                )}
              </div>
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => setShowVisibilityDropdown(false)}>
            <div className='newAddGoalItem' onClick={() => setShowVisibilityDropdown(!showVisibilityDropdown)}>
                <img src={VisibilityIcon} />
                {goalDetail.visibility ? `${capitalizeFirstLetter(goalDetail.visibility)}` : "Visibility" }
                {showVisibilityDropdown && (
                        <VisibilityDropDown selected={goalDetail.visibility} updateSelected={updateSelectedVisibility} />
                )}
              </div>
            </ClickAwayListener>
          </div>
        </div>
        <div className="error-message addGoalErrorMessage">
          {errorMessage && (
            <div className="errorMessage">
              <ErrorIcon />
              <p>{errorMessage}</p>
            </div>
          )}
        </div>
        <div className='newKrHR'></div>
        <div className="newKrButton">
          <button onClick={handleClose} className="newKrCancelButton" variant="contained">Cancel</button>
          <button  onClick={handleAddGoal} className="PrimaryButton" variant="contained">Create Goal</button>
        </div>
      </div>
    </>
  )
}
