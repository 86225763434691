import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Popper from '@mui/material/Popper';
import TextField from "@mui/material/TextField";
import { createCheckIn, createCheckInKI } from 'Services/Goalservice';
import './SliderProgressBar.css'
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { booleanProgressTypeValue, getColorByStatus, progressType } from "Constants/Styles";
import { useEffect } from "react";
import { ToastError, ToastSuccess } from "utils/Toast";
import { PROGRESSTYPE } from "Constants/Styles";
import { capitalizeFirstLetter } from "Constants/DateConstants";
import { useState } from "react";

const PopoverContent = ({setIsInputProgressChange, first,setFirst ,progress , value, oldvalue, setValue, krId, kiId, goalId, progressType, progressEndValue, progressStartValue, setAnchorEl, setOldvalue, updateGoalProgress, status ,loadKIS}) => {
  const [comment, setComment] = React.useState("");
  const [valueError, setError] = React.useState(false)

  if(first){
    if(progressStartValue > progressEndValue){
      value = progressStartValue - value;
    }else{
      value = progressStartValue + value;
    }
  }
  const [checkInData, setCheckInData] = React.useState({
    progressValue: null,
    comment: null,
    krId: null,
    kiId: null
  });

  const handleSave = (e) => {
    e.preventDefault();
      setCheckInData({
        ...checkInData,
        progressValue: value,
        comment: comment,
        krId: krId.toString(),
      })
     { kiId ?

      createCheckInKI({ progressValue: value, comment: comment, keyResultId: krId }, kiId).then((res) => {
        let results = res.data;
        if (results != null) {
          setAnchorEl(null)
              setValue(value)
              if(progressStartValue > progressEndValue){
                setOldvalue(progressStartValue - value)
              }else{
                if(progressStartValue < 0){
                  setOldvalue(progressStartValue - value)
                }else{
                  setOldvalue(Math.abs(progressStartValue - value))
                }
              }
          ToastSuccess({ text: "Progress updated successfully" })
          loadKIS(krId);
        } else {
          ToastError({ text: "Error while updating progress" })
        }
      }) 
      :
      createCheckIn({ progressValue: value, comment: comment, krId: krId.toString()}, goalId).then((res) => {
            let results = res.data;
            if (results != null) {
              setAnchorEl(null)
              setValue(value)
              if(progressStartValue > progressEndValue){
                setOldvalue(progressStartValue - value)
              }else{
                if(progressStartValue < 0){
                  setOldvalue(progressStartValue - value)
                }else{
                  setOldvalue(Math.abs(progressStartValue - value))
                }
              }
              ToastSuccess({ text: "Progress updated successfully" })
              for (const goal of results) {
                const { goalId, keyResultResponseList } = goal;
                updateGoalProgress(goalId);
              }
            } else {
              ToastError({ text: "Error while updating progress" })
            }
      }) }
  }

  const handleClose = () => {
    setValue(progress)
    setFirst(true)  
    setAnchorEl(null);
  }


  return (
    <form className="slider">
      <div className="popper-arrow" />
      <h2>CheckIn</h2> 
      <Grid className="progresContent">
        <Grid xs={6} className="progressbarPercentage">
          <InputLabel className="ProgressbarLabel">{capitalizeFirstLetter(progressType)} </InputLabel>
          <TextField
          className="checkInTextBox"
            type={progressType == PROGRESSTYPE.BOOLEAN ? "text" : "number"}
            value={progressType == PROGRESSTYPE.BOOLEAN ?
              booleanProgressTypeValue(Math.round(value)) 
            : value }
            onChange={(event) => {
              const inputValue = event.target.value;
              setValue(inputValue);
              setIsInputProgressChange(true);
            }}
          />
          <InputLabel className="ProgressbarLabel">
          Target : {progressType == PROGRESSTYPE.BOOLEAN ? (
           <span>{booleanProgressTypeValue(progressEndValue)} </span>
          ) : (
            <span>{progressEndValue}</span>
          )}
          </InputLabel>
        </Grid>
        <Grid xs={6} className="progressbarPercentage">
          <InputLabel className="ProgressbarLabel">Status </InputLabel>
          <div className="ProgressbarStatus" style={{border : `0.5px solid ${getColorByStatus(status)}`}}>
            <h4 style={{color : getColorByStatus(status)}}>{capitalizeFirstLetter(status)}</h4>
          </div>
        </Grid>
      </Grid>
      <InputLabel className="ProgressbarLabel">Comment (Optional)</InputLabel>
      <input
        type="text"
        placeholder="Type comments here"
        className="textBoxCheckInComment"
        value={comment}
        onChange={(event) => setComment(event.target.value)}
        fullWidth
        rows={5}
      />
      <Box className="popper-buttons">
          <button
            onClick={handleClose}
            className="cancelCheckIn"
          >
            Cancel
          </button>
        <button className="submitCheckIn" onClick={handleSave} >
          Check-In
        </button>
      </Box>
    </form>
  );
};

export default function SliderProgressBar({ progressEndValue, progressStartValue, progressType, krId, kiId, goalId, progress, progressPercentage, updateGoalProgressBar, status , loadKIS}) {
  const targetValue = Math.abs(progressEndValue - progressStartValue);
  let innerProgress = Math.abs(progressStartValue - progress);
  const [first , setFirst] = useState(true);
  const [isInputProgressChange , setIsInputProgressChange] = useState(false);
  const [value, setValue] = React.useState(innerProgress);
  const [oldvalue, setOldvalue] = React.useState(innerProgress);
  const [isSliding, setIsSliding] = React.useState(false);

  const handleMouseDown = (event) => {
    if (!krId) return;
    setIsSliding(true);
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
      if(!krId){
        setValue(progressPercentage);
        setFirst(true)
      }
  }, [progressPercentage])

  const handleMouseMove = (event, newValue) => {
    if (!krId || !isSliding) return;
    setFirst(true)
    setValue(newValue);
  };

  const handleMouseUp = () => {
    if (!krId) return;
    setIsSliding(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (!krId || isSliding) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (!krId) return;
    setAnchorEl(null);
  };

  const updateGoalProgress = (goalId) => {
    updateGoalProgressBar(goalId)
  }

  const setValueFunction = (val) => {
    if(isInputProgressChange){
      setValue(val)
      setFirst(false);
      setIsInputProgressChange(false);
    }
  };

  React.useEffect(()=>{
    setFirst(true)
    setValue(innerProgress)
  },[progress])

  const thumbStyle = {
    width: 11,
    height: 24,
    borderRadius: 6,
    border : "2px solid #fff",
    backgroundColor: '#EF6D00',
    marginLeft : "5px"
  };

  return (
    <Box className="borderLineProgress" id={kiId ? "kiIdprogress" : krId ? "kridProgress" : ""}>
      <Slider
        value={value}
        onChange={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onClick={handleClick}
        min={0}
        max={targetValue}
        style={{ color: getColorByStatus(status) }}
        className="sliderBar"
        sx={{
          '& .MuiSlider-thumb': thumbStyle,
          '& .MuiSlider-rail': {
            backgroundColor: '#E9E9E9',
          },
        }}
      />
      {krId ?
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          className="popperCheckIn"
        >
          <PopoverContent
            value={value}
            first={first}
            setFirst={setFirst}
            oldvalue={oldvalue}
            setValue={setValueFunction}
            krId={krId}
            goalId = {goalId}
            progressType={progressType}
            progressEndValue={progressEndValue}
            progressStartValue={progressStartValue}
            setAnchorEl={setAnchorEl}
            setOldvalue={setOldvalue}
            updateGoalProgress={updateGoalProgress}
            progress={innerProgress}
            kiId={kiId}
            status={status}
            loadKIS={loadKIS}
            setIsInputProgressChange={setIsInputProgressChange}
          />
        </Popper>
        : null
      }
    </Box>
  );
}
