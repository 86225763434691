import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import '../Components/CheckInHistory.css'
import AvatarBuilder from 'utils/AvatarBuilder';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getKeyInitCheckinHistory } from 'Services/Goalservice';
import { booleanProgressTypeValue, getColorByStatus, PROGRESSTYPE } from "Constants/Styles";
import { TimelineDot } from '@mui/lab';
import { capitalizeFirstLetter } from 'Constants/DateConstants';
import { Button } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

export default function CheckInKIHistory({ krId , kiId }) {
    const [historyDetails, setHistoryDetails] = React.useState([]);
    
    const loadHistory = (krId, kiId) => {

        getKeyInitCheckinHistory(krId, kiId).then((res) => {
            let results = res.data;
            if (results) {
                setHistoryDetails(results);
                return;
            }
        })
    }
    React.useEffect(() => {
        loadHistory(krId, kiId);
    }, []);

    return (
        <div className='CheckInHistory'>
            <h1>CheckIn History Details</h1>
            {historyDetails.length === 0 ?
                <div className='notFound'>
                    <p>No Check found</p>
                </div>
                :
                <div>
                    {
                        historyDetails.map((item, index) => {
                            return (
                                <Timeline
                                    sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                    }}
                                >
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <TimelineDot variant="outlined" style={{ borderColor : getColorByStatus(item.kiStatus) }} />
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <div className='checkinContent'>
                                                <AvatarBuilder employee={{ 'name': item.owner.empName }} variant="circular" size="small" />
                                                <h4>{item.owner.empName}</h4>
                                                <div className='checkinProgressContent'>
                                                    <h2>Change the Progress : </h2>
                                                    (<h1>{item.krProgressType == PROGRESSTYPE.BOOLEAN ? booleanProgressTypeValue(item.kiPreviousValue) : item.kiPreviousValue}</h1>
                                                    <ArrowRightAltIcon />
                                                    <h1>{ item.krProgressType == PROGRESSTYPE.BOOLEAN ? booleanProgressTypeValue(item.kiNewValue) : item.kiNewValue}</h1>)
                                                    <h3>{capitalizeFirstLetter(item.krProgressType)}</h3>
                                                </div>
                                            </div>
                                            <div className='checkinContent'>
                                                <div className='checkinStatusContent'>
                                                    <h2>Status : </h2>
                                                    <Button style={{ color: getColorByStatus(item.kiStatus) }} className='checkInStatus'>{capitalizeFirstLetter(item.kiStatus)}</Button>
                                                </div>
                                                <div className='checkinTimeline'>
                                                    <AccessTimeIcon />
                                                    <h2>{item.date}</h2>
                                                </div>
                                            </div>
                                            {
                                                item.comment ?
                                                    <div className='checkinComment'>
                                                        <h2>Comment :</h2>
                                                        <h3>{item.comment}</h3>
                                                    </div>
                                                : null
                                            }
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            )

                        })
                    }
                </div>
            }
        </div>
    )
}
