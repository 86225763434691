import React from 'react';
import LoginImages from "../../assets/images/LoginImages.svg";
import Logo from "../../assets/logo.png";

export default function AuthLeftContent() {
    return (
        <div className='authLoginLeftContainer'>
            <img src={Logo} className='loginLogo' />
            <p>Entitle your organisation for superior performance, enhanced engagement, and lasting success.</p>
            <img src={LoginImages} className='loginBackground' />
        </div>
    )
}
