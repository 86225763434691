import React, { useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import "./PerformanceQuestion.css";
import AddIcon from '@mui/icons-material/Add';
import TextQuestion from './TextQuestion';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import RatingQuestion from './RatingQuestion';
import { ToastError } from 'utils/Toast';
import { PERFORMANCE_STEPPERS } from 'Constants/Styles';


export default function PerformanceQuestion({ handleSetActiveStep, activeStep, handleSaveQuestion, data , reviewId, onClose}) {
  const [reviewQuestions, setReviewQuestions] = useState(data);

  const handleNext = () => {
    if (reviewQuestions.length != 0) {
      const nextStep = Math.min(activeStep + 1, 4);
      handleSetActiveStep(nextStep);
      handleSaveQuestion(reviewQuestions)
    } else {
      ToastError({ text: "Please Add any Question" })
    }

    if(activeStep === PERFORMANCE_STEPPERS.PEER && reviewQuestions.length != 0){
      onClose();
    }
  };

  const handlePrevious = () => {
    const prevStep = Math.max(activeStep - 1, 0);
    handleSaveQuestion(reviewQuestions)
    handleSetActiveStep(prevStep);
  };

  const handleAddNewQuestion = (newQuestionData) => {
    setReviewQuestions((prevState) => [...prevState, newQuestionData]);
  };

  const handleAddNewMultiChoiceQuestion = (newMultiChoiceQuestionData) => {
    setReviewQuestions((prevState) => [...prevState, newMultiChoiceQuestionData]);
  };

  const handleAddNewRatingQuestion = (newRatingQuestionData) => {
    setReviewQuestions((prevState) => [...prevState, newRatingQuestionData]);
  };

  const handleCloseQuestionForPR = () => {
    setShowQuestionForPR(false);
  };

  const handleCloseMultiChoiceQuestionForPR = () => {
    setShowMultiChoiceQuestionForPR(false);
  };

  const handleCloseRatingQuestionForPR = () => {
    setShowRatingQuestionForPR(false);
  };

  const handleCloseEditQuestion = () => {
    setShowEditQuestion(false);
  };

  const handleCloseEditMultiChoiceQuestion = () => {
    setShowEditMultiChoiceQuestion(false);
  };

  const handleCloseEditRatingQuestion = () => {
    setShowEditRatingQuestion(false);
  };

  const [showQuestionForPR, setShowQuestionForPR] = useState(false);
  const handleOpenQuestionForPR = () => setShowQuestionForPR(true);

  const [showMultiChoiceQuestionForPR, setShowMultiChoiceQuestionForPR] = useState(false);
  const handleOpenMultiChoiceQuestionForPR = () => setShowMultiChoiceQuestionForPR(true);

  const [showRatingQuestionForPR, setShowRatingQuestionForPR] = useState(false);
  const handleOpenRatingQuestionForPR = () => setShowRatingQuestionForPR(true);

  const [showEditQuestion, setShowEditQuestion] = useState(false);
  const [questionToEditIndex, setQuestionToEditIndex] = useState(null);
  const [questionToEditData, setQuestionToEditData] = useState("");
  const handleEditQuestion = (index) => {
    const questionToEdit = reviewQuestions[index];
    setShowEditQuestion(true);
    setQuestionToEditIndex(index);
    setQuestionToEditData(questionToEdit);
  };

  const [showEditMultiChoiceQuestion, setShowEditMultiChoiceQuestion] = useState(false);
  const [multiChoiceQuestionToEditIndex, setMultiChoiceQuestionToEditIndex] = useState(null);
  const [multiChoiceQuestionToEditData, setMultiChoiceQuestionToEditData] = useState("");
  const handleEditMultiChoiceQuestion = (index) => {
    const multiChoiceQuestionToEdit = reviewQuestions[index];
    setShowEditMultiChoiceQuestion(true);
    setMultiChoiceQuestionToEditIndex(index);
    setMultiChoiceQuestionToEditData(multiChoiceQuestionToEdit);
  }

  const [showEditRatingQuestion, setShowEditRatingQuestion] = useState(false);
  const [ratingQuestionToEditIndex, setRatingQuestionToEditIndex] = useState(null);
  const [ratingQuestionToEditData, setRatingQuestionToEditData] = useState("");
  const handleEditRatingQuestion = (index) => {
    const questionToEdit = reviewQuestions[index];
    setShowEditRatingQuestion(true);
    setRatingQuestionToEditIndex(index);
    setRatingQuestionToEditData(questionToEdit);
  };

  const handleEditRatingQuestionSave = (editedRatingQuestionData) => {
    setReviewQuestions((prevReviewQuestions) => {
      const updatedReviewRatingQuestions = [...prevReviewQuestions];
      updatedReviewRatingQuestions[ratingQuestionToEditIndex] = editedRatingQuestionData;
      return updatedReviewRatingQuestions;
    });
    setShowEditRatingQuestion(false);
  };

  const handleEditSave = (editedQuestionData) => {
    setReviewQuestions((prevReviewQuestions) => {
      const updatedReviewQuestions = [...prevReviewQuestions];
      updatedReviewQuestions[questionToEditIndex] = editedQuestionData;
      return updatedReviewQuestions;
    });
    setShowEditQuestion(false);
  };

  const handleEditMultiChoiceQuestionSave = (editedMultiChoiceQuestionData) => {
    setReviewQuestions((prevReviewQuestions) => {
      const updatedReviewMultiChoiceQuestions = [...prevReviewQuestions];
      updatedReviewMultiChoiceQuestions[multiChoiceQuestionToEditIndex] = editedMultiChoiceQuestionData;
      return updatedReviewMultiChoiceQuestions;
    });
    setShowEditMultiChoiceQuestion(false);
  };

  const handleDeleteQuestion = (selectedQuestionToDelete) => {
    setReviewQuestions((prevReviewQuestions) => {
      const updatedReviewQuestions = [...prevReviewQuestions];
      updatedReviewQuestions.splice(selectedQuestionToDelete, 1);
      return updatedReviewQuestions;
    });
    setSelectedQuestionToDelete(null);
  };

  const [selectedQuestionToDelete, setSelectedQuestionToDelete] = useState(null);

  const handleOpenDeleteConfirmation = (index) => {
    setSelectedQuestionToDelete(index);
  };

  const handleCloseDeleteConfirmation = () => {
    setSelectedQuestionToDelete(null);
  };

  const dragItem = useRef(null)
  const dragOverItem = useRef(null)

  const handleSort = () => {
    let _reviewQuestions = [...reviewQuestions]
    const dragItemContent = _reviewQuestions.splice(dragItem.current, 1)[0]

    _reviewQuestions.splice(dragOverItem.current, 0, dragItemContent)

    dragItem.current = null
    dragOverItem.current = null

    setReviewQuestions(_reviewQuestions)
  }

  return (
    <div className='PRQContent'>
      <div className='questionHeading'>
        {activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ?
          <><h2>SELF ASSESSMENT</h2>
            <h2>REVIEWER ASSESSMENT</h2></> : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? <h2>EMPLOYEE TO MANAGER</h2> : <h2>PEER </h2>}
      </div>
      {reviewQuestions.map((question, index) => (
        <div className='PRQuestion'
          key={index}
          onDragEnter={(e) => dragOverItem.current = index}
          onDragOver={(e) => e.preventDefault()}
        >
          <div className='assessmentContent'>
            <div className='selfAssessmentQuestion'>
              <h3><span>{question.answerType == "OPTION" ? "MultiPle Choice Question" : question.answerType == "RATING" ? "Rating Question" : "Text Question :"} </span>{activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ?
                question.selfQuestion : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? question.empToManagerQuestion : question.peerQuestion}</h3>
            </div>
            <div className='reviewerAssessmentQuestion'>
              {
                activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ?
                  (question.reviewerQuestion ?
                    <h3><span>{question.answerType == "OPTION" ? "MultiPle Choice Question" : question.answerType == "RATING" ? "Rating Question" : "Text Question :"} </span>{question.reviewerQuestion}</h3> :
                    <h3><span>{question.answerType == "OPTION" ? "MultiPle Choice Question" : question.answerType == "RATING" ? "Rating Question" : "Text Question :"} </span>NO QUESTION FOR REVIEWER</h3>) : null
              }
              <div className='iconsInQstnPr'>
                {
                  question.answerType == "OPTION" ?
                    <EditOutlinedIcon onClick={() => handleEditMultiChoiceQuestion(index)} /> :
                    question.answerType == "RATING" ?
                      <EditOutlinedIcon onClick={() => handleEditRatingQuestion(index)} /> :
                      <EditOutlinedIcon onClick={() => handleEditQuestion(index)} />
                }
                <div
                  draggable
                  onDragStart={(e) => {
                    e.dataTransfer.setData("text/plain", "");
                    dragItem.current = index;
                  }}
                  onDragEnd={handleSort}
                  style={{ cursor: "grab" }}
                >
                  <DragIndicatorOutlinedIcon />
                </div>
                <DeleteOutlineIcon onClick={() => handleOpenDeleteConfirmation(index)} />
              </div>
            </div>
          </div>
          {question.answerType == "OPTION" ? (
            <div className='DummyRadioButtonForQuestion'>
              <RadioGroup
                name="optionalAnswer"
              >
                {question.options.map((option, optionIndex) => (
                  <FormControlLabel
                    key={optionIndex}
                    value={option}
                    control={<Radio />}
                    label={option}
                    disabled={true}
                  />
                ))}
              </RadioGroup>
            </div>
          ) : question.answerType == "RATING" ? (
            <div className='DummyRatingForQuestion'>
              <FormControl>
                <RadioGroup
                  aria-label="rating"
                  name="rating"
                  value={null}
                >
                  {Array.from({ length: question.rating }, (_, i) => i + 1).map((value) => (
                    <FormControlLabel key={value} disabled control={<Radio />} label={value.toString()} />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          ) : (
            <div className='DummyTextBoxForQuestion'>
              <TextField
                name="selfAssessmentAnswer"
                fullWidth
                multiline
                rows={2}
                disabled={true}
              />
              {
                activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? (question.reviewerQuestion ?
                  <TextField
                    name="reviewerAssessmentAnswer"
                    fullWidth
                    multiline
                    rows={2}
                    disabled={true}
                  /> : null) : null
              }
            </div>
          )}
        </div>
      ))}
      {showEditQuestion && <TextQuestion onClose={handleCloseEditQuestion} onQuestionSave={handleEditSave} initialData={questionToEditData} activeStep={activeStep} reviewId={reviewId}/>}
      {showEditMultiChoiceQuestion && <MultipleChoiceQuestion onClose={handleCloseEditMultiChoiceQuestion} onQuestionSave={handleEditMultiChoiceQuestionSave} initialData={multiChoiceQuestionToEditData} activeStep={activeStep} reviewId={reviewId}/>}
      {showEditRatingQuestion && <RatingQuestion onClose={handleCloseEditRatingQuestion} onQuestionSave={handleEditRatingQuestionSave} initialData={ratingQuestionToEditData} activeStep={activeStep} reviewId={reviewId}/>}
      {showQuestionForPR && <TextQuestion onClose={handleCloseQuestionForPR} onQuestionSave={handleAddNewQuestion} activeStep={activeStep} reviewId={reviewId}/>}
      {showMultiChoiceQuestionForPR && <MultipleChoiceQuestion onClose={handleCloseMultiChoiceQuestionForPR} onQuestionSave={handleAddNewMultiChoiceQuestion} activeStep={activeStep} reviewId={reviewId}/>}
      {showRatingQuestionForPR && <RatingQuestion onClose={handleCloseRatingQuestionForPR} onQuestionSave={handleAddNewRatingQuestion} activeStep={activeStep} reviewId={reviewId}/>}
      <div className='addQuestionButton'>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleOpenQuestionForPR} className='SecondaryButton'>
          Add Question
        </Button>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleOpenMultiChoiceQuestionForPR} className='SecondaryButton'>
          Add Multiple Choice Question
        </Button>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleOpenRatingQuestionForPR} className='SecondaryButton'>
          Add Rating Question
        </Button>
      </div>
      <div className='PRCButtons'>
        <Button variant='outlined' onClick={handlePrevious} className='SecondaryButton'>Previous</Button>
        <Button variant="contained" onClick={handleNext} className='PrimaryButton'>{activeStep === PERFORMANCE_STEPPERS.PEER ? 'Submit' : 'Save & Next'}</Button>
      </div>

      <Dialog
        open={selectedQuestionToDelete !== null}
        onClose={handleCloseDeleteConfirmation}
      >
        <DialogTitle id="responsive-dialog-title">
          <h2>DELETE QUESTION</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h4>Are you sure you want to delete both question ?</h4>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteQuestion(selectedQuestionToDelete)} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

