import React from 'react'
import { AvatarGroup, Button, Chip, ClickAwayListener, Drawer, Modal, Popover } from '@mui/material';
import "./GoalDetails.css"
import { useState } from 'react';
import AvatarBuilder from 'utils/AvatarBuilder';
import CloseSvg from "../assets/images/close.svg"
import SliderProgressBar from 'Pages/Home/SliderProgressBar';
import { capitalizeFirstLetter, getNumericformatedDate, getformatedTime } from 'Constants/DateConstants';
import { createConversation, getConversation, getGoalDetails, getKeyInitiativeDetails, getKeyInitiatives, updateKeyResult } from 'Services/Goalservice';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddKeyInitiatives from './AddKeyInitiatives';
import { PROGRESSTYPE, getColorByStatus, truncateText } from 'Constants/Styles';
import { EditProgress, SessionDropDown } from './GoalDetails';
import EditIcon from "../assets/images/EditIcon.svg"
import BackIcon from "../assets/images/backButton.svg"
import NewAddGoal from './NewAddGoal';
import { useNavigate } from 'react-router-dom';
import KeyResultMenu from './KeyResultMenu';
import KeyInitiativeDetails from './KeyInitiativeDetails';

export default function KeyResultDetails({ krData, updateKRData ,handleGoalClose , handleCloseDrawer , deleteKr}) {
    const [krDetail, setKrDetail] = useState(krData);
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();

    const [openGoal, setOpenGoal] = React.useState(false);
    const handleOpenGoal = () => setOpenGoal(true);
    const handleCloseGoal = () => setOpenGoal(false);

    const [openKR, setOpenKR] = useState(false);
    const [updateCloseKI, setUpdateCloseKI] = useState(false);

    useEffect(() => {
        if (updateCloseKI) {
            if (typeof deleteKr === 'function') {
                deleteKr();
            }
            getKI(krDetail.id);
            setUpdateCloseKI(false);
        }
    }, [updateCloseKI]);

    const handleUpdateCloseKI = () => {
        setKiState({ "right": false });
        setUpdateCloseKI(true);
    };

    const updateKIData = (updatedGoalData) => {
        setUpdateCloseKI(true);
      };

    const [kiState, setKiState] = useState({
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setKiState({ ...kiState, [anchor]: open });
      };

    const [kiData, setKiData] = useState("");

    const handleKeyInitDetails = (kiId) =>{
        setKiData("");
        getKeyInitiativeDetails(kiId).then((res) => {
            let results = res.data;
            if (results) {
                setKiData(results);
                setKiState({ right: true });
            }
        });
    }

    const handleOpenKR = () => {
        handleCloseDrawer();
        if (typeof handleGoalClose === 'function') {
            handleGoalClose();
        }
        redirectToGoals();
        setOpenKR(true);
    }

    const redirectToGoals = () => {
        navigate("/goals/" + Date.now());
    }

    const maxVisibleAssignees = 3;

    const visibleAssignees = krDetail.ownerDetails ? krDetail.ownerDetails.slice(0, maxVisibleAssignees) : [];
    const hiddenAssignees = krDetail.ownerDetails ? krDetail.ownerDetails.slice(maxVisibleAssignees) : [];    

    const [showHiddenAssignees, setShowHiddenAssignees] = useState(false);

    const handleToggleHiddenAssignees = () => {
        setShowHiddenAssignees(!showHiddenAssignees);
    };

    const handleDelete = (employeeId) => {
    };

    const [showAllKeyResults, setShowAllKeyResults] = useState(false);

    const handleToggleShowAll = () => {
        setShowAllKeyResults(!showAllKeyResults);
    };

    const buttonLabel = showAllKeyResults ? "Hide Progress" : "View all Progress";

    const keyResultsToDisplay = krDetail && krDetail.keyInitiativeResponseList
    ? showAllKeyResults
      ? krDetail.keyInitiativeResponseList
      : krDetail.keyInitiativeResponseList.slice(0, 2)
    : [];
    
    const [conversation, setConversation] = useState({
        type: krDetail.type,
        typeId: krDetail.id,
        conversation: '',
      });

    const handleInputChange = (value) => {
        setConversation({
            ...conversation,
            conversation: value,
        });
    };

    const [updateMessages, setUpdateMessages] = useState(false);

    const handleSaveClick = () => {
        if (conversation.conversation.trim() !== '') {
            createConversation(conversation).then((res) => {
                let results = res.data;
                if (results) {
                    setUpdateMessages((prev) => !prev);
                    return;
                }
            });
            setConversation({
                ...conversation,
                conversation: '',
            });
        }
    };

    const getMessages = () =>{
        getConversation(conversation).then((res) => {
            let results = res.data;
            if (results) {
               setMessages(results);
            }
        });
    }
      
    useEffect(()=>{
        getMessages();
    },[updateMessages])

    const [showAllMessages, setShowAllMessages] = useState(false);

    const handleToggleShowAllMessages = () => {
        setShowAllMessages(!showAllMessages);
    };

    const messagesToDisplay = showAllMessages ? messages : messages.slice(0, 2);

    const getKI = (KRID) => {
        getKeyInitiatives(KRID).then((res) => {
          let results = res.data;
          if (results && results !== null) {
            setKrDetail(results);
          }
        });
      }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleModalClose = () => {
        setOpen(false); 
    };
    const kinit = {
        title: "",
        assignee: "",
        progressType: PROGRESSTYPE.PERCENTAGE,
        progressStartValue: "",
        progressEndValue: "",
        currentProgress: "",
        id: "",
        keyResultId: -1,
        goalId: -1,
        startDate: "",
        dueDate: ""
      }

    const [goalData, setGoalData] = useState("");

    const getGoalData = () =>{
        getGoalDetails(krDetail.goalId).then((res) => {
            let results = res.data;
            if (results && results != null) {
                setGoalData(results)
            }
          })
    }

    React.useEffect(() => {
        getGoalData();
      }, []);

    const [newKeyInit, setNewKeyInit] = useState(kinit)

    const [goalAssociateData, setGoalAssociateData] = useState("")
    const handleAddGoalAssociate = () => {
        const newAssociate = {
            goalName:krDetail.title,
            description:goalData.description,
            goalLevel:goalData.goalLevel,
            teamId:goalData.teamId,
            goalAlignmentResponse : {
              alignedId:goalData.goalId,
              alignedType:goalData.goalAlignmentType,
              title : goalData.title
            },
            goalOwners:[],
            goalTimeline: {
              goalCycle: goalData.goalTimeline.goalCycle,
              dueDate: goalData.goalTimeline.dueDate,
              startDate: goalData.goalTimeline.startDate
            },
            progress: {
              progressType: krDetail.progressType,
              progressStartValue: krDetail.progressStartValue,
              progressEndValue: krDetail.progressEndValue,
            },
            visibility:goalData.visibility,
            goalProgressUpdateType: goalData.goalProgressUpdateType,
            associatedKrId:krDetail.id,
            goalWeightage : krDetail.krWeightage
          };
      
          let goalOwners = krDetail.ownerDetails.map(owner => ({
            employeeId: owner.employeeId,
            name: null
          }));
          newAssociate.goalOwners = goalOwners;
        handleOpenGoal();
        setGoalAssociateData(newAssociate);
    };

    const [showSessionDropdown, setShowSessionDropdown] = useState(false);

    const handleKrDetailsChange = (updatedGoalDetail) => {
        updateKeyResult(updatedGoalDetail);
        updateKRData(updatedGoalDetail);
    }

    const updateSessionSelected = (sessions) =>{
        const krDetails = {
            ...krDetail,
            goalCycle: sessions.goalCycle,
            startDate: sessions.startDate,
            dueDate: sessions.dueDate
        }
        setKrDetail(krDetails);
        handleKrDetailsChange(krDetails);
    }

    const updateSelectedProgress = (progress) =>{
        const updatedKrDetails = {
            ...krDetail,
            progressType: progress.progressType,
            progressStartValue: progress.progressStartValue,
            progressEndValue: progress.progressEndValue,
        }
        setKrDetail(updatedKrDetails);
        updateKeyResult(updatedKrDetails);
    }

    useEffect(() => {
        getKI(krDetail.id)
        updateKRData(krDetail);
      }, [krDetail.progressType, krDetail.progressStartValue, krDetail.progressEndValue]);

    const [originalTitle, setOriginalTitle] = useState(krDetail.title);
    
    const [anchorElProgress, setAnchorElProgress] = React.useState(null);
    const handleOpenPopOver = (event) => {
      setAnchorElProgress(event.currentTarget);
    };
    const handleClosePopOver = () => {
      setAnchorElProgress(null);
    };
    const openProgressPopOver = Boolean(anchorElProgress);
    const id = openProgressPopOver ? 'simple-popover' : undefined;

    const updateKrDetails =()=>{
        getKI(krDetail.id);
        updateKRData();
    }

    return (
        <div className='goalDetails'>
            <div className='goalDetailsHeading'>
                <div className='goalDetailButtons'>
                    <img src={BackIcon} onClick={handleCloseDrawer}/>
                    <h2>Key Result details</h2>
                </div>
                <div className='goalDetailsButtons'>
                    <Button className='goalDetailsAddButton'
                    onClick={()=>{
                        handleAddGoalAssociate();
                    }}
                    >Add Child KRs</Button>
                    <KeyResultMenu
                    krId={krDetail.id}
                    krData={krDetail}
                    goalId={krDetail.goalId}
                    goalData={goalData}
                    handleAddGoalAssociate={handleAddGoalAssociate}
                    goalAssociateId={goalData.associateGoalId}
                    deleteKr={deleteKr}
                    />
                </div>
            </div>
            <div>
                <div className="goalNameGD">
                    <h2>KR</h2>
                    <input
                        value={krDetail.title}
                        onChange={(e) => {
                            setKrDetail({ ...krDetail, title: e.target.value });
                        }}
                        className='goalNameInput'
                        multiline
                        rows={3}
                        onBlur={() => {
                            if (krDetail.title !== originalTitle) {
                                handleKrDetailsChange(krDetail)
                            }
                        }}
                    />
                </div>
                <div className='goalDetailsItems'>
                    <p>Sessions</p>
                    <ClickAwayListener onClickAway={() => setShowSessionDropdown(false)}>
                    <div className='sessionContent'>
                    <h4>{capitalizeFirstLetter(krDetail.goalCycle)} - {getNumericformatedDate(krDetail.startDate)} to {getNumericformatedDate(krDetail.dueDate)}</h4>
                    <p onClick={() => setShowSessionDropdown(true)}><img src={EditIcon}/>  Edit</p>
                    {showSessionDropdown && (
                        <SessionDropDown goalTimeline={{
                            goalCycle: krDetail.goalCycle,
                            startDate: krDetail.startDate,
                            dueDate: krDetail.dueDate,
                        }} updateSessionSelected={updateSessionSelected} />
                    )}
                    </div>
                    </ClickAwayListener>
                </div>
                <div className='goalDetailsItems'>
                    <p>Assignee</p>
                    <div className='assigneeGD'>
                        {visibleAssignees.map((assignee) => (
                            <Chip
                                key={assignee.employeeId}
                                label={assignee.name}
                                variant="outlined"
                                avatar={<AvatarBuilder employee={{ name: assignee.name }} variant="circular" size="extra-samll" />}
                                onDelete={() => handleDelete(assignee.employeeId)}
                                className='goalAssignees'
                                deleteIcon={<img src={CloseSvg} alt="Close Icon" />}
                            />
                        ))}

                        {hiddenAssignees.length > 0 && (
                            <div
                                className='hidden-assignees'
                                onMouseEnter={handleToggleHiddenAssignees}
                                onMouseLeave={handleToggleHiddenAssignees}
                            >
                                <div className={`hidden-assignees-toggle ${showHiddenAssignees ? 'active' : ''}`}>
                                    +{hiddenAssignees.length}
                                </div>
                                {showHiddenAssignees && (
                                    <div className='hidden-assignees-list'>
                                        {hiddenAssignees.map((assignee) => (
                                            <Chip
                                                key={assignee.employeeId}
                                                label={assignee.name}
                                                variant="outlined"
                                                avatar={<AvatarBuilder employee={{ name: assignee.name }} variant="circular" size="extra-samll" />}
                                                onDelete={() => handleDelete(assignee.employeeId)}
                                                className='goalAssignees'
                                                deleteIcon={<img src={CloseSvg} alt="Close Icon" />}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                </div>
                <div className='goalDetailsItems'>
                    <p>Parent</p>
                    {
                        krDetail.goalTitle ?
                            <div className='parentGoalDetails'>
                                <p>Goal</p>
                                <h4>{truncateText(krDetail.goalTitle , 80)}</h4>
                            </div>
                        : null
                    }
                </div>
                <div className='goalDetailsItems'>
                    <p>Progress</p>
                    <div className={`goalDetailProgressContent ${anchorElProgress ? 'showProgressBorder' : ''}`}>
                    <h4>{capitalizeFirstLetter(krDetail.progressType)} - {Math.round(krDetail.progressStartValue)} to {krDetail.progressEndValue}</h4>
                    <p onClick={handleOpenPopOver}><img src={EditIcon}/>  Edit</p>
                        <Popover
                            id={id}
                            open={openProgressPopOver}
                            anchorEl={anchorElProgress}
                            onClose={handleClosePopOver}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <EditProgress progressData={krDetail} handleProgressClose={handleClosePopOver} updateSelectedProgress={updateSelectedProgress} />
                        </Popover>
                    </div>
                    
                </div>
                <div className='goalDetailsItems'>
                    <div className='kiDetailsSlider'>
                        <SliderProgressBar progressEndValue={krDetail.progressEndValue} progressStartValue={krDetail.progressStartValue} progressType={krDetail.progressType} krId={krDetail.id} goalId={krDetail.goalId} progress={krDetail.currentProgress} progressPercentage={krDetail.progress} status={krDetail.status} updateGoalProgressBar={updateKrDetails}/>
                        <div className='goalSliderDetails'>
                            <h3 style={{ color: getColorByStatus(krDetail.status) }}>{capitalizeFirstLetter(krDetail.status)}</h3>
                            <h2>{Math.round(krDetail.progress)}%</h2>
                            <h1>{getformatedTime(krDetail.updatedAt)}</h1>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='goalDetailProgress'>
                        <h2 className='KIProgress'>Progrerss (KIs)</h2>
                        <div></div>
                        <Button className='goalDetailsAddButton'
                            onClick={() => {
                                handleOpen();
                                let newKI = kinit;
                                newKI.keyResultId = krDetail.id;
                                setNewKeyInit(newKI)
                            }}
                        >+ Add Key Initiatives</Button>
                    </div>
                    <div className='keyResultListGD'>
                        {keyResultsToDisplay.map((keyInit, index) => (
                            <div className='goalDetailKeyResult' key={index}>
                                <h3>KI</h3>
                                <div className='keyResultNameGD'>
                                    <h4
                                    onClick={() => handleKeyInitDetails(keyInit.id)}
                                    >{truncateText(keyInit.title, 80)}</h4>
                                    <h5>Increased to {Math.round(keyInit.keyInitiativeProgress)} {capitalizeFirstLetter(keyInit.progressType)}</h5>
                                </div>
                                <div className='goalDetailAvtar'>
                                    {Array.isArray(keyInit.ownerDetails)
                                        ? keyInit.ownerDetails.map((owner, i) => (
                                            <AvatarBuilder
                                                employee={{ name: owner.name }}
                                                variant="circular"
                                                size="profile-img"
                                                key={i}
                                            />
                                        )) : null}
                                </div>
                                <div className='goalDetailSlider'>
                                    
                                    <SliderProgressBar progressEndValue={keyInit.progressEndValue} progressStartValue={keyInit.progressStartValue} progressType={keyInit.progressType} krId={keyInit.keyResultId} kiId={keyInit.id} goalId={keyInit.goalId} progress={keyInit.progress} progressPercentage={keyInit.keyInitiativeProgress} status={keyInit.keyValueStatus} loadKIS={(kiId) => getKI(kiId)} />
                                    <div className='goalSliderDetails'>
                                        <h3 style={{color : getColorByStatus(keyInit.keyValueStatus)}}>{capitalizeFirstLetter(keyInit.keyValueStatus)}</h3>
                                        <h2>{Math.round(keyInit.keyInitiativeProgress)}%</h2>
                                        <h1>{getformatedTime(keyInit.updatedAt)}</h1>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {krDetail.keyInitiativeResponseList.length > 2 && (
                        <a className="show-all-button" onClick={handleToggleShowAll}>
                            {buttonLabel}
                        </a>
                    )}
                    <div className='goalDetailProgress'>
                        <h2>Conversation</h2>
                        <div></div>
                    </div>
                    <div className="container">
                        <ReactQuill
                            className='input-box'
                            name='conversation'
                            value={conversation.conversation}
                            onChange={handleInputChange}
                            placeholder="Type a message"
                        />
                        <button className="save-button" onClick={handleSaveClick}>Save</button>
                    </div>
                    <div>
                        {messagesToDisplay.map((conversation, index) => (
                            <div className='goalDetailConversation' key={index}>
                                <AvatarBuilder
                                    employee={{ name: conversation.fromEmpName }}
                                    variant="circular"
                                    size="profile-img"
                                />
                                <div className='messageGD'>
                                    <h4 dangerouslySetInnerHTML={{ __html: conversation.conversation }} />
                                    <div className='EmployeeGD'>
                                        <h3>{conversation.fromEmpName}</h3>
                                        <p>{getformatedTime(conversation.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                         {messages.length > 2 && (
                            <a className="show-all-button" onClick={handleToggleShowAllMessages}>
                                {showAllMessages ? 'Hide conversation' : 'View all conversation'}
                            </a>
                        )}
                    </div>
                </div>
            </div>
            {
                kiData ?
                    <Drawer 
                    anchor={'right'}
                    open={kiState['right']}
                    onClose={() => {
                        if (typeof handleGoalClose === 'function') {
                            handleGoalClose();
                        }
                        handleCloseDrawer();
                        toggleDrawer('right', false);
                      }}
                      
                    >
                        {
                            <KeyInitiativeDetails kiData={kiData} goalCycle = {krDetail.goalCycle} handleGoalClose={handleGoalClose} updateKRData={updateKIData} handleCloseDrawer={toggleDrawer('right', false)} deleteKi={handleUpdateCloseKI}/>
                        }
                    </Drawer>
                    : null
            }
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleModalClose}
            >
                <AddKeyInitiatives onCancel={handleModalClose} newKeyInit={newKeyInit} setNewKeyInit={setNewKeyInit} updateKI={() => getKI(krDetail.id)}/>
            </Modal>
            <Modal
                open={openGoal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <NewAddGoal goals={goalAssociateData} handleClose={handleCloseGoal} handleOpenKR={handleOpenKR} goalData={getGoalData} />
            </Modal>
        </div>
    )
}
