import React from 'react';
import "./AuthLogin.css"
import GoogleIcon from "../../assets/images/GoogleIcon.svg"
import MicroSoftIcon from "../../assets/images/microsoft.svg"
import AuthLeftContent from './AuthLeftContent'
import { useState } from 'react';
import {Button,Checkbox,FormControl,FormControlLabel,FormHelperText,IconButton,InputAdornment,OutlinedInput,Stack,Typography} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { checkLogin, logIn } from 'Services/AuthService';
import { Link, useNavigate } from "react-router-dom";
import { FRONT_END_BASE_URL, OAUTH_BASE_URL } from 'Constants/ApiContants'
import errorAuthIcon from "../../assets/images/ErrorAuth.svg"
import refreshFeatures from 'utils/PaymentUtils';

export default function AuthLoginPage({ ...others }) {
    const [checked, setChecked] = useState(true);
    const navigate = useNavigate();
    const googleAuthRedirectUrl = "" + OAUTH_BASE_URL + "/oauth2/authorize/google?redirect_uri=" + FRONT_END_BASE_URL + "/oauth2/redirect";
    const azureAuthRedirectUrl = "" + OAUTH_BASE_URL + "/oauth2/authorize/azure?redirect_uri=" + FRONT_END_BASE_URL + "/oauth2/redirect";

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const redirectToHome = () => {
        localStorage.setItem('loginAgain', true);
        navigate("/home");
    }

    const redirectToForgotPassword = () => {
        navigate("/forgot-password");
    }

    const checkLoginFn = () => {

        checkLogin().then((res) => {
            let results = res.data;
            if (results != undefined && results.status == "SUCCESS") {
                if (results.empId != 0 || results.orgId != 0) {
                    redirectToHome();
                }
            }
        }).catch((error) => {
            console.error('Logout error:', error);
        });
    }

    React.useEffect(() => {
        checkLoginFn();
    }, []);

    return (
        <div className='authentication'>
            <AuthLeftContent />
            <div className='authLoginRightContainer'>
                <h1>👋 Welcome Back to 10XOKR</h1>
                <h2>Ready to Achieve More? Log In Now</h2>
                <a href={googleAuthRedirectUrl}>
                    <img src={GoogleIcon} />
                    <h3>Sign in with Google</h3>
                </a>
                <a href={azureAuthRedirectUrl}>
                    <img src={MicroSoftIcon} />
                    <h3>Sign in with Microsoft</h3>
                </a>
                <div className='lineBreak'>
                    <div></div>
                    <p>Or</p>
                    <div></div>
                </div>
                <h4>Sign in with Email address</h4>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        password: Yup.string().max(255).required('Password is required')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

                        logIn(values).then((results) => {
                            let data = results.data;
                            if (data !== undefined && data.token != undefined) {
                                setStatus({ success: true });
                                setSubmitting(false);
                                localStorage.setItem('token', data.token);
                                localStorage.setItem('userRole', data.role);
                                localStorage.setItem('orgId', data.orgId);
                                localStorage.setItem('employeeId', data.empId);
                                localStorage.setItem('showDemo', data.isDemoEnabled);
                                refreshFeatures();
                                
                                redirectToHome();
                            } else {
                                setStatus({ success: false });
                                setErrors({ submit: !!data.detail ? data.detail : "Something went wrong. Please try again." });
                                setSubmitting(false);
                            }
                        });

                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} {...others} className='authForm'>
                            <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                                <OutlinedInput
                                    type="email"
                                    value={values.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder='Email'
                                    inputProps={{}}
                                    className={`authInput ${touched.email ? 'errorAuthInput' : ''}`}
                                />
                                {touched.email && errors.email && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon}/> {errors.email}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                fullWidth
                                error={Boolean(touched.password && errors.password)}
                            >
                                <OutlinedInput
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    name="password"
                                    className={`authInput ${touched.password ? 'errorAuthInput' : ''}`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder='Password'
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                size="medium"
                                                className='authPasswordEye'
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputProps={{}}
                                />
                                {touched.password && errors.password && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon}/> {errors.password}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} className='actionContent'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={(event) => setChecked(event.target.checked)}
                                            name="checked"
                                            color="primary"
                                        />
                                    }
                                    label="Remember me"
                                />
                                <p onClick={redirectToForgotPassword} variant="subtitle1" color="secondary" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                    Forgot Password?
                                </p>
                            </Stack>
                            {errors.submit && (
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            )}
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                className='authButton'
                            >
                                Sign in
                            </Button>
                        </form>
                    )}
                </Formik>
                <div className='newUserLogin'>
                    <h2>Don&apos;t have an account?</h2>
                    <Typography
                        component={Link}
                        to="/register" 
                        variant="subtitle1"
                        sx={{ textDecoration: 'none' }}
                    >
                        Create one
                    </Typography>
                </div>
            </div>
        </div>
    )
}
