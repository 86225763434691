import React from 'react';
import { Typography, Grid, } from '@mui/material';
import { Chart as ChartJs, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import './Charts.css';
import { getCheckInTrend } from 'Services/Goalservice';
import { getFormatedDate } from 'Constants/DateConstants';
import { ChartOptions } from 'Constants/Styles';

ChartJs.register(
  Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler
)

const CheckInHistory = ({ params, fromDate, toDate, selectedTeamId }) => {

  const chartData = {
    labels: [''],
    datasets: [
      {
        label: 'All CheckIn',
        data: [],
      },
    ],
  };

  const [checkIn, setCheckIn] = React.useState(chartData);

  const loadCheckIn = async () => {
    try {
        const res = await getCheckInTrend({
            'goalLevel': params,
            'fromDate': getFormatedDate(fromDate),
            'toDate': getFormatedDate(toDate),
            'teamId': selectedTeamId
        });

        const results = res.data;
        if (results && results != null) {
            let labels = [];
            let input = [];
            results?.map((item, i) => {
                labels.push(item.label);
                input.push(item.value);
            });

            const obj = {
                datasets: [{
                    datasetIdKey: "value",
                    label: "All (Goals + KeyResults)",
                    data: input,
                    backgroundColor: [
                        '#FEE101'
                    ],
                    hoverOffset: 10,
                    borderColor: '#EF8484',
                    fill: false,
                    tension: 0.4,
                    pointRadius: 5,
                    pointBackgroundColor: 'transparent',
                }],
                labels: labels
            };

            setCheckIn(obj);
        }
    } catch (error) {
        console.error("Error loading CheckIn history:", error);
    }
}
let showDemoData = localStorage.getItem("showDemo");

  React.useEffect(() => {
    loadCheckIn();
  }, [toDate, showDemoData, selectedTeamId]);

  return (
    <Grid container spacing={2} className='lineChartContainer'>
      <Grid item xs={9} className='chartHeading'>
        <Typography variant="h3">CheckIn History</Typography>
      </Grid>
      <Grid item xs={12}>
        <Line data={checkIn} options={ChartOptions} className='lineChartItem' />
      </Grid>
    </Grid>
  );
};

export default CheckInHistory;

