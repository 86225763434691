import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {GlobalLoader} from 'utils/NetworkService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { render } from "react-dom";
import { createStore } from "redux";

var defaultState = {
  name: "okr"
};

function rootReducer(state = defaultState, action) {
  return state;
}

var store = createStore(rootReducer);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store} >
    <ToastContainer
            position="bottom-left"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />

  <BrowserRouter>
  < GlobalLoader />
    <App />
  </BrowserRouter>
  
  </Provider>
);

reportWebVitals();
