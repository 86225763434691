import { getGivenReviewQuestionAndAnswer, getSelfReviewQuestionAndAnswer } from 'Services/PerformanceReviewService';
import React, { useEffect, useState } from 'react';
import './WritePerformanceReviews.css';
import { TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import { PERF_REVIEW_QUESTION_TYPE } from 'Constants/Styles';
import "./YourReview.css"
import CloseIcon from '@mui/icons-material/Close';

export default function ViewPerformanceReview({ reviewData, review, onClose }) {
    const [selfReviewData, setSelfReviewData] = useState([]);
    const [reviewedEmpId , setReviewedEmpId] = useState("");
    let employeeId = localStorage.getItem('employeeId');
    let role = localStorage.getItem('userRole');

    const handleClose = () => {
        onClose();
    }

    const getYourReviewQuestion = () => {
        getSelfReviewQuestionAndAnswer(reviewData).then((res) => {
            const results = res.data;
            if (results) {
                setSelfReviewData(results);
                setReviewedEmpId(results[0].reviewedEmpId);
            }
        });
    }

    const getGivenReviewQuestionAnswer = () => {
        getGivenReviewQuestionAndAnswer(reviewData).then((res) => {
            const results = res.data;
            if (results) {
                setSelfReviewData(results);
            }
        })
    }

    useEffect(() => {
        if (review == "yourReviews") {
            getYourReviewQuestion();
        } else {
            getGivenReviewQuestionAnswer();
        }
    }, []);

    return (
        <div className="yourReviewContent">
            <div className='selfQuestionContent'>
                <div className="yourReviewHeading">
                    { review == "yourReviews" ? <h2 className='selfHeading'>SELF REVIEW</h2>  : (role == "MANAGER" || role== "OWNER") ? <h2 className='selfHeading'>MANAGER TO EMPLOYEE REVIEW</h2> : <h2 className='selfHeading'>EMPLOYEE TO MANAGER REVIEW</h2>}
                    <CloseIcon onClick={handleClose} />
                </div>
                <div className='yourSelfQuestion'>
                    {selfReviewData.map((reviewItem, i) => (
                        <div className='' key={i}>
                            <div className="selfReviewQuestion">
                                <h3>Q : {reviewItem.selfQuestion}</h3>
                            </div>
                            <div className="selfReviewQuestion">
                                {reviewItem.selfAnswerType === PERF_REVIEW_QUESTION_TYPE.TEXT ? (
                                    <>
                                        <InputLabel>Answer</InputLabel>
                                        <TextField
                                            name="name"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={reviewItem.selfAnswer}
                                        />
                                    </>
                                ) : reviewItem.selfAnswerType === PERF_REVIEW_QUESTION_TYPE.OPTIONS ? (
                                    <FormControl>
                                        <FormLabel id="demo-controlled-radio-buttons-group">Answer</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={reviewItem.selfAnswer}
                                        >
                                            {Object.entries(reviewItem.optionMap).map(([optionValue, optionLabel]) => (
                                                <FormControlLabel
                                                    key={optionValue}
                                                    value={optionLabel}
                                                    control={<Radio />}
                                                    label={optionLabel}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                ) : (
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Answer</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={reviewItem.selfAnswer}
                                        >
                                            {Object.entries(reviewItem.optionMap).map(([optionValue, optionLabel]) => (
                                                <FormControlLabel
                                                    key={optionValue}
                                                    value={optionLabel}
                                                    control={<Radio />}
                                                    label={optionLabel}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {
                (review == "yourReviews" && reviewedEmpId != employeeId) ?
                <div className='reviewerQuestionContent'>
                <div className="yourReviewHeading">
                    <h2 className='selfHeading'>MANAGER TO EMPLOYEE REVIEW</h2>
                </div>
                <div className='yourSelfQuestion'>
                    {selfReviewData.map((reviewItem, i) => (
                        <div className='' key={i}>
                            <div className="selfReviewQuestion">
                                <h3>Q : {reviewItem.reviewerQuestion == null ? <span>NO REVIEWER QUESTION IS AVAILABLE</span> : reviewItem.reviewerQuestion}</h3>
                            </div>
                            <div className="selfReviewQuestion">
                                {reviewItem.selfAnswerType === PERF_REVIEW_QUESTION_TYPE.TEXT ? (
                                    <>
                                        <InputLabel>Answer</InputLabel>
                                        <TextField
                                            name="name"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={reviewItem.reviewerAnswer}
                                            disabled={!reviewItem.reviewerAnswer}
                                        />
                                    </>
                                ) : reviewItem.selfAnswerType === PERF_REVIEW_QUESTION_TYPE.OPTIONS ? (
                                    <FormControl>
                                        <FormLabel id="demo-controlled-radio-buttons-group">Answer</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={reviewItem.reviewerAnswer}
                                        >
                                            {Object.entries(reviewItem.optionMap).map(([optionValue, optionLabel]) => (
                                                <FormControlLabel
                                                    key={optionValue}
                                                    value={optionLabel}
                                                    control={<Radio />}
                                                    label={ (reviewItem.reviewerAnswer != null)  ? optionLabel : "No Option"}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                ) : (
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Answer</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={reviewItem.reviewerAnswer}
                                        >
                                            {Object.entries(reviewItem.optionMap).map(([optionValue, optionLabel]) => (
                                                <FormControlLabel
                                                    key={optionValue}
                                                    value={optionLabel}
                                                    control={<Radio />}
                                                    label={(reviewItem.reviewerAnswer != null)  ? optionLabel : "0"}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div> : null

            }
        </div>
    );
}
