import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { InputLabel } from '@mui/material';
import "./Questions.css";
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import { PERF_REVIEW_QUESTION_TYPE, PERF_REVIEW_TYPE, PERFORMANCE_STEPPERS } from 'Constants/Styles';

const MultipleChoiceQuestion = ({ onClose, onQuestionSave, initialData, activeStep, reviewId }) => {
    const isEditMode = !!initialData;
    const [showReviewerFields, setShowReviewerFields] = useState(isEditMode ? Boolean(initialData.reviewerQuestion) : true);
    const [self, setSelf] = useState(isEditMode ? initialData.selfQuestion : '');
    const [reviewer, setReviewer] = useState(isEditMode ? initialData.reviewerQuestion || '' : '');
    const [options, setOptions] = useState(isEditMode ? initialData.options : ['']);
    const [empToMng, setEmpToMng] = useState(isEditMode ? initialData.empToManagerQuestion : '');
    const [peer, setPeer] = useState(isEditMode ? initialData.peerQuestion : '');
    const [empToMngError, setEmpToMngError] = useState('');
    const [selfError, setSelfError] = useState('');
    const [reviewerError, setReviewerError] = useState('');
    const [peerError, setPeerError] = useState('');
    const [optionsError, setOptionsError] = useState('');
  
    useEffect(() => {
      if (activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER) {
        setSelf(isEditMode ? initialData.selfQuestion : '');
        setReviewer(isEditMode ? initialData.reviewerQuestion || '' : '');
        setShowReviewerFields(isEditMode ? Boolean(initialData.reviewerQuestion) : true);
        setOptions(isEditMode ? initialData.options : []);
      } else if (activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER) {
        setEmpToMng(isEditMode ? initialData.empToManagerQuestion : '');
        setOptions(isEditMode ? initialData.options : []);
      } else if (activeStep === PERFORMANCE_STEPPERS.PEER) {
        setPeer(isEditMode ? initialData.peerQuestion : '');
        setOptions(isEditMode ? initialData.options : []);
      }
    }, [initialData, activeStep, isEditMode]);

    const handleToggleChange = () => {
        setShowReviewerFields(!showReviewerFields);
    };

    const handleSaveClick = () => {
        if (activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER) {
            if (!self.trim()) {
                setSelfError('Self  Question is mandatory.');
                return;
            } else {
                setSelfError('');
            }
            if (showReviewerFields && !reviewer.trim()) {
                setReviewerError('Reviewer  Question is mandatory.');
                return;
            } else {
                setReviewerError('');
            }
        }
        if (activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER && !empToMng.trim()) {
            setEmpToMngError('empToMng Question is mandatory.');
            return;
        } else {
            setEmpToMngError('');
        }

        if (activeStep === PERFORMANCE_STEPPERS.PEER && !peer.trim()) {
            setPeerError('peer Question is mandatory.');
            return;
        } else {
            setPeerError('');
        }

        if (options.length === 0 || options.every(option => option.trim() === '')) {
            setOptionsError('Add at least one option.');
            return;
        } else {
            setOptionsError('');
        }

        const newQuestionData = {
            selfQuestion: self,
            ...(showReviewerFields
                ? { reviewerQuestion: reviewer }
                : { reviewerQuestion: null }),
            empToManagerQuestion: empToMng,
            peerQuestion: peer,
            answerType: PERF_REVIEW_QUESTION_TYPE.OPTIONS,
            reviewType: activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? PERF_REVIEW_TYPE.SELF : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? PERF_REVIEW_TYPE.EMPLOYEE_TO_MANAGER : PERF_REVIEW_TYPE.PEER,
            options: options.filter(option => option.trim() !== ''),
            reviewId : reviewId,
            isReviewerQuestionEnabled: activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? showReviewerFields : false
        };

        onQuestionSave(newQuestionData);
        onClose();
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    const handleAddOption = () => {
        setOptions([...options, '']);
    };

    const handleRemoveOption = (index) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        setOptions(newOptions);
    };

    return (
        <div className='questionBox'>
            <h3>{activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? 'Add New Self Question' : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? 'Add New Employee to Manager Question' : 'Add New Peer Question'}</h3>
            <div className='questionForPr'>
                <div>
                    <InputLabel>{activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? 'Self Question' : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? 'Employee To Manager Question *' : 'Peer Question *'}</InputLabel>
                    <TextField
                        variant="outlined"
                        value={activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? self : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? empToMng : peer}
                        onChange={(e) => activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? setSelf(e.target.value) : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? setEmpToMng(e.target.value) : setPeer(e.target.value)}
                        fullWidth
                        multiline
                        rows={2}
                        error={activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? !!selfError : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? !!empToMngError : !!peerError}
                        helperText={activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? selfError : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? empToMngError : peerError}
                    />
                </div>
                {activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER && showReviewerFields && (
                    <div>
                        <InputLabel>Reviewer Question *</InputLabel>
                        <TextField
                            variant="outlined"
                            multiline
                            rows={2}
                            value={reviewer}
                            onChange={(e) => setReviewer(e.target.value)}
                            fullWidth
                            error={!!reviewerError}
                            helperText={reviewerError}
                        />
                    </div>
                )}
            </div>
            {
                activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ?
                    <FormControlLabel
                        control={<Switch checked={showReviewerFields} onChange={handleToggleChange} />}
                        label="Enable Reviewer Question"
                    /> :
                    null
            }
            <div>
                <InputLabel>Options (Add at least one)</InputLabel>
                {options.map((option, index) => (
                    <div key={index} className='optionTextFeild'>
                        <TextField
                            variant="outlined"
                            value={option}
                            onChange={(e) => handleOptionChange(index, e.target.value)}
                        />
                            <Button className='WithoutBorderButton' onClick={() => handleRemoveOption(index)}>
                                <CancelPresentationOutlinedIcon />
                            </Button>
                    </div>
                ))}
                <Button className='WithoutBorderButton' onClick={handleAddOption}>
                    + Add More Options
                </Button>
            </div>
            <div className='QuestionButtons'>
                <Button variant="outlined" onClick={onClose} className='SecondaryButton'>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSaveClick} className='PrimaryButton'>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default MultipleChoiceQuestion;
