export default function parentChecker() {
	if (window === window.top) {
    	// Code to run if the application is loaded directly in the browser
    	console.log("Nothing to do:: Loaded directly in the browser");
	} else {
    	// Code to run if the application is loaded inside an iframe
    	console.log(JSON.stringify(window.location.ancestorOrigins));
    	console.log(JSON.stringify(window.location.ancestorOrigins));
    	if(window.location.ancestorOrigins.length == 0) {
    		getMarketplaceDetails(window.location.href);
    	} else {
    		let marketplace = '';
    		let origins = window.location.ancestorOrigins;
    		Object.values(origins).forEach(origin => {
				marketplace = getMarketplaceDetails(origin);				
			})
			return marketplace;
    	}
	}
}


function getMarketplaceDetails(ancestorsOrigins) {
	let marketplace = "";
	if(ancestorsOrigins.includes("atlassian")) {
		marketplace = "JIRA";
	}
	return marketplace;
}