import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import {updateSubscription} from 'Services/PaymentService';

import IncDecCounter from 'Components/IncDecCounter';

import React, { useState } from "react";

export default function BillingDetails({ billingDetailsClose, currentPlanDetails }) {
	const [errors, setErrors] = useState([]);
	const [newPlan, setNewPlan] = useState("advance-plan-USD-Yearly");
	const [newPlanName, setNewPlanName] = useState("advance-plan-USD");
	const [newFrequency, setNewFrequency] = useState("Yearly");
	const [newUIFrequency, setNewUIFrequency] = useState("Year");
	const [newAmount, setNewAmount] = useState(0)
	const [newLicenseCount, setNewLicenseCount] = useState(currentPlanDetails.maxEmployeeCount);

	const price = new Map([
			["free-plan-USD-Monthly",0],
			["free-plan-USD-Yearly",0],
			["basic-plan-USD-Monthly",7],
			["basic-plan-USD-Yearly",60],
			["advance-plan-USD-Monthly",9],
			["advance-plan-USD-Yearly",84]
		]
	);
	
	const onPlanDetailChange = (e, element) => {
		if(!e.target.name.localeCompare("plan")) {
			setNewPlanName(e.target.value);
		} else {
			e.target.value.localeCompare("Month")? setNewFrequency("Yearly"): setNewFrequency("Monthly");
			setNewUIFrequency(e.target.value);
			
		}
	}

	const onCountChange = (e) => {
		console.log(e);
		//setNewLicenseCount()
	}

	const submit = () => {
		let data = {"cbPlanId":newPlan, "newLicenseCount": newLicenseCount }
		updateSubscription(data).then((res) => {
			if(res.data.shouldRedirect) {
				window.location.replace(res.data.url);	
			} else {
				window.location.replace("/");
			}
			
		});
	}

	const calculateRemainingDays = (nextBillingDateString) => {
		const nextBillingDate = new Date(nextBillingDateString);
		const currentDate = new Date();
		const differenceInMilliseconds = nextBillingDate - currentDate;
		return Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
	}

	React.useEffect(()=>{
		console.log(currentPlanDetails);

		setNewPlan(newPlanName+"-"+newFrequency);
		console.log(newPlan);
		let remainingTermDays = ((currentPlanDetails.status === "IN_TRIAL") ? currentPlanDetails.termTotalDays : calculateRemainingDays(currentPlanDetails.nextBillingDate));
		let totalCost = (((remainingTermDays * price.get(newPlan))/currentPlanDetails.termTotalDays)*(newLicenseCount-currentPlanDetails.maxEmployeeCount)).toFixed(2);
		setNewAmount((newLicenseCount<10) ? 0: totalCost)
  	}, [newPlanName, newFrequency, newLicenseCount, newAmount])

	return (
		<div>
			<Box className="addemployee-popup" style={{ width: '900px' }} >
				<Typography className="addEmployee-heading">
					<h2>Plan Changes</h2>
				</Typography>
				<hr />
				<Grid container  className="addEmployee-content" columnSpacing={2}>
					<Grid item xs={8} className="updatefeilds">
						<Typography className="updateSubscriptionLabels"> 
							Current Plan:
						</Typography>
						<InputLabel className="updateinput">{currentPlanDetails.planName}</InputLabel>
					</Grid>


					<Grid item xs={8} className="updatefeilds">
						<Typography className="updateSubscriptionLabels"> 
							Billing Frequency:
						</Typography>
						<InputLabel className="updateinput">{currentPlanDetails.frequency}</InputLabel>
					</Grid>
					<Grid item xs={8} className="updatefeilds">
						<Typography className="updateSubscriptionLabels"> 
							Current License count:
						</Typography>
						<InputLabel className="updateinput">{currentPlanDetails.currentUsers}</InputLabel>
					</Grid>

					<Grid item xs={8} className="updatefeilds">
						<Typography className="updateSubscriptionLabels"> 
							New Plan
						</Typography>
                		
		                <Select
		                  style={{minWidth:"150px"}}
		                  className="updateinput"
		                  value={newPlanName}
		                  name="plan"
		                  onChange={e => onPlanDetailChange(e, "plan")}
		                  sx={{ boxShadow: 'none' }}
		                  error={errors?.cbPlanId ? true : false}
		                  helperText={errors.cbPlanId}
		                >
		                	<MenuItem value={"free-plan-USD"}>Free</MenuItem>
		                	<MenuItem value={"basic-plan-USD"}>Basic</MenuItem>
		                	<MenuItem value={"advance-plan-USD"}>Advance</MenuItem>
		                </Select>
              		</Grid>

              		<Grid item xs={8} className="updatefeilds">
						<Typography className="updateSubscriptionLabels"> 
							New Plan Frequency
						</Typography>
                		
		                <Select
		                  style={{minWidth:"150px"}}
		                  className="updateinput"
		                  value={newUIFrequency}
		                  name="frequency"
		                  onChange={e => onPlanDetailChange(e, "frequency")}
		                  sx={{ boxShadow: 'none' }}
		                  error={errors?.frequency ? true : false}
		                  helperText={errors.frequency}
		                >
		                	<MenuItem value={"Month"}>Monthly</MenuItem>
		                	<MenuItem value={"Year"}>Yearly</MenuItem>
		                </Select>
              		</Grid>
              		<Grid item xs={8} className="updatefeilds">
              			<Typography className="updateSubscriptionLabels">
              				User License
              			</Typography>
              			<IncDecCounter className="updateinput"
              				defaultNum={currentPlanDetails.maxEmployeeCount}
              				listenerMethod ={setNewLicenseCount}
              			/>
              		</Grid>

              		<Grid item xs={8} className="updatefeilds">
						<Typography className="updateSubscriptionLabels"> 
							Price for {((currentPlanDetails.status === "IN_TRIAL") ? currentPlanDetails.termTotalDays : calculateRemainingDays(currentPlanDetails.nextBillingDate))} days:
						</Typography>
						<InputLabel className="updateinput">{newAmount<0?newAmount+"(will receive as credits)":((currentPlanDetails.status === 'IN_TRIAL'&& newAmount>0)?newAmount+"(Will be charged after Trial)":newAmount)}</InputLabel>
					</Grid>

					<Grid item xs={8} className="updatefeilds">
						<Typography className="updateSubscriptionLabels"> 
							Available credits
						</Typography>
						<InputLabel className="updateinput">{currentPlanDetails.credits}</InputLabel>
					</Grid>

					<Grid item xs={8} className="updatefeilds">
						<Typography className="updateSubscriptionLabels"> 
							Balance:
						</Typography>
						<InputLabel className="updateinput">{(newAmount - currentPlanDetails.credits)< 0? 0 : (newAmount - currentPlanDetails.credits)}</InputLabel>
					</Grid>

					





					
				</Grid>
				<Grid item xs={8} className="updatebuttons" justifyContent={'center'}>
					<Grid item>
						<Button type="submit" color="primary" variant="contained" onClick={submit} className="updatesubmitbutton">Submit</Button>
					</Grid>
					<Grid>
						<Button variant="contained" color="secondary" onClick={billingDetailsClose}> Cancel</Button>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}