import React, { useReducer, useState } from "react";
import "./GoalsGrids.css"
import { getGoals } from 'Services/Goalservice';
import { TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from "@mui/material/Autocomplete";
import {getAllEmployee } from 'Services/OrgService';
import { addNewKeyInitiatives } from "Services/Goalservice";
import { ProgressType } from 'Constants/EmpConstants';
import { ToastError, ToastSuccess } from "utils/Toast";
import { PROGRESSTYPE } from "Constants/Styles";

export default function AddNewKeyInit({ newKeyInit, setNewKeyInit, updateKI }) {

    const [data, setData] = React.useState([]);

    const temp = {
        title: "",
        assignee: "",
        progressType: PROGRESSTYPE.PERCENTAGE,
        progressStartValue: "",
        progressEndValue: "",
        currentProgress: "",
        id: "",
        keyResultId: -1,
        goalId: -1,
        startDate:"",
        dueDate:""
    }

    const [keyInit, setKeyInit] = useState(temp);

    const [employeeList, setEmployeeList] = React.useState([]);

    const loadEmpl = () => {
        getAllEmployee().then((res) => {
            let results = res.data;
            if (results) {
                setEmployeeList(results);
                return;
            }
        })
    }
    React.useEffect(() => {
        loadEmpl();
    }, []);



    const handleSubmit = (e) => {
        {
            e.preventDefault();
            if (newKeyInit && newKeyInit.progressEndValue && newKeyInit.progressStartValue &&
                newKeyInit.progressType && newKeyInit.assignee &&
                newKeyInit.title && newKeyInit.startDate && newKeyInit.dueDate) {

                addNewKeyInitiatives(newKeyInit).then((res) => {
                    let results = res.data;
                    if (results) {
                        ToastSuccess({ text: "Key initiative added successfully" })
                        updateKI(true)
                        setNewKeyInit({})
                        setKeyInit(temp)

                        return;
                    }
                })

            } else {
                ToastError({ text: "Please enter correct values" })
            }
        }
    }

    const [dateError, setDateError] = useState(false);

    const handleDateValidation = () => {
        if (new Date(newKeyInit.startDate) > new Date(newKeyInit.dueDate)) {
            setDateError(true);
        } else {
            setDateError(false);
        }
    };

    const handleChangeProgress = (e, val) => {
        if (val === null) {
            setNewKeyInit({ ...newKeyInit, progressType: null })
          } else {
            if (val.value === PROGRESSTYPE.BOOLEAN) {
                setNewKeyInit({
                ...newKeyInit,
                progressType: val.value,
                progressStartValue: "0",
                progressEndValue: "1",
              });
            } else {
                setNewKeyInit({
                ...newKeyInit,
                progressType: val.value,
              });
            }
          }
    }
    return (
        <form className="keyresult-feilds" onSubmit={handleSubmit}>
            <div className="keyresult-row">
                <div className="keyResultInput">
                    <InputLabel>Key Initiatives</InputLabel>
                    <TextField
                        name="title"
                        value={newKeyInit.title}
                        required
                        fullWidth
                        onChange={(e) => {
                            setNewKeyInit({ ...newKeyInit, title: e.target.value })
                        }}
                    />
                </div>
            </div>
            <div className="keyresult-row">
                <div className="keyResultData">
                    <InputLabel>Assignee</InputLabel>
                    <Autocomplete
                        options={employeeList}
                        getOptionLabel={(option) => option.name}
                        value={newKeyInit.krOwners}
                        renderInput={(params) => <TextField {...params} required={true} />}
                        name="assignee"
                        onChange={(e, val) => {
                            if (val) {
                                setNewKeyInit({ ...newKeyInit, assignee: val.id });
                            } else {
                                setNewKeyInit({ ...newKeyInit, assignee: null });
                            }
                        }}

                    />
                </div>
            </div>
            <div className="keyresult-progress">
                <div className="keyResultData">
                    <InputLabel>Progress Type</InputLabel>
                    <Autocomplete
                        options={ProgressType}
                        getOptionLabel={(option) => option.value}
                        defaultValue={ProgressType[0]}
                        renderInput={(params) => <TextField {...params} required={true} />}
                        name="progressType"
                        onChange={handleChangeProgress}
                    />
                </div>
                { newKeyInit.progressType == PROGRESSTYPE.BOOLEAN ?
                null :
                <>
                <div className="keyResultData">
                    <InputLabel>Start</InputLabel>
                    <TextField
                        fullWidth
                        name='startValue'
                        type="number"
                        required
                        value={newKeyInit.progressStartValue}
                        onChange={(e) => {
                            setNewKeyInit({ ...newKeyInit, progressStartValue: e.target.value })
                        }}
                    />
                </div>
                <div className="keyResultData">
                    <InputLabel>End</InputLabel>
                    <TextField
                        fullWidth
                        name='targetValue'
                        type="number"
                        required
                        value={newKeyInit.progressEndValue}
                        onChange={(e) => {
                            setNewKeyInit({ ...newKeyInit, progressEndValue: e.target.value })
                        }}
                    />
                </div>
                </>
                }
            </div>
            <div className="keyresult-progress">
                <div className="keyResultData">
                    <InputLabel>Start Date</InputLabel>
                    <TextField
                        fullWidth
                        name='startDate'
                        type="date"
                        required
                        onBlur={handleDateValidation}
                        error={dateError}
                        value={newKeyInit.startDate}
                        onChange={(e) => {
                            setNewKeyInit({ ...newKeyInit, startDate: e.target.value })
                        }}
                    />
                </div>
                <div className="keyResultData">
                    <InputLabel>End Date</InputLabel>
                    <TextField
                        fullWidth
                        name='dueDate'
                        type="date"
                        required
                        onBlur={handleDateValidation}
                        error={dateError}
                        helperText={dateError ? 'End Date must be greater than Start Date' : ''}
                        value={newKeyInit.dueDate}
                        onChange={(e) => {
                            setNewKeyInit({ ...newKeyInit, dueDate: e.target.value })
                        }}
                    />
                </div>

            </div>
            <div className="keyresult-buttons">
                <button className="PrimaryButton" variant="contained">Save</button>
                <button onClick={() => {
                    setKeyInit(temp)
                    setNewKeyInit({})
                }} className="SecondaryButton" variant="outlined">Cancel</button>
            </div>
        </form>
    )
}
