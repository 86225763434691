import {APP_BASE_URL} from 'Constants/ApiContants';
import axiosInstance from 'layout/ServerAxios';

export default  async function callApiV2(url, method,data={},queryParams=null,  freeApi=false, isFileUpload=false, internalUrl) {
  let finalURL = internalUrl
        let added = false;
        if(queryParams !== null) {
          for(let key in queryParams) {
              if(added && key !=="k") {
                finalURL += '&'
                finalURL = finalURL + key + '=' +queryParams[key]
              } else if(key !=="k") {
                added = true;
                finalURL += '?'
                finalURL = finalURL + key + '=' +queryParams[key]
              }
      
          }    
        }
        
        const headers = {
          Accept : 'application/json',
          'Content-Type':  isFileUpload  ? "multipart/form-data" : 'application/json' ,
          enctype : ''
        }

        if(! freeApi) {
            // let userToken = cookies.get('token');
            let userToken = localStorage.getItem('token');
            headers.Authorization= "Bearer "+userToken;
        }
        // data = data == null ? {} : data;
        let req = {};
        req.method = method;
        req.url = finalURL;
        req.data = isFileUpload ? data : data;
        
if(isFileUpload){
  return axiosInstance({
    // Endpoint to send files
    url: APP_BASE_URL+url,
    method: 'POST',
    headers: headers,
    data, 
  });
}else{
        return axiosInstance({
          // Endpoint to send files
          url: APP_BASE_URL+url,
          method: 'POST',
          headers: headers,
          data: req
        });
}      
}