import React, { useState} from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import './ManageEmployee.css';
import UpdateEmployee from "./UpdateEmployee";
import AddEmployee from "./AddEmployee";
import UpgradePlanModal from "../payments/UpgradePlanModal";
import { deleteEmployee, getAllEmployee, getAllTeams, getAllEmployeeWithFilters, inviteEmployee, importCsvFile } from 'Services/OrgService';
import { getSubscriptionDetails } from 'Services/PaymentService';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ToastSuccess, ToastError } from "utils/Toast";
import { Box } from "@mui/material";
import ImportCSV from "./ImportEmployee/ImportEmployee";

//table styling
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `var(--light-grey-color)`,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));




export default function CustomizedTables({changeTabs}) {
  //all the column state manage
  const [status, setStatus] = useState('');
  const [teamList, setTeamList] = useState('');
  const [searchText, setSearchText] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [selectedIndex, setSelectedIndex]=useState(null)
  const [selectedRow, setSelectedRow]= useState(null)
  const [currentEmployeeCount, setCurrentEmployeeCount] = useState();
  const [maxEmployeeCount, setMaxEmployeeCount] = useState();



  let showDemoData = localStorage.getItem("showDemo");
  React.useEffect(() => {
    getAllEmployee({
      status: status,
      teamFilter: teamFilter,
      searchText: searchText,
      showDemoData: showDemoData,
    }).then((res) => {
      let results = res.data;
      if (results) {
        setEmployeeList(results);
        return;
      }
    });
  }, [showDemoData]);



  const onChangeAutocomplete=(event)=>{
    setTeamFilter((event == null) ? "all":event.id);
    getAllEmployee({status:status, teamFilter: (event == null) ? "all":event.id, searchText: searchText}).then((res) => {
      let results = res.data;
      if(results){
        setEmployeeList(results);
        return;
      }
    }) 
  }

  const onChangeSearchText=(event)=> {
    setSearchText(event.target.value);
    getAllEmployee({status:status, teamFilter: teamFilter, searchText: event.target.value}).then((res) => {
      let results = res.data;
      if(results){
        setEmployeeList(results);
        return;
      }
    }) 
  }

  //popup add employee button
  const [open, setOpen] = useState(false);
  const [upgradePlan, setUpgradePlan] = useState(false);
  const handleOpen = () => {
    console.log(employeeList.length+ "/////////////////"+ maxEmployeeCount+ "************ "+ (employeeList.length <= maxEmployeeCount));
    if(employeeList.length < maxEmployeeCount){
      setOpen(true);
      setUpgradePlan(false);
    } else {
      setOpen(false);
      setUpgradePlan(true);
    }    
  }
  const handleClose = () => {
    setOpen(false);
    setUpgradePlan(false);
    loadLicenseDetails();
  }

  
  

  //update and delete menu
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event, index, row) => {
    setSelectedIndex(index)
    setSelectedRow(row)
    setAnchorEl(event.currentTarget);

  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //update data from table
  const [updateEmployee, setUpdateEmployee] = useState(false);
  const handleUpdateOpen = () => setUpdateEmployee(true);
  const handleUpdateClose = () => setUpdateEmployee(false);


  const [employeeList, setEmployeeList] = React.useState([]);

  const loadEmpl = () =>{
    loadLicenseDetails();
    getAllEmployee({status:status, teamFilter: teamFilter, searchText: searchText, includeViewerEmp : true}).then((res) => {
      let results = res.data;
      if(results){
        setUpdateEmployee(false);
        setOpen(false);
        setUpdateEmployee(false);
        setEmployeeList(results);
        return;
      }
    })
  }
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    var flag
    if(event.target.value==="false"){
      flag=false;
    }
    else{
      flag=true;
    }
    
    getAllEmployee({status:flag, teamFilter: teamFilter, searchText: searchText}).then((res) => {
      let results = res.data;
      if(results){
        setEmployeeList(results);
        return;
      }
    })
  };

  const inviteNewEmployee = (row) =>{
    inviteEmployee({id:row.id}).then((res) =>{
      let results = res.data;
      if(results){
        ToastSuccess({text: "Invited employee Successfully"})
      }else{
        ToastError({text: "Error while inviting employee"})
      }
    })
  }

  const [teamName, setTeamName] = React.useState([]);
  const loadAllTeams = () => {
    getAllTeams().then((res) => {
      let results = res.data;
      if(results) {
        setTeamList(results);
        const teamMapping=[]
        results.map(item => {
          teamMapping[item.id] = item.name;
        });
        setTeamName(teamMapping)
        return;
      }
    })
  } 

  const loadLicenseDetails = () => {
    getSubscriptionDetails().then((res) => {
      console.log("***************************"+JSON.stringify(res));
      setMaxEmployeeCount(res.data.maxEmployeeCount);
    })
  }

  const [newEmployee, setNewEmployee]= useState(false)
    React.useEffect(() => {
      loadEmpl();
      loadAllTeams();
    }, [newEmployee]);

      // delete employee data from table 
  const removeemployeeDetails = (index, data) =>{
    deleteEmployee(data.id).then(res=>{
      console.log(res)
    }).catch(e=>{
      console.log(e)
    })
    setEmployeeList(pre=>{
      return[
        ...pre.slice(0,index),
        ...pre.slice(index+1, pre.length)
      ]
    })
  }

  const [openImportCSV, setOpenImportCSV] = React.useState(false);
  const handleOpenImportCSV = () => setOpenImportCSV(true);
  const handleCloseImportCSV = () => setOpenImportCSV(false);

  const handleGoToPlans = () =>{
    changeTabs("billing");
  }

  return (
    <div className="manage-employee">
      <Stack spacing={1}  className="allButtons_ME">
        <div className="manageButtons1">
          <Paper component="form" className="searchBox_ME">
            <InputBase
              sx={{ ml: 1, flex: 1, minWidth:'100px'}}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search...' }}
              onChange={onChangeSearchText}
              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
          </Paper>
          <FormControl sx={{ m: 1, minWidth: 120 }}  size="small">
            <InputLabel id="demo-select-small">Status</InputLabel>
            <Select
              className="status_ME"
              value={status}
              label="Status"
              onChange={handleChangeStatus}
              sx={{ boxShadow: 'none' }}
              >
              <MenuItem value={"true"}>Active</MenuItem>
              <MenuItem value={"false"}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120, minHeight: 40}} size="small">
            <Autocomplete
                  options={teamList}
                  name='teamId'
                  size="small"
                  value={teamList.teamId}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value)=> {
                    onChangeAutocomplete(value)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Team" className="autocompleteInput"/>
                  )}
                />
            
          </FormControl>
        </div>
        <div className="manageButtons2">
        <div>
          <Button className="import" onClick={handleGoToPlans}>Go to Plans</Button>
      <Button className="import" name="file" onClick={handleOpenImportCSV}> Import CSV</Button>
    </div>
          <Button variant="text" className="import" onClick={function(event){ handleOpen()}}>Add Employee</Button>
        </div>
      </Stack>

      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow >
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Team</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Role</StyledTableCell>
              <StyledTableCell align="center">Manager</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* .filter((row,ind)=> row.name.toLocaleLowerCase().includes(input) || row.username.toLocaleLowerCase().includes(input)) */}
            {
              employeeList.map((row,index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                    <StyledTableCell align="center">{row.email}</StyledTableCell>
                    <StyledTableCell align="center">{teamName[row.teamId]}</StyledTableCell>
                    <StyledTableCell align="center">{row.isActive===true ? "Active" : "Inactive"}</StyledTableCell>
                    <StyledTableCell align="center">{row.role}</StyledTableCell>
                    <StyledTableCell align="center">{row.managerName}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        id="fade-button"
                        aria-controls={openMenu ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={function(event){handleClickMenu(event,index, row)}}
                        >
                        <MoreVertIcon/>
                      </Button>
                      <Menu
                        MenuListProps={{
                          'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                        >
                        <MenuItem onClick={function(event){inviteNewEmployee(row);handleCloseMenu()}}>Invite Employee</MenuItem>
                        <MenuItem onClick={function(event){ handleUpdateOpen(index);handleCloseMenu()}}>Update</MenuItem>
                        <MenuItem onClick={function(event){ 
                          removeemployeeDetails(selectedIndex,row);
                          handleCloseMenu() 
                          }}
                        >Delete</MenuItem>
                      </Menu>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>

      {/* modal for popup add employee */}
      <Modal
        open={open}
        onClose={handleClose}
        >
        <AddEmployee addEmployeeClose={loadEmpl} newEmployee={newEmployee} setNewEmployee={setNewEmployee}/>
      </Modal>







      <Modal
        open={upgradePlan}
        onClose={handleClose}
        >
        <UpgradePlanModal handleGoToPlans = {handleGoToPlans}/>
      </Modal>








      {/* update data of employ  */}
      <Modal
        open={updateEmployee}
        onClose={handleUpdateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <UpdateEmployee addEmployeeClose={loadEmpl} data={selectedRow} close={handleUpdateClose}/>  
      </Modal>


      {/* Import CSV modal  */}

      <Modal
        open={openImportCSV}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="yourReviewModal">
          <ImportCSV addEmployeeClose={loadEmpl} setNewEmployee={setNewEmployee} onClose={handleCloseImportCSV}/>
        </Box>
      </Modal>
    </div>
  );
}

