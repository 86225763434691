import React, { useState} from "react";
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import './ManageEmployee.css';
import Typography from "@mui/material/Typography";
import { Button, Checkbox, FormControlLabel, MenuItem, Select } from "@mui/material";
import { createTeam } from 'Services/OrgService';
import { getAllEmployee } from 'Services/OrgService';
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from 'react-toastify';

export default function AddTeam({closeTeam, setNewTeam}) {
  const [checked, setChecked] = React.useState(false);
    const [teams,setTeams] = useState({
      teamName:"",
      managerId:"",
      addEmployee:checked,
      empName:"",
      empEmail:"",
      role:null
    });

    const [errors, setErrors] = useState({
      teamName: false,
      managerId: false,
      empName:false,
      empEmail:false,
      role:false
      });

    const handleChange = (event) => {
      const isChecked = event.target.checked;
      setChecked(isChecked);
      setTeams((prevTeams) => ({ ...prevTeams, addEmployee: isChecked }));
    };

  const [employeeList, setEmployeeList] = React.useState([]);

  const loadEmpl = () => {
    getAllEmployee().then((res) => {
      let results = res.data;
      if (results) {
        setEmployeeList(results);
        return;
      }
    })
  }

  React.useEffect(() => {
    loadEmpl();
  }, []);

  const validateFields = () => {
    let valid = true;
    let newErrors = { ...errors };
    if (teams.teamName.trim() === "") {
      newErrors.teamName = true;
      valid = false;
    } else {
      newErrors.teamName = false;
    }

    if(!checked){
      if (teams.managerId === "" || teams.managerId === null) {
        newErrors.managerId = true;
        valid = false;
      } else {
        newErrors.managerId = false;
      }
    }else{
      if (teams.empName.trim() === "") {
        newErrors.empName = true;
        valid = false;
      } else {
        newErrors.empName = false;
      }
      if (teams.empEmail.trim() === "") {
        newErrors.empEmail = true;
        valid = false;
      } else {
        newErrors.empEmail = false;
      }
      if (teams.role.trim() === "") {
        newErrors.role = true;
        valid = false;
      } else {
        newErrors.role = false;
      }
    }
    
    setErrors(newErrors);
    return valid;
  }    

  const submit = () => {
    if (validateFields()) {
      createTeam(teams).then((res) => {
        let results = res.data;

        if (results != null) {
          toast.success("Team created successfully",{
            position: "bottom-left",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
          closeTeam();
          setNewTeam(true);
        } 
        else {
          toast.error("Error while creating team",{
            position: "bottom-left",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          })
          
        }
        setTeams({
          teamName:"",
          managerId:"",
          addEmployee:checked,
          empName:"",
          empEmail:"",
          role:null
        });


      });
    }
  }

  return (
    <div>
      <Box className="addemployee-popup" style={{ width: '280px' }} >
        <Typography className="addEmployee-heading">
          <h2>Add Team</h2>
        </Typography>
        <hr />

        <Grid container className="addEmployee-content" rowSpacing={3}>
          <Grid item marginLeft={3} >
            <InputLabel>Team Name</InputLabel>
            <TextField variant="outlined"
              name="teamName"
              className="addEmployee-input"
              value={teams.teamName}
              onChange={(e) => setTeams({ ...teams, teamName: e.target.value })}
              error={errors.teamName}
              helperText={errors.teamName ? "Team name is required" : ""}
            />
          </Grid>
          {checked ? 
            <Grid item xs={9.5} marginLeft={3}>
              <Grid item xs={3} >
                <InputLabel>Name</InputLabel>
                <TextField variant="outlined"
                  name="empName"
                  style={{minWidth:"200px"}}
                  className="addEmployee-input"
                  value={teams.empName}
                  onChange={(e) => setTeams({ ...teams, empName: e.target.value })}
                  error={errors.empName}
                  helperText={errors.empName ? "Employee name is required" : ""}
                />
              </Grid>
              <Grid item xs={3} >
                <InputLabel>Email</InputLabel>
                <TextField variant="outlined"
                  name="empEmail"
                  style={{minWidth:"200px"}}
                  className="addEmployee-input"
                  value={teams.empEmail}
                  onChange={(e) => setTeams({ ...teams, empEmail: e.target.value })}
                  error={errors.empEmail}
                  helperText={errors.empEmail ? "Employee email is required" : ""}
                />
              </Grid>
              <Grid item>
                <InputLabel>Role</InputLabel>
                <Select
                  style={{minWidth:"200px"}}
                  className="addEmployee-input"
                  value={teams.role}
                  name="role"
                  onChange={(e) => setTeams({ ...teams, role: e.target.value })}
                  error={errors.role}
                  helperText={errors.role ? "Role is required" : ""}
                >
                  <MenuItem value={"STAFF"}>STAFF</MenuItem>
                  <MenuItem value={"MANAGER"}>MANAGER</MenuItem>
                  <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                </Select>
              </Grid>
            </Grid>

            :
            <Grid item xs={9.5} marginLeft={3}>
            <InputLabel>Manager</InputLabel>
            <Autocomplete
              options={employeeList}
              name='managerId'
              value={employeeList.managerId}
              className="addEmployee-input"
              getOptionLabel={(option) => option.name}
                onChange={(event, value)=>setTeams({...teams,managerId: value ? value.id : null})}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Manager"
                  error={errors.managerId}
                  helperText={errors.managerId ? "Manager is required" : ""}
                  />
                )}
              />
              </Grid>
          }
          <FormControlLabel
          className="checkBoxForTeam"
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                label="Label"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Add Manager"
          />
             </Grid>
          <Grid item xs={8} className="updatebuttons" justifyContent={'center'}>
        <Grid item>
          <Button type="submit" color="primary" variant="contained" onClick={submit} className="updatesubmitbutton">Submit</Button>
        </Grid>
        <Grid>
          <Button variant="contained" color="secondary" onClick={closeTeam}> Cancel</Button>
        </Grid>
        </Grid>
        </Box>
    </div>
  )
}
