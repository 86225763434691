import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function UpgradePlanModal({handleGoToPlans}) {

	return (
		<div>
			<Box className="addemployee-popup" style={{ width: '900px' }} >
				<Typography className="addEmployee-heading">
          			<h2>Upgrade Pricing Plans</h2>
        		</Typography>
        		<Grid container  className="addEmployee-content" columnSpacing={2}>
              		<Grid item xs={8} >
	                	Seems like you have exhausted your employee limits.

	                	Kindly go to Billing page to Upgrade/add license.

	                	<Button className="import" onClick={handleGoToPlans}>Go to Plans</Button>

	            	</Grid>
	            </Grid>
        <hr />
			</Box>
		</div>
	)
}