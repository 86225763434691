import React, { useEffect, useState } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import AvatarGroup from '@mui/material/AvatarGroup';
import "./GoalsGrids.css"
import Button from '@mui/material/Button';
import SliderProgressBar from "Pages/Home/SliderProgressBar";
import { getAllEmployee } from 'Services/OrgService';
import AvatarBuilder from "utils/AvatarBuilder";
import { deleteGoal, getFilteredGoals, getGoalDetails, getKeyInitiatives } from 'Services/Goalservice';
import { capitalizeFirstLetter, getCurrentQuarterRange, getformatedTime } from "Constants/DateConstants";
import NoRecord from "utils/NoRecord";
import NoGoal from "../utils/NoGoal";
import { getColorByStatus, truncateText } from "Constants/Styles";
import rightArrow from "../assets/images/rightArrow.svg"
import DotIcon from "../assets/images/Dot.svg"
import "./Tasks.css"
import ExpandGoalIcon from "../assets/images/ExpandGoalIcon.svg";
import ExpandKRIcon from "../assets/images/ExpandKRIcon.svg";
import CollapseGoalIcon from "../assets/images/CollapseGoalIcon.svg"
import CollapseKRIcon from "../assets/images/CollapseKRIcon.svg"
import bottomArrow from "../assets/images/bottomArrow.svg"
import { Drawer } from "@mui/material";
import GoalDetails from "./GoalDetails";
import { ToastError, ToastSuccess } from "utils/Toast";
import KeyResultDetails from "./KeyResultDetails";
import { useParams } from "react-router-dom";
import GoalMenu from "./GoalMenu";
import KeyResultMenu from "./KeyResultMenu";

export default function Tasks({ filterActions }) {
    const { refId } = useParams();
    const [data, setData] = useState([]);
    const [expanded, setExpanded] = useState([])
    const [krExpanded, setKrExpanded] = useState([])
    const [goalExpanded, setGoalExpanded] = useState([])
    let id = 0;
    const rangeStartDate = getCurrentQuarterRange()[0]
    let showDemoData = localStorage.getItem("showDemo");

    const getGoalData = () => {
        let { fromDate, toDate, goalLevel, goalStatus, teamList, employeeList, visibility, isActiveGoals, searchString } = filterActions;
        let filterParams = {};
        if(goalStatus === "ALL_GOALS"){
            goalStatus = null;
        } 
        if(goalStatus === "ACTIVE"){
            goalStatus = null;
            isActiveGoals = true;
        }
        filterParams = {
            ...(fromDate && { fromDate }),
            ...(toDate && { toDate }),
            ...(goalLevel && { goalLevel }),
            ...(goalStatus && { goalStatus }),
            ...(employeeList && employeeList.length > 0 && { employeeList }),
            ...(teamList && teamList.length > 0 && { teamList }),
            ...(visibility && { visibility }),
            ...(isActiveGoals && {isActiveGoals}),
            ...(searchString && { searchString })
        };
        getFilteredGoals(filterParams).then((res) => {
            let results = res.data;
            if (results && results != null) {
                setData(results);
            }
        });
    }

    useEffect(() => {
        getGoalData();
    }, [showDemoData, filterActions, refId])

    const getGoalProgress = (goalId) => {
        if (goalId != "") {
            getGoalDetails(goalId).then((res) => {
                let results = res.data;
                updateGoalData(results);
            })
        }
    }

    const handleNodeToggle = (event, nodeIds) => {
        setGoalExpanded([...nodeIds]);
    };

    const rec = (arr, keyResultResponseList, id) => {

        if (!arr) return false;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].goalId == id) {
                arr[i].keyResultResponseList = keyResultResponseList;
                return true;
            }
            if (rec(arr[i].keyResultResponseList, keyResultResponseList, id)) {
                return true;
            }
        }
        return false;

    }

    const goalUpdateQueue = [];

    const handleGoalClick = async (goal, goalId) => {
        try {
            goalUpdateQueue.push(async () => {
                const res = await getGoalDetails(goalId);
                const goalData = res.data;

                if (goalData && goalData != null) {
                    const temp = [...data];
                    rec(temp, goalData.keyResultResponseList, goalId);
                    setData([...temp]);
                }
            });
            while (goalUpdateQueue.length > 0) {
                const updateFn = goalUpdateQueue.shift();
                await updateFn();
            }
        } catch (error) {
            ToastError("Somthing Went Wrong !!")
        }
    };

    const [state, setState] = useState({
        right: false,
      });
    
      const [krState, setKrState] = useState({
        right: false,
      });

      const toggleKrDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setKrState({ ...krState, [anchor]: open });
    };
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

    const [goalData, setGoalData] = useState("");

    const handleGoalDetails = (goalId) => {
        setGoalData("");
        getGoalDetails(goalId).then((res) => {
            let results = res.data;
            if (results != null) {
                setGoalData(results);
            }
        });
        setState({ right: true });
    }

    const [krData, setKrData] = useState("");

    const handleKeyResultDetails = (krId) =>{
        setKrData("");
        getKeyInitiatives(krId).then((res) => {
            let results = res.data;
            if (results) {
                setKrData(results);
            }
        });
        setKrState({ right: true });
    }
    const updateGoalData = (updatedGoalData, skipKeyResultCheck = false) => {
        setData((prevData) => {
          const cloneData = JSON.parse(JSON.stringify(prevData));
          const updateParents = (newData, goalId, updatedData) => {
            for (let i = 0; i < newData.length; i++) {
              const goal = newData[i];
              if (goal.goalId === goalId) {
                newData[i] = { ...newData[i], ...updatedData };
                if (updatedData.parentGoalId) {
                  const parentGoalId = updatedData.parentGoalId;
                  getGoalDetails(parentGoalId).then((res) => {
                    let results = res.data;
                    updateGoalData(results, true);
                  });
                }
                break;
              }
              if (!skipKeyResultCheck && goal.keyResultResponseList) {
                updateParents(goal.keyResultResponseList, goalId, updatedData);
              }
            }
          };
      
          updateParents(cloneData, updatedGoalData.goalId, updatedGoalData);
          return cloneData;
        });
      };      

  const isGoalDeleted = () =>{
    setState({
        right : false
    })
    getGoalData();
  }
  const [updateCloseGoal, setUpdateCloseGoal] = useState(false);

  useEffect(() => {
    if (updateCloseGoal) {
        getGoalData();
        setUpdateCloseGoal(false);
    }
  }, [updateCloseGoal]);

  const krAction = () =>{
    setUpdateCloseGoal(true);
  }

  const updateKrData = () => {
    getGoalData();
  };

  const handleDeleteGoal = (goalId) => {
    deleteGoal(goalId)
      .then((res) => {
        ToastSuccess({text:"Goal Deleted Successfully"});
        getGoalData();
      })
      .catch((error) => {
        ToastError({text:"Error while deleting Goal"});
      });
  };

    const renderTree = (goal,parentGoalId, isFirst, level) => {
        const circleClass = isFirst ? 'hide-circle' : '';
        const isGoal = goal.type == "GOAL";
        id = id + 1;
        const width = 50 + level * 3;
        return (
            <TreeItem key={String(goal.goalId)} nodeId={String(goal.goalId)} label={
                <div container className="taskContainer" onClick={() => isFirst ? null : handleGoalClick(goal, goal.goalId)}>
                    <div className="taskItem1">
                    <div className="goalsName">
                            {
                                level > 0 ? (isGoal ? <p>Goal</p> : <h2>KR</h2>) : null
                            }
                            <h4
                            onClick={(event) => { 
                                event.stopPropagation();
                                if (goal.type === "GOAL") {
                                    handleGoalDetails(goal.goalId);
                                } else {
                                    handleKeyResultDetails(goal.id);
                                }
                            }}
                            >{truncateText(goal.title, (95 - level * 8))}</h4>
                        </div>
                        <div className="goalsItem">
                            <p>Due <span>{goal.dueDate}</span></p>
                            {goal.keyResultResponseList ?
                                <>
                                    <img src={DotIcon} />
                                    <Button>{goal.keyResultResponseList.length} KRs
                                        {(goalExpanded.includes(String(goal.goalId)) || expanded.includes(String(goal.goalId))) ? (
                                            <img src={bottomArrow} alt="Close" />
                                        ) : (
                                            <img src={rightArrow} alt="Open" />
                                        )}
                                        </Button>
                                </>
                                : null}
                        </div>
                    </div>
                    <div className="taskItemSecond" style={{ width: `${width}%` }}>
                        <div className="taskItem2">
                            <AvatarGroup max={3} className="avtarIcon">
                                {Array.isArray(goal.ownerDetails)
                                    ? goal.ownerDetails.map((owner, i) => (
                                        <AvatarBuilder
                                            employee={{ name: owner.name }}
                                            variant="circular"
                                            size="profile-img"
                                            key={i}
                                        />
                                    )) : null}
                            </AvatarGroup>
                        </div>
                        <div className="taskItem3">
                            <p>{capitalizeFirstLetter(goal.progressType)} (<span>{Math.round(goal.currentProgress)}</span> of {Math.round(goal.progressEndValue)})</p>
                        </div>
                        <div className="taskItem4" onClick={(event) => event.stopPropagation()}>
                            {
                                isGoal ?
                                    <SliderProgressBar progressEndValue={goal.progressEndValue} progressStartValue={goal.progressStartValue} progressType={goal.progressType} goalId={goal.goalId} progress={goal.currentProgress} progressPercentage={goal.progress} status={goal.status} />
                                    :
                                    <SliderProgressBar progressEndValue={goal.progressEndValue} progressStartValue={goal.progressStartValue} progressType={goal.progressType} krId={goal.id} goalId={goal.goalId} progress={goal.currentProgress} progressPercentage={goal.progress} updateGoalProgressBar={getGoalProgress} status={goal.status} />
                            }
                            <div className="taskSliderItems">
                                <Button style={{ color: getColorByStatus(goal.status) }}>{capitalizeFirstLetter(goal.status)}</Button>
                                <h2>{Math.round(goal.progress)}%</h2>
                                <p>Last Update {getformatedTime(goal.updatedAt)}</p>
                            </div>
                        </div>
                        <div onClick={(event) => { event.stopPropagation()}}>
                            {
                                (goal.type === "GOAL") ?
                                    <GoalMenu
                                        parentId={parentGoalId}
                                        goalData={goal}
                                        onDelete={handleDeleteGoal}
                                        handleGoalDetails={handleGoalDetails}
                                        updateGoal={getGoalData}
                                        isTopGoal={isFirst}
                                        deleteAlignment={getGoalData}
                                        callFrom="GoalDetail"
                                    />
                                    :
                                    <KeyResultMenu
                                        krId={goal.id}
                                        krData={goal}
                                        goalId={goal.goalId}
                                        goalData={goalData}
                                        goalAssociateId={goalData.associateGoalId}
                                        deleteKr={getGoalData}
                                        handleKeyResultDetails={handleKeyResultDetails}
                                        callFrom="GoalDetail"
                                    />
                            }
                        </div>
                    </div>
                </div>
            }
                className={`goalsContainer ${circleClass}`}
            >
                {Array.isArray(goal.keyResultResponseList)
                    ? goal.keyResultResponseList.map((node, index) => (
                        (node.type === "GOAL") ? <></> :
                            <TreeView
                                aria-label="rich object"
                                defaultCollapseIcon={<img src={CollapseKRIcon} className="treeIcon" />}
                                expanded={krExpanded}
                                onNodeToggle={(event, nodeIds) => {
                                    setKrExpanded([...nodeIds])
                                }}
                                defaultExpandIcon={<img src={ExpandKRIcon} className="treeIcon" />}
                                key={index}
                            >
                                {renderTree(node,goal.goalId , false, (level + 1))}
                            </TreeView>
                    ))
                    : null}

                {Array.isArray(goal.keyResultResponseList)
                    ? goal.keyResultResponseList.map((node, index) => (
                        (node.type === "KR") ? <></> :
                            <TreeView
                                aria-label="rich object"
                                defaultCollapseIcon={<img src={CollapseGoalIcon} className="treeIcon" />}
                                expanded={goalExpanded}
                                onNodeToggle={handleNodeToggle}
                                defaultExpandIcon={<img src={ExpandGoalIcon} className="treeIcon" />}
                                key={index}
                            >
                                {renderTree(node,goal.goalId, false, (level + 1))}
                            </TreeView>
                    ))
                    : null}
            </TreeItem>
        );
    };
    return (
        <div className="tasks">
            {data == "" ?
                (rangeStartDate.isAfter(filterActions.toDate)) ? <NoRecord /> : <NoGoal />
                :
                <TreeView
                    aria-label="rich object"
                    defaultCollapseIcon={<img src={CollapseGoalIcon} className="treeIcon" />}
                    expanded={expanded}
                    onNodeToggle={(event, nodeIds) => {
                        setExpanded([...nodeIds])
                    }}
                    defaultExpandIcon={<img src={ExpandGoalIcon} className="treeIcon" />}
                    className="goals"
                >
                    {data.map((goal, i) => renderTree(goal,goal.goalId, true, 0))}

                </TreeView>
            }
            {
                goalData ?
                    <Drawer 
                    anchor={'right'}
                    open={state['right']}
                    onClose={toggleDrawer('right', false)}
                    >
                        {
                            <GoalDetails handleGoalClose = {()=> setState({ right: false })} goalData={goalData} handleGoalDetails={handleGoalDetails} updateGoalData={updateGoalData} isGoalDeleted={isGoalDeleted} krAction={krAction}/>
                        }
                    </Drawer>
                    : null
            }
             {
                krData ?
                    <Drawer 
                    anchor={'right'}
                    open={krState['right']}
                    onClose={toggleKrDrawer('right', false)}
                    >
                        {
                            <KeyResultDetails krData={krData} updateKRData={updateKrData} handleCloseDrawer={toggleKrDrawer('right', false)}/>
                        }
                    </Drawer>
                    : null
            }
        </div>

    );
}