import { useState, useEffect,useRef } from 'react';
import React from "react";

import axiosInstance from "../layout/ServerAxios";
// import { useHistory } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BeatLoader } from "react-spinners";
import "./NetworkService.css"

const errorHandler = (error) => {
    // toast.dismiss();
    let msg = getErrMsg(error);
    toast.error(`${msg}`, {
        position: "bottom-left",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        toastId: msg
    });
    
}

const getErrMsg = (error) => {

    if (!!error.response && !!error.response.data) {
        if (!!error.response.data.message) {
            return error.response.data.message;
        } else if (!!error.response.data.error) {
            return error.response.data.error;
        }
    }
    return "Something went wrong..!! Please try again";

}


export const SetupInterceptors = () => {

    const [counter, setCounter] = useState(0);

    const navigate = useNavigate();
    // const navigate = useRef(useNavigate());

    useEffect(() => {
        const inc = mod => setCounter(c => c + mod);

        const handleRequest = config => (inc(1), config);
        const handleError = error => (inc(-1), Promise.reject(error));

        // add request interceptors
        const reqInterceptor = axiosInstance.interceptors.request.use(handleRequest, handleError);
        // add response interceptors
        const resInterceptor = axiosInstance.interceptors.response.use(function (response) {
            inc(-1);
            return response;
        }, function (error) {
            inc(-1);
            if (error.response !== undefined && error.response.status !== undefined) {
                if (error.response.status == 401) {

                    if(!! error.config && !! error.config.url && error.config.url.includes('/auth/login')){
                    }else{
                        //navigate("/login");
                        return Promise.reject(error);
                    }
                } else if (error.response.status == 404) {
                    navigate("/not-found");
                    return Promise.reject(error);
                } else if (error.response.status == 403 && error.response.data.errorDetail.title === "Upgrade Plan") {
                    navigate("/setting/billing");
                    return Promise.reject(error);
                } else if (error.response.status == 403) {
                    navigate("/forbidden");
                    return Promise.reject(error);
                } else if (error.response.status == 502) {
                    navigate("/service-not-available");
                    return Promise.reject(error);
                }
            }else{
                //navigate("/login");
                return Promise.reject(error);
            }
            errorHandler(error);
            return Promise.reject({ ...error })
        });

        return () => {
            // remove all intercepts when done
            axiosInstance.interceptors.request.eject(reqInterceptor);
            axiosInstance.interceptors.response.eject(resInterceptor);
        };

    }, []);

    return counter > 0;
}

export const GlobalLoader = () => {
    const loading = SetupInterceptors();
    const location = useLocation();
    const isSignupDetailPage = location.pathname === '/signup-details';
    return (
        <div >
            {
                 <div className='spinLoader'>
                    <BeatLoader
                        color="#ed7c02"
                        margin={3}
                        size={15}
                        loading={loading}
                        speedMultiplier={1}
                    />
                    {(isSignupDetailPage && loading) ? <h1>We are setting up your account and adding sample data.  It will take around 30 seconds</h1> : null}
                </div>
                   
            }
        </div>
    );
}