import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import './Overview.css'
import PerformanceReviewCycle from './CreatePerformanceReview';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAllEmployee } from 'Services/OrgService';
import { approvePeerReview, createPeerReview, getPeerReviews, getPerfReviews, getTodoReviews } from 'Services/PerformanceReviewService';
import { useState } from 'react';
import { useEffect } from 'react';
import { ToastError, ToastSuccess } from 'utils/Toast';
import NoRecord from 'utils/NoRecord';


export default function Overview() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [allReviews, setAllReviews] = useState([]);
  const employeeId = localStorage.getItem('employeeId')
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const [peerReview, setPeerReview] = useState([]);
  const role = localStorage.getItem('userRole');

  const redirectToPerformanceReview = (reviewType, reviewId, reviewedEmpId) => {
    const url = reviewedEmpId ? `/performance-review/${reviewType}/${reviewId}/${reviewedEmpId}` : `/performance-review/${reviewType}/${reviewId}`;
    navigate(url);
  };

  const [reviewData, setReviewData] = useState({
    reviewId: '',
    reviewerEmpIds: [],
  });
  const [employeeList, setEmployeeList] = useState([]);

  const loadEmpl = () => {
    getAllEmployee().then((res) => {
      let results = res.data;
      if (results) {
        const filteredEmployeeList = results.filter((employee) => employee.id != employeeId);
        setEmployeeList(filteredEmployeeList);
        return;
      }
    })
  }

  const getReviewId =() =>{
    getPerfReviews().then((res) => {
      let results = res.data;
      if (results) {
        setReviewData({ ...reviewData, reviewId: results});
        return;
      }
    })
  }

  const getAllReviews = () => {
    getTodoReviews().then((res) => {
      let results = res.data;
      if (results.length > 0) {
        setAllReviews(results);
        return;
      }
    })
  }

  const handleApproved = (peerReviewDataApproval, reviewStatus ) => {
    const approveData = {
      fromEmpId: peerReviewDataApproval.empId,
      toEmpId: peerReviewDataApproval.reviewerEmpId,
      reviewId: peerReviewDataApproval.reviewId,
      peerReviewStatus: reviewStatus
    }

    approvePeerReview(approveData).then((res) => {
      let results = res.data;
      if (results) {
        ToastSuccess({ text: "Approving for peer review is successfull" })
        return;
      }
    }).catch((error) => {
      ToastError({ text: "Error while approving peer review" })
    })
  }

  const createPeerReviews = (reviewDatas) => {
    createPeerReview(reviewDatas).then((res) => {
      let results = res.data;
      if (results) {
        ToastSuccess({ text: "PeerReviews Created SuccessFully" })
        return;
      }
    }).catch((error) => {
      ToastError({ text: "Error while creating peerReviews" })
    })
  }

  const getApprovalsPendingPeerReviews = () => {
    if(role == "OWNER" || role == "MANAGER"){
      getPeerReviews().then((res) => {
        let results = res.data;
        if (results) {
          setPeerReview(results)
          return;
        }
      }).catch((error) => {
        ToastError({ text: "Error while getting peer reviews" })
        navigate("/forbidden")
      })
    }
  }

  useEffect(() => {
    loadEmpl();
    getAllReviews();
    getApprovalsPendingPeerReviews();
    getReviewId();
  }, [reviewSubmitted]);

  const handleSubmit = () => {
    createPeerReviews(reviewData);
  };

  return (
    <div className="overview">
      <div className="PRButton">
        <Button variant='outlined' onClick={handleOpen} className='SecondaryButton'>Create PerformanceReview</Button>
      </div>
      <div className='todoListContainer'>
        <div className='todoListItem'>
          <div className='toDoList'>
            <h2>
              Todo Reviews
            </h2>
            {
              allReviews.length == 0 ?
                <Grid item xs={12} className='no-data'>
                  <NoRecord/>
                </Grid>
                :
                allReviews.map((rev, i) => (
                  <Grid container className='grid-container'>
                    <Grid item xs={4}>
                    {getReviewHeading(rev)}
                      <h5>Due Date :{rev.reviewDueDate}</h5>
                    </Grid>
                    <Grid item xs={6} className='grid-item'>
                      <h3>{rev.reviewTitle}</h3>
                      {
                        rev.reviewEmpId ? <h5>- {rev.reviewEmployeeName}</h5> : null
                      }
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={() => redirectToPerformanceReview(rev.questionType, rev.reviewId, rev.reviewEmpId)} className='SecondaryButton'>Write</Button>
                    </Grid>
                  </Grid>
                ))
            }
          </div>
          {
            (role == "OWNER" || role == "MANAGER") ?
              <div className='toDoList'>
                <h2>
                  WorkFlow
                </h2>
                {
                  peerReview.length == 0 ?
                    <Grid item xs={12} className='no-data'>
                      <NoRecord/>
                    </Grid> :
                    peerReview.map((reviewPeer) => (
                      <Grid container className='grid-container'>
                        <Grid item xs={8}>
                          <h3>{reviewPeer.empName} is requested for Peer feedback from {reviewPeer.reviewerEmpName}</h3>
                        </Grid>
                        <Grid item xs={4} className='approvedReviewButtons'>
                          <Button className='PrimaryButton' onClick={() => handleApproved(reviewPeer, "APPROVED")}>Approve</Button>
                          <Button className='SecondaryButton' onClick={() => handleApproved(reviewPeer, "DECLINED")}>Decline</Button>
                        </Grid>
                      </Grid>
                    ))
                }
              </div> : null
          }
        </div>
        <div className='requestedReview'>
          <div>
            <Grid item xs={12} className='requestedReviewContent'>
              <h2>
                Request for Peer Review
              </h2>
              <Autocomplete
                multiple
                options={employeeList}
                className='employeePRFeilds'
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Assignee" />
                )}
                name='requestedPeerReview'
                onChange={(e, val) => {
                  const temp = []
                  val.map((t) => {
                    temp.push(t.id);
                  })
                  setReviewData({ ...reviewData, reviewerEmpIds: temp });
                }}
              />
              <Button className='PrimaryButton' onClick={handleSubmit}>Submit</Button>
            </Grid>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box className="performaceModal">
            <PerformanceReviewCycle onClose={handleClose} onReviewSubmit={() => setReviewSubmitted(true)} />
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}


function getReviewHeading(rev) {
  if (rev.questionType === "EMPLOYEE_TO_MANAGER") {
      return <h3>Write a Review for Your Manager</h3>;
  } else if (rev.questionType === "MANAGER_TO_EMPLOYEE") {
      return <h3>Write a Review for Your Reportee</h3>;
  } else if (rev.questionType === "PEER") {
      return <h3>Write a Review for {rev.reviewEmployeeName}</h3>;
  } else {
      return <h3>Write a Review for Yourself</h3>;
  }
}