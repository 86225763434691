import React from 'react';
import CheckInHistory from './CheckInHistoryChart';
import Grid from '@mui/material/Grid';
import DashboardGoals from './DashboardGoals';
import PieChart from './PieChart';
import CheckInProgress from './CheckInProgressChart';
import EmployeesComparison from './EmployeesComparison';

export default function Personal({params, fromDate, toDate, selectedTeamId}) {
  return (
    <div className='dashboardContent'>
            <Grid className='dashboard'>
            <PieChart params={params} fromDate={fromDate} toDate={toDate} selectedTeamId={selectedTeamId}/>
            <CheckInHistory params={params} fromDate={fromDate} toDate={toDate} selectedTeamId={selectedTeamId}/>
            </Grid>
            <Grid className='dashboard'>
            <Grid className='dashboardGoalContainer'>
                <DashboardGoals params={params} fromDate={fromDate} toDate={toDate} selectedTeamId={selectedTeamId}/>
            </Grid>
            <CheckInProgress params={params} fromDate={fromDate} toDate={toDate} selectedTeamId={selectedTeamId}/>
            </Grid>
        </div>
  )
}
