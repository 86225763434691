import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';

export default function Forbidden() {

  return (
    <div className="create-goal-data">
      <Grid container sx={{ m: 4 }} direction="column"
        justifyContent="center"
        alignItems="center" spacing={2}>
        <Grid sx={{}} item xs={4}>
          <h1>403 - Forbidden!</h1>
        </Grid>
        <Grid item xs={4}>
          <Link sx={{ pt: 10, size : "20px" }} to="/home">Go Home</Link>
        </Grid>

      </Grid>
    </div>

  );
};

// export default Forbidden;