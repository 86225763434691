import callApiV2 from 'utils/ApiUtilsV2';

export async function getSubscriptionDetails() {
	console.log("coming to payemnt service");
  return callApiV2("/v1/proxy/payment","GET",null,null,false,false,"/v1/get-subscription-details");
};

export async function updateSubscription(data) {
	return callApiV2("/v1/proxy/payment","POST",data,null,false,false,"/v1/update-subscription");
}

export async function syncSubscription(subscriptionId) {
	return callApiV2("/v1/proxy/payment", "GET", null, {"subscriptionId": subscriptionId}, false, false, "/v1/sync-subscription")
}

export async function getAllowedFeatures() {
	return callApiV2("/v1/proxy/payment", "GET", null, null, false, false, "/v1/get-allowed-features")
}