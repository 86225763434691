import { createPerformanceAnswer,getQuestions} from 'Services/PerformanceReviewService';
import React, { useEffect, useState } from 'react';
import './WritePerformanceReviews.css';
import { Button, FormHelperText, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import { PERF_REVIEW_QUESTION_TYPE, PERF_REVIEW_TYPE } from 'Constants/Styles';
import { ToastError, ToastSuccess } from 'utils/Toast';
import { useNavigate, useParams } from 'react-router-dom';

export default function WritePerformanceReview() {
    const { questionType,reviewId, reviewedEmpId } = useParams();
    const [selfQuestionData, setSelfQuestionData] = useState([]);
    const [answers, setAnswers] = useState({});
    const navigate = useNavigate();
    const getQuestionData = () => {
        getQuestions(reviewId, questionType).then((res) => {
            let results = res.data;
            if (results && results != null) {
                setSelfQuestionData(results);
                const initialAnswers = results.reduce((acc, questionItem) => {
                    acc[questionItem.questionId] = '';
                    return acc;
                }, {});
                setAnswers(initialAnswers);
            }
        });
    };

    useEffect(() => {
        getQuestionData();
    }, [questionType]);

    const handleAnswerChange = (questionId, value) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: value,
        }));
    };

    const createAnswerForPerformanceReview = (answersData) =>{
        createPerformanceAnswer(answersData).then((res) =>{
          let results = res.data;
            if (results) {
              ToastSuccess({ text: "You have successfully submit the review" })
              navigate("/performance-review")
            }
        }).catch((error) => {
          ToastError({ text: "Error While Creating Performance Review" });
        });
      }

      const [validationErrors, setValidationErrors] = useState({}); 

      const validateForm = () => {
          let errors = {};
  
          selfQuestionData.forEach((questionItem) => {
              if (questionItem.answerType === PERF_REVIEW_QUESTION_TYPE.TEXT) {
                  if (!answers[questionItem.questionId]) {
                      errors[questionItem.questionId] = 'Please Fill this feilds.';
                  }
              } else if (questionItem.answerType === PERF_REVIEW_QUESTION_TYPE.OPTIONS) {
                  if (!answers[questionItem.questionId]) {
                      errors[questionItem.questionId] = 'Please select an answer.';
                  }
              } else if (questionItem.answerType === PERF_REVIEW_QUESTION_TYPE.RATING) {
                  if (!answers[questionItem.questionId]) {
                      errors[questionItem.questionId] = 'Please provide a rating.';
                  }
              }
          });
  
          setValidationErrors(errors);
          return Object.keys(errors).length === 0;
      };


    const handleSubmit = () => {
        const isValid = validateForm();
        if(isValid){
        const answersData = selfQuestionData.map((answerItem) => ({
            reviewId: answerItem.reviewId,
            questionId: answerItem.questionId,
            answerType: answerItem.answerType,
            answer: answers[answerItem.questionId],
            reviewedEmpId : reviewedEmpId,
            reviewType : answerItem.reviewType
        }));

        createAnswerForPerformanceReview(answersData);
        }
    };

    return (
        <div className="questionContent">
            {
                questionType == PERF_REVIEW_TYPE.SELF ? <h1>Self Question</h1> : questionType == PERF_REVIEW_TYPE.EMPLOYEE_TO_MANAGER
                ? <h1>Employee To Manager</h1> : questionType == PERF_REVIEW_TYPE.MANAGER_TO_EMPLOYEE ? <h1>Manager To Employee</h1> : <h1>Peer Question</h1>
            }
            {selfQuestionData.map((questionItem, i) => (
                <div className='reviewQuestionItem' key={i}>
                    <h3>Q : {questionItem.selfQuestion}</h3>
                    {questionItem.answerType === PERF_REVIEW_QUESTION_TYPE.TEXT ? (
                        <>
                            <InputLabel>Answer</InputLabel>
                            <TextField
                                name="name"
                                fullWidth
                                multiline
                                rows={4}
                                value={answers[questionItem.questionId]}
                                onChange={(e) =>
                                    handleAnswerChange(questionItem.questionId, e.target.value)
                                }
                                error={!!validationErrors[questionItem.questionId]}
                                helperText={validationErrors[questionItem.questionId]}
                            />
                        </>
                    ) : questionItem.answerType === PERF_REVIEW_QUESTION_TYPE.OPTIONS ? (
                        <FormControl
                            error={!!validationErrors[questionItem.questionId]}
                        >
                            <FormLabel id="demo-controlled-radio-buttons-group">Answer</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={answers[questionItem.questionId]}
                                onChange={(e) =>
                                    handleAnswerChange(questionItem.questionId, e.target.value)
                                }
                            >
                                {Object.entries(questionItem.optionMap).map(([optionValue, optionLabel]) => (
                                    <FormControlLabel
                                        key={optionValue}
                                        value={optionLabel}
                                        control={<Radio />}
                                        label={optionLabel}
                                    />
                                ))}
                            </RadioGroup>
                            <FormHelperText>{validationErrors[questionItem.questionId]}</FormHelperText>
                        </FormControl>
                    ) : (
                        <FormControl
                            error={!!validationErrors[questionItem.questionId]}
                        >
                            <FormLabel id="demo-row-radio-buttons-group-label">Answer</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={answers[questionItem.questionId]}
                                onChange={(e) =>
                                    handleAnswerChange(questionItem.questionId, e.target.value)
                                }
                            >
                                {Object.entries(questionItem.optionMap).map(([optionValue, optionLabel]) => (
                                    <FormControlLabel
                                        key={optionValue}
                                        value={optionLabel}
                                        control={<Radio />}
                                        label={optionLabel}
                                    />
                                ))}
                            </RadioGroup>
                            <FormHelperText>{validationErrors[questionItem.questionId]}</FormHelperText>
                        </FormControl>
                    )}
                </div>
            ))}
            <Button onClick={handleSubmit} className='SecondaryButton'>Submit</Button>
        </div>
    );
}
