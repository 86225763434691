import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import "./Summary.css"
import { ProgressType, GoalCycles, GoalProgressUpdateType, ProgressTypeForManual, GoalCycle } from 'Constants/EmpConstants';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getCurrentQuarterRange, getFormatedDate } from "Constants/DateConstants";
import { Button, ButtonGroup } from "@mui/material";
import DateNavigator from "utils/DateNavigator";
import { updateGoalProgress } from "Services/Goalservice";
import { ToastSuccess } from "utils/Toast";
import dayjs from 'dayjs';
import { PROGRESSTYPE } from "Constants/Styles";

export default function GoalProgress(props) {
  const [goalDatas, setGoalDatas] = useState(props.goalDetails);

  const [progress, setProgress] = useState(goalDatas);

  const [timeLine, setTimeLine] = useState({
    'goalCycle' : goalDatas.goalTimeline.goalCycle,
    'startDate' : goalDatas.goalTimeline.startDate,
    'dueDate' : goalDatas.goalTimeline.dueDate
});
  const [timeLineErrors, setTimeLineErrors] = useState({
    goalCycle: false,
    startDate: false,
    dueDate: false
  });

  const updateProgress = (e) => {
    e.preventDefault();
      let progressUpdateReq = {
        goalId : progress.goalId,
        progressType : progress.progressType,
        progressStartValue : progress.progressStartValue,
        progressEndValue : progress.progressEndValue,
        goalProgressUpdateType : goalDatas.goalProgressUpdateType
      }

      if(goalDatas.goalOwners){
        progressUpdateReq.goalOwners = [];
        goalDatas.goalOwners.map((t) => {
          progressUpdateReq.goalOwners.push( !!t.id ? t.id : t.employeeId);
        })
      }

      updateGoalProgress(progressUpdateReq).then((res)=>{
        let results = res.data;
        if(results != null){
          ToastSuccess({text:"Goal Progress is Updated Successfully"});
        }
      })
  }

  const validateTimeline = () => {
    let valid = true;
    let newErrors = { ...timeLineErrors };
    if (timeLine.goalCycle && timeLine.goalCycle.trim() === "") {
      newErrors.goalCycle = true;
      valid = false;
    } else {
      newErrors.goalCycle = false;
    }

    if (timeLine.startDate) {
      newErrors.startDate = true;
      valid = false;
    } else {
      newErrors.startDate = false;
    }

    if (timeLine.dueDate ) {
      newErrors.dueDate = true;
      valid = false;
    } else {
      newErrors.dueDate = false;
    }

    setTimeLineErrors(newErrors);
    return true;
  }

  const updateTimeline = (e) => {
    e.preventDefault();
    if (validateTimeline()) {

      let summaryUpdateReq = {
        goalId : progress.goalId,
        goalCycle : timeLine.goalCycle,
        startDate : timeLine.startDate,
        dueDate : timeLine.dueDate,
      }
      if(goalDatas.goalOwners){
        summaryUpdateReq.goalOwners = [];
        goalDatas.goalOwners.map((t) => {
          summaryUpdateReq.goalOwners.push( !!t.id ? t.id : t.employeeId);
        })
      }

      updateGoalProgress(summaryUpdateReq).then((res)=>{
        let results = res.data;
        if(results != null){
          ToastSuccess({text:"Goal TimeLine is Updated Successfully"});
        }
      })

    }
  }

  const [fromDate, setFromDate] = useState(dayjs(timeLine.startDate));
  const [toDate, setToDate] = useState(dayjs(timeLine.dueDate));

  const getFromDate = (date) =>{
    setFromDate(date);
  }
  const getToDate = (date) =>{
    setToDate(date);
  }
  const [bgColorState, setBgColorState]= useState(goalDatas.goalTimeline.goalCycle);

  useEffect(()=>{
    setTimeLine((prevTimeLineData)=>({ 
      ...prevTimeLineData, 
      startDate: getFormatedDate(fromDate),
      dueDate: getFormatedDate(toDate)
    }))
  }, [getFormatedDate(fromDate), getFormatedDate(toDate)])

  const [dateError, setDateError] = useState(false);
  const handleDateValidation = () => {
    if (new Date(timeLine.startDate) > new Date(timeLine.dueDate)) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };
  return (
      <Box className="goalProgress">
        
        <Grid container spacing={1.5} className="summaryContent" >   
                  <form className="timelineSection goalProgressSection">
                  <div className="progressHeading">
                  <InputLabel>Progress</InputLabel>
                  <FormControlLabel control={<Checkbox 
                  checked = {goalDatas.goalProgressUpdateType === PROGRESSTYPE.PERCENTAGE}
                  onChange={(e) =>{
                    const isChecked = e.target.checked;
                    const updatedValue = isChecked ? PROGRESSTYPE.PERCENTAGE : GoalProgressUpdateType[1].value;
                    // let updatedProgressType = isChecked ? ProgressType[0].value : "PERCENTAGE"
                    let updatedStartValue = isChecked ? 0 : "";
                    let updatedTargetValue = isChecked ? 100 : "";
                    setGoalDatas({ ...goalDatas, goalProgressUpdateType: updatedValue }); 
                    if(isChecked){
                      setProgress({ ...progress, progressType:PROGRESSTYPE.PERCENTAGE,
                                                 progressStartValue:updatedStartValue,
                                                 progressEndValue:updatedTargetValue })
                    }

                          
                  } }
                  />} label="Compute Goal Progress from Key Result"/>
                  </div>
                  { goalDatas.goalProgressUpdateType == PROGRESSTYPE.PERCENTAGE ? goalDatas.progressType == PROGRESSTYPE.BOOLEAN ?
                  (() => {
                    progress.progressStartValue = 0;
                    progress.progressEndValue = 1;
                  }
                  )
                  :
                  <div>
                      <InputLabel>Type</InputLabel>
                      <Autocomplete
                        disablePortal
                        fullWidth
                        options={ProgressType}
                        renderInput={(params) => <TextField {...params} required={true}/>}
                        name="progressType"
                        value={progress.progressType}
                        onChange={(e, val) => {
                          if (val === null) {
                            progress.progressType = null;
                          } else {
                            progress.progressType = val.value;
                          }
                          setProgress({ ...progress, progressType: val.value })
                        }}

                        getOptionLabel={(option) => {
                          if (option && option.value) {
                            return option.value;
                          }
                          return option;
                        }}
                      />
                    <div className="timelineContent">
                    <div className="progressInputs">
                      <InputLabel>Start</InputLabel>
                      <TextField
                        name='startValue'
                        className='date'
                        type="number"
                        value={progress.progressStartValue}
                        required
                        onChange={(e) => {
                          progress.progressStartValue = e.target.value;
                          setProgress({ ...progress, progressStartValue : e.target.value })
                        }}
                      />
                    </div>
                    <div className="progressInputs">
                      <InputLabel>End</InputLabel>
                      <TextField
                        required
                        name='targetValue'
                        className='date'
                        type="number"
                        value={progress.progressEndValue}
                        onChange={(e) => {
                          progress.progressEndValue = e.target.value;
                          setProgress({ ...progress, progressEndValue : e.target.value })
                        }}
                      />
                    </div>
                    </div>
                    </div> : <div className="progressContent">
                      <div className="progressInputs">
                      <InputLabel>Type</InputLabel>
                      <Autocomplete
                        disablePortal
                        fullWidth
                        options={ProgressType}
                        renderInput={(params) => <TextField {...params} required={true}/>}
                        name="progressType"
                        value={progress.progressType}
                        onChange={(e, val) => {
                          if (val === null) {
                            progress.progressType = null;
                          } else {
                            progress.progressType = val.value;
                            if(val.value == PROGRESSTYPE.BOOLEAN){
                              progress.startValue = "0";
                              progress.targetValue = "1";
                            }
                          }
                          setProgress({ ...progress, progressType: val.value })
                        }}

                        getOptionLabel={(option) => {
                          if (option && option.value) {
                            return option.value;
                          }
                          return option;
                        }}
                      />
                      </div>
                    {
                      progress.progressType == PROGRESSTYPE.BOOLEAN ?
                      null :
                      <Grid xs={12} className="timelineContent">
                    <div className="progressInputs">
                      <InputLabel>Start</InputLabel>
                      <TextField
                        name='startValue'
                        className='date'
                        type="number"
                        value={progress.progressStartValue}
                        required
                        onChange={(e) => {
                          progress.progressStartValue = e.target.value;
                          setProgress({ ...progress, progressStartValue : e.target.value })
                        }}
                      />
                    </div>
                    <div className="progressInputs">
                      <InputLabel>End</InputLabel>
                      <TextField
                        required
                        name='targetValue'
                        className='date'
                        type="number"
                        value={progress.progressEndValue}
                        onChange={(e) => {
                          progress.progressEndValue = e.target.value;
                          setProgress({ ...progress, progressEndValue : e.target.value })
                        }}
                      />
                    </div>
                    </Grid> 
                    }
                    </div>
                    }

                    <Grid item className="goalData goalSummarySubmit" xs={"12"}>
                      <button variant="contained"
                      className="PrimaryButton"
                      onClick={updateProgress} >Update Progress</button>
                    </Grid>

                  </form>
            </Grid>
            <Grid container spacing={1.5} className="summaryContent" >
                  
                  <form className="timelineSection goalProgressSection">
                  <div className="progressHeading">
                  <InputLabel>TimeLine</InputLabel>
                  </div>
                    <InputLabel>Goal Cycle</InputLabel>

                    <div className="goalCycleButtons">
                    <ButtonGroup variant="outlined" aria-label="outlined button group" className='goalCycle-button' size="large">
                      <Button
                        onClick={() => {
                          setTimeLine({ ...timeLine, goalCycle: GoalCycle[0].value , startDate: getFormatedDate(fromDate), dueDate: getFormatedDate(toDate) })
                          setBgColorState("MONTHLY")
                        }}
                        style={{backgroundColor:bgColorState==="MONTHLY" ? 'var(--light-grey-color)':'var(--white-color)'}}
                      >
                        MONTHLY
                      </Button>
                      <Button
                        onClick={() => {
                          setTimeLine({ ...timeLine, goalCycle: GoalCycle[1].value , startDate: getFormatedDate(fromDate), dueDate: getFormatedDate(toDate) })
                          setBgColorState("QUARTERLY")
                        }}
                        style={{backgroundColor:bgColorState==="QUARTERLY" ? 'var(--light-grey-color)':'var(--white-color)'}}
                      >
                        QUARTERLY
                      </Button>
                      <Button
                        onClick={() => {
                          setTimeLine({ ...timeLine, goalCycle: GoalCycle[2].value , startDate: getFormatedDate(fromDate), dueDate: getFormatedDate(toDate) })
                          setBgColorState("HALFYEARLY")
                        }}
                        style={{backgroundColor:bgColorState==="HALFYEARLY" ? 'var(--light-grey-color)':'var(--white-color)'}}
                      >
                        HALF-YEARLY
                      </Button>
                      <Button
                        onClick={() => {
                          setTimeLine({ ...timeLine, goalCycle: GoalCycle[3].value , startDate: getFormatedDate(fromDate), dueDate: getFormatedDate(toDate) })
                          setBgColorState("ANNUALLY")
                        }}
                        style={{backgroundColor:bgColorState==="ANNUALLY" ? 'var(--light-grey-color)':'var(--white-color)'}}
                      >
                        ANNUALLY
                      </Button>
                      <Button
                        onClick={() => {
                          setTimeLine({ 
                            ...timeLine, 
                            goalCycle: GoalCycle[4].value, 
                            startDate: "",
                            dueDate: ""
                          })
                          setBgColorState("CUSTOM")
                        }}
                        style={{backgroundColor:bgColorState==="CUSTOM" ? 'var(--light-grey-color)':'var(--white-color)'}}
                      >
                        CUSTOM
                      </Button>
                    </ButtonGroup>
                    </div>
                    {(timeLine.goalCycle == GoalCycle[4].value)?
                    <div className="timelineContent">
                      <div>
                        <InputLabel>Start Date</InputLabel>
                        <TextField
                          id="date"
                          type="date"
                          className='date'
                          required
                          onBlur={handleDateValidation}
                          error={dateError}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name='startDate'
                          value={timeLine.startDate}
                          onChange={(e) => {
                            setTimeLine({ ...timeLine, startDate : e.target.value  })
                          }}
                        />
                      </div>
                      <div>
                        <InputLabel>End Date</InputLabel>
                        <TextField
                          id="date"
                          type="date"
                          className='date'
                          required
                          onBlur={handleDateValidation}
                          error={dateError}
                          helperText={dateError ? 'End Date must be greater than Start Date' : ''}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={timeLine.dueDate}
                          name='dueDate'
                          onChange={(e) => {
                            setTimeLine({ ...timeLine, dueDate : e.target.value  })  
                          }}
                        />
                      </div>
                    </div>:<div className="dateNavigatorContainer"><DateNavigator fromDate={getFromDate} toDate={getToDate} goalCycle={timeLine.goalCycle} disableButton={true} className="DateNavigatorAddGoal"/></div>}
                    <Grid item className="goalData goalSummarySubmit" xs={"12"}>
                          <button variant="contained"
                          className="PrimaryButton"
                          onClick={updateTimeline} >Update Timeline</button>
                    </Grid>
                  </form>
        </Grid>
      </Box>
  )
}