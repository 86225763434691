import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import Grid from '@mui/material/Grid';
import {updateOwner } from 'Services/OrgService';
export default function UpdateOwner({afterSubmit, payload, close}) {

	const submit = () => {	  
		updateOwner(payload).then((res) => {
    		afterSubmit(payload.newOwnerId);
   	});
   	close();
  }


	return (
			<div>
      <Box className="addemployee-popup">
        <Typography className="addEmployee-heading">
          <h2>Warning</h2>
        </Typography>
        <hr />
        {
          
            <Grid container key="1" >
              <Grid item>
                <p>This operation is not reverseble.  Do you want to proceed</p>
              </Grid>
	          <Grid item xs={8} className="updatebuttons" justifyContent={'center'}>
	          	<Grid item>
	              <Button type="submit" color="primary" variant="contained" onClick={submit} className="updatesubmitbutton">Submit</Button>
	          	</Grid>
	          	<Grid>
	              <Button variant="contained" color="secondary" onClick={close}> Cancel</Button>
	          	</Grid>
	        	</Grid>
        	</Grid>
        }
      </Box>
    </div>
		)
}