import React, { useState } from "react";
import "./GoalsGrids.css"
import { ClickAwayListener, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { getAllEmployee } from 'Services/OrgService';
import { addNewKeyResult } from 'Services/Goalservice';
import { ProgressType } from 'Constants/EmpConstants';
import { ToastError, ToastSuccess } from "utils/Toast";
import { PROGRESSTYPE } from "Constants/Styles";
import "./AddKeyResults.css"
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getKPIs } from 'Services/Goalservice';
import { SessionDropDown } from "./GoalDetails";
import TimeLineIcon from "../assets/images/LevelIcon.svg"
import {  getNumericformatedDate } from "Constants/DateConstants";

export default function AddKeyResults({ onCancel,goalData, newKeyResult, setNewKeyResult, updateGoalProgress, updateKR }) {

  const [data, setData] = React.useState([]);
  const [showSessionDropdown, setShowSessionDropdown] = useState(false);
  const [employeeList, setEmployeeList] = React.useState([]);
  const [kpiList, setKpiList] = useState([]);
  const [kpiProgressType, setKpiProgressType] = useState("");
  const [kpiData, setKpiData] = useState({
    kpi: "",
    kpiStartValue: "",
    kpiEndValue: ""
  });

  const loadEmpl = () => {
    getAllEmployee().then((res) => {
      let results = res.data;
      if (results) {
        setEmployeeList(results);
        return;
      }
    })
  }
  React.useEffect(() => {
    loadEmpl();
    loadKPI();
  }, []);

  const loadKPI = () => {
    getKPIs().then((res) => {
      let results = res.data;
      if (results) {
        setKpiList(results);
        return;
      }
    })
  }

  const updateSessionSelected = (sessions) => {
    const updatedGoalDetail = {
      ...newKeyResult,
      progressStartDate : sessions.startDate, 
      progressDueDate: sessions.dueDate,
    }
    setNewKeyResult(updatedGoalDetail);
  }

  const closeSessions = () => {
    setShowSessionDropdown(false);
  }

  const updateKeyResultNames = (kpiData) => {
    if(kpiData.kpi != "" && kpiData.kpiStartValue != "" && kpiData.kpiEndValue != ""){
      newKeyResult.title = kpiData.kpi + " from "+ kpiData.kpiStartValue + " to " + kpiData.kpiEndValue;
    }else if (kpiData.kpi != "" && kpiData.kpiStartValue != ""){
      newKeyResult.title = kpiData.kpi + " from "+ kpiData.kpiStartValue + " to " + kpiData.kpiEndValue;
    }
    setData({ ...newKeyResult, title: newKeyResult.title });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newKeyResult && newKeyResult.progressEndValue && newKeyResult.progressStartValue &&
      newKeyResult.progressType && newKeyResult.assignee &&
      newKeyResult.title && (parseInt(newKeyResult.weightage) <= 100) && (parseInt(newKeyResult.weightage) >= 0)) {

      addNewKeyResult(newKeyResult).then((res) => {
        let results = res.data;
        if (results) {
          ToastSuccess({ text: "Key result added successfully" })
          updateKR(true)
          updateGoalProgress(newKeyResult.goalId, results.id)
          setNewKeyResult({})
          onCancel(false)
          return;
        }
      })
    } else {
      ToastError({ text: "Please enter correct values" })
    }
  }

  const handleChangeProgress = (e, val) => {
    if (val === null) {
      setNewKeyResult({ ...newKeyResult, progressType: null });
    } else {
      if (val.value === PROGRESSTYPE.BOOLEAN) {
        setNewKeyResult({
          ...newKeyResult,
          progressType: val.value,
          progressStartValue: "0",
          progressEndValue: "1",
        });
      } else {
        setNewKeyResult({
          ...newKeyResult,
          progressType: val.value,
          progressStartValue: "",
          progressEndValue: "",
        });
      }
    }
  }
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
  const [isProgressTypeOpen, setIsProgresstypeOpen] = useState(false);
  return (
    <form className="newKr" onSubmit={handleSubmit}>
      <div className="newKrContent">
        <h2>Add Key Result</h2>
        <h3>Key Result</h3>
        <input
          name="title"
          value={newKeyResult.title}
          required
          fullWidth
          onChange={(e) => {
            setNewKeyResult({ ...newKeyResult, title: e.target.value })
          }}
          className="newKrInputs"
        />
        <h3>Assignee</h3>
        <Autocomplete
          options={employeeList}
          getOptionLabel={(option) => option.name}
          value={newKeyResult.krOwners}
          className="newKrAssignee"
          renderInput={(params) => <TextField {...params} required={true} className="newKrAutoComplete" />}
          name="assignee"
          onChange={(e, val) => {
            if (val) {
              setNewKeyResult({ ...newKeyResult, assignee: val.id });
            } else {
              setNewKeyResult({ ...newKeyResult, assignee: null });
            }
          }}
          popupIcon={<CustomPopupIcon isOpen={isAssigneeOpen} />}
        />
        <div className="addKRProgress">
          <div className="addKrProgressType">
            <h3>Progress Type</h3>
            <Autocomplete
              options={ProgressType}
              getOptionLabel={(option) => option.value}
              defaultValue={ProgressType[0]}
              renderInput={(params) => <TextField {...params} required={true} className="newKrAutoComplete" />}
              name="progressType"
              onChange={(e, val) => {
                handleChangeProgress(e, val)
                if (val != null && (val.value == PROGRESSTYPE.INCREASE_KPI || val.value == PROGRESSTYPE.DECREASE_KPI)) {
                  setKpiProgressType(val.value)
                  newKeyResult.progressType = ""
                  setNewKeyResult({ ...newKeyResult, progressType: newKeyResult.progressType });
                } else {
                  setKpiProgressType("")
                }
                }
                }
              popupIcon={<CustomPopupIcon isOpen={isProgressTypeOpen} />}
              className="newKrProgressType"
            />
          </div>
          {newKeyResult.progressType == PROGRESSTYPE.BOOLEAN ?
            null
            :
            <>
              <div className="addKrProgressValue">
                <h3>Start</h3>
                <input
                  fullWidth
                  name='startValue'
                  type="number"
                  required
                  value={newKeyResult.progressStartValue}
                  onChange={(e) => {
                    const updatedKpiData = {
                      ...kpiData,
                      kpiStartValue :e.target.value
                    };
                    updateKeyResultNames(updatedKpiData)
                    setKpiData(updatedKpiData);
                    setNewKeyResult({ ...newKeyResult, progressStartValue: e.target.value })
                  }}
                  className="newKrInputs"
                />
              </div>
              <div className="addKrProgressValue">
                <h3>End</h3>
                <input
                  fullWidth
                  name='targetValue'
                  type="number"
                  required
                  value={newKeyResult.progressEndValue}
                  onChange={(e) => {
                    const updatedKpiData = {
                      ...kpiData,
                      kpiEndValue :e.target.value
                    };
                    updateKeyResultNames(updatedKpiData)
                    setKpiData(updatedKpiData);
                    setNewKeyResult({ ...newKeyResult, progressEndValue: e.target.value })
                  }}
                  className="newKrInputs"
                />
              </div>
            </>

          }
        </div>
        {
              kpiProgressType == PROGRESSTYPE.INCREASE_KPI || kpiProgressType == PROGRESSTYPE.DECREASE_KPI ?
                <>
                      <h3>List of KPI</h3>
                      <Autocomplete
                      fullWidth
                      disablePortal
                      className="newKrAssignee"
                      options={kpiList}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} required={true} className="newKrAutoComplete"/>}
                      name="kpi"
                      onChange={(e, val) => {
                         if (val != null) {
                          const updatedKpiData = {
                            ...kpiData,
                            kpi: val.name
                          };
                          updateKeyResultNames(updatedKpiData)
                          newKeyResult.progressType = val.type;
                          setKpiData(updatedKpiData);
                          setData({ ...newKeyResult, progressType: val.type });
                         }
                      }}
                      popupIcon={<CustomPopupIcon isOpen={isAssigneeOpen} />}
                    />
                </> : null
                }
        <div className="addKRProgressDate">
          <div>
          <h3>TimeLine</h3>
          <ClickAwayListener onClickAway={() => setShowSessionDropdown(false)}>
              <div className='newAddGoalItem'>
              <div className='sessionsItem' onClick={() => setShowSessionDropdown(true)}>
                {goalData.goalTimeline.goalCycle ? (
                  <>
                    {`${getNumericformatedDate(newKeyResult.progressStartDate)} - ${getNumericformatedDate(newKeyResult.progressDueDate)}`}
                  </>
                ) : 'TimeLine'}
              </div>
              {showSessionDropdown && (
                  <SessionDropDown goalTimeline={goalData.goalTimeline} updateSessionSelected={updateSessionSelected} closeSessions={closeSessions} />
                )}
              </div>
            </ClickAwayListener>
          </div>
          {/* <div>
            <h3>KR Weightage</h3>
            <input
              fullWidth
              name='weightage'
              type="number"
              value={newKeyResult.weightage}
              required
              onChange={(e) => {
                setNewKeyResult({ ...newKeyResult, weightage: e.target.value })
              }}
              className="newKrInputs"
            />
          </div> */}
        </div>
      </div>
      <div className='newKrHR'></div>
      <div className="newKrButton">
      <button onClick={() => {
          setNewKeyResult({})
          onCancel(false)
        }} className="newKrCancelButton" variant="contained">Cancel</button>
        <button className="PrimaryButton" variant="contained">Save</button>
      </div>
    </form>
  )
}

function CustomPopupIcon({ isOpen }) {
  return isOpen ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />;
}