import React, { useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material';
import { getListOfGivenReviews, getListOfSelfReviews } from 'Services/PerformanceReviewService';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ViewPerformanceReview from './ViewPerformanceReview';
import NoRecord from 'utils/NoRecord';
import { useParams } from 'react-router-dom';

export default function ReviewList() {
    const {review , reviewId} = useParams();
    const [allReviews, setAllReviews] = useState([]);
    const [reviewData, setReviewData ] = useState({
        reviewId : "",
        reviewType :"",
        reviewedEmpId :""
    })

    const [open, setOpen] = React.useState(false);
    const handleOpen = (id, reviewType, reviewedEmpId) => {
        setReviewData({
            reviewId: id,
            reviewType: reviewType,
            reviewedEmpId: reviewedEmpId
        });
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    }

    const getYourReview = () =>{
        getListOfSelfReviews(reviewId).then((res) => {
            const results = res.data;
            setAllReviews(results);
          });
    }

    const getGivenReviews = () =>{
      getListOfGivenReviews(reviewId).then((res) =>{
        const results = res.data;
        setAllReviews(results);
      })
    }
  
    useEffect(() => {
      if(review == "yourReviews"){
        getYourReview();
      }else{
        getGivenReviews()
      }
    }, []);

    return (
      <>
        <div className='reviewsContainer' style={{marginTop:"80px"}}>
          {
            allReviews.length == 0 ?
              <Grid item xs={12} className='no-data'>
                <NoRecord/>
              </Grid>
              :
              allReviews.map((rev, i) => (
                <Grid container className='grid-container'>
                  <Grid item xs={6}>
                  <h3>{rev.reviewName}</h3>
                  <h5>Due Date :{rev.reviewDueDate}</h5>
                  </Grid>
                  <Grid item xs={4} className='grid-item'>
                  <h4>{rev.reviewType}</h4> &nbsp;&nbsp;&nbsp; 
                  {
                    review == "givenReviews" ? <span>{rev.reviewEmpName}</span> : null
                  }
                  </Grid>
                  <Grid item xs={2}>
                    <Button onClick={()=>handleOpen(rev.reviewId, rev.reviewType, rev.reviewedEmpId)} className='SecondaryButton'>View</Button>
                  </Grid>
                </Grid>
              ))
          }
        </div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="yourReviewModal">
            <ViewPerformanceReview reviewData={reviewData} review={review} onClose={handleClose}/>
          </Box>
        </Modal>
      </>
    )
  }
  