import React, { useState } from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button } from '@mui/material';
import './ImportCSV.css';
import { CSVData } from 'Constants/Styles';
import { importCsvFile } from 'Services/OrgService';
import { ToastError } from 'utils/Toast';

export default function UploadEmployee({ handleSetActiveStep, activeStep, handleEmployeeDetails }) {

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleDownload = () => {
        const csvContent = CSVData.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobURL;
        a.download = 'sample_employee.csv';
        a.click();
        URL.revokeObjectURL(blobURL);
    };

    const handleNext = () => {
        if (!selectedFile) {
            ToastError({ text: "Please select a file before proceeding." })
            return;
        }

        const nextStep = Math.min(activeStep + 1, 2);
        handleSetActiveStep(nextStep);
        const formData = new FormData();
        formData.append('file', selectedFile);
        importCsvFile(formData).then((res) => {
            let results = res.data;
            if (results) {
                handleEmployeeDetails(results);
            }
        })

    };

    return (
        <div className='importCSVContainer'>
            <div className='uploadCSV'>
                <SaveAltIcon />
                <h2>Effortless Bulk Employee Import</h2>
                <p>Quickly add multiple employees to OKR with ease.</p>
                <p>Supported formats: CSV and xls (File size limit: 5 MB)</p>
                <>
                    <input
                        type="file"
                        accept=".csv, application/vnd.ms-excel"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="fileInput"
                    />
                    <label htmlFor="fileInput">
                        <Button component="span" className='PrimaryButton uploadCSVButton'>
                            Upload
                        </Button>
                    </label>
                    {selectedFile &&
                        <>
                            <p>Selected file: {selectedFile.name}</p>
                            <p>File size: {Math.round(selectedFile.size / 1024)} KB</p>
                        </>
                    }
                </>
                <h3 onClick={handleDownload}>Download sample template for Import</h3>
                <p>If you'd like to preview the structure, click to download a sample CSV file.</p>
            </div>

            <div className='PRCButtons importCSVNextButton'>
                <Button variant="contained" onClick={handleNext} className='PrimaryButton'>Upload & Next</Button>
            </div>
        </div>
    )
}
