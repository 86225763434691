import React, { useState } from "react";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import './ManageEmployee.css';
import Typography from "@mui/material/Typography";
import { getAllEmployee } from 'Services/OrgService';
import Autocomplete from '@mui/material/Autocomplete';
import { UpdateTeamDetail } from 'Services/OrgService';
import { ToastError, ToastSuccess } from "utils/Toast";

export default function UpdateEmployee({closeTeam, data, close }) {

  const [updateValues, setupdateValues] = useState(data);
  const [managerName, setManagerName] = useState('')

  const [errors, setErrors] = useState({
    name: false,
    managerId: false
    });

  const handleUpdateChange = (e) => {
    setupdateValues({
      ...updateValues,
      [e.target.name]: e.target.value
    });
  };

  const validateFields = () => {
    let valid = true;
    let newErrors = { ...errors };
    if (updateValues.name.trim() === "") {
      newErrors.name = true;
      valid = false;
    } else {
      newErrors.name = false;
    }
    
    if (updateValues.managerId === "" || updateValues.managerId === null) {
      newErrors.managerId = true;
      valid = false;
    } else {
      newErrors.managerId = false;
    }
    
    setErrors(newErrors);
    return valid;
  }    

const handleUpdateSubmit = () =>{
  if(validateFields()){
    UpdateTeamDetail(updateValues).then((res) => {
      let results = res.data;
      if (results !=null ) {
        ToastSuccess({ text: "team updated successfully" });
        closeTeam();
        close();
      }else{
        ToastError({ text: "error while update team" });
      }
      
  });
  }
}


  const [employeeList, setEmployeeList] = React.useState([]);

  const loadTeam = () => {
    getAllEmployee().then((res) => {
      let results = res.data;
      if (results) {
        setEmployeeList(results);
        results.forEach(element => {
          if (element.id == data.managerId ) {
            setManagerName(element)
          }

        });
        return;
      }
    })
  }

  React.useEffect(() => {
    loadTeam();

  }, []);
  return (
    <div>
      <Box className="update-popup">
        <Typography className="addEmployee-heading">
          <h2>Update Team</h2>
        </Typography>
        <hr />
        <Grid container spacing={2} className="updateemployee" alignItems="center">
          <Grid item xs={8} className="updatefeilds" >
            <Typography className="updatelabel">
              Name:
            </Typography>
            <TextField
              className="updateinput"
              value={updateValues.name}
              name="name"
              type="text"
              variant="outlined"
              onChange={handleUpdateChange}

              error={errors.name}
              helperText={errors.name ? "Team name is required" : ""}

            />
          </Grid>
          <Grid item xs={8} className="updatefeilds">
            <Typography className="updatelabel">
              Status:
            </Typography>
            <Select
              className="updateinput"
              value={updateValues.isActive === true ? "true" : "false"}
              name="isActive"
              onChange={handleUpdateChange}
              sx={{ boxShadow: 'none' }}
            >
              <MenuItem value={"true"}>Active</MenuItem>
              <MenuItem value={"false"}>Inactive</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={8} className="updatefeilds">
            <Typography className="updatelabel">
              Manager:
            </Typography>
            <Autocomplete
              options={employeeList}
              name='managerId'
              value={managerName}
              className="updateinput"
              getOptionLabel={(option) => option.name}
              onChange={(e, value) => {
                setManagerName(value)
                let managerId = !!value ? value.id : null;
                setupdateValues({ ...updateValues, managerId: managerId })
              }
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Team" className="updateInput" type="text"
                error={errors.managerId}
                helperText={errors.managerId ? "Manager is required" : ""} />
              )}
            />
          </Grid>
          <Grid item xs={8} className="updatebuttons" justifyContent={'center'}>
            <Grid item>
              <Button type="submit" variant="contained" 
              sx={{
                ml: 1,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "white",
                  color:"#ed7c02",
                  border:"2px solid #ed7c02"
                }
              }}
              onClick={handleUpdateSubmit} className="updatesubmitbutton Button">
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={close}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
