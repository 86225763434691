import React, { useState } from "react";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import './ManageEmployee.css';
import Typography from "@mui/material/Typography";
import {getAllTeams } from 'Services/OrgService';
import Autocomplete from '@mui/material/Autocomplete';
import { UpdateEmployeeDetail} from 'Services/OrgService';

import { isValidStr,isValidNumber } from 'utils/CommonUtils';
import { ToastError, ToastSuccess } from "utils/Toast";

  
export default function UpdateEmployee({addEmployeeClose,data,close}) {
  
  const [teamName, setTeamName]=useState('');
  const [teamList, setTeamList] = React.useState([]);
  const [updateValues, setupdateValues] = useState(data);

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    teamId: false,
    role: false
    });

  const validateFields = () => {
    let valid = true;
    let newErrors = { ...errors };
    if ( ! isValidStr(updateValues.name)) {
      newErrors.name = true;
      valid = false;
    } else {
      newErrors.name = false;
    }
    
    if ( ! isValidStr(updateValues.email)) {
      newErrors.email = true;
      valid = false;
    } else {
      newErrors.email = false;
    }

    if (! isValidNumber(updateValues.teamId) ) {
      newErrors.teamId = true;
      valid = false;
    } else {
      newErrors.teamId = false;
    }

    if (! isValidStr(updateValues.role) ) {
      newErrors.role = true;
      valid = false;
    } else {
      newErrors.role = false;
    }
    
    setErrors(newErrors);
    return valid;
  }    

  const handleUpdateChange = (e) => {
    e.preventDefault();
    setupdateValues({
      ...updateValues,
      [e.target.name]: e.target.value
    });
  };

  const handleUpdateSubmit = (e) => {
      e.preventDefault();
      if(validateFields()){
        UpdateEmployeeDetail(updateValues).then((res) => {
          let results = res.data;
          if (results !=null ) {
            ToastSuccess({ text: "Employee updated successfully" });
            addEmployeeClose();
            close();
          }else{
            ToastError({ text: "error while update Employee" });
          }
          
      });
      }

  };
  

  const loadTeam = () =>{
    getAllTeams().then((res) => {
   let results = res.data;
   if(results){
     setTeamList(results);
     results.forEach(element => {
      if(element.id==data.teamId){
        setTeamName(element)
      }
      
     });
     return;
   }
})
}

 React.useEffect(() => {
   loadTeam();
   
 }, []);
  return (
    <div>
      <Box className="update-popup">
        <Typography className="addEmployee-heading">
          <h2>Update Employee</h2>
        </Typography>
        <hr/>
        <Grid container spacing={2} className="updateemployee">
        <Grid item xs={8} className="updatefeilds">
          <Typography className="updatelabel"> 
            Name:
          </Typography>
          <TextField
          className="updateinput"
          type="text"
          value={updateValues.name}
            name="name"
            label="Name"
            variant="outlined"
            onChange={handleUpdateChange}

            error={errors.name}
            helperText={errors.name ? "Name is required" : ""}
          />
        </Grid>
        <Grid item xs={8} className="updatefeilds">
         <Typography className="updatelabel">
            Email:
          </Typography>
          <TextField
          value={updateValues.email}
          className="updateinput"
          disabled
          type="email"
            name="email"
            label="Email"
            variant="outlined"
            onChange={handleUpdateChange}
            error={errors.email}
            helperText={errors.email ? "Email is required" : ""}
          />
        </Grid>
        
        <Grid item xs={8} className="updatefeilds">
          <Typography className="updatelabel">
            Mobile:
          </Typography>
          <TextField
          value={updateValues.phoneNumber}
          className="updateinput"
          type="tel"
            name="phoneNumber"
            label="Mobile"
            variant="outlined"
            onChange={handleUpdateChange}
          />
        </Grid>
        <Grid item xs={8} className="updatefeilds">
          <Typography className="updatelabel">
            Team:
          </Typography>
          <Autocomplete
                options={teamList}
                name='teamId'
                value={teamName}
                className="updateinput"
                getOptionLabel={(option) => option.name}
                onChange={(e, value)=>{
                  setTeamName(value)
                  let teamId = !!value ? value.id : null;
                setupdateValues({ ...updateValues, teamId: teamId })
                }
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Team" className="updateInput"
                  error={errors.teamId}
                helperText={errors.teamId ? "Team is required" : ""}
                 type="text"/>
                )}
              />
        </Grid>
        <Grid item xs={8} className="updatefeilds">
          <Typography className="updatelabel" >
            Role: 
          </Typography>
          <Select
            className="updateinput"
            value={updateValues.role}
            name="role"
            onChange={handleUpdateChange}
            >
            <MenuItem disabled hidden value={"OWNER"}>Owner</MenuItem>
            <MenuItem value={"STAFF"}>Staff</MenuItem>
            <MenuItem value={"VIEWER"}>Viewer</MenuItem>
            <MenuItem value={"MANAGER"}>Manager</MenuItem>
            <MenuItem value={"ADMIN"}>Admin</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={8} className="updatebuttons" justifyContent={'center'}>
          <Grid item>
            <Button type="submit" variant="contained"
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "white",
                color:"#ed7c02",
                border:"2px solid #ed7c02"
              }
            }}
            onClick={handleUpdateSubmit} className="updatesubmitbutton Button">
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={close}> 
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
        </Box>
    </div>
  )
}
