import React, { useEffect, useState } from 'react';
import './GoalGrid.css';
import AllGoals from '../assets/images/AllGoals.svg';
import CompleteGoals from '../assets/images/CompleteGoals.svg';
import BehindGoals from '../assets/images/BehindGoals.svg';
import NotStartedGoals from '../assets/images/NotStartedGoals.svg';
import OnTrackGoals from '../assets/images/OnTrackGoals.svg';
import AtRiskGoals from '../assets/images/AtRiskGoals.svg';
import AheadGoals from '../assets/images/AheadGoals.svg';
import AllGoalsColor from '../assets/images/AllGoalsColor.svg';
import CompleteGoalsColor from '../assets/images/CompleteGoalsColor.svg';
import BehindGoalsColor from '../assets/images/BehindGoalsColor.svg';
import NotStartedGoalsColor from '../assets/images/NotStartedGoalsColor.svg';
import OnTrackGoalsColor from '../assets/images/OnTrackGoalsColor.svg';
import AtRiskGoalsColor from '../assets/images/AtRiskGoalsColor.svg';
import AheadGoalsColor from '../assets/images/AheadGoalsColor.svg';
import { Button, Grid } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DateNavigator from 'utils/DateNavigator';
import FilterDropDown from "../assets/images/FilterDropDown.svg"
import EmployeeIcon from "../assets/images/EmployeeIcon.svg"
import TeamIcon from "../assets/images/TeamIcon.svg"
import LevelIcon from "../assets/images/LevelIcon.svg"
import VisibilityIcon from "../assets/images/VisibilityIcon.svg"
import Tasks from './Tasks';
import { getAllEmployee, getAllTeams } from 'Services/OrgService';
import { GoalLevelList, VISIBILITY } from 'Constants/EmpConstants';
import { getCurrentQuarterRange, getFormatedDate } from 'Constants/DateConstants';
import AvatarBuilder from 'utils/AvatarBuilder';

export default function GoalGrid() {
    const [activeButton, setActiveButton] = useState("ALL_GOALS");      
    const rangeStartDate = getCurrentQuarterRange()[0]
    const rangeEndDate = getCurrentQuarterRange()[1]
    const [fromDate, setFromDate] = useState(rangeStartDate);
    const [toDate, setToDate] = useState(rangeEndDate);

    const temp = {
        goalLevel: "",
        goalStatus: "",
        visibility: "",
        employeeList: [],
        teamList: [],
        fromDate: getFormatedDate(fromDate),
        toDate: getFormatedDate(toDate),
        isActiveGoals : "",
      }

    const [filterActions, setFilterActions] = useState(temp);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName === activeButton ? null : buttonName);
        updateFilterActions("goalStatus", buttonName);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getFromDate = (date) =>{
        setFromDate(date);
    }
    const getToDate = (date) =>{
        setToDate(date);
    }

    useEffect(() => {
        setFilterActions((prevActions) => ({
          ...prevActions,
          fromDate: getFormatedDate(fromDate),
          toDate: getFormatedDate(toDate),
        }));
    }, [fromDate, toDate]);

    const [selectEmployeeOptions, setSelectEmployeeOptions] = useState(false);
    const [selectTeamOptions, setSelectTeamOptions] = useState(false);
    const [selectLevelOptions, setSelectLevelOptions] = useState(false);
    const [selectVisibilityOptions, setSelectVisibilityOptions] = useState(false);
    const [allClearButtonVisible, setAllClearButtonVisible] = useState(false);

    const handleMenuItemClick = (label) => () => {
        if (label == 'Employee') {
            setSelectEmployeeOptions(true);
            setAllClearButtonVisible(true);
        }
        if (label == 'Team') {
            setSelectTeamOptions(true);
            setAllClearButtonVisible(true);
        }
        if (label == 'Level') {
            setSelectLevelOptions(true);
            setAllClearButtonVisible(true);
        }
        if (label == 'Visibility') {
            setSelectVisibilityOptions(true);
            setAllClearButtonVisible(true);
        }
        handleClose();
    };

    const handleAllClear = () => {
        setSelectEmployeeOptions(false);
        setSelectTeamOptions(false);
        setSelectLevelOptions(false);
        setSelectVisibilityOptions(false);
        setActiveButton("ALL_GOALS")
        setFilterActions(temp);
        setAllClearButtonVisible(false);
    }

    const debounce = (func, delay) => {
        let timeoutId;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func.apply(context, args);
            }, delay);
        };
    };

    const debouncedHandleSearchInputChange = debounce((searchValue) => {
        updateFilterActions("searchString", searchValue);
    }, 500);

    const handleSearchInputChange = (event) => {
        const searchValue = event.target.value;
        debouncedHandleSearchInputChange(searchValue);
    };

    const updateFilterActions = (paramType, selectedOptions) => {
        setFilterActions((prevActions) => ({
          ...prevActions,
          [paramType]: selectedOptions,
        }));
      };

    return (
        <div className='goalsGridContainer'>
            <div className="goalsGrid">
                <p>Goal quick view</p>
                <Button
                    className={activeButton === 'ALL_GOALS' ? 'active-button' : ''}
                    onClick={() => handleButtonClick('ALL_GOALS')}
                >
                    <img src={activeButton === 'ALL_GOALS' ? AllGoalsColor : AllGoals} alt="ALL_GOALS" /> All goals
                </Button>
                <Button
                    className={activeButton === 'ACTIVE' ? 'active-button' : ''}
                    onClick={() => handleButtonClick('ACTIVE')}
                >
                    <img src={activeButton === 'ACTIVE' ? AllGoalsColor : AllGoals} alt="ACTIVE" /> Active goals
                </Button>
                <Button
                    className={activeButton === 'COMPLETED' ? 'active-button' : ''}
                    onClick={() => handleButtonClick('COMPLETED')}
                >
                    <img src={activeButton === 'COMPLETED' ? CompleteGoalsColor : CompleteGoals} alt="COMPLETED" /> Complete
                </Button>
                <Button
                    className={activeButton === 'BEHIND' ? 'active-button' : ''}
                    onClick={() => handleButtonClick('BEHIND')}
                >
                    <img src={activeButton === 'BEHIND' ? BehindGoalsColor :BehindGoals} alt="BEHIND" /> Behind
                </Button>
                <Button
                    className={activeButton === 'AT_RISK' ? 'active-button' : ''}
                    onClick={() => handleButtonClick('AT_RISK')}
                >
                    <img src={activeButton === 'AT_RISK' ? AtRiskGoalsColor : AtRiskGoals} alt="AT_RISK" /> At-Risk
                </Button>
                <Button
                    className={activeButton === 'NOT_STARTED' ? 'active-button' : ''}
                    onClick={() => handleButtonClick('NOT_STARTED')}
                >
                    <img src={activeButton === 'NOT_STARTED' ? NotStartedGoalsColor : NotStartedGoals} alt="NOT_STARTED" /> Not-Started
                </Button>
                <Button
                    className={activeButton === 'ON_TRACK' ? 'active-button' : ''}
                    onClick={() => handleButtonClick('ON_TRACK')}
                >
                    <img src={activeButton === 'ON_TRACK' ? OnTrackGoalsColor : OnTrackGoals} alt="ON_TRACK" /> On-Track
                </Button>
                <Button
                    className={activeButton === 'AHEAD' ? 'active-button' : ''}
                    onClick={() => handleButtonClick('AHEAD')}
                >
                    <img src={activeButton === 'AHEAD' ? AheadGoalsColor :AheadGoals} alt="AHEAD" /> Ahead
                </Button>
            </div>
            <div className='goalsGridContent'>
                <div className='goalsGridItem'>
                    <div className='filterOptions'>
                        {selectEmployeeOptions && (
                            <MultiSelectDropdown params={"employeeList"} onSelectOption={updateFilterActions} />
                        )}
                        {selectTeamOptions && (
                            <MultiSelectDropdown params={"teamList"} onSelectOption={updateFilterActions} />
                        )}
                        {selectLevelOptions && (
                            <SelectDropDown params={"goalLevel"} onSelectOption={updateFilterActions} />
                        )}
                        {selectVisibilityOptions && (
                            <SelectDropDown params={"visibility"} onSelectOption={updateFilterActions} />
                        )}
                        <div className={`filterDropDown ${anchorEl != null ? 'input-container-highlight' : ''}`}>
                            <Button
                                aria-controls="dropdown-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                endIcon={<img src={FilterDropDown} />}
                            >
                                <p>Filter</p>
                            </Button>
                            <Menu
                                id="dropdown-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem className='filterMenuLabel'>Select Filters</MenuItem>
                                <MenuItem onClick={handleMenuItemClick('Employee')} className='filterMenuItems'><img src={EmployeeIcon} /> Employee</MenuItem>
                                <MenuItem onClick={handleMenuItemClick('Team')} className='filterMenuItems'> <img src={TeamIcon} />Team</MenuItem>
                                <MenuItem onClick={handleMenuItemClick('Level')} className='filterMenuItems'> <img src={LevelIcon} />Level</MenuItem>
                                <MenuItem onClick={handleMenuItemClick('Visibility')} className='filterMenuItems'> <img src={VisibilityIcon} /> Visibility</MenuItem>
                            </Menu>
                        </div>
                        {
                            allClearButtonVisible ?
                            <div className='clearOptions' onClick={handleAllClear}>
                            <p>Clear &#10006;</p>
                            </div> : null
                        }
                        <input
                            type="text"
                            placeholder="Search..."
                            onChange={handleSearchInputChange}
                        />
                    </div>
                    <DateNavigator fromDate={getFromDate} toDate={getToDate}/>
                </div>
                <div className='goalsContent'>
                    <Grid container className='goalHeading'>
                        <Grid className='goalItems1 goalItems'>
                            <p>GOALS</p>
                        </Grid>
                        <Grid className='goalItems2 goalItems'>
                            <p>ASSIGNEE</p>
                        </Grid>
                        <Grid className='goalItems3 goalItems'>
                            <p>SUCCESS METRIC</p>
                        </Grid>
                        <Grid className='goalItems4 goalItems'>
                            <p>PROGRESS</p>
                        </Grid>
                    </Grid>

                    <div className='TaskComponent'>
                        <Tasks filterActions={filterActions}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

const MultiSelectDropdown = ({ params, onSelectOption }) => {
    const [selected, setSelected] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const [employeeList, setEmployeeList] = React.useState([]);

    useEffect(() => {
        const containerClassName = params === "teamList" ? "SelectForTeam" : "SelectForEmployee";

        const handleClickOutside = (event) => {
            if (isDropdownVisible) {
                const inputContainer = document.querySelector(`.${containerClassName}`);
                if (inputContainer && !inputContainer.contains(event.target)) {
                    setIsDropdownVisible(false);
                }
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isDropdownVisible, params]);

    const loadTeam = () => {
        getAllTeams().then((res) => {
            let results = res.data;
            if (results) {
                setEmployeeList(results);
                return;
            }
        })
    }

    const loadEmpl = () => {
        getAllEmployee().then((res) => {
            let results = res.data;
            if (results) {
                setEmployeeList(results);
                return;
            }
        })
    }

    React.useEffect(() => {
        if (params == "teamList") {
            loadTeam();
        } else {
            loadEmpl();
        }
    }, []);

    const toggleOption = (id, name) => {
        const updatedSelected = [...selected];
        const existingIndex = updatedSelected.findIndex((item) => item.id === id);

        if (existingIndex !== -1) {
            updatedSelected.splice(existingIndex, 1);
        } else {
            updatedSelected.push({ name, id });
        }
        setSelected(updatedSelected);
        const selectedEmployeeIds = updatedSelected.map((item) => item.id);
        onSelectOption(params, selectedEmployeeIds);
    };

    const handleInputClick = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleOptionClick = (value, label) => {
        toggleOption(value, label);
    };

    const handleClearClick = () => {
        setSelected([]);
        onSelectOption(params, []);
        setIsDropdownVisible(false);
    };

    const getSelectedDisplay = () => {
        if (selected.length === 1) {
            return selected[0].name;
        } else if (selected.length > 1) {
            return `${selected[0].name} + ${selected.length - 1}`;
        } else {
            return "";
        }
    };

    return (
        <div className={`multiSelect ${params == "teamList" ? "SelectForTeam" : "SelectForEmployee"}`}>
            <div className={`input-container ${isDropdownVisible ? 'input-container-highlight' : ''}`}>
                <p>{params == "teamList" ? "Team" : "Employee"}</p>
                <input
                    type="text"
                    placeholder={`Select ${params}`}
                    value={getSelectedDisplay()}
                    readOnly
                    className="selected-items-text"
                    onClick={handleInputClick}
                />
                <span className="clear-icon" onClick={handleClearClick}>&#10006;</span>
            </div>
            {isDropdownVisible && (
                <div className="multiselect-dropdown">
                    <label className='optionLabel'>Select Employee</label>
                    {employeeList.map((option) => (
                        <label key={option.id} className="option">
                            <input
                                type="checkbox"
                                checked={selected.some(item => item.id === option.id)}
                                onChange={() => handleOptionClick(option.id, option.name)}
                            />
                            {params == "teamList" ? "" : <AvatarBuilder employee={{ name: option.name }} variant="circular" size="extra-samll"/>}
                            {option.name}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
}

const SelectDropDown = ({ params, onSelectOption }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        onSelectOption(params, option.toUpperCase());
        setIsOpen(false);
    };

    const handleClearClick = () => {
        onSelectOption(params, '');
        setSelectedOption('');
    };

    useEffect(() => {

        const handleClickOutside = (event) => {
            const containerClassName = params === "goalLevel" ? "SelectForLevel" : "SelectForVisibility";
            if (isOpen) {
                const inputContainer = document.querySelector(`.${containerClassName}`);
                if (inputContainer && !inputContainer.contains(event.target)) {
                    setIsOpen(false);
                }
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    let options = [];
    if (params == "goalLevel") {
        options = GoalLevelList;
    } else {
        options = VISIBILITY;
    }

    return (
        <div className={`multiSelect ${params == "goalLevel" ? "SelectForLevel" : "SelectForVisibility"}`}>
            <div className={`input-container ${isOpen ? 'input-container-highlight' : ''}`}>
                <p>{params == "goalLevel" ? "Level" : "Visibility"}</p>
                <input
                    type="text"
                    value={selectedOption}
                    placeholder="Select an option"
                    readOnly
                    onClick={toggleDropdown}
                    className="selected-items-text"
                />
                <span className="clear-icon" onClick={handleClearClick}>&#10006;</span>
            </div>
            {isOpen && (
                <div className="selectDropdown">
                    {options.map((option) => (
                        <p key={option} onClick={() => handleOptionClick(option)}>
                            {option}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
}