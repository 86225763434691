import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { resetPassword } from 'Services/AuthService';
import { getEmployee , updateMyAccount } from 'Services/OrgService';
import { ToastError, ToastSuccess } from "utils/Toast";

export default function GeneralSetting() {
  const [employee, setEmployee] = React.useState([]);
  const [password, setPassword] = React.useState({
    newPassword:"",
    confirmPassword:"",
    verificationToken:localStorage.getItem('token')
  });

  const handleSubmit = (e) => {
    e.preventDefault()
    employee.empId = employee.id;
    setEmployee({...employee,empId:employee.id})
    updateMyAccount(employee).then((res) => {
      ToastSuccess({ text: "Details updated successfully" });
    });
  };

  const updatePassword = (e) => {
    e.preventDefault();
    
    resetPassword(password).then((res) => {
      let results = res.data;
      if(results !=null) {
        localStorage.setItem('token', results.token);
        ToastSuccess({text:"Password Reset Successfully"});
      } else {
        ToastError({text:`Something went wrong.  Please try again sometime later or reach out to support@10xokr.com`})
      }
    });
    
    password.newPassword = "";
    password.confirmPassword = "";
  };

  React.useEffect(() => {
    getEmployee().then((res) => {
      setEmployee(res.data);
    });
    
    
  }, []);

  return (
    <Box className="addEmployeeSection" sx={{'& > :not(style)': { m: 1, width: '1' }}}>
      <Grid container spacing={1.5} >
        <Grid item className="grid-cell" xs={"auto"} lg={6}>
          <InputLabel className="inputlabel-form">Name</InputLabel>
          <TextField className="outlined-basic" variant="outlined"
            name="employeeName"
            value={employee.name}
            onChange={(e)=>setEmployee({...employee,name:e.target.value})}
          />
        </Grid>
        <Grid item className="grid-cell" xs={"auto"} lg={6}>
          <InputLabel className="inputlabel-form">New Password</InputLabel>
          <TextField className="outlined-basic" variant="outlined"
            id="outlined-password-input"
            type="password"
            value={password.newPassword}
            onChange={(e)=>setPassword({...password,newPassword:e.target.value})}
          />
        </Grid>
        <Grid item className="grid-cell" lg={6} xs={"auto"}>
          <InputLabel className="inputlabel-form">Contact Number</InputLabel>
          <TextField className="outlined-basic" variant="outlined"
            name="contactNumber"
            value={employee.phoneNumber}
            onChange={(e)=>setEmployee({...employee,phoneNumber:e.target.value})}
          />
        </Grid>
        
        <Grid item className="grid-cell" xs={"auto"} lg={6}>
          <InputLabel className="inputlabel-form">Confirm Password</InputLabel>
          <TextField className="outlined-basic" variant="outlined"
            id="outlined-password-input"
            type="password"
            value={password.confirmPassword}
            onChange={(e)=>setPassword({...password,confirmPassword:e.target.value})}
          />
        </Grid>
        <Grid item className="grid-cell" xs={6} sm={6} md={10} lg={6}>
          <button variant="contained" className="PrimaryButton" onClick={handleSubmit}>Submit</button>
        </Grid>
        <Grid item className="grid-cell" lg={6}>
          <button variant="contained" className="PrimaryButton" onClick={updatePassword}>Reset Password</button>
        </Grid>
      </Grid>
    </Box>
  );
}
