import React, {useEffect, useState} from "react";
function IncDecCounter({ defaultNum, listenerMethod, minBound=0}){
  let [num, setNum]= useState(defaultNum);

  let incNum =()=>{
    setNum(Number(num)+1);
    listenerMethod(Number(num)+1);
  };
  let decNum = () => {
     if(num>minBound)
     {
      setNum(num - 1);
      listenerMethod(num -1);
     }
  }
 let handleChange = (e)=>{
   setNum(e.target.value);
   listenerMethod(e.target.value)
  }
   

   return(
    <>
    <div className="col-xl-1">
    <div class="input-group">
  <div class="input-group-prepend">
    <button class="btn btn-outline-primary" type="button" onClick={decNum}>-</button>
  </div>
  <input type="text" class="form-control" value={num} onChange={handleChange}/>
  <div class="input-group-prepend">
    <button class="btn btn-outline-primary" type="button" onClick={incNum}>+</button>
  </div>
</div>
</div>
   </>
  );
}

export default IncDecCounter;