

//////SLACK
export const SLACK_APP_BASE_URL="https://slack.com/oauth/v2/authorize?client_id=";
export const SLACK_CLIENT_ID = "5824436188822.5876994064438";
export const SLACK_SCOPES = "&scope=app_mentions:read,channels:read,chat:write,chat:write.public,commands,groups:read,im:history,im:read,im:write,users:write&state=";



//////GOOGLE SHEET
export const GOOGLE_SHEET_SCOPE = "https://www.googleapis.com/auth/drive.readonly";
export const GOOGLE_SHEET_REDIRECT_URI = "http://localhost:3000/v1/google-sheets/install";
export const GOOGLE_SHEET_CLIENT_ID = "277036223289-e8dm60brssg0gbsiadjt83gccr84f57k.apps.googleusercontent.com";
export const GOOGLE_SHEET_CLIENT_SECRET = "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";
export const GOOGLE_SHEET_OAUTH_URI = "https://accounts.google.com/o/oauth2/v2/auth?";