import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import "./Summary.css"
import { searchGoal,updateGoalAlignment} from 'Services/Goalservice';
import { ToastSuccess } from "utils/Toast";
import { Button } from "@mui/material";
import {useNavigate } from "react-router-dom";


export default function Alignment(props) {
  const [goalDatas, setGoalDatas] = useState(props.goalDetails);
  const [data, setData] = useState({
    parentId : !!goalDatas.goalAlignmentResponse ? goalDatas.goalAlignmentResponse.alignmentId : null,
    alignedType : !!goalDatas.goalAlignmentResponse ? goalDatas.goalAlignmentResponse.alignmentType : null,
    title : !!goalDatas.goalAlignmentResponse ? goalDatas.goalAlignmentResponse.title : "",
  });

  const [errors, setErrors] = useState({
    parentId : false
  });

  const validateFields = () => {
    let valid = true;
    let newErrors = { ...errors };
    if (data.parentId == undefined || data.parentId == null || data.parentId == '') {
      newErrors.parentId = true;
      valid = false;
    } else {
      newErrors.parentId = false;
    }
    setErrors(newErrors);
    return valid;
  }

  const [alignGoalList, setAlignGoalList] = React.useState([]);
  const [selectedGoal, setSelectedGoal] = React.useState({});
  const search = (value) => {
    if (value == undefined || value.length < 3) {
      return;
    }
    searchGoal({ "search": value }).then((res) => {
      let results = res.data;
      setAlignGoalList(results);
    });
  };


  const handleSubmit = () => {

    if (validateFields()) {
      let alignReq = {
        goalId : goalDatas.goalId,
        parentId : data.parentId,
        goalAlignmentType : data.alignedType
      }

      updateGoalAlignment(alignReq).then((res) => {
        let results = res.data;
        if (results) {
          ToastSuccess({ text: "Goal Alignment updated successfully." });
          setGoalDatas(results);
        }
      })

    }
  }


  const navigateGoal = useNavigate();

  const redirectGoalViewDetails = (goalId) => {
    if(goalId){
    navigateGoal("/goal-details/" + goalId);
    window.location.reload();
    }
  }

  React.useEffect(() => {
  }, []);

  return (
    <div>
      <Box sx={{ '& > :not(style)': { m: 1, width: '1' } }}>
        <Grid container spacing={1.5} className="summaryContent" >
          <Grid item className="goalData" xs={"auto"}>
            <Autocomplete
                    sx={{ width: 350 }}
                    freeSolo
                    value={goalDatas.goalAlignmentResponse}
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    getOptionLabel={(option) => option&&option.title ? option.title : ""}
                    onChange={(event, newValue) => {
                      setSelectedGoal(newValue);
                      let alignedId = newValue != undefined ? newValue.id : null;
                      let alignedType = newValue != undefined ? newValue.goalAlignmentType : null;
                      data.parentId = alignedId;
                      data.alignedType = alignedType;
                      setData(data);
                    }
                    }
                    options={alignGoalList}

                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.id}
                      >
                        <Grid container >
                          <Grid item sm={8}>
                            <Grid container direction="column" spacing={1} >
                              <Grid item>
                                <Typography variant="h7"> {option.title + "-" + option.goalAlignmentType} </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => search(e.target.value)}
                        label="Search Goal"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}

                        error={errors.parentId}
                        helperText={errors.parentId ? "Parent goal is required" : ""}
                      />
                    )}
                  />
                  {
                    goalDatas.goalAlignmentResponse.alignmentId != null ? 
                    <Button onClick={(event) => { event.stopPropagation() ; redirectGoalViewDetails(goalDatas.goalAlignmentResponse.alignmentId)}} variant="outlined" size="small" className="">Go to {goalDatas.goalAlignmentResponse.title}</Button>
                    : null
                  }
          </Grid>
          <Grid item className="goalData goalSummarySubmit" xs={"12"}>
            <button variant="contained" className="PrimaryButton" onClick={handleSubmit} >Submit</button>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
