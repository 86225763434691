import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { checkLogin } from 'Services/AuthService';
import { syncSubscription } from 'Services/PaymentService';


const headers = {
    Accept : 'application/json',
    'Content-Type': 'application/json',
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

const PaymentReturn = () => {
    const navigate = useNavigate();

    const redirectToDashboard = () => {
        navigate("/home");
    }

    const useQuery= () => {
       return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    const validateToken = () => {

        let userToken = localStorage.getItem('token');
        headers.Authorization= "Bearer "+userToken;
        console.log("************************************************* 2"+query);
        syncSubscription(query.get('sub_id')).then((res)=> {
            console.log(res);
            redirectToDashboard();
        })

        // checkLogin().then((res) => {
        //     let results = res.data;
        //         if(results != undefined && results.status == "SUCCESS"){
        //             localStorage.setItem('userRole',  results.role);
        //             localStorage.setItem('orgId', results.orgId);
        //             localStorage.setItem('employeeId', results.empId);
        //             localStorage.setItem('showDemo', results.isDemoEnabled);
        //             redirectToDashboard();
        //             return;
        //         }
        //     }
        // );
    }

    React.useEffect(() => {
        console.log("************************************************* 1");
        validateToken();
    }, [query.get('sub_id'), query.get('state'), query.get('id')]);

    return (
        <div></div>
    );
}

export default withParams(PaymentReturn);