import { Link } from 'react-router-dom';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'ui-component/AnimateButton';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

import { useParams } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation
} from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { strengthColorFunc, strengthIndicatorFunc } from 'utils/password-strength';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { resetPassword } from 'Services/AuthService';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
  }
  

const ResetPassword = ({ ...others }) => {

    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [checked, setChecked] = useState(true);

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();
    const [showPassword, setShowPassword] = useState(false);


    const [confirmStrength, setConfirmStrength] = useState(0);
    const [confirmLevel, setConfirmLevel] = useState();
    const [showConfirmPassword, setConfirmShowPassword] = useState(false);

    const changePassword = (value, type) => {
        const temp = strengthIndicatorFunc(value);
        if(type =='PASSWORD'){
            setStrength(temp);
            setLevel(strengthColorFunc(temp));
        }else{
            setConfirmStrength(temp);
            setConfirmLevel(strengthColorFunc(temp));
        }
       
    };

    const redirectToDashboard = () => {
        navigate("/home");
      }

    const handleClickShowPassword = () => {
            setShowPassword(!showPassword);
            
    };

    const handleClickShowConfirmPassword = () => {
        setConfirmShowPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event,type) => {
        event.preventDefault();
    };

    const useQuery= () => {
        return new URLSearchParams(useLocation().search);
     }

    let query = useQuery();

    React.useEffect(() => {
        if( query.get('token') === null) {
          alert("Invalid token");
          navigate("/login");
          return;
        }
        localStorage.setItem('token', query.get('token'));
    }, [query.get('token')]);

 

    return (
        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item sx={{ mb: 3 }}>
                                <Link to="#">
                                    {/* <Logo /> */}
                                </Link>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container direction="column" justifyContent="center" spacing={2}>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center">
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="subtitle1">Forgot your password..?</Typography>
                                        </Box>
                                    </Grid>


                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Formik
                                    initialValues={{
                                        password :"",
                                        confirmPassword :"",
                                        submit: null
                                    }}
                                    validationSchema={Yup.object().shape({
                                        password: Yup.string().max(255).required('Password is required'),
                                        confirmPassword: Yup.string().max(255).required('Password is required'),
                                    })}
                                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                        if(values.password && values.confirmPassword && values.password != values.confirmPassword){
                                            setErrors({password:"Passwords are not match"});
                                            setErrors({confirmPassword:"Passwords are not match"});
                                            return;
                                        }
                                        let res = {};
                                        res.verificationToken = localStorage.getItem('token');
                                        res.newPassword =values.password;
                                        resetPassword(res).then((res) => {

                                            let results = res.data;
                                            if (results !=null) {
                                                setStatus({ success: true });
                                                setSubmitting(false);
                                                localStorage.setItem('token', results.token);
                                                redirectToDashboard();
                                            }else{
                                                setStatus({ success: false });
                                                setErrors({ submit: !! results.detail ? results.detail :  "Something went wrong. Please try again." });
                                                setSubmitting(false);
                                            }
                                        });
                    
                                        

                                    }}
                                >
                                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                        <form noValidate onSubmit={handleSubmit} {...others}>
                                            {/* <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                                                <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-email-login"
                                                    type="email"
                                                    value={values.email}
                                                    name="email"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Email Address"
                                                    inputProps={{}}
                                                />
                                                {touched.email && errors.email && (
                                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                                        {errors.email}
                                                    </FormHelperText>
                                                )}
                                            </FormControl> */}


                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.password && errors.password)}
                                                sx={{ ...theme.typography.customInput }} >
                                                <InputLabel htmlFor="outlined-adornment-password-register">Password</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password-register"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    name="password"
                                                    label="Password"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        changePassword(e.target.value,'PASSWORD');
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                size="large"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    inputProps={{}}
                                                />
                                                {touched.password && errors.password && (
                                                    <FormHelperText error id="standard-weight-helper-text-password-register">
                                                        {errors.password}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                            {strength !== 0 && (
                                                <FormControl fullWidth>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item>
                                                                <Box
                                                                    style={{ backgroundColor: level?.color }}
                                                                    sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="subtitle1" fontSize="0.75rem">
                                                                    {level?.label}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </FormControl>
                                            )}


                                            <FormControl
                                                fullWidth
                                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                                sx={{ ...theme.typography.customInput }} >
                                                <InputLabel htmlFor="outlined-adornment-Confirmpassword-register">Confirm Password</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-Confirmpassword-register"
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    value={values.confirmPassword}
                                                    name="confirmPassword"
                                                    label="confirmPassword"
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        changePassword(e.target.value,'confirm');
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowConfirmPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                size="large"
                                                            >
                                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    inputProps={{}}
                                                />
                                                {touched.confirmPassword && errors.confirmPassword && (
                                                    <FormHelperText error id="standard-weight-helper-text-confirmPassword-register">
                                                        {errors.confirmPassword}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                            {confirmStrength !== 0 && (
                                                <FormControl fullWidth>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item>
                                                                <Box
                                                                    style={{ backgroundColor: confirmLevel?.color }}
                                                                    sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="subtitle1" fontSize="0.75rem">
                                                                    {confirmLevel?.label}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </FormControl>
                                            )}


                                            {errors.submit && (
                                                <Box sx={{ mt: 3 }}>
                                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                                </Box>
                                            )}

                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                    >
                                                        Reset
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>

                            </Grid>


                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item container direction="column" alignItems="center" xs={12}>
                                    <Typography
                                        component={Link}
                                        to="/register"
                                        variant="subtitle1"
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        Don&apos;t have an account?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};


export default withParams(ResetPassword);
