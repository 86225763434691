import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const getName = (emp) => {
    if(!! emp.name && !! emp.lastName ){
        return (emp.name.charAt(0) +emp.lastName.charAt(0)).toUpperCase();
    }else if(!! emp.name){
        return emp.name.charAt(0).toUpperCase();
    }else if(!! emp.lastName){
        return emp.lastName.charAt(0).toUpperCase();
    }
    return "";
}

export const stringToColor = (string) => {
  string = string || "";

  const shades = ['#66a9ff', '#66d9bf', '#ffd95a', '#ffad80', '#ffcabf', '#ff9a9a', '#5b7c8e', '#a3d4d0', '#e3c96f',
  '#8a8a8a', '#35d4c4', '#1a928b', '#2b8acb', '#1f1f85', '#a7bfa9', '#f3edd2', '#dcb87b', '#f4e266',
  '#4acaea', '#6c4ad2', '#d97a72', '#f4f5e8', '#b7c7bd', '#f9d8d0', '#accf91', '#f7f9f1', '#9bc472',
  '#e7f8e7', '#9acbb3', '#512c87', '#ffe549', '#ff63c2', '#e6ec96', '#c2d6a3', '#237c62', '#ffd1ea',
  '#eef4ed', '#9ebcd7', '#7680b4', '#f58cee', '#ffdfa4', '#5d3c52', '#6289a7', '#f9a382', '#a1e8df',
  '#d7d8d9', '#f38eb2', '#99d8c4', '#ed638e', '#995e78', '#8f89a6', '#f3e7a9', '#ea6fa8', '#65b2d4',
  '#706b70', '#ea6476', '#a8d6e4', '#48a2b6', '#55c9cd', '#6a8f94', '#ff947a', '#9283c6'];

  const hash = string.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);

  const index = Math.abs(hash) % shades.length;

  return shades[index];
}

const colorProperty = (string, size) => {
  let color = stringToColor(string);

    let property = { bgcolor :  color +' !important', color : "white" +' !important'};

    if(!! size){
        if(size=="small"){
            property.width=28;
            property.height=28;
            property.fontSize = "12px";
            property.padding = '5px !important';
        }else if(size=="extra-samll"){
          property.width=18;
          property.height=18;
          property.fontSize = '10px'
          property.margin = '0px 0px 0px 7px !important';
      }else if(size=="medium"){
          property.width=35;
          property.height=35;
      }else if(size=="large"){
            property.width=100;
            property.height=80;
            property.fontSize = '60px'
        } else if(size=="extra-large"){
            property.width=160;
            property.height=130;
            property.fontSize = '60px'
        }else if(size=="profile-img"){
            property.width=38;
            property.height=38;
            property.margin = '0px 0px 0px -16px !important';
            property.cursor = 'pointer'
        } else if(size=="org-logo"){
          property.width=150;
          property.height=70;
          property.fontSize = '60px'
      }
    }
  
    return property;
  }

function AvatarBuilder(props) {
    const { classes } = props;
    const { size } = props;
    const { variant } = props;

  return (
    <Stack direction="row" spacing={2}>
      <Avatar
        sx={ colorProperty(props.employee.name, size)}
        src= {props.employee.imageUrl}
        variant ={ !!variant ? variant : "circular"}
      >
          {getName(props.employee)}
      </Avatar>
      
    </Stack>
  );
}

export default AvatarBuilder;

// export default withStyles(styles)(AvatarBuilder);

