import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import "./Summary.css"
import { GoalLevelList, PrivacyType } from 'Constants/EmpConstants';
import { getAllTeams,getAllEmployee } from 'Services/OrgService';
import { updateGoalSummary } from 'Services/Goalservice';
import { isValidStr,isValidNumber } from 'utils/CommonUtils';
import { ToastSuccess } from "utils/Toast";


export default function Summary(props) {
  const [goalDatas, setGoalDatas] = useState(props.goalDetails);
  const [teamName, setTeamName]=useState('');


  const [errors, setErrors] = useState({
    title: false,
    description: false,
    goalOwners: [],
    goalLevel: false,
    team: false,
    visibility: false
  });

  const validateFields = () => {
    let valid = true;
    let newErrors = { ...errors };
    if (! isValidStr(goalDatas.title) ) {
      newErrors.title = true;
      valid = false;
    } else {
      newErrors.title = false;
    }

    if (goalDatas.goalOwners && goalDatas.goalOwners.length === 0) {
      newErrors.goalOwners = true;
      valid = false;
    } else {
      newErrors.goalOwners = false;
    }

    if (! isValidStr(goalDatas.goalLevel)) {
      newErrors.goalLevel = true;
      valid = false;
    } else {
      newErrors.goalLevel = false;
    }

    if(goalDatas.goalLevel == 'TEAM' && ! isValidNumber(goalDatas.teamId)){
      newErrors.teamId = true;
      valid = false;
    } else {
      newErrors.teamId = false;
    }

    if (! isValidStr(goalDatas.visibility) ) {
      newErrors.visibility = true;
      valid = false;
    } else {
      newErrors.visibility = false;
    }

    setErrors(newErrors);
    return valid;
  }

  const [employeeList, setEmployeeList] = React.useState([]);
  const [teamList, setTeamList] = React.useState([]);

    const loadEmpl = () =>{
      getAllEmployee().then((res) => {
      let results = res.data;
      if(results){
        setEmployeeList(results);
        return;
      }
  })
  }

  const loadTeam = () =>{
    getAllTeams().then((res) => {
   let results = res.data;
   if(results){
     setTeamList(results);
     results.forEach(element => {
      if(element.id==goalDatas.teamId){
        setTeamName(element)
      }
      
     });
     return;
   }
})
}



  const handleSubmit = () => {
    if (validateFields()) {

      let summaryUpdateReq = {
        goalId : goalDatas.goalId,
        title : goalDatas.title,
        description : goalDatas.description,
        goalLevel : goalDatas.goalLevel,
        teamId : goalDatas.teamId,
        visibility : goalDatas.visibility
      }


      if(goalDatas.goalOwners){
        summaryUpdateReq.goalOwners = [];
        goalDatas.goalOwners.map((t) => {
          summaryUpdateReq.goalOwners.push( !!t.id ? t.id : t.employeeId);
        })
      }
      
      updateGoalSummary(summaryUpdateReq).then((res) => {
        let results = res.data;
        if (results) {
          ToastSuccess({ text: "Goal updated successfully." });
        }
      })


    }
  }


  React.useEffect(() => {
    loadEmpl();
    loadTeam();
  }, []);
  return (
    <div>
      <Box sx={{ '& > :not(style)': { m: 1, width: '1' } }}>
        <Grid container spacing={1.5} className="summaryContent" >
          <Grid item className="goalData" xs={"auto"}>
            <InputLabel className="inputLabels">Name</InputLabel>
            <TextField variant="outlined"
              className="inputFeilds"
              name="title"
              value={goalDatas.title}
              onChange={(e) => setGoalDatas({ ...goalDatas, title: e.target.value })}
              error={errors.title}
              helperText={errors.title ? "Name is required" : ""}
            />
          </Grid>
          <Grid item className="goalData" xs={"auto"}>
            <InputLabel className="inputLabels">Description</InputLabel>
            <TextField variant="outlined"
              className="inputFeilds"
              name="description"
              value={goalDatas.description}
              onChange={(e) => setGoalDatas({ ...goalDatas, description: e.target.value })}
              error={errors.description}
              helperText={errors.description ? "Description is required" : ""}
            />
          </Grid>
          <Grid item className="goalData" xs={"auto"}>
            <InputLabel className="inputLabels">Assignee</InputLabel>
        <Autocomplete className="inputFeilds"
                value={goalDatas.goalOwners}
                multiple
                options={ employeeList }
                getOptionLabel={(option) => option.name}

                renderInput={(params) => (
                  <TextField {...params} placeholder="Assignee"/>
                )}
                getOptionDisabled={(option) => goalDatas.goalOwners.some((owner) => owner.employeeId === option.id)}
                name='goalOwners'
                onChange={(e,val)=>{
                  const temp = []
                  val.map((t) => {
                    temp.push( t);
                  })
                  setGoalDatas({...goalDatas, goalOwners: temp})
              }}
              />


          </Grid>
          <Grid item className="goalData" xs={"auto"}>
            <InputLabel className="inputLabels">Goal Level</InputLabel>

            <Autocomplete
              className="inputFeilds"
              disablePortal
              options={GoalLevelList}
              value={goalDatas.goalLevel}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(options, value) => options === value}
              renderInput={(params) => <TextField {...params} />}
              name="goalLevel"
              onChange={(e, val) => {
                setGoalDatas({ ...goalDatas, goalLevel: val })
              }}
              error={errors.goalLevel}
              helperText={errors.goalLevel ? "Goal Level is required" : ""}
            />

          </Grid>
          <Grid item className="goalData" xs={"auto"}>
            <InputLabel className="inputLabels">Team</InputLabel>
            <Autocomplete
                options={teamList}
                name='teamId'
                className="inputFeilds"
                value={goalDatas.goalLevel == "TEAM" ? teamName : null}
                getOptionLabel={(option) => option.name}
                onChange={(e, value)=>{
                  setTeamName(value)
                  let teamId = !!value ? value.id : null;
                  setGoalDatas({ ...goalDatas, teamId: teamId })
                }
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Team" className="updateInput"
                  error={errors.teamId}
                helperText={errors.teamId ? "Team is required" : ""}
                 type="text"/>
                )}
              />
          </Grid>
          <Grid item className="goalData" xs={"auto"}>
            <InputLabel className="inputLabels">Visibility</InputLabel>
            <Autocomplete
              className="inputFeilds"
              name="numberofEmployee"
              options={PrivacyType}

              value={goalDatas.visibility}

              onChange={(e, value) => setGoalDatas({ ...goalDatas, visibility: value.value })}
              
              getOptionLabel={(option) => {
                if (option && option.dis) {
                  return option.value;
                }
                return option;
              }}

              isOptionEqualToValue={(options, value) => options.value === value}
              renderInput={(params) => <TextField {...params}
                error={errors.visibility}
                helperText={errors.visibility ? "Visibility is required" : ""}
              />}
            />
          </Grid>
          <Grid item className="goalData goalSummarySubmit" xs={"12"}>
            <button variant="contained" className='PrimaryButton'
            onClick={handleSubmit} >Submit</button>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
