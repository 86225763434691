import React, { useState } from "react";
import "./GoalsGrids.css"
import { TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from "@mui/material/Autocomplete";
import { getAllEmployee } from 'Services/OrgService';
import { addNewKeyInitiatives } from "Services/Goalservice";
import { ProgressType } from 'Constants/EmpConstants';
import { ToastError, ToastSuccess } from "utils/Toast";
import { PROGRESSTYPE } from "Constants/Styles";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function AddKeyInitiatives({onCancel, newKeyInit, setNewKeyInit, updateKI }) {

  const [data, setData] = React.useState([]);

  const temp = {
    title: "",
    assignee: "",
    progressType: PROGRESSTYPE.PERCENTAGE,
    progressStartValue: "",
    progressEndValue: "",
    currentProgress: "",
    id: "",
    keyResultId: -1,
    goalId: -1,
    startDate: "",
    dueDate: ""
  }

  const [keyInit, setKeyInit] = useState(temp);

  const [employeeList, setEmployeeList] = React.useState([]);

  const loadEmpl = () => {
    getAllEmployee().then((res) => {
      let results = res.data;
      if (results) {
        setEmployeeList(results);
        return;
      }
    })
  }
  React.useEffect(() => {
    loadEmpl();
  }, []);



  const handleSubmit = (e) => {
      e.preventDefault();
      if (newKeyInit && newKeyInit.progressEndValue && newKeyInit.progressStartValue &&
        newKeyInit.progressType && newKeyInit.assignee &&
        newKeyInit.title && newKeyInit.startDate && newKeyInit.dueDate) {

        addNewKeyInitiatives(newKeyInit).then((res) => {
          let results = res.data;
          if (results) {
            ToastSuccess({ text: "Key initiative added successfully" })
            updateKI(true)
            setNewKeyInit({})
            setKeyInit(temp)

            return;
          }
        })

      } else {
        ToastError({ text: "Please enter correct values" })
      }
      onCancel(false)
  }

  const [dateError, setDateError] = useState(false);

  const handleDateValidation = () => {
    if (new Date(newKeyInit.startDate) > new Date(newKeyInit.dueDate)) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  };

  const handleChangeProgress = (e, val) => {
    if (val === null) {
      setNewKeyInit({ ...newKeyInit, progressType: null })
    } else {
      if (val.value === PROGRESSTYPE.BOOLEAN) {
        setNewKeyInit({
          ...newKeyInit,
          progressType: val.value,
          progressStartValue: "0",
          progressEndValue: "1",
        });
      } else {
        setNewKeyInit({
          ...newKeyInit,
          progressType: val.value,
        });
      }
    }
  }
  const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
  return (
    <form className="newKr" onSubmit={handleSubmit}>
      <div className="newKrContent">
        <h2>Add Key Initiative</h2>
        <h3>Key Initiative</h3>
        <input
          name="title"
          value={newKeyInit.title}
          required
          fullWidth
          onChange={(e) => {
            setNewKeyInit({ ...newKeyInit, title: e.target.value })
          }}
          className="newKrInputs"
        />
        <h3>Assignee</h3>
        <Autocomplete
          options={employeeList}
          getOptionLabel={(option) => option.name}
          value={newKeyInit.krOwners}
          className="newKrAssignee"
          renderInput={(params) => <TextField {...params} required={true} className="newKrAutoComplete"/>}
          name="assignee"
          onChange={(e, val) => {
            if (val) {
              setNewKeyInit({ ...newKeyInit, assignee: val.id });
            } else {
              setNewKeyInit({ ...newKeyInit, assignee: null });
            }
          }}
          popupIcon={<CustomPopupIcon isOpen={isAssigneeOpen} />}
        />
        <div className="addKRProgress">
          <div>
            <h3>Progress Type</h3>
            <Autocomplete
              options={ProgressType}
              getOptionLabel={(option) => option.value}
              defaultValue={ProgressType[0]}
              renderInput={(params) => <TextField {...params} required={true} className="newKrAutoComplete" />}
              name="progressType"
              onChange={handleChangeProgress}
              className="newKrProgressType"
            />
          </div>
          {newKeyInit.progressType == PROGRESSTYPE.BOOLEAN ?
            null :
            <>
              <div>
                <h3>Start</h3>
                <input
                  fullWidth
                  name='startValue'
                  type="number"
                  required
                  value={newKeyInit.progressStartValue}
                  onChange={(e) => {
                    setNewKeyInit({ ...newKeyInit, progressStartValue: e.target.value })
                  }}
                  className="newKrInputs"
                />
              </div>
              <div>
                <h3>End</h3>
                <input
                  fullWidth
                  name='targetValue'
                  type="number"
                  required
                  value={newKeyInit.progressEndValue}
                  onChange={(e) => {
                    setNewKeyInit({ ...newKeyInit, progressEndValue: e.target.value })
                  }}
                  className="newKrInputs"
                />
              </div>
            </>
          }
        </div>
        <div className="addKRProgressDate">
          <div>
            <h3>Start Date</h3>
            <input
              fullWidth
              name='startDate'
              type="date"
              required
              onBlur={handleDateValidation}
              error={dateError}
              value={newKeyInit.startDate}
              onChange={(e) => {
                setNewKeyInit({ ...newKeyInit, startDate: e.target.value })
              }}
              className="newKrInputs"
            />
          </div>
          <div>
            <h3>End Date</h3>
            <input
              fullWidth
              name='dueDate'
              type="date"
              required
              onBlur={handleDateValidation}
              error={dateError}
              helperText={dateError ? 'End Date must be greater than Start Date' : ''}
              value={newKeyInit.dueDate}
              onChange={(e) => {
                setNewKeyInit({ ...newKeyInit, dueDate: e.target.value })
              }}
              className="newKrInputs"
            />
          </div>
        </div>
      </div>
      <div className='newKrHR'></div>
      <div className="newKrButton">
        <button onClick={() => {
          setKeyInit(temp)
          setNewKeyInit({})
          onCancel(false)
        }} className="newKrCancelButton" variant="contained">Cancel</button>
        <button className="PrimaryButton" variant="contained">Save</button>
      </div>
    </form>
  )
}

function CustomPopupIcon({ isOpen }) {
  return isOpen ? <KeyboardArrowUpIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />;
}