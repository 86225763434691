import callApiV2 from 'utils/ApiUtilsV2';

export async function createPerformanceReview(data) {
  return callApiV2("/v1/proxy/perf-review","POST",data,null,false,false,"/v1/create/performance/review");
};

export async function createPerformanceReviewQuestion(data) {
  return callApiV2("/v1/proxy/perf-review","POST",data,null,false,false,"/v1/create/review/questions");
};

export async function createPerformanceAnswer(data) {
  return callApiV2("/v1/proxy/perf-review","POST",data,null,false,false,"/v1/create-review-answer");
};

export async function createPeerReview(data) {
  return callApiV2("/v1/proxy/perf-review","POST",data,null,false,false,"/v1/create/peer-review");
};

export async function getQuestions(reviewId,questionType) {
  const queryParams = new URLSearchParams({
    reviewId: reviewId,
    reviewType: questionType,
  });
  return callApiV2("/v1/proxy/perf-review","GET",null,null,false,false,`/v1/getQuestions?${queryParams.toString()}`);
}

export async function getTodoReviews(data) {
  return callApiV2("/v1/proxy/perf-review","GET",data,null,false,false,"/v1/perf-review/todo/reviews");
};

export async function getListOfSelfReviews(reviewId) {
  return callApiV2("/v1/proxy/perf-review","GET",null,null,false,false,"/v1/get-self-review/"+reviewId);
};

export async function getSelfReviewQuestionAndAnswer(data) {
  return callApiV2("/v1/proxy/perf-review","POST",data,null,false,false,"/v1/get-self-review-answers");
};

export async function getListOfGivenReviews(reviewId) {
  return callApiV2("/v1/proxy/perf-review","GET",null,null,false,false,"/v1/get-given-review/"+reviewId);
};

export async function getGivenReviewQuestionAndAnswer(data) {
  return callApiV2("/v1/proxy/perf-review","POST",data,null,false,false,"/v1/get-given-review-answers");
};

export async function getYourPerfReviews(data) {
  return callApiV2("/v1/proxy/perf-review","GET",data,null,false,false,"/v1/get-your-perf-reviews");
};

export async function getPeerReviews(data) {
  return callApiV2("/v1/proxy/perf-review","GET",data,null,false,false,"/v1/get/peer-review");
};

export async function getPerfReviews() {
  return callApiV2("/v1/proxy/perf-review","GET",null,null,false,false,"/v1/perf-review/current-review");
};

export async function approvePeerReview(data) {
  return callApiV2("/v1/proxy/perf-review","POST",data,null,false,false,"/v1/approve/peer-review");
};