import React from 'react'
import { toast } from 'react-toastify'

function ToastSuccess({text}) {
    
    toast.success(text,{
        position: "bottom-left",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      })

}

function ToastError({text}){

  toast.error(text,{
    position: "bottom-left",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  })
}

export {ToastSuccess, ToastError}