import dayjs from 'dayjs';
import { isValidStr,isValidNumber } from 'utils/CommonUtils';
const currentMonth = dayjs().month();
const currentYear = dayjs().year();



export const getCurrentQuarterRange = () => {
  let startMonth, endMonth;
  if (currentMonth >= 0 && currentMonth <= 2) {
    startMonth = 0;
    endMonth = 2;
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    startMonth = 3;
    endMonth = 5;
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    startMonth = 6;
    endMonth = 8;
  } else {
    startMonth = 9;
    endMonth = 11;
  }

  const startDate = dayjs().set('year', currentYear).set('month', startMonth).set('date', 1);
  const endDate = dayjs().set('year', currentYear).set('month', endMonth).set('date', dayjs().endOf('month').date());

  return [startDate, endDate];
};

export const getCurrentMonthRange = () => {
  const startDate = dayjs().set('year', currentYear).set('month', currentMonth).set('date', 1);
  const endDate = dayjs().set('year', currentYear).set('month', currentMonth).set('date', dayjs().daysInMonth());

  return [startDate, endDate];
};

export const getCurrentHalfYearRange = () => {
  let startMonth, endMonth;
  if (currentMonth >= 0 && currentMonth <= 5) {
    startMonth = 0;
    endMonth = 5;
  } else {
    startMonth = 6;
    endMonth = 11;
  }

  const startDate = dayjs().set('year', currentYear).set('month', startMonth).set('date', 1);
  const endDate = dayjs().set('year', currentYear).set('month', endMonth).set('date', dayjs().daysInMonth());

  return [startDate, endDate];
};

export const getCurrentYearRange = () => {
  const startDate = dayjs().set('year', currentYear).set('month', 0).set('date', 1);
  const endDate = dayjs().set('year', currentYear).set('month', 11).set('date', 31);

  return [startDate, endDate];
};

export const getFormatedDate = (date) =>{
  if (/\d{4}-\d{2}-\d{2}/.test(date)) {
    return date;
  } else if (/\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} \w{3}\+\d{4} \([\w\s]+\)/.test(date)) {
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
    const day = originalDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  return date = date.format('YYYY-MM-DD');
}

export const getNumericformatedDate = (inputDate) => {
  const changesDate = getFormatedDate(inputDate)
  if( ! isValidStr(changesDate) ){
      return "";
  }
  const dateParts = changesDate.split('-');
  const year = dateParts[0];
  const month = new Date(dateParts[1] + "-01-01").toLocaleString('default', { month: 'short' });
  const day = dateParts[2];

  return `${day} ${month} ${year}`;
}

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
}

export const getformatedTime = (timestamp) => {
  if( ! isValidStr(timestamp) ){
    return "";
}
  const currentDate = new Date();
  const inputDate = new Date(timestamp);
  const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000);
  
  const intervals = [
    { label: 'second', seconds: 1 },
    { label: 'minute', seconds: 60 },
    { label: 'hour', seconds: 3600 },
    { label: 'day', seconds: 86400 },
    { label: 'month', seconds: 2592000 },
  ];

  for (let i = intervals.length - 1; i >= 0; i--) {
    const interval = intervals[i];
    const timeValue = Math.floor(timeDifferenceInSeconds / interval.seconds);
    if (timeValue >= 1) {
      return `${timeValue} ${interval.label}${timeValue > 1 ? 's' : ''} ago`;
    }
  }

  return 'just now';
}

export const capitalizeFirstLetter = (inputString) => {
  
  if( ! isValidStr(inputString) ){
    return "";
}

  const words = inputString.split('_').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return words.join(' ');
}
