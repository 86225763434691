import React, { useReducer, useState } from "react";
import "./GoalsGrids.css"
import { getGoals } from 'Services/Goalservice';
import { TextField } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from "@mui/material/Autocomplete";
import Grid from '@mui/material/Grid';
import { getAllEmployee } from 'Services/OrgService';
import { addNewKeyResult } from 'Services/Goalservice';
import { ProgressType } from 'Constants/EmpConstants';
import { ToastError, ToastSuccess } from "utils/Toast";
import { PROGRESSTYPE } from "Constants/Styles";

export default function AddNewKeyResult({ goal, newKeyResult, setNewKeyResult,updateGoalProgress, updateKR }) {

  const [data, setData] = React.useState([]);

  const temp = {
    title: "",
    assignee: "",
    progressType: PROGRESSTYPE.PERCENTAGE,
    progressStartValue: "",
    progressEndValue: "",
    keyValueStatus: "",
    completeddate: "",
    keyValueStatus: "",
    progress: "",
    id: "",
    goalId: -1
  }

  const [keyResult, setKeyResult] = useState(temp);

  const [employeeList, setEmployeeList] = React.useState([]);

  const loadEmpl = () => {
    getAllEmployee().then((res) => {
      let results = res.data;
      if (results) {
        setEmployeeList(results);
        return;
      }
    })
  }
  React.useEffect(() => {
    loadEmpl();
  }, []);

  const handleSubmit = (e) =>{
    {
      e.preventDefault();
      if (newKeyResult && newKeyResult.progressEndValue && newKeyResult.progressStartValue &&
        newKeyResult.progressType && newKeyResult.assignee &&
        newKeyResult.title) {

        addNewKeyResult(newKeyResult).then((res) => {
          let results = res.data;
          if (results) {
            ToastSuccess({ text: "Key result added successfully" })
            updateKR(true)
            updateGoalProgress(newKeyResult.goalId,results.id)
            setNewKeyResult({})
            setKeyResult(temp)
            
            return;
          }
        })
      } else {
        ToastError({text: "Please enter correct values"})
      }
    }
  }

  const handleChangeProgress = (e, val)=>{
    if (val === null) {
      setNewKeyResult({ ...newKeyResult, progressType: null });
    } else {
      if (val.value === PROGRESSTYPE.BOOLEAN) {
        setNewKeyResult({
          ...newKeyResult,
          progressType: val.value,
          progressStartValue: "0",
          progressEndValue: "1",
        });
      } else {
        setNewKeyResult({
          ...newKeyResult,
          progressType: val.value,
          progressStartValue: "",
          progressEndValue: "",
        });
      }
    }
  }
  
  return (
    <form className="keyresult-feilds" onSubmit={handleSubmit}>
      <div className="keyresult-row">
        <div className="keyResultInput">
          <InputLabel>Key Result</InputLabel>
          <TextField
            name="title"
            value={newKeyResult.title}
            required
            fullWidth
            onChange={(e) => {
              setNewKeyResult({ ...newKeyResult, title: e.target.value })
            }}
          />
        </div>
      </div>
      <div className="keyresult-row">
        <div className="keyResultData">
          <InputLabel>Assignee</InputLabel>
          <Autocomplete
            options={employeeList}
            getOptionLabel={(option) => option.name}
            value={newKeyResult.krOwners}
            renderInput={(params) => <TextField {...params} required={true} />}
            name="assignee"
            onChange={(e, val) => {
              if (val) {
                setNewKeyResult({ ...newKeyResult, assignee: val.id });
              } else {
                setNewKeyResult({ ...newKeyResult, assignee: null });
              }
            }}

          />
        </div>
        </div>
        <div className="keyresult-progress">
        <div className="keyResultData">
          <InputLabel>Progress Type</InputLabel>
          <Autocomplete
            options={ProgressType}
            getOptionLabel={(option) => option.value}
            defaultValue={ProgressType[0]}
            renderInput={(params) => <TextField {...params} required={true} />}
            name="progressType"
            onChange={handleChangeProgress}
          />
        </div>
        </div>
        {newKeyResult.progressType == PROGRESSTYPE.BOOLEAN ? 
        null
        : 
        
        <div className="keyresult-row">
        <div className="keyResultData">
          <InputLabel>Start</InputLabel>
          <TextField
            fullWidth
            name='startValue'
            type="number"
            required
            value={newKeyResult.progressStartValue}
            onChange={(e) => {
              setNewKeyResult({ ...newKeyResult, progressStartValue: e.target.value })
            }}
          />
        </div>
        <div className="keyResultData">
          <InputLabel>End</InputLabel>
          <TextField
            fullWidth
            name='targetValue'
            type="number"
            required
            value={newKeyResult.progressEndValue}
            onChange={(e) => {
              setNewKeyResult({ ...newKeyResult, progressEndValue: e.target.value })
            }}
          />
        </div>
      </div>

        }
      <div className="keyresult-buttons">
        <button className="PrimaryButton" variant="contained">Save</button>
        <button onClick={() => {
          setKeyResult(temp)
          setNewKeyResult({})
        }} className="SecondaryButton" variant="outlined">Cancel</button>
      </div>
    </form>
  )
}
