import { Link } from 'react-router-dom';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'ui-component/AnimateButton';
import {forgetPassword}  from 'Services/AuthService'; 
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

const ForgotPassword = ({ ...others }) => {

    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));


    React.useEffect(() => {
    }, []);

    return (
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            {/* <Logo /> */}
                                        </Link>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container direction="column" justifyContent="center" spacing={2}>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center">
                                                <Box sx={{ mb: 2 }}>
                                                    <Typography variant="subtitle1">Forgot your password..?</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Formik
                                        initialValues={{
                                            email: '',
                                            submit: null
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                        })}
                                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                            forgetPassword(values).then((res) => {
                                                //+JSON.stringify(res)
                                                alert("Check your inbox to reset your mail");
                                            });
                                        }}
                                    >
                                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                            <form noValidate onSubmit={handleSubmit} {...others}>
                                                <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                                                    <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
                                                    <OutlinedInput
                                                        id="outlined-adornment-email-login"
                                                        type="email"
                                                        value={values.email}
                                                        name="email"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Email Address"
                                                        inputProps={{}}
                                                    />
                                                    {touched.email && errors.email && (
                                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                                            {errors.email}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>


                                                {errors.submit && (
                                                    <Box sx={{ mt: 3 }}>
                                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                                    </Box>
                                                )}

                                                <Box sx={{ mt: 2 }}>
                                                    <AnimateButton>
                                                        <Button
                                                            disableElevation
                                                            disabled={isSubmitting}
                                                            fullWidth
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color="secondary"
                                                        >
                                                            Reset
                                                        </Button>
                                                    </AnimateButton>
                                                </Box>
                                            </form>
                                        )}
                                    </Formik>

                                    </Grid>
                                   

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item container direction="column" alignItems="center" xs={12}>
                                            <Typography
                                                component={Link}
                                                to="/register"
                                                variant="subtitle1"
                                                sx={{ textDecoration: 'none' }}
                                            >
                                                Don&apos;t have an account?
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    );
};

export default ForgotPassword;
