import callApi from 'utils/ApiUtils';
import callApiV2 from 'utils/ApiUtilsV2';

export async function signUp(data) {
    return callApi("/v1/proxy/auth/createuser","POST",data,null,true);
};

export async function createOrg(data) {
    return callApi("/v1/proxy/auth/create-org","POST",data,null,false);
};

export async function checkLogin(data) {
    return callApi("/v1/proxy/auth/validate-token","POST",data,null,false);
};

export async function logIn(data) {
    return callApi("/v1/proxy/auth/login","POST",data,null,true);
};

export async function resetPassword(data) {
    return callApi("/v1/proxy/auth/resetpassword","POST",data,null,false);
};

export async function forgetPassword(data) {
    return callApi("/v1/proxy/auth/forgetpassword", "POST", data, null, true);
}

export async function createSlackUser(data) {
    return callApiV2("/v1/slack/create-slack-user","POST",data,null,false,false,"/v1/slack/create-user");
}

export async function slackUserIntegration(data) {
    return callApiV2("/v1/slack/install","POST",data,null,false,false,"/v1/slack/oauth/callback");
}

export async function getSlackUserLogin(data) {
    return callApiV2("/v1/slack/get-slack-user","POST",data,null,false,false,"/v1/slack/get-slack-user-login");
}

export async function disconnectSlackUser(data) {
    return callApiV2("/v1/slack/delete-slack-user","POST",data,null,false,false,"/v1/slack/delete-slack-user-login");
}

export async function googleSheetIntegration(data) {
    return callApiV2("/v1/google-sheets/install","POST",data,null,false,false,"/v1/google-sheets/oauth2/callback");
}

export async function getGoogleSpreadSheetList() {
    return callApiV2("/v1/proxy/auth","POST",null,null,false,false,"/v1/google-sheets/get-list-of-google-sheets");
};

export async function getGoogleSheetNames(spreadSheetId) {
    return callApiV2("/v1/proxy/auth","POST",null,null,false,false,"/v1/google-sheets/get-spread-sheet-info/"+spreadSheetId);
};

export async function getGoogleSheetCellValue(spreadSheetId, sheetName, cell) {
    return callApiV2("/v1/proxy/auth","POST",null,null,false,false,"/v1/google-sheets/get-spread-sheet-value/"+spreadSheetId+"/"+sheetName+"/"+cell);
};