import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "./PerformanceReviewCycle.css";
import Steppers from './Steppers';
import PerformanceCycle from './PerformanceReviewCycle';
import PerformanceTimeLine from './PerformanceReviewTimeLine';
import PerformanceQuestion from './PerformanceQuestion';
import { createPerformanceReview, createPerformanceReviewQuestion } from 'Services/PerformanceReviewService';
import { ToastError, ToastSuccess } from 'utils/Toast';
import { PERFORMANCE_STEPPERS } from 'Constants/Styles';
import { getCurrentYearRange, getFormatedDate } from 'Constants/DateConstants';

export default function PerformanceReviewCycle({ onClose, onReviewSubmit }) {

  const startPRDate = getCurrentYearRange()[0]
  const endPRDate = getCurrentYearRange()[1]
  const [reviewData, setReviewData] = useState({
    name : "Reflecting on Achievements and Growth",
    description : "Thank you for your dedicated participation in the performance review process. This review focuses on the accomplishments and progress made during the specified review period. Your commitment and contributions have undoubtedly played a significant role in our team's success.",
    startDate : getFormatedDate(startPRDate) ,
    endDate : getFormatedDate(endPRDate)
  });
  const [reviewQuestion, setReviewQuestion] = useState([])
  const [empToMngQstn, setEmpToMngQstn] = useState([])
  const [peerQstn, setPeerQstn] = useState([])
  const [compareData, setCompareData] = useState("")
  const [reviewId, setReviewId] = useState ("")

  const [activeStep, setActiveStep] = useState(0);

  const handleSetActiveStep = (step) => {
    setActiveStep(step);
  };

  const handleSaveData = (data) => {
    setReviewData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };
  const handleSaveQuestion = (question) => {
    setReviewQuestion(question);
  };

  const handleSaveQuestionForEmpToMng = (EmpToMng) => {
    setEmpToMngQstn(EmpToMng)
  }

  const handleSaveQuestionForPeer = (Peer) => {
    setPeerQstn(Peer)
  }

  const questionForReview = [...reviewQuestion, ...peerQstn, ...empToMngQstn]

  const PerformanceReviewAPI = () => {
    createPerformanceReview(reviewData).then((res) =>{
      let results = res.data;
      if (results) {
        setReviewId(results.id)
        ToastSuccess({ text: "Performance Review Created successfully" })
      }
      else{
        ToastError({ text: "Error While Creating Performance Review" })
      }
    }).catch((error) => {
      ToastError({ text: "Error While Creating Performance Review" });
    });
}

const PerformanceReviewQuestionAPI = () =>{
  createPerformanceReviewQuestion(questionForReview).then((res) =>{
    let results = res.data;
      if (results) {
        ToastSuccess({ text: "Performance Review Created successfully" })
      }
  }).catch((error) => {
    ToastError({ text: "Error While Creating Performance Review" });
  });
}

useEffect(() => {
  if (activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER) {
    const isDataChanged = JSON.stringify(compareData) !== JSON.stringify(reviewData);
    if (compareData == "") {
      PerformanceReviewAPI();
    }else if(isDataChanged) {
      ToastSuccess({ text: "Performance Review Updated successfully" })
    }
    setCompareData(reviewData);
  }
}, [reviewData]);

useEffect(()=>{
  if (activeStep === PERFORMANCE_STEPPERS.PEER && peerQstn != "") {
    PerformanceReviewQuestionAPI();
    onReviewSubmit();
  }
},[peerQstn])


  return (
    <div className='performanceReviewCycle'>
      <div className='PRCHeader'>
        <div className='closeIcon'>
          <IconButton onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Steppers setActiveStep={activeStep} />
      </div>
      {activeStep == 0 && (
        <PerformanceCycle handleSetActiveStep={handleSetActiveStep} activeStep={activeStep} handleSaveDataPRC={handleSaveData} data={reviewData} />
      )}
      {activeStep == 1 && (
        <PerformanceTimeLine handleSetActiveStep={handleSetActiveStep} activeStep={activeStep} handleSaveDataPRT={handleSaveData} data={reviewData}/>
      )}
      {activeStep == 2 && (
        <PerformanceQuestion handleSetActiveStep={handleSetActiveStep} activeStep={activeStep} handleSaveQuestion={handleSaveQuestion} data={reviewQuestion} reviewId={reviewId}/>
      )}
      {activeStep == 3 && (
        <PerformanceQuestion handleSetActiveStep={handleSetActiveStep} activeStep={activeStep} handleSaveQuestion={handleSaveQuestionForEmpToMng} data={empToMngQstn} reviewId={reviewId}/>
      )}
      {activeStep == 4 && (
        <PerformanceQuestion handleSetActiveStep={handleSetActiveStep} activeStep={activeStep} handleSaveQuestion={handleSaveQuestionForPeer} data={peerQstn} reviewId={reviewId} onClose={onClose}/>
      )}
    </div>
  )
}
