import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import { countries } from '../../lib/navData';
import UpdateOwner from "./UpdateOwner";
import Modal from '@mui/material/Modal';
import { getAccountSummary, getAllEmployee, updateAccountSummary } from 'Services/OrgService';
import { ToastError, ToastSuccess } from "utils/Toast";

export default function BasicGrid() {
  const [employeeList, setEmployeeList] = React.useState([]);
  const [accountSummary, setAccountSummary] = React.useState([]);
  const [updateOwnerData, setUpdateOwnerData] = React.useState([]);
  const [ownerName, setOwnerName]=useState({
    orgName: "",
    ownerEmpId: "",
    ownerName:"",
    phoneNumber:"",
    employeeCount:"",
    empRole:"",
    country:"",
    logoURL:"",
    businessCategory:""
  });

  const handleSubmit = (e) => {
    e.preventDefault()
    updateAccountSummary(accountSummary).then((res) => {
      let results = res.data;
      if (results !=null ) {
        ToastSuccess({ text: "Employee updated successfully" });
      }else{
        ToastError({ text: "Error while update Employee" });
      }
    })
  };

  const loadEmpl = (empId) =>{
    getAllEmployee().then((res) => {
      let results = res.data;
      if(results){
        setEmployeeList(results);    
         results.forEach(element => {
          if(element.role==="OWNER"){
            setOwnerName(element)
          }
        })
      }
    })
  }
  

  React.useEffect(() => {
      getAccountSummary().then((res) => {
      let results = res.data;
      if(results){
        setAccountSummary(results);
        loadEmpl(results.ownerEmpId)
        return;
      }
    })
  }, []);

  const [updateOwnerModel, setUpdateOwnerModel] = useState(false);
  const handleUpdateOpen = () => setUpdateOwnerModel(true);
  const handleUpdateClose = () => setUpdateOwnerModel(false);

  const updateOwnerHandler = (event, value) => {
    let newRoleOfCurrentOwner = "ADMIN";
    let currentOwner = ownerName.id;
    let newOwner = value.id;
    loadEmpl(newOwner);
    setUpdateOwnerData({newRoleOfCurrentOwner, currentOwnerId: currentOwner, newOwnerId: newOwner});
    handleUpdateOpen();
  }

  return (
    <Box className="addEmployeeSection" sx={{'& > :not(style)': { m: 1, width: '1' }}}>
      <Modal
          open={updateOwnerModel}
          onClose={handleUpdateClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <UpdateOwner afterSubmit={loadEmpl} payload={updateOwnerData} close={handleUpdateClose}/>  
      </Modal>
      <Grid container spacing={1.5} >
        <Grid item className="grid-cell" xs={"auto"} lg={6}>
        <InputLabel className="inputlabel-form">Company Name</InputLabel>
        <TextField className="outlined-basic" variant="outlined"
        name="companyName"
        value={accountSummary.orgName}
        onChange={(e)=>setAccountSummary({...accountSummary,orgName:e.target.value})}
        />
        </Grid>
        <Grid item className="grid-cell" xs={"auto"} lg={6}>
        <InputLabel className="inputlabel-form">Owner Employee</InputLabel>
        <Autocomplete
            options={employeeList}
            name="ownerEmployee"
            value={ownerName}
            className="outlined-basic"
            getOptionLabel={(option) => {
              if (option.hasOwnProperty('name')) {
                return option.name;
              }
              return '';
            }}
            onChange={(event, value)=> {
              updateOwnerHandler(event, value)
            }}
            renderInput={(params) => (
                <TextField {...params} placeholder="Owner Name" className="updateInput"
                type="text"/>
              )}
            isOptionEqualToValue={(options, value) => options.id === value.id}
            
        />
        </Grid>
        <Grid item className="grid-cell" lg={6} xs={"auto"}>
        <InputLabel className="inputlabel-form">Contact Number</InputLabel>
        <TextField className="outlined-basic" variant="outlined"
        name="contactNumber"
        value={accountSummary.phoneNumber}
        onChange={(e)=>setAccountSummary({...accountSummary,phoneNumber:e.target.value})}
        />
        </Grid>
        <Grid item className="grid-cell" xs={"auto"} lg={6}>
          <InputLabel className="inputlabel-form">Number of Employee</InputLabel>
          <TextField className="outlined-basic" value={accountSummary.employeeCount}
          onChange={(e)=>setAccountSummary({...accountSummary,employeeCount:e.target.value})}
          ></TextField>
        </Grid>
        <Grid item className="grid-cell" xs={"auto"} lg={6}>
        <InputLabel className="inputlabel-form">Business Category</InputLabel>
        <TextField className="outlined-basic" variant="outlined"
        name="businessCategory"
        value={accountSummary.businessCategory}
        onChange={(e)=>setAccountSummary({...accountSummary,businessCategory:e.target.value})}
        />
        </Grid>
        <Grid item className="grid-cell" xs={"auto"} lg={6}>
        <InputLabel className="inputlabel-form">Country</InputLabel>

        <Autocomplete
            options={countries}
            name="country"
            value={accountSummary}
            className="outlined-basic"
            getOptionLabel={(option) => {
              if (option.hasOwnProperty('country')) {
                return option.country;
              }
              return '';
            }}
            onChange={(event, value)=> {
              setAccountSummary({...accountSummary,country:value.country})
            }}
            renderInput={(params) => <TextField {...params} />}
            isOptionEqualToValue={(options, value) => options.country === value.country}
        />
        
        </Grid>
        <Grid item className="grid-cell" xs={6} sm={12} md={10}>
        <button variant="contained" className="PrimaryButton" onClick={handleSubmit}>Submit</button>
        </Grid>
      </Grid>
    </Box>
  );
}