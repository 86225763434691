import callApi from 'utils/ApiUtils';
import callApiV2 from 'utils/ApiUtilsV2';


export async function createOrg(data) {
    return callApi("/v1/proxy/org/create-org","POST",data,null,false);
};

export async function getEmployee() {
    return callApi("/v1/proxy/org/employee","GET",null,null,false);
};

export async function globalSearch(data) {
    return callApi("/v1/proxy/org","GET",null,data,false,"/v1/employee/globalsearch");
};

export async function getAllEmployee(data) {
    return callApiV2("/v1/proxy/org","GET",null,data,false,false,"/v1/employee/all");
};


export async function getAllTeams(data) {
    return callApiV2("/v1/proxy/org","GET",null,data,false,false,"/v1/team/all");
};

export async function createTeam(data) {
    return callApiV2("/v1/proxy/org","POST",data,null,false,false,"/v1/team");
};

export async function createEmployee(data) {
    return callApiV2("/v1/proxy/org","POST",data,null,false,false,"/v1/employee");
};
export async function deleteEmployee(id) {
    return callApiV2("/v1/proxy/org","DELETE",null,null,false,false,"/v1/delete-employee/"+id);
};
export async function deleteTeam(data) {
    return callApiV2("/v1/proxy/org","DELETE",data,null,false,false,"/v1/team");
};
export async function getAccountSummary(data) {
    return callApiV2("/v1/proxy/org", "GET", data, null, false, false, "/v1/getAccountSummary")
}

export async function UpdateEmployeeDetail(data) {
    return callApiV2("/v1/proxy/org", "PATCH", data, null, false, false, "/v1/employee")
}

export async function UpdateTeamDetail(data) {
    return callApiV2("/v1/proxy/org", "PATCH", data, null, false, false, "/v1/team")
}

export async function updateOwner(data) {
    return callApiV2("/v1/proxy/org", "POST", data, null, false, false, "/v1/update-owner")
}

export async function updateAccountSummary(data) {
    return callApiV2("/v1/proxy/org", "POST", data, null, false, false, "/v1/updateAccountSummary")
}

export async function updateMyAccount(data) {
    return callApiV2("/v1/proxy/org", "POST", data, null, false, false, "/v1/update-my-account")
}

export async function inviteEmployee(data) {
    return callApiV2("/v1/proxy/org", "POST", data, null, false, false, "/v1/trigger-invite-email")
}

export async function getTeamsByRole() {
    return callApiV2("/v1/proxy/org", "GET", null, null, false, false, "/v1/get-employee-teams")
}

export async function getTeamsByTeamId(teamId) {
    return callApiV2("/v1/proxy/org", "GET", null, null, false, false, "/v1/team/"+teamId)
}
export async function importCsvFile(data) {
    return callApiV2("/v1/proxy/org/file", "POST", data, null, false, true, "/v1/file")
}
export async function showDemoData(show) {
    return callApiV2("/v1/proxy/org","GET",null,null,false,false,"/v1/display-demo-goals/"+show);
}
// export async function getSlackChannelList() {
//     return callApiV2("/v1/proxy/org","GET",null,null,false,false,"/v1/get-slack-channel-list");
// }
// export async function addSlackChannel(data) {
//     return callApiV2("/v1/proxy/org","POST",data,null,false,false,"/v1/add-slack-channel");
// };