import React, { useState } from 'react'
import './PerformanceReview.css'
import Overview from './Overview';
import PerfReviewOfEmployee from './PerformanceReviewList';

export default function PerformanceReviewLayout() {
  const [state, setState] = useState('Overview');

  return (
    <div className="performanceReview">
      <div className="tabButtons performanceReviewTabs">
        <button onClick={() => setState('Overview')} className='btn-personal' style={{ backgroundColor: state === "Overview" ? 'var(--primary-color)' : 'var(--white-color)', color: state === "Overview" ? 'var(--white-color)' : 'var(--text-color)' }}>
          {/* <img src={personalSVG}
            alt="Personal Icon"
            style={{
              filter: state === "overview" ? "invert(0)" : "invert(1)",
            }}
          /> */}
          Overview
        </button>
        <button onClick={() => setState('YourReview')} className='btn-personal' style={{ backgroundColor: state === "YourReview" ? 'var(--primary-color)' : 'var(--white-color)', color: state === "YourReview" ? 'var(--white-color)' : 'var(--text-color)' }}>
          {/* <img src={teamSVG}
            alt="Team Icon"
            style={{
              filter: state === "YourReview" ? "invert(0)" : "invert(1)",
            }}
          /> */}
          Your Review
        </button>
        <button onClick={() => setState('GivenReview')} className='btn-personal' style={{ backgroundColor: state === "GivenReview" ? 'var(--primary-color)' : 'var(--white-color)', color: state === "GivenReview" ? 'var(--white-color)' : 'var(--text-color)' }}>
          {/* <img src={companySVG}
            alt="Company Icon"
            style={{
              filter: state === "GivenReview" ? "invert(0)" : "invert(1)",
            }}
          /> */}
          Given Review
        </button>
      </div>
      {state === "Overview" ? (<Overview />) : null}
      {state === "YourReview" ? (<PerfReviewOfEmployee review="yourReviews" />) : null}
      {state === "GivenReview" ? (<PerfReviewOfEmployee review="givenReviews" />) : null}
    </div>
  )
}
