import React, { useRef } from 'react'
import { AvatarGroup, Button, Chip, ClickAwayListener, Drawer, Modal, Popover } from '@mui/material';
import "./GoalDetails.css"
import { useState } from 'react';
import AvatarBuilder from 'utils/AvatarBuilder';
import CloseSvg from "../assets/images/close.svg"
import SliderProgressBar from 'Pages/Home/SliderProgressBar';
import { capitalizeFirstLetter, convertDateFormat, formatDate,getCurrentQuarterRange, getFormatedDate, getNumericformatedDate, getformatedTime } from 'Constants/DateConstants';
import { createConversation, deleteGoal, deleteKeyResult, getConversation, getFilteredGoals, getGoalDetails, getKPIs, getKeyInitiatives, updateGoalProgress, updateGoalSummary } from 'Services/Goalservice';
import { useEffect } from 'react';
import KeyResultDetails from './KeyResultDetails';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddKeyResults from './AddKeyResults';
import { PROGRESSTYPE, getColorByStatus, truncateText } from 'Constants/Styles';
import MenuIcon from "../assets/images/threeDot.svg"
import { getAllEmployee, getAllTeams } from 'Services/OrgService';
import leftSideArrow from "../assets/images/leftSideArrow.svg";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from "../assets/images/EditIcon.svg"
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ErrorIcon from '@mui/icons-material/Error';
import DateNavigator from 'utils/DateNavigator';
import DateRangeCalenderPicker from './DateRangeCalenderPicker';
import { ToastError, ToastSuccess } from 'utils/Toast';
import BackIcon from "../assets/images/backButton.svg"
import TickIcon from "../assets/images/Tick.svg"
import GoalMenu from './GoalMenu';
import KeyResultMenu from './KeyResultMenu';

export default function GoalDetails({ goalData , handleGoalDetails, updateGoalData, isGoalDeleted , krAction , handleGoalClose}) {
    const [goalDetail, setGoalDetail] = useState(goalData);
    const [messages, setMessages] = useState([]);

    const maxVisibleAssignees = 3;

    const visibleAssignees = goalDetail.ownerDetails ? goalDetail.ownerDetails.slice(0, maxVisibleAssignees) : [];
    const hiddenAssignees = goalDetail.ownerDetails ? goalDetail.ownerDetails.slice(maxVisibleAssignees) : [];    

    const [showHiddenAssignees, setShowHiddenAssignees] = useState(false);

    const handleToggleHiddenAssignees = () => {
        setShowHiddenAssignees(!showHiddenAssignees);
    };

    const handleDelete = (id, name) => {
        const updatedSelected = [...goalDetail.ownerDetails];
      const existingIndex = updatedSelected.findIndex((item) => item.employeeId === id);
  
      if (existingIndex !== -1) {
        updatedSelected.splice(existingIndex, 1);
      } else {
        updatedSelected.push({employeeId : id, name : name});
      }
      const updatedGoalDetail = { ...goalDetail, ownerDetails: updatedSelected };
      setGoalDetail(updatedGoalDetail);
      handleGoalDetailsChange(updatedGoalDetail);
    };

    const [showAllKeyResults, setShowAllKeyResults] = useState(false);

    const handleToggleShowAll = () => {
        setShowAllKeyResults(!showAllKeyResults);
    };

    const buttonLabel = showAllKeyResults ? "Hide Progress" : "View all Progress";

    const keyResultsToDisplay = goalDetail && goalDetail.keyResultResponseList
    ? showAllKeyResults
      ? goalDetail.keyResultResponseList
      : goalDetail.keyResultResponseList.slice(0, 2)
    : [];
    
    const [conversation, setConversation] = useState({
        type: goalDetail.type,
        typeId: goalDetail.goalId,
        conversation: '',
      });

    const handleInputChange = (value) => {
        setConversation({
            ...conversation,
            conversation: value,
        });
    };

    const [updateMessages, setUpdateMessages] = useState(false);

    const handleSaveClick = () => {
        if (conversation.conversation.trim() !== '') {
            createConversation(conversation).then((res) => {
                let results = res.data;
                if (results) {
                    setUpdateMessages((prev) => !prev);
                    return;
                }
            });
            setConversation({
                ...conversation,
                conversation: '',
            });
        }
        setShowAllMessages(true);
    };

    const getMessages = () =>{
        getConversation(conversation).then((res) => {
            let results = res.data;
            if (results) {
               setMessages(results);
            }
        });
    }
    const scrollToBottom = useRef(null);      
    useEffect(()=>{
        getMessages();
        if (scrollToBottom.current) {
            scrollToBottom.current.scrollTop = scrollToBottom.current.scrollHeight;
        }
    },[updateMessages])

    const [showAllMessages, setShowAllMessages] = useState(false);

    const handleToggleShowAllMessages = () => {
        setShowAllMessages(!showAllMessages);
    };

    const messagesToDisplay = showAllMessages ? messages : messages.slice(0, 2);

    const [state, setState] = useState({
        right: false,
      });
    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

    const [krData, setKrData] = useState("");

    const handleKeyResultDetails = (krId) =>{
        setKrData("");
        getKeyInitiatives(krId).then((res) => {
            let results = res.data;
            if (results) {
                setKrData(results);
            }
        });
        setState({ right: true });
    }

    const getGoalProgress = (goalId) => {
        if (goalId != "") {
            getGoalDetails(goalId).then((res) => {
                let results = res.data;
                setGoalDetail(results);
                setUpdateCloseKR(true);
            })
        }
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleModalClose = () => {
        setOpen(false);
    };

    const temp = {
        title: "",
        assignee: "",
        progressType: PROGRESSTYPE.PERCENTAGE,
        progressStartValue: "0",
        progressEndValue: "100",
        keyValueStatus: "",
        completeddate: "",
        keyValueStatus: "",
        progress: "",
        id: "",
        weightage :"0",
        goalId: -1,
        progressStartDate : goalDetail.goalTimeline.startDate,
        progressDueDate : goalDetail.goalTimeline.dueDate
    }

    const [newKeyResult, setNewKeyResult] = useState(temp)

    const getGoal = () => {
        getGoalDetails(goalDetail.goalId).then((res) => {
            let results = res.data;
            setGoalDetail(results);
            setUpdateCloseKR(true);
        })
    }
    const loadKRS = () => {
        getGoalDetails(goalDetail.goalId).then((res) => {
            let results = res.data;
            if (results != null) {
                setGoalDetail(results);
            }
        });
    }
    const [updateCloseKR, setUpdateCloseKR] = useState(false);

    useEffect(() => {
        if (updateCloseKR) {
            loadKRS();
            krAction();
            setUpdateCloseKR(false);
        }
    }, [updateCloseKR]);

    const handleUpdateCloseKR = () => {
        setState({ "right": false });
        setUpdateCloseKR(true);
    };

    const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
    const [showLevelDropdown, setShowLevelDropdown] = useState(false);
    const [showSessionDropdown, setShowSessionDropdown] = useState(false);
    const [showVisibilityDropdown, setShowVisibilityDropdown] = useState(false);

    const updateKrData = (updatedGoalData) => {
        setUpdateCloseKR(true);
      };

    const closeSessions = () =>{
        setShowSessionDropdown(false);
    }

    const updateSelectedEmployees = (selected) => {
        const updatedGoalDetail = { ...goalDetail, ownerDetails: selected };
        setGoalDetail(updatedGoalDetail);
        handleGoalDetailsChange(updatedGoalDetail);
        setShowAssigneeDropdown(false);
    };

    const updateSelectedLevel = (selected) => {
        const updatedGoalDetail = { ...goalDetail, goalLevel: selected };
        setGoalDetail(updatedGoalDetail);
        handleGoalDetailsChange(updatedGoalDetail);
        setShowLevelDropdown(false);
    };

    const updateSelectedVisibility = (selected) => {
        const updatedGoalDetail = { ...goalDetail, visibility: selected };
        setGoalDetail(updatedGoalDetail);
        handleGoalDetailsChange(updatedGoalDetail);
        setShowVisibilityDropdown(false);
    };

    const updateTeamSelected = (selected) => {
        const updatedGoalDetail = {
            ...goalDetail,
            goalLevel: selected.level,
            teamName: selected.team.name,
            teamId: selected.team.id,
        };
        setGoalDetail(updatedGoalDetail);
        handleGoalDetailsChange(updatedGoalDetail);
        setShowLevelDropdown(false);
    };

    const updateSelectedProgress = (progress) =>{
        const updatedGoalDetail = {
            ...goalDetail,
            progressType: progress.progressType,
            progressStartValue: progress.progressStartValue,
            progressEndValue: progress.progressEndValue,
        }
        setGoalDetail(updatedGoalDetail);
        handleProgressDetailsChange(updatedGoalDetail);
    }
    const updateSessionSelected = (sessions) =>{
        const updatedGoalDetail = {
            ...goalDetail,
            goalTimeline: {
                goalCycle: sessions.goalCycle,
                startDate: sessions.startDate,
                dueDate: sessions.dueDate,
            },
        }
        setGoalDetail(updatedGoalDetail);
        const progressPrams = {
            goalCycle: updatedGoalDetail.goalTimeline.goalCycle,
            startDate: updatedGoalDetail.goalTimeline.startDate,
            dueDate: updatedGoalDetail.goalTimeline.dueDate,
            goalId: updatedGoalDetail.goalId,
          }
        handleProgressDetailsChange(progressPrams);
    }

    const handleProgressDetailsChange = (updateGoal) =>{
        updateGoalProgress(updateGoal)
        krAction();
    }

    const handleGoalDetailsChange = (updatedGoalDetail) => {
        updateGoalSummary(updatedGoalDetail);
        updateGoalData(updatedGoalDetail);
    }

    const handleDeleteGoal = (goalId) => {
        deleteGoal(goalId)
          .then((res) => {
            ToastSuccess({text:"Goal Deleted Successfully"});
            isGoalDeleted();
          })
          .catch((error) => {
            ToastError({text:"Error while deleting Goal"});
          });
      };
    const [originalTitle, setOriginalTitle] = useState(goalData.title);
    const [originalDescription, setOriginalDescription] = useState(goalData.description);
    
    const [anchorElProgress, setAnchorElProgress] = React.useState(null);
    const handleOpenPopOver = (event) => {
      setAnchorElProgress(event.currentTarget);
    };
    const handleClosePopOver = () => {
      setAnchorElProgress(null);
    };
    const openProgressPopOver = Boolean(anchorElProgress);
    const id = openProgressPopOver ? 'simple-popover' : undefined;

    const [showTooltip, setShowTooltip] = useState(false);

    const handleHover = () => {
        setShowTooltip(true);
    };

    const deleteKeyResult = () =>{
        getGoal();
    }

    return (
        <div className='goalDetails' ref={scrollToBottom}>
            <div className='goalDetailsHeading'>
                <div className='goalDetailButtons'>
                    <img src={BackIcon} onClick={handleGoalClose} />
                    <h2>Goal details</h2>
                </div>
                <div className='goalDetailsButtons'>
                    <Button className='goalDetailsAddButton' 
                    onClick={()=>{
                        handleOpen();
                        let newKey = temp;
                        newKey.goalId = goalDetail.goalId;
                        setNewKeyResult(newKey)
                    }}
                    >+ Add Key Result</Button>
                    <GoalMenu goalData={goalDetail} onDelete={handleDeleteGoal} updateGoal={getGoal}/>
                </div>
            </div>
            <div>
                <div className="goalNameGD">
                    <p>Goal</p>
                    <input
                        value={goalDetail.title}
                        onChange={(e) => {
                            setGoalDetail({ ...goalDetail, title: e.target.value });
                        }}
                        className='goalNameInput'
                        multiline
                        rows={3}
                        onBlur={() => {
                            if (goalDetail.title !== originalTitle) {
                                handleGoalDetailsChange(goalDetail);
                            }
                        }}
                    />
                </div>
                <div className='goalDetailsItems'>
                    <p>Sessions</p>
                    <ClickAwayListener onClickAway={() => setShowSessionDropdown(false)}>
                    <div className='sessionContent'>
                    <h4>{capitalizeFirstLetter(goalDetail.goalTimeline.goalCycle)} - {formatDate(goalDetail.goalTimeline.startDate)} to {formatDate(goalDetail.goalTimeline.dueDate)}</h4>
                    <p onClick={() => setShowSessionDropdown(true)}><img src={EditIcon}/>  Edit</p>
                    {showSessionDropdown && (
                        <SessionDropDown goalTimeline={goalDetail.goalTimeline} updateSessionSelected={updateSessionSelected} closeSessions={closeSessions}/>
                    )}
                    </div>
                    </ClickAwayListener>
                </div>
                <div className='goalDetailsItems'>
                    <p>Assignee</p>
                    <div className='assigneeGD'>
                        {visibleAssignees.map((assignee) => (
                            <Chip
                                key={assignee.employeeId}
                                label={assignee.name}
                                variant="outlined"
                                avatar={<AvatarBuilder employee={{ name: assignee.name }} variant="circular" size="extra-samll" />}
                                onDelete={() => {
                                    if (visibleAssignees.length > 1) {
                                      handleDelete(assignee.employeeId);
                                    }
                                  }}
                                className='goalAssignees'
                                deleteIcon={<img src={CloseSvg} alt="Close Icon" />}
                            />
                        ))}

                        {hiddenAssignees.length > 0 && (
                            <div
                                className='hidden-assignees'
                                onMouseEnter={handleToggleHiddenAssignees}
                                onMouseLeave={handleToggleHiddenAssignees}
                            >
                                <div className={`hidden-assignees-toggle ${showHiddenAssignees ? 'active' : ''}`}>
                                    +{hiddenAssignees.length}
                                </div>
                                {showHiddenAssignees && (
                                    <div className='hidden-assignees-list'>
                                        {hiddenAssignees.map((assignee) => (
                                            <Chip
                                                key={assignee.employeeId}
                                                label={assignee.name}
                                                variant="outlined"
                                                avatar={<AvatarBuilder employee={{ name: assignee.name }} variant="circular" size="extra-samll" />}
                                                onDelete={() => handleDelete(assignee.employeeId, assignee.name)}
                                                className='goalAssignees'
                                                deleteIcon={<img src={CloseSvg} alt="Close Icon" />}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        <ClickAwayListener onClickAway={() => setShowAssigneeDropdown(false)}>
                        <div className='addAssignee'>
                        <a onClick={() => setShowAssigneeDropdown(true)}>+ Assignee</a>
                        {showAssigneeDropdown && (
                            <AssigneeDropDown selected={goalDetail.ownerDetails} updateSelected={updateSelectedEmployees}/>
                        )}
                        </div>
                        </ClickAwayListener>
                    </div>
                </div>
                <div className='goalDetailsItems goalLevelContainer'>
                    <p>Level</p>
                    <ClickAwayListener onClickAway={() => setShowLevelDropdown(false)}>
                    <h4 onClick={() => setShowLevelDropdown(!showLevelDropdown)}
                    className={showLevelDropdown ? 'goalLevelName' : null}>
                    {capitalizeFirstLetter(goalDetail.goalLevel)} 
                    {goalDetail.goalLevel == "TEAM" ? ` - ${goalDetail.teamName}` : null}
                    <div className={showLevelDropdown ? 'arrow-up' : 'arrow-down' }></div>
                    </h4>
                    </ClickAwayListener>
                    {showLevelDropdown && (
                        <LevelDropDown selected={goalDetail.goalLevel} updateSelected={updateSelectedLevel} updateTeamSelected={updateTeamSelected} />
                    )}
                </div>
                <div className='goalDetailsItems'>
                    <p>Progress</p>
                    <div className={`goalDetailProgressContent ${anchorElProgress ? 'showProgressBorder' : ''}`}>
                    <h4>{capitalizeFirstLetter(goalDetail.progressType)} - {Math.round(goalDetail.progressStartValue)} to {goalDetail.progressEndValue}</h4>
                    {
                        (goalDetail.goalProgressUpdateType == "AUTOMATIC") ? 
                        <h1 
                        title="You cannot update the progress, Its a automatic goal"
                        onMouseEnter={handleHover}
                        onMouseLeave={() => setShowTooltip(false)}
                        ><img src={EditIcon}/>  Edit</h1> :
                        <p onClick={handleOpenPopOver}><img src={EditIcon}/>  Edit</p>
                    }
                        <Popover
                            id={id}
                            open={openProgressPopOver}
                            anchorEl={anchorElProgress}
                            onClose={handleClosePopOver}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <EditProgress progressData = {goalDetail} handleProgressClose={handleClosePopOver} updateSelectedProgress={updateSelectedProgress} />
                        </Popover>
                    </div>
                    
                </div>
                <div className='goalDetailsItems goalLevelContainer'>
                    <p>Visibility</p>
                    <ClickAwayListener onClickAway={() => setShowVisibilityDropdown(false)}>
                    <h4 onClick={() => setShowVisibilityDropdown(!showVisibilityDropdown)}
                    className={showVisibilityDropdown ? 'goalLevelName' : null}>
                    {capitalizeFirstLetter(goalDetail.visibility)}
                    <div className={showVisibilityDropdown ? 'arrow-up' : 'arrow-down' }></div>
                    </h4>
                    </ClickAwayListener>
                    {showVisibilityDropdown && (
                        <VisibilityDropDown selected={goalDetail.visibility} updateSelected={updateSelectedVisibility} />
                    )}
                </div>
                <div className='goalDetailsItems'>
                    <p>Description</p>
                    <input
                        value={goalDetail.description}
                        onChange={(e) => {
                            setGoalDetail({ ...goalDetail, description: e.target.value });
                        }}
                        className='goalNameInput'
                        multiline
                        rows={3}
                        onBlur={() => {
                            if (goalDetail.description !== originalDescription) {
                                handleGoalDetailsChange(goalDetail);
                            }
                        }}
                    />
                </div>
                <div>
                    <div className='goalDetailProgress'>
                        <h2>Progrerss (KRs)</h2>
                        <div></div>
                    </div>
                    <div className='keyResultListGD'>
                        {keyResultsToDisplay.map((keyResult, index) => (
                            <div className='goalDetailKeyResult' key={index}>
                                {
                                    keyResult.type == "GOAL" ? <h2>GOAL</h2> : <p>KR</p>
                                }
                                <div className='keyResultNameGD'>
                                    <h4
                                    onClick={(event) => { 
                                        if (keyResult.type === "GOAL") {
                                            handleGoalDetails(keyResult.goalId);
                                        } else {
                                            handleKeyResultDetails(keyResult.id)
                                        }
                                    }}
                                    >{truncateText(keyResult.title, 80)}</h4>
                                    <div className='krHighlights'>
                                        <h5>Increased to {Math.round(keyResult.progress)} {capitalizeFirstLetter(keyResult.progressType)}</h5>
                                        <h6>Weightage : {keyResult.krWeightage}</h6>
                                    </div>
                                </div>
                                <div className='goalDetailAvtar'>
                                    <AvatarGroup max={3} className="avtarIcon">
                                        {Array.isArray(keyResult.ownerDetails)
                                            ? keyResult.ownerDetails.map((owner, i) => (
                                                <AvatarBuilder
                                                    employee={{ name: owner.name }}
                                                    variant="circular"
                                                    size="profile-img"
                                                    key={i}
                                                />
                                            )) : null}
                                    </AvatarGroup>
                                </div>
                                <div className='goalDetailSlider' onClick={(event) => {event.stopPropagation()}}>
                                    {
                                        keyResult.type == "GOAL" ?
                                            <SliderProgressBar progressEndValue={keyResult.progressEndValue} progressStartValue={keyResult.progressStartValue} progressType={keyResult.progressType} goalId={keyResult.goalId} progress={keyResult.currentProgress} progressPercentage={keyResult.progress} status={keyResult.status} />
                                            :
                                            <SliderProgressBar progressEndValue={keyResult.progressEndValue} progressStartValue={keyResult.progressStartValue} progressType={keyResult.progressType} krId={keyResult.id} goalId={keyResult.goalId} progress={keyResult.currentProgress} progressPercentage={keyResult.progress} status={keyResult.status} updateGoalProgressBar={getGoalProgress}/>
                                    }
                                    <div className='goalSliderDetails'>
                                        <h3 style={{color : getColorByStatus(keyResult.status)}}>{capitalizeFirstLetter(keyResult.status)}</h3>
                                        <h2>{Math.round(keyResult.progress)}%</h2>
                                        <h1>{getformatedTime(keyResult.updatedAt)}</h1>
                                    </div>
                                </div>
                                <div>
                                    {
                                        (keyResult.type === "GOAL") ?
                                            <GoalMenu
                                                parentId={goalDetail.goalId}
                                                goalData={keyResult}
                                                onDelete={handleDeleteGoal}
                                                handleGoalDetails={handleGoalDetails}
                                                updateGoal={getGoal} 
                                                deleteAlignment={deleteKeyResult}
                                                callFrom="GoalDetail"
                                                isTopGoal={0}
                                                />
                                            :
                                            <KeyResultMenu
                                                krId={keyResult.id}
                                                krData={keyResult}
                                                goalId={keyResult.goalId}
                                                goalData={goalData}
                                                goalAssociateId={goalData.associateGoalId}
                                                deleteKr={deleteKeyResult}
                                                handleKeyResultDetails={handleKeyResultDetails}
                                                callFrom="GoalDetail"
                                            />
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {goalDetail.keyResultResponseList.length > 2 && (
                        <a className="show-all-button" onClick={handleToggleShowAll}>
                            {buttonLabel}
                        </a>
                    )}
                    {goalDetail.parentGoals.length > 0 && (
                        <div className='goalDetailProgress'>
                            <h2>Parent Goal</h2>
                            <div></div>
                        </div>
                    )}
                    <div className='keyResultListGD'>
                        {goalDetail.parentGoals.map((keyResult, index) => (
                            <div className='goalDetailKeyResult' key={index}>
                                <h2>GOAL</h2>
                                <div className='keyResultNameGD'>
                                    <h4
                                    onClick={(event) => { 
                                        handleGoalDetails(keyResult.goalId);
                                    }}
                                    >{truncateText(keyResult.title, 80)}</h4>
                                    <h5>Increased to {Math.round(keyResult.progress)} {capitalizeFirstLetter(keyResult.progressType)}</h5>
                                </div>
                                <div className='goalDetailAvtar'>
                                    <AvatarGroup max={3} className="avtarIcon">
                                        {Array.isArray(keyResult.ownerDetails)
                                            ? keyResult.ownerDetails.map((owner, i) => (
                                                <AvatarBuilder
                                                    employee={{ name: owner.name }}
                                                    variant="circular"
                                                    size="profile-img"
                                                    key={i}
                                                />
                                            )) : null}
                                    </AvatarGroup>
                                </div>
                                <div className='goalDetailSlider' onClick={(event) => {event.stopPropagation()}}>
                                    <SliderProgressBar progressEndValue={keyResult.progressEndValue} progressStartValue={keyResult.progressStartValue} progressType={keyResult.progressType} goalId={keyResult.goalId} progress={keyResult.currentProgress} progressPercentage={keyResult.progress} status={keyResult.status} />
                                    <div className='goalSliderDetails'>
                                        <h3 style={{color : getColorByStatus(keyResult.status)}}>{capitalizeFirstLetter(keyResult.status)}</h3>
                                        <h2>{Math.round(keyResult.progress)}%</h2>
                                        <h1>{getformatedTime(keyResult.updatedAt)}</h1>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='goalDetailProgress'>
                        <h2>Conversation</h2>
                        <div></div>
                    </div>
                    <div className="container">
                        <ReactQuill
                            className='input-box'
                            name='conversation'
                            value={conversation.conversation}
                            onChange={handleInputChange}
                            placeholder="Type a message"
                        />
                        <button className="save-button" onClick={handleSaveClick}>Save</button>
                    </div>
                    <div>
                        {messagesToDisplay.map((conversation, index) => (
                            <div className='goalDetailConversation' key={index}>
                                <AvatarBuilder
                                    employee={{ name: conversation.fromEmpName }}
                                    variant="circular"
                                    size="profile-img"
                                />
                                <div className='messageGD'>
                                    <h4 dangerouslySetInnerHTML={{ __html: conversation.conversation }} />
                                    <div className='EmployeeGD'>
                                        <h3>{conversation.fromEmpName}</h3>
                                        <p>{getformatedTime(conversation.createdAt)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                         {messages.length > 2 && (
                            <a className="show-all-button" onClick={handleToggleShowAllMessages}>
                                {showAllMessages ? 'Hide conversation' : 'View all conversation'}
                            </a>
                        )}
                    </div>
                </div>
            </div>
            {
                krData ?
                    <Drawer 
                    anchor={'right'}
                    open={state['right']}
                    onClose={() => {
                        toggleDrawer('right', false);
                        handleGoalClose();
                      }}
                      
                    >
                        {
                            <KeyResultDetails krData={krData} handleGoalClose={handleGoalClose} updateKRData={updateKrData} handleCloseDrawer={toggleDrawer('right', false)} deleteKr={handleUpdateCloseKR}/>
                        }
                    </Drawer>
                    : null
            }
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AddKeyResults onCancel={handleModalClose} goalData={goalDetail} newKeyResult={newKeyResult} updateGoalProgress={getGoal} setNewKeyResult={setNewKeyResult} updateKR={handleUpdateCloseKR} isWeightageEdit={goalDetail.keyResultResponseList.length}/>
            </Modal>
        </div>
    )
}

export const AssigneeDropDown = ({ selected, updateSelected }) => {
    const [employeeList, setEmployeeList] = useState([]);
  
    useEffect(() => {
      const loadEmpl = () => {
        getAllEmployee().then((res) => {
          let results = res.data;
          if (results) {
            setEmployeeList(results);
          }
        });
      };
      loadEmpl();
    }, []);
  
    const toggleOption = (id, name) => {
      const updatedSelected = [...selected];
      const existingIndex = updatedSelected.findIndex((item) => item.employeeId === id);
  
      if (existingIndex !== -1) {
        updatedSelected.splice(existingIndex, 1);
      } else {
        updatedSelected.push({employeeId : id, name : name});
      }
  
      updateSelected(updatedSelected);
    };
    return (
      <div className="GoalDetailAddAssignee">
          <label className='optionLabel'>Select Employee</label>
          {employeeList.map((option) => (
            <label key={option.id} className="option">
              <input
                type="checkbox"
                checked={selected && selected.some(item => item.employeeId === option.id)}
                onChange={() => toggleOption(option.id, option.name)}
              />
              <AvatarBuilder employee={{ name: option.name }} variant="circular" size="extra-samll" />
              {option.name}
            </label>
          ))}
      </div>
    );
  };

export  const LevelDropDown = ({ selected, updateSelected, updateTeamSelected }) => {
    const levels = ["INDIVIDUAL", "TEAM", "ORG"];
    const [isTeamHovered, setIsTeamHovered] = useState(false);
    const [teams, setTeams] = useState([]);
  
    const loadTeam = () => {
      getAllTeams().then((res) => {
        let results = res.data;
        if (results) {
          setTeams(results);
          return;
        }
      })
    }
    React.useEffect(() => {
      loadTeam();
    }, []);
  
    const handleLevelSelect = (level) => {
        if(level != "TEAM"){
            updateSelected(level);
        }
    };
  
    const handleTeamHover = () => {
      setIsTeamHovered(true);
    };
  
    const handleTeamLeave = () => {
      setIsTeamHovered(false);
    };
  
    const handleTeamSelect = (team) => {
        updateTeamSelected({ level: "TEAM", team });
    };
  
    return (
      <div className="levelSelectGoalDetails">
        {levels.map((level) => (
          <div
            key={level}
            className={`level-option ${selected === level ? "selected" : ""}`}
            onClick={() => handleLevelSelect(level)}
            onMouseEnter={level === "TEAM" ? handleTeamHover : null}
            onMouseLeave={level === "TEAM" ? handleTeamLeave : null}
          >
            {level}{level == "TEAM" ? <img src={leftSideArrow} /> : null}
            {level === "TEAM" && isTeamHovered &&
              (
                <div className="teamSelectGD">
                  {teams.map((team) => (
                    <div
                      key={team.id}
                      className="level-option"
                      onClick={() => handleTeamSelect(team)}
                    >
                      {team.name}
                    </div>
                  ))}
                </div>
              )
            }
          </div>
        ))}
      </div>
    );
  };

export const EditProgress = ({ handleProgressClose , updateSelectedProgress, progressData }) => {
    const [formData, setFormData] = useState({
        progressType: progressData.progressType,
        kpiName: progressData.kpiName,
        kpiType : progressData.kpiType,
        progressStartValue: progressData.progressStartValue,
        progressEndValue: progressData.progressEndValue,
    });
    const [kpiOptions, setKPIOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        let newFormData = { ...formData };
    
        if (name === "progressType") {
            newFormData = {
                ...newFormData,
                [name]: value,
                progressStartValue: value === "BOOLEAN" ? "0" : formData.progressStartValue,
                progressEndValue: value === "BOOLEAN" ? "1" : formData.progressEndValue,
            };
        } else {
            newFormData = {
                ...newFormData,
                [name]: value,
            };

            if (name === "kpiName") {
                const selectedKPI = kpiOptions.find(kpi => kpi.name === value);
                if (selectedKPI) {
                    newFormData.kpiType = selectedKPI.type;
                }
            }
        }
        setFormData(newFormData);
    };
    

    const loadKPI = () => {
        getKPIs().then((res) => {
          let results = res.data;
          if (results) {
            setKPIOptions(results);
            return;
          }
        })
      }

    useEffect(() => {
        loadKPI();
    }, []);

    const handleCancelProgress = () => {
        handleProgressClose();
    }

    const handleSaveProgress = () => {
        const missingFields = [];
        if (!formData.progressType) missingFields.push('Progress Type');
        if (!formData.progressStartValue) missingFields.push('Start Value');
        if (!formData.progressEndValue) missingFields.push('End Value');

        if (
            (formData.progressType === 'INCREASE_KPI' || formData.progressType === 'DECREASE_KPI') &&
            !formData.kpiName
        ) {
            missingFields.push('KPI Name');
        }

        if (missingFields.length > 0) {
            setErrorMessage(`Please fill in the following fields: ${missingFields.join(', ')}.`);
        }else if (
            (formData.progressType === 'INCREASE_KPI' && parseFloat(formData.progressStartValue) >= parseFloat(formData.progressEndValue)) ||
            (formData.progressType === 'DECREASE_KPI' && parseFloat(formData.progressStartValue) <= parseFloat(formData.progressEndValue))
        ) {
            setErrorMessage(`Start Value must be ${
                formData.progressType === 'INCREASE_KPI' ? 'less than' : 'greater than'
            } End Value.`);
        } else {
            if (formData.kpiType) {
                formData.progressType = formData.kpiType;
            }
            updateSelectedProgress(formData);
            handleProgressClose();
        }
    }

    const [isProgressDropDownOpen, setIsProgressDropDownOpen] = useState(false);
    const [isKpiDropDownOpen, setIsKpiDropDownOpen] = useState(false);

    return (
        <div className="progressContentGD">
            <h2>Edit Progress</h2>
            <p>Progress</p>
            <FormControl className='progressContentGDDropdown'>
                <Select
                    name="progressType"
                    value={formData.progressType}
                    onChange={handleChange}
                    IconComponent={isProgressDropDownOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                >
                    <MenuItem value="NUMBER">Number</MenuItem>
                    <MenuItem value="PERCENTAGE">Percentage</MenuItem>
                    <MenuItem value="BOOLEAN">Boolean</MenuItem>
                    <MenuItem value="DOLLAR">Dollar</MenuItem>
                    <MenuItem value="INCREASE_KPI">Increase KPI</MenuItem>
                    <MenuItem value="DECREASE_KPI">Decrease KPI</MenuItem>
                </Select>
            </FormControl>
            {
                formData.progressType == PROGRESSTYPE.DECREASE_KPI || formData.progressType == PROGRESSTYPE.INCREASE_KPI ?
                    <>
                        <p>KPI</p>
                        <FormControl className='progressContentGDDropdown'>
                            <Select name="kpiName" value={formData.kpiName} onChange={handleChange}
                                IconComponent={isKpiDropDownOpen ? KeyboardArrowUpIcon : KeyboardArrowDownIcon}
                            >
                                {kpiOptions.map((kpiOption) => (
                                    <MenuItem key={kpiOption.id} value={kpiOption.name}>
                                        {kpiOption.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                : null
            }
            {
                formData.progressType != PROGRESSTYPE.BOOLEAN ?
                    <div className='GoalDetailprogressValue'>
                        <div>
                            <p>Start</p>
                            <input
                                name="progressStartValue"
                                className='GoalDetailprogressInputValue'
                                value={formData.progressStartValue}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <p>End</p>
                            <input
                                name="progressEndValue"
                                className='GoalDetailprogressInputValue'
                                value={formData.progressEndValue}
                                onChange={handleChange}
                            />
                        </div>
                    </div> : null
            }
            <div className="error-message">
                {errorMessage && (
                    <div className="errorMessage">
                        <ErrorIcon />
                        <p>{errorMessage}</p>
                    </div>
                )}
            </div>
            <div className='progressGDButton'>
                <button onClick={handleCancelProgress} className="CancelButton">Cancel</button>
                <button onClick={handleSaveProgress} className="PrimaryButton">Save</button>
            </div>
        </div>
    );
};


export const SessionDropDown = ({ goalTimeline, updateSessionSelected, closeSessions }) => {
  const sessions = ["MONTHLY", "QUARTERLY", "HALF_YEARLY", "ANNUALLY", "CUSTOM"];
  const [isSessionHovered, setIsSessionHovered] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [fromDate, setFromDate] = useState((goalTimeline.startDate));
  const [toDate, setToDate] = useState((goalTimeline.dueDate));

  const [timeLine, setTimeline] = useState(goalTimeline);

  const handleSessionHover = (session) => {
    setSelectedSession(session);
    setIsSessionHovered(true);
    setTimeline({
        ...timeLine,
        goalCycle: session,
      });
  };

  const getFromDate = (date) => {
    setFromDate(date);
  };

  const getToDate = (date) => {
    setToDate(date);
  };

  useEffect(()=>{
    setSelectedSession(timeLine.goalCycle);
    const updatedTimeline ={
        ...timeLine,
        startDate: getFormatedDate(fromDate),
        dueDate: getFormatedDate(toDate),
      };
      setTimeline(updatedTimeline);
      updateSessionSelected(updatedTimeline);
  }, [(fromDate), (toDate)])

  const updateTimeline = (startDate, dueDate) => {
    const updatedTimeline = {
      ...timeLine,
      startDate: startDate,
      dueDate: dueDate,
    };
    setTimeline(updatedTimeline);
    updateSessionSelected(updatedTimeline);
  };

  const closeCalender = () =>{
    closeSessions();
  }
  
  
  return (
    <div className='sessionDropDown'>
      {sessions.map((timeLineSession) => (
        <div
          key={timeLineSession}
          className={`sessionOption ${selectedSession === timeLineSession && "sessionSelected"}`}
          onClick={() => handleSessionHover(timeLineSession)}
        >
          <div className='sessionDateView'>
          <p>{capitalizeFirstLetter(timeLineSession)}</p>
          {selectedSession === timeLineSession ? (
            <h1>
              {formatDate(fromDate)} - {formatDate(toDate)}
            </h1>
          ) : null}
          </div>
          {selectedSession === timeLineSession ? (
            <img src={TickIcon} />
          ) : null}
          {isSessionHovered && selectedSession === timeLineSession && (
          <div className="sessionDateNavigator">
            {timeLine.goalCycle === "CUSTOM" ? (
                  <DateRangeCalenderPicker updateTimeline={updateTimeline} closeCalender={closeCalender}/>
            ) : (
              <div className="DateNavigatorAddGoal">
                <h2>Quarterly Duration</h2>
                <DateNavigator
                fromDate={getFromDate}
                toDate={getToDate}
                goalCycle={timeLine.goalCycle}
                disableButton={true}
              />
              </div>
            )}
          </div>
        )}
        </div>
      ))}
    </div>
  );
};

export  const VisibilityDropDown = ({ selected, updateSelected }) => {
    const visibility = ["PUBLIC", "PRIVATE"];
  
    const handleLevelSelect = (visibility) => {
        updateSelected(visibility);
    };
  
    return (
      <div className="levelSelectGoalDetails">
        {visibility.map((visibility) => (
          <div
            key={visibility}
            className={`level-option ${selected === visibility ? "selected" : ""}`}
            onClick={() => handleLevelSelect(visibility)}
          >
            {visibility}
          </div>
        ))}
      </div>
    );
  };