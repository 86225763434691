import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Setting from './Pages/Setting/Setting';
import MainLayout from './Components/MainLayout';
import AuthLayout from './Components/AuthLayout';
import Register from './Pages/authentication/register';
import Login from './Pages/authentication/AuthLogin';
import SignupDetails from './Pages/authentication/SignupDetails';
import OAuthRedirect from './Pages/authentication/OAuthRedirect';
import PaymentReturn from './Pages/payments/PaymentReturn';
import TestPayment from './Pages/authentication/TestPayment';
import ForgotPassword from './Pages/authentication/ForgotPassword';
import ResetPassword from './Pages/authentication/ResetPassword';
import GoalDetails from './Pages/GoalSummary/GoalDetails'

import themes from 'themes';
import { useSelector } from 'react-redux';
import NavigationScroll from 'layout/NavigationScroll';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { checkLogin } from 'Services/AuthService';
import { createBrowserHistory } from 'history';
import { SetupInterceptors } from 'utils/NetworkService';
import Forbidden from './utils/Forbidden';
import NotFound from './utils/Notfound';
import { useNavigate } from "react-router-dom";
import PerformanceReviewLayout from 'Pages/PerformanceReview/PerformanceReviewLayout';
import WritePerformanceReview from 'Pages/PerformanceReview/WritePerformanceReview';
import ReviewList from 'Pages/PerformanceReview/PerformanceReviewCycleList';
import SlackLogin from 'Pages/authentication/SlackLogin';

import GoalGrid from 'Components/GoalGrid';
import SlackIntegration from 'utils/SlackIntegration';
import AuthLoginPage from 'Pages/authentication/AuthLoginPage';
import AuthSignUp from 'Pages/authentication/AuthSignUp';
import RegisterDetails from 'Pages/authentication/RegisterDetails';
import AtlassianLogin from 'Pages/authentication/AtlassianLogin';
import GoogleSheetsIntegration from 'utils/GoogleSheetsIntegration';
import parentChecker from 'Components/ParentChecker';
import { useEffect } from "react";
import refreshFeatures from 'utils/PaymentUtils';

function App() {
  
  const hostname = window.location.hostname;
  const atlassianSubstring = 'atlassian.net';
  const isAtlassianHost = hostname.includes(atlassianSubstring);

  useEffect(() => {
    let domain = parentChecker();
    if(domain !== undefined)
      localStorage.setItem("origin", "JIRA");
  },[]);

  return (
    <div >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes({})}>
          <CssBaseline />
          <NavigationScroll>
            <Routes>
              <Route path="/" element={<MainLayout />} >
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/setting/:tabName" element={<Setting />} />
                <Route path='/goals/:refId' element={<GoalGrid />} />
                <Route path='/goal-details/:goalId' element={<GoalDetails />} />
                <Route path="/forbidden" element={<Forbidden />} />
                <Route path="/not-found" element={<NotFound />} />
                <Route path='/performance-review' element={<PerformanceReviewLayout/>} />
                <Route path='/performance-review/:questionType/:reviewId/:reviewedEmpId?' element={<WritePerformanceReview/>} />
                <Route path='/performance-review-list/:reviewId/:review' element={<ReviewList/>} />
                <Route path='/v1/slack/install' element={<SlackIntegration/>} />
                <Route path='/v1/google-sheets/install' element={<GoogleSheetsIntegration/>} />
              </Route>
              <Route element={<AuthLayout />} >
                <Route path="/login" element={isAtlassianHost ? <AtlassianLogin /> : <AuthLoginPage /> } />
                <Route path="/login/slack/:userId/:teamId" element={<SlackLogin />} />
                <Route path="/register" element={<AuthSignUp />} />
                <Route path="/signup-details" element={<RegisterDetails />} />
                <Route path="/oauth2/redirect" element={<OAuthRedirect />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/test-payment" element={<PaymentReturn/>} />
                <Route path="/payment/return" element={<PaymentReturn />} />
              </Route>
            </Routes>
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}


// export default App;

export default function MainApp(props) {
  const history = createBrowserHistory();
  SetupInterceptors(history);
  const auth = useSelector(state => state.customization);

  const navigate = useNavigate();

  const redirectToDashboard = () => {
    navigate("/home");
  }

  const redirectToLogin = () => {
    navigate("/login");
  }

  const redirectToSignUpDetails = () => {
    navigate("/signup-details");
  }
  const checkLoginFn = () => {

    if (localStorage.getItem('token') == undefined || localStorage.getItem('token') == null) {
      redirectToLogin();
      return;
    }

    checkLogin().then((res) => {
      let results = res.data;
      if (results && results.status === "SUCCESS") {
        if (results.empId == 0 || results.orgId == 0) {
          redirectToSignUpDetails();
        } else {
          refreshFeatures();
          }
        }
      })
      .catch(() => {
        redirectToLogin();
      });
  }

  React.useEffect(() => {
    checkLoginFn();
  }, []);

  return <App {...props} location={props.location} customization={auth} />;
}
