import React, { useState } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import "./DashboardGoals.css"
import { Button, Grid } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getGoals,getOrgGoals,getTeamGoals } from 'Services/Goalservice';
import { capitalizeFirstLetter, getFormatedDate } from 'Constants/DateConstants';
import { useNavigate } from "react-router-dom/dist";
import NoRecord from "utils/NoRecord";
import { getEmployee, getTeamsByRole } from "Services/OrgService";

const statusOptions = [
    'COMPLETED',
    'NOT_STARTED',
    'BEHIND',
    'AT_RISK',
    'AHEAD',
    'ON_TRACK'
];

export default function DashboardGoals({params, fromDate, toDate, selectedTeamId }) {
    const [goalData, setGoalData] = React.useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItemLabel, setSelectedItemLabel] = useState('AT_RISK');
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (label) => () => {
        setSelectedItemLabel(label);
        handleClose();
    };

    const filteredGoalData = goalData.filter(goal => goal.status === selectedItemLabel);

    const navigate = useNavigate();

        const getGoalData = () => {
            let filter = { 'pageName': 'DASHBOARD' };
            filter.level = params;
            filter.fromDate = getFormatedDate(fromDate);
            filter.toDate = getFormatedDate(toDate);
            filter.teamId = selectedTeamId;

            if (params == 'INDIVIDUAL') {
                getGoals(filter).then((res) => {
                    let results = res.data;
                    if (results && results != null) {
                        setGoalData(results);
                    }
                });
            }
            else if(params == 'TEAM'){
                getTeamGoals(filter, selectedTeamId).then((res) => {
                let results = res.data;
                if (results) {
                    setGoalData(results)
                    return;
                }
            })
        } else{
                getOrgGoals(filter).then((res) => {
                  let results = res.data;
                  if (results && results != null) {
                      setGoalData(results);
                  }
              });
        }

    }

    let showDemoData = localStorage.getItem("showDemo");

    React.useEffect(() => {
        getGoalData();
    }, [toDate, selectedItemLabel, showDemoData, selectedTeamId]);

    const redirectToGoals = () => {
        navigate("/goals/" + Date.now());
    }

    return (
        <div className="dashboardGoals">
            <div className="dashboardcontainer">
            <div className='dashboardGoalsItem'>
            <div className='dashboardGoalDropDown'>
            <Button
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
                <p>{capitalizeFirstLetter(selectedItemLabel)} Goal</p>
            </Button>
            <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {statusOptions?.map((option) => (
                    <MenuItem key={option} onClick={handleMenuItemClick(option)} className="dashboardGoalMenuItem">
                        {capitalizeFirstLetter(option)}
                    </MenuItem>
                ))}
            </Menu>
        </div>
                <Button className="WithoutBorderButton" onClick={redirectToGoals}>View all</Button>
            </div>
            <Grid container className='dashboardGoalsHeading'>
                <Grid item xs={8} pl={10} className="dashboardGoalHeadingItem">
                    <h3>GOALS</h3>
                </Grid>
                <Grid item xs={2} className="dashboardGoalHeadingItem">
                    <h3>DUE</h3>
                </Grid>
                <Grid item xs={2} className="dashboardGoalHeadingItem">
                    <h3>PROGRESS</h3>
                </Grid>
            </Grid>
            <div className="scrollableContainer">
            {filteredGoalData.length == 0 ? (
                <NoRecord/>
            ) : (
                filteredGoalData?.map((goal) => (
                    <Grid container className='goalContent' key={goal.goalId}>
                        <Grid item xs={8} className="goalItem">
                            <p>Goal</p>
                            <h3>{goal.title}</h3>
                        </Grid>
                        <Grid item xs={2} pl={1} className="goalItem">
                            <h4>{goal.dueDate}</h4>
                        </Grid>
                        <Grid item xs={2} pl={2.5} className="goalItem">
                            <h4>{Math.round(goal.currentProgress)}%</h4>
                        </Grid>
                        <TreeView
                            aria-label="simple-goals"
                            className="keyResultTreeView"
                            defaultCollapseIcon={<KeyboardArrowUpIcon/>}
                            defaultExpandIcon={<KeyboardArrowDownIcon/>}
                        >
                            <TreeItem
                                nodeId={`keyResult_${goal.goalId}`}
                                label={
                                    <div className="keyResultDropdown">
                                        <h4>Key Results :</h4>
                                    </div>
                                }
                                className='dashboardKeyResultContent'
                            >
                                {goal.keyResultResponseList?.map((keyResult) => (
                                    <Grid container className="dashboardkeyResultComponent" key={keyResult.id}>
                                        <Grid item xs={7} className="dashboardkeyResultItem">
                                            <h3>{keyResult.title}</h3>
                                        </Grid>
                                        <Grid item xs={3} pl={6.5} className="goalItem">
                                            <h4>{keyResult.dueDate}</h4>
                                        </Grid>
                                        <Grid item xs={2} pl={1} className="goalItem">
                                            <h4>{Math.round(keyResult.progress)}%</h4>
                                        </Grid>
                                    </Grid>
                                ))}
                            </TreeItem>
                        </TreeView>
                    </Grid>
                ))
            )}
            </div>
            </div>
        </div>
    );
}
