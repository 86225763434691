import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import "./PerformanceReviewCycle.css";
import InputLabel from '@mui/material/InputLabel';
import "./PerformanceReviewCycle.css";

export default function PerformanceTimeLine({ handleSetActiveStep, activeStep, handleSaveDataPRT, data }) {
  const [reviewTimelineData, setReviewTimelineData] = useState(data);
  const [errors, setErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const validateFields = () => {
    const newErrors = {};
    if (!reviewTimelineData.employeeSelfEvaluationDueDate) {
      newErrors.employeeSelfEvaluationDueDate = "Employee Self Evaluation Due Date is required";
    }

    if (!reviewTimelineData.employeeManagerEvaluationDueDate) {
      newErrors.employeeManagerEvaluationDueDate = "Employee Manager Evaluation Due Date is required";
    }

    if (!reviewTimelineData.reviewCommunicationDueDate) {
      newErrors.reviewCommunicationDueDate = "Review Communication Due Date is required";
    }

    if (!reviewTimelineData.appealDate) {
      newErrors.appealDate = "Appeal Date is required";
    }

    if (!reviewTimelineData.signDate) {
      newErrors.signDate = "Sign Date is required";
    }

    if (!reviewTimelineData.autoSignDate) {
      newErrors.autoSignDate = "Auto Sign Date is required";
    }

    if (data.endDate && reviewTimelineData.employeeSelfEvaluationDueDate) {
      const endDate = new Date(data.endDate);
      const selfEvaluationDueDate = new Date(reviewTimelineData.employeeSelfEvaluationDueDate);
  
      if (endDate > selfEvaluationDueDate) {
        newErrors.employeeSelfEvaluationDueDate = "Employee Self Evaluation must be greater than End Date";
      }
    }

    const dateFields = [
      "employeeSelfEvaluationDueDate",
      "employeeManagerEvaluationDueDate",
      "reviewCommunicationDueDate",
      "appealDate",
      "signDate",
      "autoSignDate",
    ];

    for (let i = 1; i < dateFields.length; i++) {
      const currentDate = reviewTimelineData[dateFields[i]];
      const previousDate = reviewTimelineData[dateFields[i - 1]];
      if (currentDate && previousDate && new Date(currentDate) <= new Date(previousDate)) {
        newErrors[dateFields[i]] = `${dateFields[i]} must be greater than ${dateFields[i - 1]}`;
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setReviewTimelineData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setReviewTimelineData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleNext = () => {
    setIsFormSubmitted(true);
    const isValid = validateFields();
    if (isValid) {
      const nextStep = Math.min(activeStep + 1, 4);
      handleSetActiveStep(nextStep);
      handleSaveDataPRT(reviewTimelineData)
    }
  };

  const handlePrevious = () => {
    const prevStep = Math.max(activeStep - 1, 0);
    handleSaveDataPRT(reviewTimelineData)
    handleSetActiveStep(prevStep);
  };

  useEffect(() => {
    if(isFormSubmitted){
      validateFields();
    }
  }, [reviewTimelineData]);

  return (
    <div className='PRCContent'>
          <InputLabel>Due Date For Submission of Review *</InputLabel>
          <TextField
            name="employeeSelfEvaluationDueDate"
            type="date"
            value={reviewTimelineData.employeeSelfEvaluationDueDate}
            onChange={handleChange}
            className='timeLineDate'
            error={!!errors.employeeSelfEvaluationDueDate}
            helperText={errors.employeeSelfEvaluationDueDate}
          />
          <InputLabel>Employee Manager Evaluation Due Date *</InputLabel>
          <TextField
            name="employeeManagerEvaluationDueDate"
            type="date"
            value={reviewTimelineData.employeeManagerEvaluationDueDate}
            onChange={handleChange}
            className='timeLineDate'
            error={!!errors.employeeManagerEvaluationDueDate}
            helperText={errors.employeeManagerEvaluationDueDate}
          />
          <InputLabel>Review Communication Due Date *</InputLabel>
          <TextField
            name="reviewCommunicationDueDate"
            type="date"
            value={reviewTimelineData.reviewCommunicationDueDate}
            onChange={handleChange}
            className='timeLineDate'
            error={!!errors.reviewCommunicationDueDate}
            helperText={errors.reviewCommunicationDueDate}
          />
          <InputLabel>Appeal Date *</InputLabel>
          <TextField
            name="appealDate"
            type="date"
            value={reviewTimelineData.appealDate}
            onChange={handleChange}
            className='timeLineDate'
            error={!!errors.appealDate}
            helperText={errors.appealDate}
          />
          <InputLabel>Sign Date *</InputLabel>
          <TextField
            name="signDate"
            type="date"
            value={reviewTimelineData.signDate}
            onChange={handleChange}
            className='timeLineDate'
            error={!!errors.signDate}
            helperText={errors.signDate}
          />
          <InputLabel>Auto Sign Date *</InputLabel>
          <TextField
            name="autoSignDate"
            type="date"
            value={reviewTimelineData.autoSignDate}
            onChange={handleChange}
            className='timeLineDate'
            error={!!errors.autoSignDate}
            helperText={errors.autoSignDate}
          />
      <div className='PRCButtons'>
        <Button variant='outlined' onClick={handlePrevious} className='SecondaryButton'>Previous</Button>
        <Button variant="contained" onClick={handleNext} className='PrimaryButton'>Save & Next</Button>
      </div>
    </div>
  );
}
