import * as React from "react";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from 'react-router-dom';
import {navData} from '../lib/navData';
import Hrhookslogo from '../assets/logo.png';
import './TopSection.css';
import { useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

import {
  Grid,
} from '@mui/material';

const ResponsiveDrawer = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

const drawer = (
    <div>
      <Toolbar className="Logo">
        <img src={Hrhookslogo} alt="HrHooks Logo"/>
      </Toolbar>
      <Divider />
      <List>
        {navData.map((item, index) => (
          <NavLink to={item.link} key={index}>
          <ListItem key={index} disablePadding>
            <ListItemButton className="Navbar-menu">
              <ListItemIcon className="Navbar-icons">
                {item.icon}
              </ListItemIcon>
              <ListItemText className="Navbar-text" primary={item.text}/>
            </ListItemButton>
          </ListItem>
          </NavLink>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
      <Outlet/> 
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func
};

export default ResponsiveDrawer;
