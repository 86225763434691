import React, { useEffect, useState } from 'react'
import { Button, Grid } from '@mui/material';
import { getYourPerfReviews } from 'Services/PerformanceReviewService';
import NoRecord from 'utils/NoRecord';
import { useNavigate } from 'react-router-dom';

export default function PerfReviewOfEmployee({ review }) {
  const [allReviews, setAllReviews] = useState([]);
  const navigate = useNavigate();

  const getYourPerfReview = () => {
    getYourPerfReviews().then((res) => {
      const results = res.data;
      setAllReviews(results);
    });
  }

  const handleView = (id) =>{
    navigate("/performance-review-list/"+id+"/"+review)
  }
 
  useEffect(() => {
    getYourPerfReview();
  }, []);
  return (
    <>
      <div className='reviewsContainer'>
        {
          allReviews.length == 0 ?
            <Grid item xs={12} className='no-data'>
              <NoRecord/>
            </Grid>
            :
            allReviews.map((rev, i) => (
              <Grid container className='grid-container'>
                <Grid item xs={6}>
                <h3>{rev.name}</h3>
                </Grid>
                <Grid item xs={4} className='grid-item'>
                <h5>Due Date :{rev.endDate}</h5>
                </Grid>
                <Grid item xs={2}>
                  <Button onClick={()=>handleView(rev.id)} className='SecondaryButton'>View</Button>
                </Grid>
              </Grid>
            ))
        }
      </div>
    </>
  )
}
