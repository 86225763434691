import React, { useState } from 'react';
import './KrWeightage.css';
import { addKrWeightage } from 'Services/Goalservice';

export default function KrWeightage({ onCancel, goalDetail, updateGoal }) {
  const [inputValues, setInputValues] = useState(goalDetail.keyResultResponseList.map((krItem) => krItem.krWeightage));
  const [errors, setErrors] = useState([]);
  const [sumErrorMessage, setSumErrorMessage] = useState('');

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const handleSaveClick = () => {
    const inputSum = inputValues.reduce((sum, value) => sum + parseFloat(value) || 0, 0);

    const isAllFieldsFilled = inputValues.every(value => value !== '');

    if (isAllFieldsFilled && inputSum <= 100) {
      setErrors([]);
      setSumErrorMessage('');
      const apiData = goalDetail.keyResultResponseList.map((krItem, index) => ({
        id: krItem.id,
        alignmentId: goalDetail.goalId,
        type: krItem.type,
        value: parseFloat(inputValues[index]) || 0,
      }));

      addKrWeightage(apiData).then((res) => {
        console.log("ass", res.data);
        onCancel();
        updateGoal();
      })

    } else {
      const errorArray = goalDetail.keyResultResponseList.map((krItem, index) => {
        const inputValue = parseFloat(inputValues[index]) || 0;
        return {
          id: krItem.id,
          value: inputValue,
          error: inputValue < 0 || inputValue > 100 || inputValues[index] === '',
        };
      });

      setErrors(errorArray);

      if (inputSum > 100) {
        setSumErrorMessage('Sum of all values should be less than or equal to 100');
      }
    }
  };
  console.log("adsad", goalDetail);
  return (
    <div className='krweightage'>
      <h1>Add Krs Weightage</h1>
      {goalDetail.keyResultResponseList.map((krItem, index) => (
        <div key={index} className='krwContent'>
          {
            krItem.type == "GOAL" ? <h5>GOAL</h5> : <h6>KR</h6>
          }
          <div className='krwItem'>
            <h1>{krItem.title}</h1>
            <h2><span>Due :</span> {krItem.dueDate}</h2>
          </div>
          <div className='krInputItem'>
            <input
              type="number"
              value={inputValues[index]}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
            {errors[index]?.error && <p className="errorMessage">Incorrect Value(0 to 100)</p>}
          </div>
        </div>
      ))}
      {sumErrorMessage && <p className="errorMessage">{sumErrorMessage}</p>}
      <div className='horizontalLine'></div>
      <div className="goalAlignmentButtons">
        <button onClick={() => onCancel()} className="newKrCancelButton" variant="contained">Cancel</button>
        <button onClick={handleSaveClick} className="PrimaryButton" variant="contained">Save</button>
      </div>
    </div>
  );
}