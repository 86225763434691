import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./Tree.css"
import Summary from "./Summary";
import Alignment from "./Alignment"
import KeyResult from "./KeyResults"
import GoalAudit from "./GoalAudit"
import GoalProgress from "./GoalProgress"

import { getGoalDetails } from 'Services/Goalservice';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

export default function GoalDetails() {
  const { goalId } = useParams();

  const [value, setValue] = React.useState(-1);

  const [goalDetails, setGoalDetails] = React.useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    getGoalDetails(goalId).then((res) => {
      let results = res.data;
      if (results !=null ) {
        setGoalDetails(results);
        setValue(0);
      }

      });
}, []);

useEffect(()=>{
        getGoalDetails(goalId).then((res) => {
          let results = res.data;
          if (results !=null ) {
            setGoalDetails(results);
          }
          });
},[(value==3)])

  return (
    <Box className="fetchGoal">
     <h2 className="goalsHeading"> { !!goalDetails ? goalDetails.title : ""}</h2>
      <AppBar position="static" className="tabSection">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
        >
          <Tab label="Summary" {...a11yProps(0)}  className="tabs"/>
          <Tab label="Alignment" {...a11yProps(1)} className="tabs" />
          <Tab label="Progress" {...a11yProps(2)} className="tabs"/>
          <Tab label="Key Result" {...a11yProps(3)} className="tabs" />
        </Tabs>
      </AppBar>
        <TabPanel value={value} index={0}>
          <Summary goalDetails ={goalDetails} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Alignment goalDetails ={goalDetails} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GoalProgress goalDetails ={goalDetails} />
        </TabPanel>
        <TabPanel value={value} index={3}>
            <KeyResult goalDetails ={goalDetails} />
        </TabPanel>
        <TabPanel value={value} index={4}>
            <GoalAudit goalDetails ={goalDetails} />
        </TabPanel>
    </Box>
  );
}
