import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { NavLink, useLocation } from 'react-router-dom';
import {navData} from '../lib/navData';
import Hrhookslogo from '../assets/logo.png';
import './TopSection.css';
import { Outlet } from 'react-router-dom';
import TopSection from "./TopSection";
import AvatarBuilder from "utils/AvatarBuilder";
import { Popover } from "@mui/material";
import UserDetails from "./UserDetails";
import { getEmployee } from "Services/OrgService";
import { useState } from "react";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

const drawerWidth = 68;



const ResponsiveDrawer = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // 
  const location = useLocation();


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [userDetails, setUserDetails] = useState({});

  const getUserDetails = () => {

    let orgId = localStorage.getItem('orgId');
    let employeeId = localStorage.getItem('employeeId');
    if(orgId && employeeId && employeeId > 0 && orgId > 0){
      getEmployee().then((res) => {
        let results = res.data;
        if (results && results !=null ) {
          setUserDetails(results);
        }
        });
    }
    }

    useEffect(() => {
      getUserDetails();
  }, []);

  const [topHeading, setTopHeading] = useState("OKR Insight")

const drawer = (
    <div className="sideBarMenu">
      <Toolbar className="Logo">
        <img src={Hrhookslogo} alt="HrHooks Logo"/>
      </Toolbar>
      <List>
        {navData.map((item, index) => (
          <NavLink to={item.link} key={index}
          className="active-navlink"
          >
          <ListItem key={index} disablePadding className={`navcontent ${location.pathname === item.link ? "active" : ""}`}>
          <Tooltip title={item.name} placement="right">
            <ListItemButton className="Navbar-menu">
              <ListItemIcon className="Navbar-icons">
                <img src={location.pathname === item.link ? item.activeIcon : item.icon} 
                  alt="Personal Icon"
                  />
              </ListItemIcon>
            </ListItemButton>
            </Tooltip>
          </ListItem>
          </NavLink>
        ))}
      </List>

      <div className="avatar-container">
      <div onClick={handleClick} className="avatarBuilder">
        <AvatarBuilder employee={{ name: userDetails.name }} variant="circular" size="small" />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserDetails userDetails={userDetails} />
      </Popover>
    </div>

    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box className="navbar-box">
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor:'var(--white-color)',
          boxShadow:"1px"
        }}
      >
        <Toolbar>
          <IconButton
            color="var(--black-color)"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
          <MenuIcon className="menu-icon"/>
          </IconButton>
          <TopSection/>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        className="sidebarDrawer"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor:'var(--sidebar-color)'
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor:'var(--sidebar-color)'
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Outlet/>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func
};

export default ResponsiveDrawer;
