import React from 'react'
import AuthLeftContent from './AuthLeftContent'
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, FormControl, FormHelperText, OutlinedInput, TextField, MenuItem} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error'
import { headCounts } from 'Constants/EmpConstants';
import { createOrg } from 'Services/OrgService';
import errorAuthIcon from "../../assets/images/ErrorAuth.svg"
import refreshFeatures from 'utils/PaymentUtils';

export default function RegisterDetails({ ...others }) {
    const navigate = useNavigate();

    const redirectToHome = () => {
        navigate("/home");
    }

    useEffect(() => {
    }, []);

    return (
        <div className='authentication'>
            <AuthLeftContent />
            <div className='authLoginRightContainer'>
                <h1>🎉 You are almost there!</h1>
                <h2>Help us to know more about you for better service.</h2>
                <h4>Basic information</h4>
                <Formik
                    initialValues={{
                        userName: '',
                        companyName: '',
                        phoneNo: '',
                        employeeCountRange: '',
                    }}
                    validationSchema={Yup.object().shape({
                        userName: Yup.string().max(255).required('User name is required'),
                        companyName: Yup.string().max(255).required('Organization name is required'),
                        phoneNo: Yup.number().required('Phone number is required'),
                        employeeCountRange: Yup.string().max(255).required('Organization head count is required'),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        createOrg(values).then((res) => {
                            let results = res.data;
                            if (results != null) {
                                localStorage.setItem('token', results.token);
                                localStorage.setItem('userRole', results.role);
                                localStorage.setItem('orgId', results.orgId);
                                localStorage.setItem('employeeId', results.empId);
                                localStorage.setItem('showDemo', results.isDemoEnabled);
                                refreshFeatures();

                                setStatus({ success: true });
                                setSubmitting(false);
                                redirectToHome();
                            } else {
                                setStatus({ success: false });
                                setErrors({ submit: !!results.detail ? results.detail : "Something went wrong. Please try again." });
                                setSubmitting(false);
                            }
                        });



                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} {...others} className='authForm'>
                            <ConnectedFocusError />
                            <FormControl fullWidth error={Boolean(touched.userName && errors.userName)}>
                                <OutlinedInput
                                    type="text"
                                    value={values.userName}
                                    name="userName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    className={`authInput ${touched.userName ? 'errorAuthInput' : ''}`}
                                    placeholder='User Name'
                                />
                                {touched.userName && errors.userName && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon}/>{errors.userName}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl fullWidth error={Boolean(touched.companyName && errors.companyName)}>
                                <OutlinedInput
                                    type="text"
                                    value={values.companyName}
                                    name="companyName"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    className={`authInput ${touched.companyName ? 'errorAuthInput' : ''}`}
                                    placeholder='Company Name'
                                />
                                {touched.companyName && errors.companyName && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon}/>{errors.companyName}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl fullWidth error={Boolean(touched.phoneNo && errors.phoneNo)}>
                                <OutlinedInput
                                    type="number"
                                    value={values.phoneNo}
                                    name="phoneNo"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    className={`authInput ${touched.phoneNo ? 'errorAuthInput' : ''}`}
                                    placeholder='Phone Number'
                                />
                                {touched.phoneNo && errors.phoneNo && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon}/>{errors.phoneNo}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl fullWidth error={Boolean(touched.employeeCountRange && errors.employeeCountRange)}>
                                <TextField 
                                    defaultValue=""
                                    fullWidth 
                                    autoComplete="none" 
                                    select
                                    onChange={handleChange} 
                                    name="employeeCountRange"
                                    value={values.employeeCountRange} 
                                    required 
                                    inputProps={{}}
                                    placeholder='Employee Count'
                                    className='authInputSelect'
                                >
                                    {headCounts.map((option) => (
                                        <MenuItem key={option.id} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {touched.employeeCountRange && errors.employeeCountRange && (
                                    <FormHelperText error className='errorAuthText'>
                                        <img src={errorAuthIcon}/> {errors.employeeCountRange}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {errors.submit && (
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            )}
                            <Button
                                disableElevation
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                className='authButton'
                            >
                                Get started
                            </Button>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
