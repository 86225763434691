import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TreeItem from "@mui/lab/TreeItem";
import "./Summary.css"
import { createGoal, deleteKeyInitiatives, deleteKeyResult } from 'Services/Goalservice';
import SliderProgressBar from "Pages/Home/SliderProgressBar";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import UpdateKeyResult from "./UpdateKeyResult";
import Modal from '@mui/material/Modal';
import AvatarBuilder from "utils/AvatarBuilder";
import { getGoalDetails } from 'Services/Goalservice';
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getKeyInitiatives } from "Services/Goalservice";
import UpdateKeyInitiatives from "./UpdateKeyInitiatives";
import {useNavigate } from "react-router-dom";
import CheckInMenu from "Components/CheckInMenu";
import AddNewKeyResult from "Components/AddNewKeyResult";
import AddNewKeyInit from "Components/AddNewKeyInit";
import { ToastError, ToastSuccess } from "utils/Toast";
import { toast } from "react-toastify";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { PROGRESSTYPE } from "Constants/Styles";

export default function KeyResult(props) {
  const navigate = useNavigate();
  const [goalDatas, setGoalDatas] = useState(props.goalDetails);
  const [expanded, setExpanded] = React.useState([])
  const loadKRS = () => {
    getGoalDetails(goalDatas.goalId).then((res) => {
      let results = res.data;
      if (results != null) {
        setGoalDatas(results);
      }

    });
  }
  const handleUpdateCloseKR = () => {
    setAddNewKr(false)
    loadKRS()
  }

  const setProgress = (goalId, data, krProgress, krId) => {
    if (data.goalId === goalId) {
      for (let j = 0; j < data.keyResultResponseList.length; j++) {
        if (data.keyResultResponseList[j].id == krId) {
          data.keyResultResponseList[j].krProgress = krProgress
        }
      }
    }
    setGoalDatas(data)
  };

  const getGoal = (goalId, krId) => {
    if (goalId != "") {
      getGoalDetails(goalDatas.goalId).then((res) => {
        let results = res.data;
        if (results && results != null) {
          let krProgress = 0;
          if (res.data.goalId === goalId) {
            for (let j = 0; j < res.data.keyResultResponseList.length; j++) {
              if (res.data.keyResultResponseList[j].id == krId) {
                krProgress = res.data.keyResultResponseList[j].krProgress
              }
            }
            setProgress(goalId, results, krProgress, krId)
          }
        }
      })
    }
  }
  const temp = {
    title: "",
    assignee: "",
    progressType: PROGRESSTYPE.PERCENTAGE,
    progressStartValue: "",
    progressEndValue: "",
    keyValueStatus: "",
    completeddate: "",
    keyValueStatus: "",
    progress: "",
    id: "",
    goalId: -1
  }

  const kinit = {
    title: "",
    assignee: "",
    progressType: PROGRESSTYPE.PERCENTAGE,
    progressStartValue: "",
    progressEndValue: "",
    currentProgress: "",
    id: "",
    keyResultId: -1,
    goalId: -1,
    startDate: "",
    dueDate: ""
  }

  const [newKeyInit, setNewKeyInit] = useState(kinit)

  const [newKeyResult, setNewKeyResult] = useState(temp)

  const gettingKeyInit = (goalId, keyResultId) => {
    for (let j = 0; j < goalDatas.keyResultResponseList.length; j++) {
      if (goalDatas.keyResultResponseList[j].id == keyResultId) {
        getKeyInitiatives(keyResultId).then((res) => {
          let results = res.data;
          if (results && results !== null) {
            goalDatas.keyResultResponseList[j].keyInitResponseList = results;
            setGoalDatas(goalDatas)
          }
        });

      }
    }
  }

  const handleClickTreeItem = (event, keyResultID, goalId) => {
    gettingKeyInit(goalId, keyResultID);
  };

  const loadKIS = (KRID) => {
    getKeyInitiatives(KRID).then((res) => {
      let results = res.data;
      if (results && results !== null) {
        for (let j = 0; j < goalDatas.keyResultResponseList.length; j++) {
          if (goalDatas.keyResultResponseList[j].id == KRID) {
            goalDatas.keyResultResponseList[j].keyInitResponseList = results;
          }
        }
      }

    });
  }
  const updateKIList = (keyResultId, results) => {
    for (let j = 0; j < goalDatas.keyResultResponseList.length; j++) {
      if (goalDatas.keyResultResponseList[j].id == keyResultId) {
        goalDatas.keyResultResponseList[j].keyInitResponseList = results;
      }
    }
  }
  const getKI = (KRID) => {
    getKeyInitiatives(KRID).then((res) => {
      let results = res.data;
      if (results && results !== null) {
        updateKIList(KRID, results);
      }
    });
  }

  const redirectToGoals = () => {
    navigate("/goals/" + Date.now());
  }

  const [addNewKr, setAddNewKr] = useState(false);
  const [addNewKi, setAddNewKi] = useState(false);
  const [newGoalAssociate, setNewGoalAssociate] = useState([]);
  const handleAddGoalAssociate = (newGoal) => {
    createGoal(newGoal).then((res) => {
      let results = res.data;
      if (results) {
        toast.success("Goal created successfully",{
          position: "bottom-left",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        })
        setNewGoalAssociate(newGoal)
        redirectToGoals();
      }
    })
  };

  const navigateGoalAssociate = useNavigate();

  const redirectGoalAssociateViewDetails = (goalId) => {
    navigateGoalAssociate("/goal-details/" + goalId);
    window.location.reload();
  }

  return (
    <div>
      <Box sx={{ '& > :not(style)': { m: 1, width: '1' } }}>
        <Grid container spacing={1.5} className="summaryContent keyresultContent" >
          {Array.isArray(goalDatas.keyResultResponseList)
            ? goalDatas.keyResultResponseList.map((node, i) => (
              (node === "") ? <></> :
                <TreeView
                  aria-label="rich object"
                  defaultCollapseIcon={<ExpandMoreIcon className="expand-collaps-icon" />}
                  expanded={expanded}
                  onNodeToggle={(event, nodeIds) => {
                    setExpanded([...nodeIds])
                  }}
                  defaultExpandIcon={<ChevronRightIcon className="expand-collaps-icon" />}
                  className="keyInitTree"
                >
                  <TreeItem key={String(node.id)} nodeId={String(node.id)} onClick={(event) => handleClickTreeItem(event, node.id, node.goalId)} label={
                    <Grid item xs={10} mb={1} >
                      <div className="data-content keyresult-datacontent">
                        <div className="first-content">
                          <h4><span>KR</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{node.title}</h4>
                        </div>
                        <Button onClick={(event) => { event.stopPropagation() ; redirectGoalAssociateViewDetails(node.associateGoalId)}}>{node.associateGoalId ? <h6 className="AssociateGoalButton">Associated Goal</h6> : null}</Button>
                        <div className="second-content">
                          <AvatarBuilder employee={{ 'name': node.assigneeDetails ? node.assigneeDetails.empName : "" }} variant="circular" size="profile-img" />
                          <div className="progressbar-data" onClick={(event) => event.stopPropagation()}>
                            <SliderProgressBar krId={node.id} progressEndValue={node.progressEndValue} progressStartValue={node.progressStartValue} progressType={node.progressType} goalId={node.goalId} progressPercentage={node.krProgress} progress={node.progress} updateGoalProgressBar={getGoal} status={node.keyValueStatus}/>
                            <div className="keyResultContentMenu">
                              <CheckInMenu node={node} />
                              <KeyResultMenu krId={node.id} krData={node} loadKRS={loadKRS} goalId={node.goalId} goalData={goalDatas} handleAddGoalAssociate={handleAddGoalAssociate} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  }
                    className="tree keyInitTree"
                  >
                    {
                      Array.isArray(goalDatas.keyResultResponseList)
                        ? goalDatas.keyResultResponseList.map((KrNode, i) => (
                          KrNode.keyInitResponseList && Array.isArray(KrNode.keyInitResponseList)
                            ? KrNode.keyInitResponseList.map((KiNode) => (
                              KiNode.keyResultId == node.id // Add this condition to check if keyResultId matches krId
                                ? (
                                  <div className="data-content keyresult-datacontent keyinit-datacontent">
                                    <div className="first-content child-content">
                                      <h4><span>KI</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{KiNode.title}</h4>
                                    </div>
                                    <div className="second-content keyinit-second-content">
                                      <AvatarBuilder employee={{ 'name': KiNode.assigneeDetails ? KiNode.assigneeDetails.empName : " " }} variant="circular" size="profile-img" />
                                      <div className="progressbar-data">
                                        <SliderProgressBar progressEndValue={KiNode.progressEndValue} progressStartValue={KiNode.progressStartValue} progressType={KiNode.progressType} krId={KiNode.keyResultId} kiId={KiNode.id} goalId={KiNode.goalId} progress={KiNode.progress} progressPercentage={KiNode.keyInitiativeProgress} status={KiNode.keyValueStatus} loadKIS={(kiId)=>getKI(kiId)}/>
                                        <div className="keyResultContentMenu">
                                          <CheckInMenu node={KiNode} />
                                          <KeyInitMenu kiId={KiNode.id} data={KiNode} loadKIS={() => loadKIS(KiNode.keyResultId)} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                                : null
                            ))
                            : null
                        ))
                        : null
                    }
                    {newKeyInit && (newKeyInit.keyResultId === node.id) ? (
                      <AddNewKeyInit newKeyInit={newKeyInit} setNewKeyInit={setNewKeyInit} updateKI={() => getKI(node.id)} />
                    ) : null}
                    <div className="KeyInitButtons">
                    <button
                      onClick={() => {
                        setAddNewKi(true);
                        let newKI = kinit;
                        newKI.keyResultId = node.id;
                        setNewKeyInit(newKI)
                      }}
                      className="data-button"
                    >
                      <div className="add-key-button">
                        <AddIcon className="Add-key-result-icon" />
                        <h5>Add a Key Initiative</h5>
                      </div>
                    </button>
                    </div>
                  </TreeItem>
                </TreeView>
            ))
            : null}
          {addNewKr && newKeyResult.goalId == goalDatas.goalId ? (
            <AddNewKeyResult newKeyResult={newKeyResult} updateGoalProgress={getGoal} setNewKeyResult={setNewKeyResult} updateKR={handleUpdateCloseKR} />
          ) : null}
          <Button
            onClick={() => {
              setAddNewKr(true);
              let newKey = temp;
              newKey.goalId = goalDatas.goalId;
              setNewKeyResult(newKey)
            }}
            className="addNewKeyResult"
          >
            <div className="add-key-button">
              <AddIcon className="Add-key-result-icon" />
              <h5>Add new Key Result</h5>
            </div>
          </Button>
        </Grid>
      </Box>
    </div>
  )
}


function KeyResultMenu({ krId, loadKRS, krData, goalId, goalData, handleAddGoalAssociate }) {

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [updateKR, setUpdateKR] = useState(false);
  const handleUpdateOpen = () => setUpdateKR(true);
  const handleUpdateClose = () => {
    setUpdateKR(false);
    loadKRS();
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirmed = () => {
    deleteKeyResult(krData.goalId, krId)
    .then((res) => {
      loadKRS();
      ToastSuccess({ text: "Key Result Deleted successfully" });
    }).catch((error) => {
      ToastError({ text: "Error while Deleting keyResult" });
    });
    handleDeleteClose();
  };


  const handleNewGoalAssociate = () => {
    const newAssociate = {
      goalName:krData.title,
      description:goalData.description,
      goalLevel:goalData.goalLevel,
      teamId:goalData.teamId,
      alignedId:goalData.alignedId,
      alignedType:goalData.alignedType,

      goalTimeline: {
        goalCycle: goalData.goalTimeline.goalCycle,
        dueDate: goalData.goalTimeline.dueDate,
        startDate: goalData.goalTimeline.startDate
      },
      progress: {
        progressType: krData.progressType,
        startValue: krData.progressStartValue,
        targetValue: krData.progressEndValue,
      },
      visibility:goalData.visibility,
      goalProgressUpdateType: goalData.goalProgressUpdateType,
      associatedKrId:krId
    };

    let goalOwners = [];
    for (let i = 0; i < krData.ownerDetails.length; i++) {
        goalOwners.push(krData.ownerDetails[i].employeeId);
    }

    newAssociate.goalOwners = goalOwners;

    handleAddGoalAssociate(newAssociate);
    handleCloseMenu();

  };

  return (
    <div className="menuitems">
      <Button id={krId}
        aria-controls={openMenu ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClickMenu}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id={krId}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={function (event) { handleNewGoalAssociate(); handleCloseMenu() }}>Add Goal Associate</MenuItem>
        <MenuItem onClick={function (event) { handleUpdateOpen(krId); handleCloseMenu() }} >Edit KeyResult </MenuItem>
        <MenuItem onClick={function (event) { handleDeleteOpen(); handleCloseMenu() }} >Delete KeyResults</MenuItem>
      </Menu>

      <Modal
        open={updateKR}
        onClose={handleUpdateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpdateKeyResult krDetails={krData} krId={krId} close={handleUpdateClose} loadKRS={loadKRS}/>
      </Modal>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
      <DialogTitle id="responsive-dialog-title">
          <h2>DELETE KEY RESULT</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this KEY RESULT
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

function KeyInitMenu({ kiId, data, loadKIS }) {

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [updateKI, setUpdateKI] = useState(false);
  const handleUpdateOpen = () => setUpdateKI(true);
  const handleKIUpdateClose = () => {
    setUpdateKI(false);
    loadKIS();
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteConfirmed = () => {
    deleteKeyInitiatives(data.keyResultId, kiId)
    .then((res) => {
      loadKIS();
      ToastSuccess({ text: "Key Initiative Deleted successfully" });
    }).catch((error) => {
      ToastError({ text: "Error while Deleting key Initiatives" });
    });
    handleDeleteClose();
  };

  return (
    <div className="menuitems">
      <Button id={kiId}
        aria-controls={openMenu ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClickMenu}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id={kiId}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={function (event) { handleUpdateOpen(kiId); handleCloseMenu() }} >Edit Key Initiatives</MenuItem>
        <MenuItem onClick={function (event) { handleDeleteOpen(); handleCloseMenu() }} >Delete Key Initiatives</MenuItem>
      </Menu>

      <Modal
        open={updateKI}
        onClose={handleKIUpdateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpdateKeyInitiatives kiDetails={data} kiId={kiId} close={handleKIUpdateClose} loadKIS={loadKIS}/>
      </Modal>

      <Dialog open={deleteDialogOpen} onClose={handleDeleteClose}>
      <DialogTitle id="responsive-dialog-title">
          <h2>DELETE KEY INITIATIVE</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this KEY INITIATIVE
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}