import React, {useEffect, useState} from 'react';
import { Button, Grid } from '@mui/material';
import { SLACK_APP_BASE_URL, SLACK_CLIENT_ID, SLACK_SCOPES, GOOGLE_SHEET_CLIENT_ID, GOOGLE_SHEET_CLIENT_SECRET, GOOGLE_SHEET_OAUTH_URI, GOOGLE_SHEET_REDIRECT_URI, GOOGLE_SHEET_SCOPE } from 'Constants/IntegrationConstants';
 import './Integration.css';
import { ToastError, ToastSuccess } from 'utils/Toast';
import { getSlackUserLogin, disconnectSlackUser } from 'Services/AuthService';


const orgId = localStorage.getItem("orgId");
const empId = localStorage.getItem("employeeId");

const slackUrl = `${SLACK_APP_BASE_URL}${SLACK_CLIENT_ID}${SLACK_SCOPES}${orgId}-${empId}`;


export default function Integration() {
  const [isSlackUserExist, setIsSlackUserExist] = useState(false)
  

  useEffect(() => {

    getSlackUserLogin({
      orgId:orgId,
      empId:empId
    }).then((res) =>{
      if(res.data.exist){
        setIsSlackUserExist(true)
      }else{
        setIsSlackUserExist(false)
      }
      
    }).catch((err)=>{
      console.log(err);
    })
  
}, []);

const handleDisconnectSlackUser = () => {
    disconnectSlackUser({
      orgId:orgId,
      empId:empId
    }).then((res) =>{
      if(res.data.STATUS=="SUCCESS"){
        ToastSuccess({text: "Successfully disconnected from slack"})
      }
      setIsSlackUserExist(false)
    }).catch(()=>{
      ToastError({text:"Error disconnecting from slack.. Please try again after some time"})
    })
}



const redirectToGoogleSheets = () => {

  const authorizationUrl = GOOGLE_SHEET_OAUTH_URI +
    "client_id=" + GOOGLE_SHEET_CLIENT_ID + "&" +
    "redirect_uri=" + GOOGLE_SHEET_REDIRECT_URI + "&" +
    "scope=" + GOOGLE_SHEET_SCOPE + "&" +
    "state=" + orgId + "-" + empId + "&" +
    "access_type="+"offline" + "&" +
    "prompt="+"consent"+ "&"+
    "response_type=code";

    window.location.href = authorizationUrl;

}

  return (
    <div className="centered-container"> 
      <Grid container justifyContent="center" alignItems="center">

        {
          isSlackUserExist ? 
          <>
          <Button
          className="PrimaryButton"
          onClick={handleDisconnectSlackUser}
          >Disconnect from slack</Button>
          </> : 
          <>
          <h1>{"Add to Slack -->"}</h1>
          <a
            target="_self"
            className="c-link"
            href={slackUrl}
            rel="noopener noreferrer"
          >
            <img style={{marginLeft : "20px"}}
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a></>
        }
      </Grid>
      <Grid container justifyContent="center" alignItems="center" className='google-sheet'>
      <h1>{"Add Google sheets -->"}</h1>
      <Button variant="contained" onClick={redirectToGoogleSheets}>Integrate Google sheets</Button>
      </Grid>
    </div>
  );
}

