import React, { useEffect } from 'react'
import { Button } from '@mui/material';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

export default function UploadDetails({addEmployeeClose, handleSetActiveStep,setNewEmployee, activeStep , onClose, importEmpData}) {

    const handleNext = () => {
        const nextStep = Math.min(activeStep + 1, 2);
        handleSetActiveStep(nextStep);
        onClose();
        addEmployeeClose();
        setNewEmployee(true); 
    };

    const handleDownloadRecords = () => {
        const errorData = importEmpData.error;
        const csvData = convertToCSV(errorData);

        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Added_Employee_Records.csv');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    useEffect(() =>{
        if(importEmpData){
            handleDownloadRecords();
        }
    },[(importEmpData != "")])

    return (
        <div className='importSummary'>

            <div className='uploadCSV'>
                <RecentActorsIcon />

                <h2>Import Successful</h2>
                <p>🚀 Your data has been seamlessly imported.</p>

                <label htmlFor="fileInput">
                    <Button component="span" className='SecondaryButton uploadCSVButton' onClick={handleNext}>
                        View Records
                    </Button>
                </label>
                <p>[ Click "View Records" to see the details ]</p> 
            </div>

            <div className='recordDetails'>
            <div className='recordCounts'>
                <h2>Number of Successful Imports</h2>
                <h3>{importEmpData.successCount}</h3>
            </div>
            <div className='recordCounts'>
                <h2>Number of Errors Encountered</h2>
                <h3>{importEmpData.errorCount}</h3>
            </div>
            </div>

            <div className='PRCButtons importCSVNextButton'>
                <Button variant="contained" onClick={handleNext} className='PrimaryButton'>Done</Button>
            </div>
        </div>
    )
}


function convertToCSV(data) {
    const header = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(obj => Object.values(obj).map(val => {
        return typeof val === 'string' ? decodeURIComponent(val) : val;
    }).join(',')).join('\n');
    return header + rows;
}