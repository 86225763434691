import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import "./PerformanceReviewCycle.css";
import InputLabel from '@mui/material/InputLabel';

export default function PerformanceCycle({ handleSetActiveStep, activeStep, handleSaveDataPRC, data }) {
  const [reviewCycleData, setReviewCycleData] = useState(data);
  const [errors, setErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const validateFields = () => {
    const newErrors = {};
    if (!reviewCycleData.name) {
      newErrors.name = "Name is required";
    }

    if (!reviewCycleData.description) {
      newErrors.description = "Description is required";
    }

    if (!reviewCycleData.startDate) {
      newErrors.startDate = "Start Date is required";
    }

    if (!reviewCycleData.endDate) {
      newErrors.endDate = "End Date is required";
    }

    if (reviewCycleData.startDate && reviewCycleData.endDate) {
      const startDate = new Date(reviewCycleData.startDate);
      const endDate = new Date(reviewCycleData.endDate);
  
      if (startDate > endDate) {
        newErrors.startDate = "Start Date must be before End Date";
        newErrors.endDate = "End Date must be after Start Date";
      }
    }
  

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setReviewCycleData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setReviewCycleData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleNext = () => {
    setIsFormSubmitted(true);
    const isValid = validateFields();
    if (isValid) {
      const nextStep = Math.min(activeStep + 1, 4);
      handleSetActiveStep(nextStep);
      handleSaveDataPRC(reviewCycleData);
    }
  };

  const handlePrevious = () => {
    const prevStep = Math.max(activeStep - 1, 0);
    handleSetActiveStep(prevStep);
  };

  useEffect(() => {
    if(isFormSubmitted){
      validateFields();
    }
  }, [reviewCycleData]);

  return (
    <>
      <div className='PRCContent'>
        <InputLabel>PR Cycle Name *</InputLabel>
        <TextField
          name="name"
          className='prName'
          value={reviewCycleData.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <InputLabel>Description *</InputLabel>
        <TextField
          name="description"
          value={reviewCycleData.description}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
          error={!!errors.description}
          helperText={errors.description}
        />
        <div className='PRCDate'>
          <div>
            <InputLabel>Start Date *</InputLabel>
            <TextField
              name="startDate"
              type="date"
              value={reviewCycleData.startDate}
              onChange={handleChange}
              fullWidth
              error={!!errors.startDate}
              helperText={errors.startDate}
            />
          </div>
          <div>
            <InputLabel>End Date *</InputLabel>
            <TextField
              name="endDate"
              type="date"
              value={reviewCycleData.endDate}
              onChange={handleChange}
              fullWidth
              error={!!errors.endDate}
              helperText={errors.endDate}
            />
          </div>
        </div>
        <FormControlLabel
          control={<Checkbox name="isEnablePeerReview" checked={reviewCycleData.isEnablePeerReview} onChange={handleChange} className='boxIcon' />}
          label="Is EnablePeerReview"
        />
      </div>
      <div className='PRCButtons'>
        <Button variant='outlined' onClick={handlePrevious} className='SecondaryButton'>Previous</Button>
        <Button variant="contained" onClick={handleNext} className='PrimaryButton'>Save & Next</Button>
      </div>
    </>
  );
}
