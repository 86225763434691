import { slackUserIntegration } from 'Services/AuthService';
import React, {useEffect} from 'react'
import { useLocation } from "react-router-dom";
import { ToastError, ToastSuccess } from './Toast';
import { useNavigate } from "react-router-dom";


function SlackIntegration() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    if(queryParams.has("error")){
      navigate('/setting/integration');
    }
  
    useEffect(() => {
        const handleIntegration = async () => {
          try {
            await slackUserIntegration({
              code: code,
              state: state,
            }).then((res)=>{
              if(res.data == "SUCCESS"){
                ToastSuccess({ text: 'Slack Integration Done Successfully.. Please try commands in slack' });
              }else{
                ToastSuccess({ text: "Problem with slack side..Please try again after some time" });
              }
            })
    
          } catch (error) {
            ToastError({ text: 'Failed to integrate slack.. Please try again later' });
          } finally {
            navigate('/setting/integration');
          }
        };
    
        if (code && state) {
          handleIntegration();
        }
      }, [code, state]);
    


  return (
    <>
    </>
  )
}

export default SlackIntegration