import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { InputLabel } from '@mui/material';
import "./Questions.css";
import { PERF_REVIEW_QUESTION_TYPE, PERF_REVIEW_TYPE, PERFORMANCE_STEPPERS } from 'Constants/Styles';

const TextQuestion = ({ onClose, onQuestionSave,initialData, activeStep,reviewId }) => {
  const isEditMode = !!initialData;
  const [showReviewerFields, setShowReviewerFields] = useState(isEditMode ? Boolean(initialData.reviewerQuestion) : true);
  const [self, setSelf] = useState(isEditMode ? initialData.selfQuestion : '');
  const [reviewer, setReviewer] = useState(isEditMode ? initialData.reviewerQuestion || '' : '');
  const [empToMng, setEmpToMng] = useState(isEditMode ? initialData.empToManagerQuestion : '');
  const [peer, setPeer] = useState(isEditMode ? initialData.peerQuestion : '');
  const [empToMngError, setEmpToMngError] = useState('');
  const [selfError, setSelfError] = useState('');
  const [reviewerError, setReviewerError] = useState('');
  const [peerError, setPeerError] = useState('');

  useEffect(() => {
    if (activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER) {
      setSelf(isEditMode ? initialData.selfQuestion : '');
      setReviewer(isEditMode ? initialData.reviewerQuestion || '' : '');
      setShowReviewerFields(isEditMode ? Boolean(initialData.reviewerQuestion) : true);
    } else if (activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER) {
      setEmpToMng(isEditMode ? initialData.empToManagerQuestion : '');
      setPeer('');
    } else if (activeStep === PERFORMANCE_STEPPERS.PEER) {
      setEmpToMng('');
      setPeer(isEditMode ? initialData.peerQuestion : '');
    }
  }, [initialData, activeStep, isEditMode]);

  const handleToggleChange = () => {
    setShowReviewerFields(!showReviewerFields);
  };

  const handleSaveClick = () => {
    if (activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER) {
      if (!self.trim()) {
        setSelfError('Self  Question is mandatory.');
        return;
      } else {
        setSelfError('');
      }
      if (showReviewerFields && !reviewer.trim()) {
        setReviewerError('Reviewer  Question is mandatory.');
        return;
      } else {
        setReviewerError('');
      }
    }
    else if (activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER) {
      if (!empToMng.trim()) {
        setEmpToMngError('empToMng Question is mandatory.');
        return;
      } else {
        setEmpToMngError('');
      }
    } else if (activeStep === PERFORMANCE_STEPPERS.PEER) {
      if (!peer.trim()) {
        setPeerError('peer Question is mandatory.');
        return;
      } else {
        setPeerError('');
      }
    }

    const newQuestionData = {
      selfQuestion: self,
      ...(showReviewerFields
        ? { reviewerQuestion: reviewer }
        : { reviewerQuestion: null }),
      empToManagerQuestion: empToMng,
      peerQuestion: peer,
      answerType: PERF_REVIEW_QUESTION_TYPE.TEXT,
      reviewType: activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? PERF_REVIEW_TYPE.SELF : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? PERF_REVIEW_TYPE.EMPLOYEE_TO_MANAGER : PERF_REVIEW_TYPE.PEER,
      options: null,
      reviewId : reviewId,
      isReviewerQuestionEnabled: activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? showReviewerFields : false
    };

    onQuestionSave(newQuestionData);
    onClose();
  };

  return (
    <div className='questionBox'>
      <h3>{activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? 'Add New Self Question' : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? 'Add New Employee to Manager Question' : 'Add New Peer Question'}</h3>
      <div className='questionForPr'>
        <div>
          <InputLabel>{activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? 'Self Question' : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? 'Employee To Manager Question *' : 'Peer Question *'}</InputLabel>
          <TextField
            variant="outlined"
            value={activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? self : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? empToMng : peer}
            onChange={(e) => activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? setSelf(e.target.value) : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? setEmpToMng(e.target.value) : setPeer(e.target.value)}
            fullWidth
            multiline
            rows={2}
            error={activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? !!selfError : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? !!empToMngError : !!peerError}
            helperText={activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ? selfError : activeStep === PERFORMANCE_STEPPERS.EMPLOYEE_TO_MANAGER ? empToMngError : peerError}
          />
        </div>
        {activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER && showReviewerFields && (
          <div>
            <InputLabel>Reviewer Question *</InputLabel>
            <TextField
              variant="outlined"
              multiline
              rows={2}
              value={reviewer}
              onChange={(e) => setReviewer(e.target.value)}
              fullWidth
              error={!!reviewerError}
              helperText={reviewerError}
            />
          </div>
        )}
      </div>
      {
        activeStep === PERFORMANCE_STEPPERS.SELF_AND_MANAGER ?
          <FormControlLabel
            control={<Switch checked={showReviewerFields} onChange={handleToggleChange} />}
            label="Enable Reviewer Question"
          /> :
          null
      }
      <div className='QuestionButtons'>
        <Button variant="outlined" onClick={onClose} className='SecondaryButton'>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSaveClick} className='PrimaryButton'>
          Save
        </Button>
      </div>
    </div>
  );
};

export default TextQuestion;
