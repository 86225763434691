import React from 'react'

export default function RoleBasedAccess() {
  const role = localStorage.getItem("userRole");

  if(role == "STAFF" || role == "VIEWER"){
    return false;
  }else{
    return true;
  }
}
